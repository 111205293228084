//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//zoho
import { zoho } from 'assets/zoho';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/Select/Select.jsx";
import Button from "components/CustomButtons/Button.jsx";

//kekaha
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/PlaylistAdd";
import Affiliates from "@material-ui/icons/SupervisedUserCircle";
import { CircularProgress } from '@material-ui/core';
import Discount from "@material-ui/icons/MoneyOff";

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class AffiliateEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedCustomer: {contact_id:"",floorPercentage:0},
      addCustomerVisible: false,
      customers:[],
      inProgress: false,
      floorPercentage: 0,
      affiliate: {
        FirstName:"",
        LastName:"",
        Email:"",
        Customers:[],
        commissionType:"margin",
        floorCommission: 0,
        baseCommission: 0,
        parent:""
      },
      affiliates: []
    };

    this.addCustomer = this.addCustomer.bind(this);
    this.chooseCustomer = this.chooseCustomer.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);

    this.saveAffiliate = this.saveAffiliate.bind(this);
    this.updateAffiliate = this.updateAffiliate.bind(this);

    this.refreshCustomerList = this.refreshCustomerList.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  handleClickOpen(modal) {
    if(this.props.affiliate) {
      var affiliate = this.props.affiliate;
      if(!affiliate.Customers)
        affiliate.Customers = [];
      if(!affiliate.commissionType)
        affiliate.commissionType = "margin";
      affiliate.Customers.sort(this.byName);
      this.setState({affiliate: affiliate});
    }

    if(this.props.affiliates) {
      var affiliates = [];
      this.props.affiliates.forEach(affiliate => {
        if(affiliate.id !== this.props.affiliate.id)
          affiliates.push({key: affiliate.id, label: affiliate.FirstName + ' ' + affiliate.LastName})
      })
      this.setState({affiliates: affiliates});
    }

    this.refreshCustomerList();

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  byName(a,b) {

    var x = a.Name;
    var y = b.Name;
    
    if (x < y)
        return -1;
    if (x > y)
        return 1;
    return 0;
  }

  refreshCustomerList() {
    
    var customers = [];

    for(var i = 0; i < this.props.customers.length; i++) {
        var isFound = false;
        for(var j = 0; j < this.state.affiliate.Customers.length; j++) {
            if(this.props.customers[i].contact_id === this.state.affiliate.Customers[j].id) {
                isFound = true;
                break;
            }
        }
        if(!isFound) {
          var customer = this.props.customers[i];
          customer.key = customer.id;
          customer.label = customer.company_name;
          customers.push(this.props.customers[i]);
        }
    }

    this.setState({customers:customers});
  }

  addCustomer() {
    var obj = this.state.affiliate;
    obj.Customers.push({id: this.state.selectedCustomer.contact_id, Name: this.state.selectedCustomer.company_name, FloorPercentage: this.state.floorPercentage });
    
    console.log(obj.Customers);

    this.setState({affiliate: obj, addCustomerVisible: false, floorPercentage: 0}, () => {
        this.refreshCustomerList();
    });
  }

  chooseCustomer(id) {
      for(var i = 0; i < this.props.customers.length; i++) {
          if(this.props.customers[i].contact_id === id) {
              this.setState({selectedCustomer: this.props.customers[i]});
              break;
          }
      }
  }

   deleteCustomer(customer) {
    var user = this.state.affiliate;
    for(var i = 0; i < user.Customers.length; i++) {
      if(user.Customers[i].id === customer.id) {

        try {
          if(user.Customers[i].contactID)
             zoho.deleteZohoContact(user.Customers[i].contactID);
          }
        catch(e) {
          console.log('unable to delete Zoho contact');
          console.log(e);
        }

        console.log('here');
        user.Customers.splice(i,1);
        break;
      }
    }
    
    console.log('and here')
    this.setState({affiliate: user});
    this.refreshCustomerList();
  }

  updateCustomer(customer, property, value) {
    var d = this.state.affiliate;

    for(var i = 0; i < d.Customers.length; i++) {
      if(d.Customers[i].id === customer.id) {
        d.Customers[i][property] = value;
        break;
      }
    }
    this.setState({affiliate: d});
  }

  updateAffiliate(property, value) {
    var d = this.state.affiliate;
    d[property] = value;
    this.setState({affiliate: d});
  }

  async saveAffiliate() {
  
    this.setState({inProgress: true});

    var user = this.state.affiliate;
    user.userType = 'Affiliate';
    
    var contacts = await db.getContacts();
    
    for(var j = 0; j < user.Customers.length; j++) {

      if(!user.Customers[j].contactID) {

        var isFound = false;
        for(var k = 0; k < contacts.length; k++) {

          if(contacts[k].customerID === user.Customers[j].id 
            && contacts[k].email.toLowerCase() === user.Email.toLowerCase() ) {

              user.Customers[j].contactID = contacts[k].id;
              isFound = true;
              //console.log('existing contact found');
              break;
            }
        }

        if(!isFound) {
          //console.log('no contact found, contact being created')
          var contactID = await zoho.createZohoContact(user.Customers[j].id, user);
          user.Customers[j].contactID = contactID;
        }
      }    
    }

    if(!user.id) // assign an id to new users
      user.id = uuidv4();
      
    db.updateUser(user.id, user).then(doc => {
    
      if(this.props.addNew) { // i.e. if it's a new obj

        // reset to blank
        this.setState( { affiliate: {
            FirstName: "", LastName: "", Email:"", Customers: []
        }} );

        this.props.onCreated(user);
      } else {
        this.props.onChanged(user);   
      }

      this.handleClose("modal");
      this.setState({inProgress: false});
    });
  }

  showAddCustomer() {
    if(this.state.addCustomerVisible) {
        return "block";
    } else {
        return "none";
    }
  }

  sendInvitation() {
    console.log('sendInvitation()')
    var user = this.state.affiliate;
    if(!user.id) // assign an id to new users
      user.id = uuidv4();

    this.setState({affiliate: user}, () => {
      this.sendEmail(this.state.affiliate.Email,'https://www.eccjan.com/signup/' + this.state.affiliate.id);  
      this.saveAffiliate();
    }); 
  }

  sendEmail(email, link) {
    console.log('sendEmail()')
    var text = "You are invited to use our new website to quickly and easily place orders on behalf of your customers! Use the following link to sign up: " + link + " Thank you!";
    
    var html = '<html>';
    html = html + 'You are invited to use our new website to quickly and easily place orders on behalf of your customers! Use the following link to sign up:';
    html = html + '<br /><br />';
    html = html + link + ' <br /><br />';
    html = html + 'Thank you!';
    html = html + '<br /><br />';
    html = html + '- The Ecclesia Team';
    html = html + '</html>';

    var subject = "Ecclesia Invitation";
    
    // email
    const json = {
      to: email,
      bcc: 'rbj@eccjan.com',
      subject: subject,
      text: text,
      html: html
    }

    fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail", {
        method: "POST", 
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(json)
      })
      .then((response) => {
          console.log('send email api response')
          console.log(response);
          if(response.ok) {
              return response.json();
          } else {
            console.log("error (1): ");  
            console.log(response);
          }

          //callback();
      })
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error (2):  " + error);
          //callback();
      });
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  showButtons() {
    if(this.state.inProgress) {
      return "none";
    } else {
      return "block";
    }
  }

  showProgressIndicator() {
    if(this.state.inProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  showFloorCommission() {
    if(this.state.affiliate.commissionType === 'margin') {
      return "block";
    } else {
      return "none";
    }
  }

  commissionColumns() {
    if(this.state.affiliate.commissionType === 'margin') {
      return 4;
    } else {
      return 8;
    }
  }

  disableSave() {
    if(this.state.affiliate.Customers.length > 0
      && (this.state.affiliate.commissionType === 'flat' || this.state.affiliate.commissionType === 'margin' )
      && this.state.affiliate.Email.length > 3
      && this.state.affiliate.FirstName.length > 0
      && this.state.affiliate.LastName.length > 0
      ) 
      return false;
    else return true;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title={this.props.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Affiliate</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Affiliates,
                  tabContent: (
                    
                      <GridContainer direction="row" style={{marginLeft:"0px !important"}}>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="First Name"
                            value={this.state.affiliate.FirstName}
                            inputProps={{
                                placeholder: "First Name"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAffiliate("FirstName", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Last Name"
                            value={this.state.affiliate.LastName}
                            inputProps={{
                                placeholder: "Last Name"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAffiliate("LastName", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomSelect
                            labelText="Parent"
                            inputProps={{ placeholder: "Parent" }}
                            formControlProps={{ fullWidth: true }}
                            onChange={(e) => this.updateAffiliate("parent", e.target.value)}
                            options={this.state.affiliates}
                            value={this.state.affiliate.parent}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                              labelText="Email Address"
                              value={this.state.affiliate.Email}
                              inputProps={{
                                  placeholder: "Email Address"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateAffiliate("Email", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <CustomSelect
                            labelText="Commission Type"
                            inputProps={{ placeholder: "Commission Type" }}
                            formControlProps={{ fullWidth: true }}
                            onChange={(e) => this.updateAffiliate("commissionType", e.target.value)}
                            options={[
                              {key:"flat",label:"Gross Sales"},
                              {key:"margin",label:"Margin"}
                            ]}
                            value={this.state.affiliate.commissionType}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} style={{display:this.showFloorCommission()}} >
                          <CustomInput
                              labelText="Floor Commission"
                              value={this.state.affiliate.floorCommission}
                              inputProps={{
                                placeholder: "Percentage",
                                endAdornment: (<InputAdornment position="end"><Discount/></InputAdornment>)
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateAffiliate("floorCommission", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={this.commissionColumns()} >
                          <CustomInput
                              labelText="Commission"
                              value={this.state.affiliate.baseCommission}
                              inputProps={{
                                placeholder: "Percentage",
                                endAdornment: (<InputAdornment position="end"><Discount/></InputAdornment>)
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateAffiliate("baseCommission", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <br />
                          <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding:"3px"}}>
                                        Customer Name
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                        Floor Percentage
                                    </TableCell>
                                    <TableCell style={{padding:"3px", width:"89px"}}>
                                        
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.affiliate.Customers.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{padding:"3px"}}>
                                        {value.Name}
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                        <input type="text" value={value.FloorPercentage} placeholder="floor %" 
                                          onChange={(e) => this.updateCustomer(value, "FloorPercentage", e.target.value)} 
                                          className={classes.modalTextBox} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", width:"89px", textAlign:"right"}}>
                                        <Tooltip id="tooltip-top-start" title="Remove Customer" placement="top" classes={{ tooltip: classes.tooltip }} >
                                            <ConfirmDialog header="Confirm Remove Customer" description="Are you sure you want to remove this customer? This action cannot be undone." confirmed={() => this.deleteCustomer(value)} />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                          <Tooltip
                            id="tooltip-top-start"
                            title="Add Customer"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={() => this.setState({addCustomerVisible:true})}>
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                        <GridItem xs={12} style={{backgroundColor:"#efefef", borderRadius:"6px", display:this.showAddCustomer()}}>
                           <GridContainer style={{marginTop:"-6px"}}>
                            <GridItem xs={7} >
                                <CustomSelect
                                  labelText="Customer"
                                  inputProps={{ placeholder: "Customer" }}
                                  formControlProps={{ fullWidth: true }}
                                  onChange={(e) => this.chooseCustomer(e.target.value)}
                                  options={this.state.customers}
                                  value={this.state.selectedCustomer.contact_id}
                                />
                            </GridItem>
                            <GridItem xs={3} >
                              <CustomInput
                                labelText="Floor %"
                                value={this.state.floorPercentage}
                                inputProps={{ placeholder: "Floor %" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.setState({floorPercentage: e.target.value})}
                              />
                            </GridItem>
                            <GridItem xs={2} >
                                <div style={{paddingTop:"37px"}}>
                                    <Button onClick={this.addCustomer} size="sm" >Add</Button>
                                </div>
                            </GridItem>
                           </GridContainer>
                        </GridItem>
                        
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
            <div style={{display:this.showButtons()}}>
            
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
                <Button
                  onClick={this.sendInvitation}
                  disabled={this.disableSave()}
                >
                  Save and Send Invitation
                </Button>
              &nbsp;
              <Button
                onClick={() => { this.saveAffiliate(); }}
                disabled={this.disableSave()}
                color="success">
                Save
              </Button>
            </div>
            <div style={{display:this.showProgressIndicator()}}>
              <CircularProgress />
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
  //return {
  //  vocabulary: state.vocabulary
  //}
}

AffiliateEditor = connect(mapStateToProps)(AffiliateEditor);
export default withStyles(modalStyle)(AffiliateEditor);