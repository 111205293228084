// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  "How can I get last minute reservations ...",
  "When will the Pools of `Ohe`o open again ... ",
  "Given the hurricane forecases is the park expected ... ",
  "If I'm camping on site do I still have to buy ..."
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server
};
