//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
//import CustomSelect from "components/Select/Select.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/PlaylistAdd";
import Delete from "@material-ui/icons/DeleteOutline";
import Manufacturer from "@material-ui/icons/Business";

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class ManufacturerEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    if(props.manufacturer) {
      var manufacturer = this.props.manufacturer;
      manufacturer.Brands = manufacturer.Brands.sort(this.compareName);
      this.state.manufacturer = manufacturer;
    }
    else {
      this.state.manufacturer = { Name: "", Brands:[] };
    }
    
    this.addBrand = this.addBrand.bind(this);
    this.updateBrand = this.updateBrand.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);

    this.saveManufacturer = this.saveManufacturer.bind(this);
    this.updateManufacturer = this.updateManufacturer.bind(this);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  addBrand() {
    var obj = this.state.manufacturer;
    const brand = {id: uuidv4(), Name: "" };
    obj.Brands.push(brand);
    obj.Brands = obj.Brands.sort(this.compareName);

    this.setState({manufacturer: obj});
  }

  deleteBrand(brand) {
    var d = this.state.manufacturer;
    for(var i = 0; i < d.Brands.length; i++) {
      if(d.Brands[i].id === brand.id) {
        d.Brands.splice(i,1);
        break;
      }
    }
    d.Brands = d.Brands.sort(this.compareName);
    this.setState({manufacturer: d});
  }

  updateBrand(brand, property, value) {
    var d = this.state.manufacturer;

    for(var i = 0; i < d.Brands.length; i++) {
      if(d.Brands[i].id === brand.id) {
        d.Brands[i][property] = value;
        break;
      }
    }
    this.setState({manufacturer: d});
  }

  updateManufacturer(property, value) {
    var d = this.state.manufacturer;
    d[property] = value;
    this.setState({manufacturer: d});
  }

  saveManufacturer() {
  
    var d = this.state.manufacturer;
    d.Brands = d.Brands.sort(this.compareName);

    db.updateManufacturer(d.id, d).then(doc => {
    
      if(!d.id) { // i.e. if it's a new obj

          d.id = doc.id; 
          this.props.onCreated(d);
          
          // reset to blank
          this.setState( { manufacturer: {
              Name: "", Brands: []
          }} );

      } else { // i.e. an existing Vocabulary

          this.props.onChanged(d);   
      }
    });
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title={this.props.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Manufacturer</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Manufacturer,
                  tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Name"
                            value={this.state.manufacturer.Name}
                            inputProps={{
                                placeholder: "Name"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateManufacturer("Name", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <br />
                          <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{padding:"3px"}}>
                                            Brand Name
                                        </TableCell>
                                        <TableCell style={{padding:"3px", width:"69px"}}>
                                          
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.manufacturer.Brands.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{padding:"3px"}}>
                                          <input type="text" value={value.Name} placeholder="name" 
                                            onChange={(e) => this.updateBrand(value, "Name", e.target.value)} 
                                            style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"21px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                        </TableCell>
                                        <TableCell style={{padding:"3px", width:"69px"}}>
                                          <Tooltip id="tooltip-top-start" title="Remove Brand" placement="top" classes={{ tooltip: classes.tooltip }} >
                                            <IconButton aria-label="Close" className={classes.tableActionButton} onClick={() => this.deleteBrand(value)} >
                                              <Delete className={ classes.tableActionButtonIcon + " " + classes.close } />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                            </Table>
                            <Tooltip
                              id="tooltip-top-start"
                              title="Add Brand"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}>
                              <IconButton onClick={this.addBrand}>
                                <Add />
                              </IconButton>
                            </Tooltip>
                        </GridItem>
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveManufacturer();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
  //return {
  //  vocabulary: state.vocabulary
  //}
}

ManufacturerEditor = connect(mapStateToProps)(ManufacturerEditor);
export default withStyles(modalStyle)(ManufacturerEditor);