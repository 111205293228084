//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { OrgUpdated } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

// icons
import Location from "@material-ui/icons/LocationOn";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class OrganizationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Description: "",
      Website: ""
    };
  }

  componentWillMount() {
    
    if (this.props.user.authenticated) {

      this.setState(
        {
          ID: this.props.org.id,
          Name: this.props.org.name,
          Description: this.props.org.description,
          Website: this.props.org.website,
          Latitude: this.props.org.latitude,
          Longitude: this.props.org.longitude
        }
      )
    }
  }

  saveOrg() {

    var org = {
      Name: this.state.Name,
      Description: this.state.Description,
      Website: this.state.Website,
      Latitude: parseFloat(this.state.Latitude),
      Longitude: parseFloat(this.state.Longitude),
      modified: Date.now()
    }
    
    db.updateOrganization(this.state.ID, org).then(doc => {

      this.props.onOrgUpdated({
        id: this.state.ID,
        name: this.state.Name,
        description: this.state.Description,
        website: this.state.Website,
        latitude: this.state.Latitude,
        longitude: this.state.Longitude,
        modified: Date.now()
      });
    });
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    ReactGA.pageview("admin.haleakala.app/organization");

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Organization</h4>
              <p className={classes.cardCategoryWhite}>
                These settings apply to your entire organization and affect every location.
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Organization"
                    id="organization"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={this.state.Name}
                    onChange={(e) => this.setState({Name: e.target.value})}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Website"
                    id="website"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={this.state.Website}
                    onChange={(e) => this.setState({Website: e.target.value})}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={this.state.Description}
                    onChange={(e) => this.setState({Description: e.target.value})}
                    inputProps={{
                        placeholder: "Description",
                        multiline:true
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                        id="latitude"
                        labelText="Latitude"
                        value={this.state.Latitude}
                        inputProps={{
                            placeholder: "Latitude",
                            endAdornment: (<InputAdornment position="end"><Location/></InputAdornment>)
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={(e) => this.setState({Latitude: e.target.value})}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                        id="longitude"
                        labelText="Longitude"
                        value={this.state.Longitude}
                        inputProps={{
                            placeholder: "Longitude",
                            endAdornment: (<InputAdornment position="end"><Location/></InputAdornment>)
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={(e) => this.setState({Longitude: e.target.value})}
                    />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
                <div style={{textAlign:"left"}}>
                <Button onClick={() => this.handleClose("modal")} >
                    Cancel
                </Button>
                <Button style={{marginLeft:"20px"}}
                    onClick={() => {
                        this.saveOrg();
                    }}
                    color="success">
                    Save
                </Button>
                </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onOrgUpdated: OrgUpdated }, dispatch);  
}
OrganizationPage = connect(mapStateToProps, mapDispatchToProps)(OrganizationPage);
export default withStyles(styles)(OrganizationPage);
