//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/Select/Select.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

//icons
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/AddShoppingCart";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
    var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
    return time;
}

class RecurringOrder extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            modal: false,
            editMode: false,
            tax: 0.00,
            addingNew: false,
            productIDQuery: "",
            productNameQuery: "",
            searchInProgress: false,
            searchResults:[],
            order: {
                Name:"",
                IsEnabled: true,
                tax: 0.00,
                orderTotal: 0.00,
                Items:[],
                Frequency: "w",
                StartDate: "",
                EndDate:"",
                shippingAddressID: "",
                poNumber: ""
            },
            shipToAddress: {

            },
            pricing: props.pricing
        }

        this.addNew = this.addNew.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.saveRecurringOrder = this.saveRecurringOrder.bind(this);
        this.searchProductIDs = this.searchProductIDs.bind(this);
        this.searchProductNames = this.searchProductNames.bind(this);

        //console.log(props);
    }

  handleClickOpen(modal) {
    
    if(this.props.order) {
        this.setState({order: this.props.order, editMode: true, shipToAddress: this.props.shippingAddress});
    } else {
        var order = this.state.order;
        order.Items = this.props.items;
        order.customerID = this.props.user.customerID;
        order.shippingAddressID = this.props.shipTo;
        if(this.props.poNumber)
            order.poNumber = this.props.poNumber;
        this.setState({order: order, shippingAddressID: this.props.shipTo, shipToAddress: this.props.shippingAddress}, () => this.calculateTax());
    }
    
    var x = [];
    x[modal] = true;
    this.setState(x);

    console.log(this.props)
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateOrder(property, value, callback) {
    var obj = this.state.order;
    obj[property] = value;
    this.setState({order: obj});

    if(callback)
        callback();
  }

  updateStartDate(value) {
    var obj = this.state.order;
    delete obj.ProcessedDate; // reset any prior processed dates
    obj.StartDate = value;
    this.setState({order:obj});
  }

  addLineItem(item) {

    var x = this.state.order;
    x.Items.push(item);
    this.setState({order: x});
  }

  updateLineItem(id, property, value) {
    var x = this.state.order;

    for(var i = 0; i < x.Items.length; i++) {

      if(x.Items[i].id === id)
        x.Items[i][property] = value;
      }

    this.setState({order: x}, () => {
      this.calculateTax();
    });
  }

  deleteLineItem(id) {

    var x = this.state.order;

    for(var i = 0; i < x.Items.length; i++) {
      if(x.Items[i].id === id) {
        x.Items.splice(i,1);
        break;
      }    
    }

    this.setState({order: x});
    this.calculateTax();
  }

  setSearchProductIDsTimer(query) {
    clearTimeout(this.searchProductIDs);
    this.setState({productIDQuery: query}, () => {
        setTimeout(this.searchProductIDs,1700);
    });
  }

  searchProductIDs() {

    algolia.getProductsByID(this.state.productIDQuery).then(results => {

      if(results.length > 0) {
        if(results[0].productID.toLowerCase() === this.state.productIDQuery.toLowerCase()) {

          // get any custom pricing
          for(var p=0; p < this.state.pricing.length; p++) {
          
            if(results[0].id === this.state.pricing[p].productID) {
            
              if(this.state.pricing[p].Price) {
                results[0].listPrice = this.state.pricing[p].Price;
              }
              else {
                results[0].originalPrice = results[0].listPrice;
                var percentage = this.state.pricing[p].discount / 100;
                var discount = results[0].listPrice * percentage;
                results[0].listPrice = (results[0].listPrice - discount).toFixed(2);
              }
              
              break;
            }
          }

          var lineItem = {
            id: results[0].id,
            name: results[0].name,
            listPrice: results[0].listPrice,
            qty: 1,
            productID: results[0].productID,
            total: Number(results[0].listPrice).toFixed(2)
          }
  
          this.addLineItem(lineItem);
          this.setState({addingNew: false, productIDQuery: ""});
        }
      }
    });
  }

  setSearchProductNamesTimer(query) {
    clearTimeout(this.searchProductNames);
    this.setState({productNameQuery: query}, () => {
        setTimeout(this.searchProductNames,1700);
    });
  }

  searchProductNames() {
    algolia.getProductsByName(this.state.productNameQuery).then(results => {

      // get any custom pricing
      for(var i = 0; i < results.length; i++) {

        for(var p=0; p < this.state.pricing.length; p++) {
            
          if(results[i].id === this.state.pricing[p].productID) {
          
            if(this.state.pricing[p].Price) {
              results[i].listPrice = this.state.pricing[p].Price;
            }
            else {
              results[i].originalPrice = results[i].listPrice;
              var percentage = this.state.pricing[p].discount / 100;
              var discount = results[i].listPrice * percentage;
              results[i].listPrice = (results[i].listPrice - discount).toFixed(2);
            }
            
            break;
          }
        }
      }

      this.setState({searchResults: results, searchInProgress: true });
    });
  }

  addProduct(product) {

    var lineItem = {
      id: product.id,
      name: product.name,
      listPrice: product.listPrice,
      qty: 1,
      productID: product.productID,
      total: Number(product.listPrice).toFixed(2)
    }

    this.addLineItem(lineItem);
    this.setState({addingNew: false, productNameQuery: "", searchInProgress: false, orderChanged: true});
  }

  addNew() {
    this.setState({addingNew: true});
  }

  showAddNew() {
    if(this.state.addingNew) {
      return "table-row";
    } else {
      return "none";
    }
  }

  showAddNewButton() {
    if(this.state.addingNew) {
      return "none";
    } else {
      return "block";
    }
  }

  calculateTax() {

    console.log("calculateTax()");

    var today = new Date();
    var dateString = today.getFullYear() + "-" + Number(Number(today.getMonth()) + 1) + "-" + today.getDate();

    var lines = [];
    var cart = this.state.order.Items;
    for(var i = 0; i < cart.length; i++) {
      
        var amount = Number(cart[i].qty) * Number(cart[i].listPrice).toFixed(2);
        lines.push({ quantity: Number(cart[i].qty), amount: amount, itemCode: cart[i].productID });    
    }
    
    var customerCode = this.props.user.id;
    if(this.props.user.companyName)
      customerCode = this.props.user.companyName;
    else {
      console.log('NO COMPANY NAME')
      console.log('user props:')
      console.log(this.props.user)
    }
      
    if(lines.length === 0) { // i.e. if nothing is taxable skip Avalara
      this.setState({tax: 0.00}, this.calculateTotal);
    } else {

      const taxDocument = {
        type: 'SalesOrder',
        companyCode: 'ECCLESIAMANAGEMENTLLC',// KEKAHA
        date: dateString,
        customerCode: customerCode,
        addresses: {
          ShipFrom: {
            line1: '4100 Eldorado Pkwy',
            city: 'Mckinney',
            region: 'TX',
            country: 'US',
            postalCode: '75070-6102'
          },
          ShipTo: {
            line1: this.state.shipToAddress.address,
            city: this.state.shipToAddress.city,
            region: this.state.shipToAddress.state,
            country: this.state.shipToAddress.country,
            postalCode: this.state.shipToAddress.zip
          }
        },
        lines: lines,
        commit: false,
        currencyCode: 'USD',
        description: 'Janitorial Supplies'
      }

      this.setState({taxDocument: taxDocument});

      fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/calculateTax", {
        method: "POST", 
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(taxDocument)
      })
      .then((response) => {
          
          if(response.ok) {
              return response.json();
          } else {
            console.log("error (1): ");  
            console.log(response);
          }
      })
      .then((json) => {
      
        this.setState({tax: Number(json.totalTax).toFixed(2)}, this.calculateTotal);
        
      })
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error (2):  " + error);
      });
    }
  }

  calculateTotal() {
 
    var x = this.state.order;

    var total = 0.00;
    for(var i = 0; i < x.Items.length; i++) {

      if(!isNaN(x.Items[i].qty) && !isNaN(x.Items[i].listPrice)) {
        x.Items[i].total = (Number(x.Items[i].qty) * Number(x.Items[i].listPrice)).toFixed(2);
      } else {
        x.Items[i].total = 0.00;
      }
      total = (Number(total) + Number(x.Items[i].total)).toFixed(2);
    }

    x.orderTotal = (Number(total) + Number(this.state.tax)).toFixed(2);
    this.setState({order: x}, () => {
      
    });
  }

  saveRecurringOrder() {
    
    var order = this.state.order;

    for (var i = 0; i < order.Items.length; i++) {
        var item = order.Items[i];

        order.Items[i] = {
            id: item.id,
            listPrice: item.listPrice,
            name: item.name,
            productID: item.productID,
            qty: item.qty,
            total: item.total
        }

        if(item.sku)
            order.Items[i].sku = item.sku;
    }

    order.lastModifiedBy = this.props.user.firstName + " " + this.props.user.lastName;
    order.lastModifiedOn = Math.round((new Date()).getTime() / 1000);
  
    console.log('order');
    console.log(order);

    db.updateRecurringOrder(order.id, order).then(() => {
        console.log('order saved');

        if(this.props.orderSaved)
            this.props.orderSaved(order);
        this.handleClose("modal");
    });
  
  }

  disableSave() {
    var order = this.state.order;
    
    if(order.Name.length > 0 &&
        order.StartDate.length > 0) {
            return false;
        }
    else return true;
  }

  trim(value, maxLength) {
    if(value) {
        if(value.trim().length > maxLength)
            return value.trim().substring(0,maxLength) + "...";
        else
            return value.trim();
    } else return "";
  }

  showButton() {
    if(this.props.button)
        return "block";
    else
        return "none";
  }

  showIconButton () {
    if(this.props.iconButton)
        return "block";
    else
        return "none";
  }

  showSearchResults() {
    if(this.state.searchInProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  getLastModified() {
      if(this.state.order.lastModifiedBy && this.state.order.lastModifiedOn) {
          return <div>
              Last updated by {this.state.order.lastModifiedBy} on {timeConverter(this.state.order.lastModifiedOn)}.
          </div>
      } else {
          return "";
      }
  }

  render() {

    const { classes } = this.props;

    return (
      
        <div>
            <Button style={{width:"100%", display:this.showButton()}} disabled={this.props.isDisabled} onClick={() => this.handleClickOpen("modal")} >Save As Recurring Order</Button>
            <IconButton style={{display:this.showIconButton()}} onClick={() => this.handleClickOpen("modal")} ><Edit /></IconButton>
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                maxWidth="md"
                fullWidth
                open={this.state.modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("modal")}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">

                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    style={{backgroundColor:"#efefef"}} >

                    <GridContainer direction="row">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.modalTitle}>Recurring Order</h4>
                    </GridItem>
                    </GridContainer>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                    style={{backgroundColor:"#efefef", paddingTop:"-0px" }}>

                    <Card style={{minHeight:"369px", padding:"0px", paddingBottom:"0px"}}>

                        <GridContainer direction="row"  >

                            <GridItem xs={3}  >
                                <CustomInput
                                    labelText="Name"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    inputProps={{
                                        placeholder: "Name"
                                    }}
                                    value={this.state.order.Name}
                                    onChange={(e) => this.updateOrder("Name", e.target.value)}
                                />
                            </GridItem>
                            <GridItem xs={3} >
                                <CustomSelect
                                    labelText="Frequency"
                                    inputProps={{ placeholder: "Recurring" }}
                                    formControlProps={{ fullWidth: true }}
                                    onChange={(e) => this.updateOrder("Frequency",e.target.value)}
                                    options={[
                                        {key:"w",label:"Weekly"},
                                        {key:"m",label:"Monthly"},
                                        {key:"eom",label:"Twice Monthly"},
                                        {key:"q",label:"Quarterly"}
                                    ]}
                                    value={this.state.order.Frequency}
                                />
                            </GridItem>
                            <GridItem xs={2}  >
                                <CustomInput 
                                    labelText="Start Date"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    inputProps={{
                                        placeholder: "Start Date"
                                    }}
                                    value={this.state.order.StartDate}
                                />
                            </GridItem>
                            <GridItem xs={2}  >
                                <CustomInput
                                    labelText="End Date"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    inputProps={{
                                        placeholder: "End Date"
                                    }}
                                    value={this.state.order.EndDate}
                                    
                                />
                            </GridItem>
                            <GridItem xs={2}  >
                                <CustomInput
                                    labelText="PO Number"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.formControl
                                    }}
                                    inputProps={{
                                        placeholder: "PO Number"
                                    }}
                                    value={this.state.order.poNumber}
                                    onChange={(e) => this.updateOrder("poNumber", e.target.value)}
                                />
                            </GridItem>

                            <GridItem xs={12} style={{marginTop:"23px"}}>
                                <div style={{fontWeight:500, fontSize:"17px"}}>Product Summary</div>
                                <div style={{ paddingBottom:"20px", maxHeight:"369px", overflowY:"auto" }} >
                                    <Table className={classes.table} >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{padding:"0px", width:"50px"}}></TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    Product ID
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    Name
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    Qty
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    Price
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    Total
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.state.order.Items.map((value, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{padding:"0px", width:"50px"}}>
                                                    <Tooltip id="tooltip-top-start" title="Delete Line Item" placement="top" classes={{ tooltip: classes.tooltip }} >
                                                        <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this product? This action cannot be undone." confirmed={() => this.deleteLineItem(value.id)} />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    {value.productID}
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    {this.trim(value.name,54)}
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    <input type="text" value={value.qty} placeholder="qty" 
                                                        onChange={(e) => this.updateLineItem(value.id, "qty", e.target.value)} 
                                                        className={classes.modalTextBox} />
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    ${value.listPrice}
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    ${value.total}
                                                </TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow style={{display:this.showAddNew()}}>
                                                <TableCell style={{padding:"0px", width:"50px"}}>
                                                    <IconButton onClick={this.addLineItem} style={{color:"green"}}>
                                                        <Add />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell style={{padding:"3px"}}>
                                                    <input type="text" placeholder="Product ID" className={classes.modalTextBox} style={{textAlign:"left"}}
                                                        onChange={(e) => this.setSearchProductIDsTimer(e.target.value)} value={this.state.productIDQuery} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", position: "relative"}}>
                                                    <input type="text" placeholder="Product Name" className={classes.modalTextBox} style={{textAlign:"left"}} 
                                                        onChange={(e) => this.setSearchProductNamesTimer(e.target.value)} value={this.state.productNameQuery} />
                                                    <div id="divSearchResults" style={{position: "absolute", height:"120px", width:"100%", backgroundColor:"#efefef", borderRadius:"6px", marginLeft:"6px", overflow:"auto", padding:"10px", fontSize:"11px", border:"solid 1px green", display:this.showSearchResults()}} >
                                                        {this.state.searchResults.map((value, index) => (
                                                        <div key={index} style={{cursor:"pointer", color:"232323"}} 
                                                            onClick={() => this.addProduct(value)}><span style={{color:"green"}}>{value.productID}</span> - {this.trim(value.name,54)}</div>
                                                        ))}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{padding:"0px", width:"50px"}}>
                                                    <IconButton onClick={this.addNew} style={{display:this.showAddNewButton()}}>
                                                        <Add />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell colSpan="3">{this.getLastModified()}</TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    <b>Tax</b>
                                                    <br /><b>Total</b>
                                                </TableCell>
                                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                    <b>${this.state.order.tax.toFixed(2)}</b>
                                                    <br /><b>${this.state.order.orderTotal}</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </Card>

                </DialogContent>
                <DialogActions
                    className={classes.modalFooter }
                    style={{backgroundColor:"#efefef"}}>
                    
                    <GridContainer style={{width:"100%"}}>
                        <GridItem xs={4} style={{textAlign:"left"}}>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={this.state.order.IsEnabled}
                                    onChange={(e) => this.updateOrder("IsEnabled", e.target.checked)}
                                    classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    icon: classes.switchIcon,
                                    iconChecked: classes.switchIconChecked,
                                    bar: classes.switchBar
                                    }}
                                />
                                }
                                classes={{ label: classes.label }}
                                label="Enabled"
                            />
                        </GridItem>
                        <GridItem xs={8}>
                            <Button onClick={() => this.handleClose("modal")}  >
                                Cancel
                            </Button>
                            &nbsp;
                            <Button color="success" onClick={this.saveRecurringOrder} disabled={this.disableSave()}  >
                                OK
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
            </Dialog>
        </div>
      
      );
    }
}

const mapStateToProps = state => {
    return state;
}
  
RecurringOrder = connect(mapStateToProps)(RecurringOrder);
export default withStyles(modalStyle)(RecurringOrder);