//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Vendor from "@material-ui/icons/LocalShipping";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class AddressEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      address: { name: "", shipToName: "", address:"", city:"", state:"", zip:"" }
    };

    this.saveAddress = this.saveAddress.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
  }

  handleClickOpen(modal) {
    if(this.props.address)
        this.setState({address: this.props.address});

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateAddress(property, value) {
    var d = this.state.address;
    d[property] = value;
    this.setState({address: d});
  }

  saveAddress() {
  
    var address = this.state.address;
    
    db.updateAddress(address.address_id, address).then(() => {
    
        this.props.onChanged(address);   
    });
  }


  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title="Edit Address"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Address</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Vendor,
                  tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Name"
                            value={this.state.address.name}
                            inputProps={{
                                placeholder: "Name",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAddress("name", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Ship To Name"
                            value={this.state.address.shipToName}
                            inputProps={{
                                placeholder: "Ship To Name",
                                disabled: false
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAddress("shipToName", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Street Address"
                            value={this.state.address.address}
                            inputProps={{
                                placeholder: "Street Address",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAddress("address", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                          <CustomInput
                            labelText="City"
                            value={this.state.address.city}
                            inputProps={{
                                placeholder: "City",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAddress("city", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="State"
                            value={this.state.address.state}
                            inputProps={{
                                placeholder: "State",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAddress("state", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Postal Code"
                            value={this.state.address.zip}
                            inputProps={{
                                placeholder: "Postal Code",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateAddress("zip", e.target.value)}
                          />
                        </GridItem>
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveAddress();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

AddressEditor = connect(mapStateToProps)(AddressEditor);
export default withStyles(modalStyle)(AddressEditor);