//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ManufacturerCreated, ManufacturersLoaded, ManufacturerUpdated } from 'redux/actions';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import ManufacturerEditor from "components/ManufacturerEditor/ManufacturerEditor.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class ManufacturerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manufacturers: []
    }

    this.ManufacturerCreated = this.ManufacturerCreated.bind(this);
    this.ManufacturerUpdated = this.ManufacturerUpdated.bind(this);
    this.deleteManufacturer = this.deleteManufacturer.bind(this);
  }

  componentWillMount() {
    this.refreshManufacturers();
  }

  refreshManufacturers() {
    db.getManufacturers().then(manufacturers => {
      this.setState({manufacturers: manufacturers});
      this.props.onManufacturersLoaded(manufacturers); 
    });
  }

  ManufacturerUpdated(manufacturer) {
    
    var manufacturers = this.state.manufacturers;
    for(var i = 0; i < manufacturers.length; i++) {
      if(manufacturers[i].id === manufacturer.id) {
        manufacturers[i] = manufacturer;
        break;
      }
    }
    manufacturers = manufacturers.sort(this.compareName);
    this.setState({manufacturers: manufacturers});
    this.props.onManufacturersLoaded(manufacturers); 
  }

  ManufacturerCreated(manufacturer) {
    
    var manufacturers = this.state.manufacturers;
    manufacturers.push(manufacturer);
    manufacturers = manufacturers.sort(this.compareName);
    this.setState({manufacturers: manufacturers});
    this.props.onManufacturersLoaded(manufacturers); 
  }

  deleteManufacturer(manufacturer) {
    db.deleteManufacturer(manufacturer.id).then(() => {
      var manufacturers = this.state.manufacturers;
      for(var i = 0; i < manufacturers.length; i++) {
        if(manufacturers[i].id === manufacturer.id) {
          manufacturers.splice(i,1);
          break;
        }
      }

      manufacturers = manufacturers.sort(this.compareName);
      this.setState({manufacturers: manufacturers});
      this.props.onManufacturersLoaded(manufacturers); 
    });
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Manufacturers</h4>
                  <p className={classes.cardCategoryWhite}>
                    This list allows products to be classified by manufacturers and their brands.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  <ManufacturerEditor title="Add Manufacturer" onCreated={this.ManufacturerCreated} />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        English
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        # Brands
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"23px"}}> 
                          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.manufacturers.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <ManufacturerEditor title="Edit Manufacturer" manufacturer={value} onChanged={this.ManufacturerUpdated}  />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.Name}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.Brands.length}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Manufacturer" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this manufacturer? This action cannot be undone." confirmed={() => this.deleteManufacturer(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onManufacturerCreated: ManufacturerCreated, 
    onManufacturersLoaded: ManufacturersLoaded,
    onManufacturerUpdated: ManufacturerUpdated }, dispatch);  
}

ManufacturerList = connect(mapStateToProps, mapDispatchToProps)(ManufacturerList);
export default withStyles(styles)(ManufacturerList);
