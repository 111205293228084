//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
    var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
    return time;
  }
  
class UserActivity extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      user: { FirstName: "", LastName: "", activities: [] }
    };
   
  }

  handleClickOpen(modal) {
      console.log(this.props.User)
    if(this.props.User)
        this.setState({user: this.props.User});

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  getActivityDescriptor(lastActivity) {
    if(lastActivity === 0)
      return <div style={{color:'maroon'}}>No Activity</div>
    else if (lastActivity > this.state.lastMonth) 
      return <div style={{color:'green'}}>{timeConverter(lastActivity)}</div>
    else
      return timeConverter(lastActivity);
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title="View Detailed Activity"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Timeline className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>User Activity</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "Activity",
                  tabIcon: Timeline,
                  tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={12} >
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding:"6px"}}> 
                                        Date / Time
                                    </TableCell>
                                    <TableCell style={{padding:"6px"}}> 
                                        Type
                                    </TableCell>
                                    <TableCell style={{padding:"6px"}}> 
                                        
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.user.activities.map(value => (
                                <TableRow key={value.id} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                                        {this.getActivityDescriptor(value.modified)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                                        {value.ActivityType}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                                    
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        </GridItem>
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.handleClose("modal");
                  }}
                color="success">
                OK
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

UserActivity = connect(mapStateToProps)(UserActivity);
export default withStyles(modalStyle)(UserActivity);