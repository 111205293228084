//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//affiliate
import { affiliate } from 'assets/affiliate';

//algolia full text search
import { algolia } from 'assets/algolia';

// html parser
import Parser from 'html-react-parser';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import AddressChooser from "components/AddressChooser/AddressChooser.jsx";
import VendorPopover from "components/VendorPopover/VendorPopover.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Event from "@material-ui/icons/Event";
import Receipt from "@material-ui/icons/Receipt";
import Add from "@material-ui/icons/AddShoppingCart";
import NewInvoice from "@material-ui/icons/PlaylistAdd";

import Backordered from "@material-ui/icons/DateRange";
import Confirmed from "@material-ui/icons/Done";
import Rejected from "@material-ui/icons/Clear";
import Shipped from "@material-ui/icons/Commute";
import HelpIcon from "@material-ui/icons/Info";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + date + ' ' + year;
  return time;
}
  
class OrderDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      order: { items: [], notes: "", poNumber: "" },
      address: { street:"", city:"", state:"" },
      addresses: [],
      tax: 0.00,
      addingNew: false,
      productIDQuery: "",
      productNameQuery: "",
      
      orderChanged: false,
      searchResults:[],
      searchInProgress: false,
      pricing:[],
      bills:[]
    };

    this.addNew = this.addNew.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.cancel = this.cancel.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.getCompany = this.getCompany.bind(this);
    this.upsertPurchaseOrder = this.upsertPurchaseOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.getPurchaseOrderNumbers = this.getPurchaseOrderNumbers.bind(this);
    this.getVendors = this.getVendors.bind(this);
    this.searchProductIDs = this.searchProductIDs.bind(this);
    this.searchProductNames = this.searchProductNames.bind(this);
    this.validatePricingAndSKUs = this.validatePricingAndSKUs.bind(this);
    this.vendorChanged = this.vendorChanged.bind(this);
  }

  handleClickOpen(modal) {

    //console.log(this.props);

    if(!this.state.baselineOrder) {

      this.getCompany().then((company) => {

        this.setState({company: company}, () => {

          this.getVendors(this.props.order).then((initialOrder) => {
          
            this.getPricing(initialOrder).then(pricing => {
    
              this.setState({pricing: pricing}, () => {
    
                this.updateRecurringOrderData(initialOrder).then(order => {

                  this.validatePricingAndSKUs(order).then(order => {

                    this.setState({order: order}, () => {
          
                      this.getBaselineOrder();
                      this.getShippingAddress();
                      this.getBills();
                      
                      if(this.state.order.tax) {
                        this.setState({tax: this.state.order.tax});
                      }
                    });
                  });
                });
              });
            });
          });
        });

        var x = [];
        x[modal] = true;
        this.setState(x);
      });

    } else {
      var json = JSON.stringify(this.state.baselineOrder);
      this.setState({order: JSON.parse(json), modal: true});
    }
  }

  getCompany() {
    return new Promise((resolve, reject) => {
      if(this.props.order.customerID) {
        db.getCompany(this.props.order.customerID).then((company) => {
          resolve(company);
        });
      } else {
        resolve();
      }
    });
  }

  validatePricingAndSKUs(order) {

    var pricing = this.state.pricing;

    return new Promise(function(resolve, reject) {

      var promises = [];

      for(var j = 0; j < order.items.length; j++) {

        // pricing
        for(var p = 0; p < pricing.length; p++) {
                  
          if(order.items[j].id === pricing[p].productID) {
          
            if(pricing[p].Price) {
              order.items[j].expectedPrice = Number(pricing[p].Price).toFixed(2);
            }
            else {

              if(pricing[p].ruleType === 'discount') {

                var p1 = Number((100-pricing[p].percentage) / 100);
                order.items[j].expectedPrice = (order.items[j].listPrice * p1).toFixed(2);
  
              } else if (pricing[p].ruleType === 'margin') {
                
                var p2 = Number(pricing[p].percentage / 100);
                var margin = order.items[j].listPrice - order.items[j].cost;
                order.items[j].expectedPrice = (Number(order.items[j].cost) + (margin * p2)).toFixed(2);
  
              } else if (pricing[p].ruleType === 'cost') {
                
                var p3 = Number(pricing[p].percentage / 100);
                order.items[j].expectedPrice = (Number(order.items[j].cost) + (order.items[j].cost * p3)).toFixed(2);
                
              }
            }

            break;
          }
        }

        // delete order.items[j].sku; (uncomment only when testing SKU validation)

        // skus
        if(!order.items[j].sku) {
          promises.push(db.getProductByID(order.items[j].id));
        }
      }

      if(promises.length === 0)
        resolve(order);
      else {
        Promise.all(promises).then(products => {

          for(var i = 0; i < order.items.length; i++) {
            for(var p = 0; p < products.length; p++) {
              
              if(order.items[i].id === products[p].id) {
                for(var v = 0; v < products[p].Vendors.length; v++) {
                  if(products[p].Vendors[v].id === order.items[i].vendorID) {
                    order.items[i].sku = products[p].Vendors[v].sku; // (comment to test SKU validation)
                    break;
                  }
                }

                break;
                
              }
            }
          }
          
          order.skuMissed = order.items.reduce((result, item) => {
            return result || !item.sku
          }, false)

          resolve(order);
        });
      }
    });
  }

  updateRecurringOrderData(order) {

    var pricing = this.state.pricing;
    var company = this.state.company;

    return new Promise(function(resolve, reject) {
      
      // update pricing
      if(order.isRecurring) {

        var promises = [];
        for(var i = 0; i < order.items.length; i++) 
          promises.push(db.getProductByID(order.items[i].id));
        
        Promise.all(promises).then(products => {

          for(var j = 0; j < order.items.length; j++) {

            // STEP 1 update prices from DB if necessary

            for(var k = 0; k < products.length; k++) {
              if(products[k].id === order.items[j].id) {
                order.items[j].listPrice = products[k].ListPrice;
                order.items[j].cost = products[k].WholesalePrice;
                break;
              }
            }

            // STEP 2 apply custom pricing rules if necessary

            for(var p = 0; p < pricing.length; p++) {
              
              if(order.items[j].id === pricing[p].productID) {
              
                if(pricing[p].Price) {
                  order.items[j].listPrice = pricing[p].Price;
                }
                else {
                  order.items[j].originalPrice = order.items[j].listPrice;
                  var percentage = pricing[p].percentage / 100;
                  var discount = order.items[j].listPrice * percentage;
                  order.items[j].listPrice = (order.items[j].listPrice - discount).toFixed(2);
                }
                
                break;
              }
            }

            // STEP 3 update totals
            order.items[j].total = ( Number(order.items[j].qty) * Number(order.items[j].listPrice) ).toFixed(2);

          }

          // associate affiliate to the order
          
          if(company) {
          
            if(company.affiliate) {

              db.getAffiliate(company.affiliate).then(a => {

                order.affiliate = {
                  baseCommission: a.baseCommission,
                  commissionType: "margin",
                  firstName: a.FirstName,
                  floorCommission: a.floorCommission,
                  id: a.id,
                  lastName: a.LastName
                }
                
                for(var c = 0; c < a.Customers.length; c++) {
                  if(a.Customers[c].id === order.customerID) {

                    if(a.Customers[c].FloorPercentage) 
                      order.affiliate.floorPercentage = (Number(1) + (Number(a.Customers[c].FloorPercentage) / 100)).toFixed(2);
                      
                    break;
                  }
                }
                
                // update line item and total commissions
                var totalCommission = 0.00;
                for(var i = 0; i < order.items.length; i++) {
              
                  order.items[i].commission = affiliate.calculateCommission(order.items[i],order.items[i].listPrice, order.affiliate);
                  order.items[i].totalCommission = (Number(order.items[i].commission) * Number(order.items[i].qty)).toFixed(2);
                  totalCommission = Number(totalCommission) + Number(order.items[i].totalCommission);
                }
                order.commission = totalCommission.toFixed(2);

                //console.log('updated order');
                //console.log(order);
                resolve(order);
              

              });
            }
          } else {
            resolve(order);
          }
          
        });

      } else { // not a recurring order
        resolve(order);
      }
    });
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  getBaselineOrder() {

    var json = JSON.stringify(this.state.order);
    this.setState({baselineOrder: JSON.parse(json)});
  }

  checkOrderChanged() {
    
    var changed = false;

    var order = this.state.order;
    var baseline = this.state.baselineOrder;

    if(baseline.poNumber !== order.shippingAddressID) {
      changed = true;
    }
    else if(baseline.shippingAddressID !== order.shippingAddressID) {
      changed = true;
    } else if(baseline.items.length !== order.items.length) {
      changed = true;
    } else {
      for(var i = 0; i < baseline.items.length; i++) {
        var isFound = false;
        for(var j = 0; j < order.items.length; j++) {
          if(baseline.items[i].productID === order.items[j].productID) {
            isFound = true;
            
            if(baseline.items[i].qty !== order.items[j].qty ||
               baseline.items[i].listPrice !== order.items[j].listPrice) {
                changed = true;
            
                break;
              }
          }
        }
        if(!isFound) {
          changed = true;
          break;
        }
      }
    }

    this.setState({orderChanged: changed});
  }

  getShippingAddress() {

    var id = this.state.order.userID; // internet customer 
    if(this.state.order.customerID)
      id = this.state.order.customerID; // managed customer

      if(this.props.order.customerID) {
        db.getAddressesByCustomer(id).then(addresses => {
          
          this.setState({addresses: addresses});

          for(var i = 0; i < addresses.length; i++) {
            
            if(addresses[i].address_id === this.state.order.shippingAddressID) {
              
              this.setState({address: addresses[i]}, () => {
                
                //testing
                if(this.state.order.customerID === '1343284000000708001') {
                  // if it's supposed to be an order for child company
                  if(this.state.address.customer_id) { // only exists when a child company address
                    //console.log('forcing customer ID from ' + this.state.order.customerID + ' to ' + this.state.address.customer_id);
                  }
                }
              });
              
              break;
            }
          }
        });
      } else {
       this.setState({address: this.state.order.shipToAddress}); 
      }
  }

  getExceptions(customerID) {

    return new Promise((resolve, reject) => {
  
      if(this.state.company) {
          
          var profileID = "none";
          profileID = this.state.company.pricingProfile;
          
          if(profileID !== "none") {
              db.getExceptionsByProfile(profileID).then((exceptions) => {
                  resolve(exceptions);
              });

          } else {
              resolve();
          }
      } else {
          resolve();
      }
    });
  }

  processExceptions(exceptions) {

    return new Promise((resolve, reject) => {

        if(exceptions) {   

          var processed = [];
          var promises = [];

          for(var i = 0; i < exceptions.length; i++) {
             
            if(exceptions[i].productID) { // individual exception

                processed.push(exceptions[i]);

            } else { // rules
            
                var filter = exceptions[i].filter;
                var promise = algolia.getProductsIDs("", filter.manufacturers, filter.category, filter.subCat1, filter.subCat2, filter.subCat3, exceptions[i]);
                promises.push(promise);
            }
          }
      
          if(promises.length > 0) { // if they have any category rules
      
              Promise.all(promises).then((queries) => {
                  
                for(var j = 0; j < queries.length; j++) {
                  for(var k = 0; k < queries[j].length; k++) {
                      var rule = queries[j][k].referenceData;

                      var percentage = 0;
                      var ruleType = "discount";
                      if(rule.discount)
                          percentage = Number(rule.discount);
                      if(rule.percentage) {
                          percentage = Number(rule.percentage);
                          ruleType = rule.ruleType;
                      }
                          
                      processed.push({ productID: queries[j][k].id, percentage: percentage, ruleType: ruleType });
                  }
                }

                resolve(processed);
              });

          } else { // otherwise we're done

              resolve(processed);      
          }
        } else resolve();
    });
  }

  getPricing(order) {

    return new Promise((resolve, reject) => {
      if(this.props.order.customerID) {
        this.getExceptions(order.customerID).then(pbe => { // profile based exceptions
          this.processExceptions(pbe).then((profileExceptions) => {

              if(!profileExceptions) profileExceptions = [];

              db.getPricingByCustomer(order.customerID).then((cse) => { // customer specific exceptions
                  this.processExceptions(cse).then((customerExceptions) => {
                  
                      if(customerExceptions) { // overwrite profile exceptions if we have conflicting customer specific exceptions
                          
                          for(var i=0; i < customerExceptions.length; i++) {
                              
                              var isFound = false;
                              for(var j = 0; j < profileExceptions.length; j++) {
                                  if(customerExceptions[i].productID === profileExceptions[j].productID) {
                                      profileExceptions[j] = customerExceptions[i];
                                      isFound = true;
                                      break;
                                  }
                              }
                              if(!isFound)
                                  profileExceptions.push(customerExceptions[i]);
                          }
                      }
  
                      resolve(profileExceptions);                    
                  });
              });
          });
        })
      } else {
        resolve([]);
      }
    });
  }

  getVendors(order) {
    
    return new Promise(function(resolve, reject) {
      var promises = [];

      for(var i = 0; i < order.items.length; i++) 
        promises.push(db.getProductByID(order.items[i].id));
      
      Promise.all(promises).then((products) => {
        for(var j = 0; j < order.items.length; j++) {
          for(var k = 0; k < products.length; k++) {
            if(order.items[j].id === products[k].id) {
              
              // if no default vendor has been assigned, use the first vendor
              if(!products[k].DefaultVendor) products[k].DefaultVendor = products[k].Vendors[0].id;

              // if the assigned default vendor no longer sells the product, use the first one that does
              var defaultVendorFound = false;
              for(var l = 0; l < products[k].Vendors.length; l++) {
                if(products[k].Vendors[l].id === products[k].DefaultVendor) {
                  defaultVendorFound = true;
                  break;
                }
              }
              if(!defaultVendorFound) 
                products[k].DefaultVendor = products[k].Vendors[0].id;

              order.items[j].Vendors = products[k].Vendors;
              
              order.items[j].DefaultVendor = products[k].DefaultVendor;
              order.items[j].vendorID = products[k].DefaultVendor;

              break;
            }
          }
        }
      });

      resolve(order);
    });
  }

  getBills() {
    
    db.getBillsByOrder(this.state.order.id).then(bills => {

      for(var i = 0; i < bills.length; i++) {
        for(var j = 0; j < this.props.vendors.length; j++) {
          if(bills[i].vendorID === this.props.vendors[j].id) {
            bills[i].vendorName = this.props.vendors[j].Name;
            break;
          }
        }
      }

      this.setState({bills:bills});
      
    });
  }

  sendToZoho() {

    this.setState({syncInProgress: true}, () => {
      
      var order = this.state.order;
      
      //zoho sync ...

      // first sync products
      var promises = [];
      for(var i = 0; i < order.items.length; i++) {
        promises.push(this.upsertZohoProduct(order.items[i]));  
      }

      Promise.all(promises).then((results) => {

        var lineItems = [];
        var vendors = [];

        for(var j = 0; j < order.items.length; j++) {

          for(var k = 0; k < results.length; k++) {
            if(order.items[j].id === results[k].id) {

              var lineItem = {
                "item_id": results[k].ZohoID,
                "name": order.items[j].productID,
                "description": order.items[j].name,
                "quantity": Number(order.items[j].qty),
                "rate": order.items[j].listPrice,
                "isExempt": order.items[j].isExempt,
                "vendorID": order.items[j].vendorID
              }

              if(order.items[j].sku)
                lineItem.description = lineItem.description + ', sku: ' + order.items[j].sku;    

              if(order.items[j].isExempt) {
                lineItem.item_id = results[k].ZohoTaxExemptID;
                lineItem.avatax_tax_code = 'NT';
              }

              lineItems.push(lineItem);
              
              break;
            }
          }

          var vendorFound = false;
          for(var v = 0; v < vendors.length; v++) {
            if(vendors[v] === order.items[j].vendorID) {
              vendorFound = true;
              break;
            }
          }
          if(!vendorFound)
            vendors.push(order.items[j].vendorID);

        }
        
        var customerID = order.customerID;
        var contactID = order.contactID;


        if(customerID === '1343284000000063075') { // special case for DTK

          // if it's supposed to be an order for child company
          if(this.state.address.customer_id) { // only exists when a child company address
            customerID = this.state.address.customer_id;
            if(this.state.address.customer_id === '1343284000001915048') { //dtk3
              contactID = '1343284000001915050';
            } else if(this.state.address.customer_id === '1343284000001266431') { //dtk2
              contactID = '1343284000001264260';
            }
          } 
        }

        var terms = 15;
        for(var c = 0; c < this.props.customers.length; c++) {
          if(this.props.customers[c].contact_id === customerID) {
            terms = this.props.customers[c].payment_terms;
            break;
          }
        }
        
        var data = {
          "customer_id" : customerID,
          "shipping_address_id" : order.shippingAddressID,  
          "contact_persons" : [contactID],
          "line_items" : lineItems,
          "notes" : order.notes,
          "reference_number": order.poNumber,
          "payment_terms": terms,
          "custom_fields" : [ { "index": 1,
                                "value": order.poNumber,
                                "custom_field_id": "cf_purchase_order" },
                                { "index": 2,
                                "value": 'n/a',
                                "custom_field_id": "status" } ]
        }

        //console.log(data);
        
        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/createSalesOrder";
        if(order.salesOrderNumber) {
          url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/updateSalesOrder";
          data.id = order.salesOrderID;
        }

        fetch(url, {
          method: "POST", 
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
            } else {
              console.log("error1");  
              console.log(response);
            }
        })
        .then((json) => {
          
          if(json) {
            
            //then... create purchase orders
            
            //update line items to wholesale prices for PO
            for(var m = 0; m < lineItems.length; m++) {
              
              for(var n = 0; n < results.length; n++) {
                
                if(!lineItems[m].isExempt && lineItems[m].item_id === results[n].ZohoID) {
                  
                  lineItems[m].rate = results[n].WholesalePrice;
                  break;
                } else if(lineItems[m].isExempt && lineItems[m].item_id === results[n].ZohoTaxExemptID) { 
                  
                  lineItems[m].rate = results[n].WholesalePrice;
                  break;
                }
              }
            }

            var salesOrder = json.salesorder;
            order.salesOrderID = salesOrder.salesorder_id;
            order.salesOrderNumber = salesOrder.salesorder_number;

            var poPromises = [];
            for(var w = 0; w < vendors.length; w++) {
              
              var poItems = [];
              for(var x = 0; x < lineItems.length; x++) {
                if(lineItems[x].vendorID === vendors[w]) {
                  
                  var lineItem = lineItems[x];
                  delete lineItem.vendorID;
                  
                  poItems.push(lineItem);
                }
              }

              var zohoID;
              if(order.purchaseOrders) { 
                for(var y=0; y<order.purchaseOrders.length; y++) {
                  if(order.purchaseOrders[y].vendorID === vendors[w]) {
                    zohoID = order.purchaseOrders[y].id;
                    console.log('existing PO# found');
                    break;
                  }
                }
              }

              poPromises.push(this.upsertPurchaseOrder(vendors[w], customerID, order.shippingAddressID, order.salesOrderID, poItems, order.poNumber, zohoID));
            }

            Promise.all(poPromises).then((purchaseOrders) => {

              order.purchaseOrders = [];
              for(var p = 0; p < purchaseOrders.length; p++) {
              
                order.purchaseOrders.push( { id: purchaseOrders[p].purchaseorder_id, vendorID: purchaseOrders[p].vendorID, number: purchaseOrders[p].purchaseorder_number } );
              }

              order.status = "APPROVED";

              console.log(order);

              db.updateOrder(order.id, order).then(() => {
                this.props.orderChanged(order);
                this.setState({order:order, syncInProgress: false, orderChanged: false});
                this.getBaselineOrder();
              });
            });
          }
        })
        .catch((error) => {
          
          console.log(error);
          
          const debugInfo = {
            error: error,
            action: 'sendToZohoException',
            order: order,
          };

          fetch(
            'https://us-central1-kekaha-ebf1f.cloudfunctions.net/logDebugInfo',
            {
              method: 'POST',
              cache: 'no-cache',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(debugInfo),
            },
          )
            .then((response) => {
              console.log('exception logged');
            })
            .catch((error) => {
              // If there is any error you will catch them here
              console.log('error (2):  ' + error);
            });
        });
      });
    });
  }

  upsertPurchaseOrder(vendorID, customerID, shippingAddressID, salesOrderID, lineItems, poNumber, zohoID ) {

    var _ = this;

    return new Promise(function(resolve, reject) {

      db.getVendor(vendorID).then(vendor => {

        var poData = {
          "vendor_id" : vendor.ZohoID,
          "delivery_customer_id" : customerID,
          "delivery_org_address_id" : shippingAddressID,
          "salesorder_id" : salesOrderID,
          "line_items" : lineItems,
          "reference_number": poNumber,
          "custom_fields" : [ { "index": 1,
                                "value": poNumber,
                                "custom_field_id": "cf_purchase_order" } ]
        }
  
        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/createPurchaseOrder";
        if(zohoID) {
          url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/updatePurchaseOrder";
          poData.id = zohoID;
        }

        fetch(url, {
          method: "POST", 
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(poData)
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
            } else {
              console.log("PO error1");  
              console.log(response);
              reject('error');
            }
        })
        .then((json) => {

          var ecclesiaPO = json.purchaseorder.purchaseorder_number;

          //debug 
          //vendor.NotificationEmail = 'michael@spiffyventures.com';

          if(vendor.NotificationEmail) 
            if(vendor.NotificationEmail.length > 0) {

              var order = _.state.order;
                
              var text = "You have a new Purchase Order from Ecclesia!";
              if(zohoID)
                text = "You have an UPDATED Purchase Order from Ecclesia!";
              
              var html = '<html>';
              //html = html + "To: " + vendor.Name + "<br /><br />"
              if(zohoID)
                html = html + 'You have an UPDATED Purchase Order from Ecclesia!';
              else 
                html = html + 'You have a new Purchase Order from Ecclesia!';
              html = html + '<br /><br />';
              
              html = html + '<b>PO Number</b><br />';
              html = html + ecclesiaPO;

              html = html + '<br /><br />';
              html = html + '<b>Shipping Address</b><br />';
              if(_.state.address.name)
                if(_.state.address.name.length > 0)
                  html = html + _.state.address.name + "<br />";
              html = html + _.state.address.address + "<br />";
              html = html + _.state.address.city + ", " + _.state.address.state + " " + _.state.address.zip + "<br />";
              html = html + 'REF: ' + poNumber + '<br />';

              html = html + '<br /><br />';

              html = html + '<table style="width:100%">';
              html = html + '<tr>';
              html = html + '<td><b>Qty</b></td>';  
              html = html + '<td><b>ID</b></td>'; 
              html = html + '<td><b>SKU</b></td>';
              html = html + '<td><b>Name</b></td>';  
              html = html + '<td><b>Price</b></td>';
              html = html + '<td><b>Total</b></td>';  
              html = html + '</tr>';

              var orderTotal = 0.00;
              for(var i = 0; i < order.items.length; i++) {
                if(order.items[i].vendorID === vendorID) {
                  var total = ( Number(order.items[i].qty) * Number(order.items[i].cost) ).toFixed(2);
                  orderTotal = Number(orderTotal) + Number(total);
  
                  html = html + '<tr>';
                  html = html + '<td>' + order.items[i].qty + '</td>';  
                  html = html + '<td>' + order.items[i].productID + '</td>'; 
                  html = html + '<td>' + order.items[i].sku + '</td>'; 
                  html = html + '<td>' + order.items[i].name + '</td>';  
                  html = html + '<td>$' + order.items[i].cost + '</td>';  
                  html = html + '<td>$' + total + '</td>';  
                  html = html + '</tr>';
                }
              }
              html = html + '</table>';

              html = html + '<br />';
              html = html + '<div style="fontWeight:600;">Order Total: ' + orderTotal.toFixed(2) + '</div>';  
              
              html = html + '<br /><br />';

              html = html + 'Thank you!';
              html = html + '<br /><br />';
              html = html + '- The Ecclesia Team';
              html = html + '</html>';

              var subject = "Purchase Order - Ecclesia ";
              
              // email
              const json = {
                to: vendor.NotificationEmail,
                bcc: 'rbj@eccjan.com',
                subject: subject,
                text: text,
                html: html
              }

              fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail", {
                  method: "POST", 
                  cache: "no-cache",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(json)
                })
                .then((response) => {
                    
                    if(!response.ok) {
                      console.log("error (1): ");  
                      console.log(response);
                    } else {
                      console.log('vendor notification email SENT');
                    }
                })
                .catch((error) => {
                    // If there is any error you will catch them here
                    console.log("error sending PO email:  " + error);
                });

            }
         
          json.purchaseorder.vendorID = vendor.id;
          resolve(json.purchaseorder);
        })
        .catch((error) => {
          // If there is any error you will catch them here
          console.log("error creating PO: " + error);
          reject(error);
        });
      });
    });
  }

  upsertZohoProduct(item) {

    return new Promise(function(resolve, reject) {
    
      db.getProduct(item.id).then(doc => {

        var product = doc.data();
        product.id = doc.id;

        var data = {
          "name" : "ecc-" + product.ProductID,
          "description" : product.ProductName,
          "rate" : product.ListPrice,
          "purchase_rate": product.WholesalePrice,
          "product_type": "goods",
          "item_type": "sales_and_purchases"
        }

        if(item.isExempt) {
          data.name = "ecc-" + product.ProductID + "-te";
          data.avatax_tax_code = 'NT';
          //data.is_taxable = false;
        }
        
        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/createProduct";

        if(product.ZohoID && !item.isExempt) {
          data.id = product.ZohoID;
          url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/updateProduct";
        } 
        else if(product.ZohoTaxExemptID && item.isExempt) {
          data.id = product.ZohoTaxExemptID;
          url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/updateProduct";
        }

        fetch(url, {
          method: "POST", 
          cache: "no-cache",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
            } else {
              console.log("error1");  
              console.log(response);
            }
        })
        .then((json) => {
          
          if(json) {  
            
            if(!product.ZohoID && !item.isExempt) {
              product.ZohoID = json.item.item_id;
              
              db.updateProduct(product.id, product).then(() => {
                resolve(product);
              })
            } else if (!product.ZohoTaxExemptID && item.isExempt) {
              product.ZohoTaxExemptID = json.item.item_id;
              
              db.updateProduct(product.id, product).then(() => {
                resolve(product);
              })
            } else {
              
              resolve(product);
            }
          }
        })
        .catch((error) => {
          // If there is any error you will catch them here
          console.log("error upserting product in zoho: " + error);
        });
        
      });
    });
  }

  showSyncProgressIndicator() {
    if(this.state.syncInProgress) {
      return "block";
    } else {
      return "none";
    }
  }
  
  showActionButtons() {
    if(!this.state.syncInProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  getCommission() {
    if(this.state.order.commission)
      return this.state.order.commission;
    else
      return 0.00;
  }
 
  getAffiliateName() {
    if(this.state.order.affiliate)
      return "Affiliate: " + this.state.order.affiliate.firstName + " " + this.state.order.affiliate.lastName;
    else 
      return "No Affiliate Assigned";
  }

  getNotes() {
    var notes = this.state.order.notes;
    while(notes.indexOf('\n') > -1) {
      notes = notes.replace('\n','<br>')
    }
    
    return Parser(notes)
  }

  calculateTax() {

    var today = new Date();
    var dateString = today.getFullYear() + "-" + Number(Number(today.getMonth()) + 1) + "-" + today.getDate();

    var lines = [];
    var cart = this.state.order.items;

    for(var i = 0; i < cart.length; i++) {
      
      var amount = Number(cart[i].qty) * Number(cart[i].listPrice).toFixed(2);
      lines.push({ quantity: Number(cart[i].qty), amount: amount, itemCode: cart[i].productID });
    }
    
    var customerCode = this.state.order.customerName;
    //todo, if it's not a managed customer ...
      
    if(lines.length === 0) { // i.e. if nothing is taxable skip Avalara
      this.setState({tax: 0.00}, this.calculateTotal);
    } else {

      const taxDocument = {
        type: 'SalesOrder',
        companyCode: 'ECCLESIAMANAGEMENTLLC',// KEKAHA
        date: dateString,
        customerCode: customerCode,
        addresses: {
          ShipFrom: {
            line1: '4100 Eldorado Pkwy',
            city: 'Mckinney',
            region: 'TX',
            country: 'US',
            postalCode: '75070-6102'
          },
          ShipTo: {
            line1: this.state.address.address,
            city: this.state.address.city,
            region: this.state.address.state,
            country: this.state.address.country,
            postalCode: this.state.address.zip
          }
        },
        lines: lines,
        commit: false,
        currencyCode: 'USD',
        description: 'Janitorial Supplies'
      }

      this.setState({taxDocument: taxDocument});

      fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/calculateTax", {
        method: "POST", 
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(taxDocument)
      })
      .then((response) => {
          
          if(response.ok) {
              return response.json();
          } else {
            console.log("error (1): ");  
            console.log(response);
          }
      })
      .then((json) => {
      
       // console.log('calculate tax response');
       // console.log(json);
        this.setState({tax: Number(json.totalTax)}, this.calculateTotal);
        
      })
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error (2):  " + error);
      });
    }
    
  }

  updateOrder(property, value) {
    var x = this.state.order;
    x[property] = value;
    this.setState({order: x}, this.checkOrderChanged);
  }

  calculateTotal() {
 
    var x = this.state.order;

    var total = 0.00;
    var commission = 0.00;

    for(var i = 0; i < x.items.length; i++) {

      if(!isNaN(x.items[i].qty) && !isNaN(x.items[i].listPrice)) {
        x.items[i].total = (Number(x.items[i].qty) * Number(x.items[i].listPrice)).toFixed(2);
      } else {
        x.items[i].total = 0.00;
      }
      total = (Number(total) + Number(x.items[i].total)).toFixed(2);

      if(x.affiliate) {
        //console.log('i:');
        //console.log(x.items[i]);
        x.items[i].totalCommission = ( Number(x.items[i].commission) * Number(x.items[i].qty) ).toFixed(2);
        commission = commission + (parseFloat(x.items[i].totalCommission));
      }
        
    }

    x.orderTotal = (Number(total) + Number(this.state.tax)).toFixed(2);
    x.commission = Number(commission).toFixed(2);
    this.setState({order: x}, () => {
      this.checkOrderChanged();
    });
  }

  addNew() {
    this.setState({addingNew: true});
  }

  showAddNew() {
    if(this.state.addingNew) {
      return "table-row";
    } else {
      return "none";
    }
  }

  showAddNewButton() {
    if(this.state.addingNew) {
      return "none";
    } else {
      return "block";
    }
  }

  addLineItem(item) {

    var x = this.state.order;
    x.items.push(item);

    // update line item and total commissions
    var totalCommission = 0.00;
    for(var i = 0; i < x.items.length; i++) {
  
      x.items[i].commission = affiliate.calculateCommission(x.items[i],x.items[i].listPrice, x.affiliate);
      x.items[i].totalCommission = (Number(x.items[i].commission) * Number(x.items[i].qty)).toFixed(2);
      totalCommission = Number(totalCommission) + Number(x.items[i].totalCommission);
    }
    x.commission = Number(totalCommission).toFixed(2);

    this.setState({order: x});
  }

  updateLineItem(id, property, value) {
    var x = this.state.order;

    for(var i = 0; i < x.items.length; i++) {

      if(x.items[i].id === id)
        x.items[i][property] = value;
      }

    this.setState({order: x}, () => {
      this.calculateTax();
    });
  }

  deleteLineItem(id) {

    var x = this.state.order;

    for(var i = 0; i < x.items.length; i++) {

      if(x.items[i].id === id) {
        x.items.splice(i,1);
        break;
      }    
    }

    this.setState({order: x});
    this.calculateTax();
  }

  setSearchProductIDsTimer(query) {
    clearTimeout(this.searchProductIDs);
    this.setState({productIDQuery: query}, () => {
        setTimeout(this.searchProductIDs,1700);
    });
  }

  searchProductIDs() {

    algolia.getProductsByID(this.state.productIDQuery).then(results => {

      if(results.length > 0) {
        if(results[0].productID.toLowerCase() === this.state.productIDQuery.toLowerCase()) {

          // get any custom pricing
          for(var p=0; p < this.state.pricing.length; p++) {
          
            if(results[0].id === this.state.pricing[p].productID) {
            
              if(this.state.pricing[p].Price) {
                results[0].listPrice = this.state.pricing[p].Price;
              }
              else {
                results[0].originalPrice = results[0].listPrice;
                var percentage = this.state.pricing[p].discount / 100;
                var discount = results[0].listPrice * percentage;
                results[0].listPrice = (results[0].listPrice - discount).toFixed(2);
              }
              
              break;
            }
          }

          db.getProductByID(results[0].id).then(product => {

            console.log('order Detail', results[0], product)
            var lineItem = {
              id: product.id,
              name: product.ProductName,
              cost: product.WholesalePrice,
              listPrice: product.ListPrice,
              qty: 1,
              productID: product.ProductID,
              total: Number(product.ListPrice).toFixed(2),
              Vendors: product.Vendors,
              DefaultVendor: product.DefaultVendor,
              vendorID: product.DefaultVendor,
              sku: product.Vendors.find(v => v.id === product.DefaultVendor) ? product.Vendors.find(v => v.id === product.DefaultVendor).sku : product.Vendors[0].sku
            }

            this.addLineItem(lineItem);
            this.setState({addingNew: false, productIDQuery: ""});
          });
        }
      }
    });
  }

  setSearchProductNamesTimer(query) {
    clearTimeout(this.searchProductNames);
    this.setState({productNameQuery: query}, () => {
        setTimeout(this.searchProductNames,1700);
    });
  }

  searchProductNames() {
    algolia.getProductsByName(this.state.productNameQuery).then(results => {

      // get any custom pricing
      for(var i = 0; i < results.length; i++) {

        for(var p=0; p < this.state.pricing.length; p++) {
            
          if(results[i].id === this.state.pricing[p].productID) {
          
            if(this.state.pricing[p].Price) {
              results[i].listPrice = this.state.pricing[p].Price;
            }
            else {
              results[i].originalPrice = results[i].listPrice;
              var percentage = this.state.pricing[p].discount / 100;
              var discount = results[i].listPrice * percentage;
              results[i].listPrice = (results[i].listPrice - discount).toFixed(2);
            }
            
            break;
          }
        }
      }

      this.setState({searchResults: results, searchInProgress: true });
    });
  }

  addProduct(product) {

    db.getProductByID(product.id).then(p2 => {

      var lineItem = {
        id: product.id,
        name: product.name,
        cost: product.cost,
        listPrice: product.listPrice,
        qty: 1,
        productID: product.productID,
        total: Number(product.listPrice).toFixed(2),
        Vendors: p2.Vendors,
        DefaultVendor: p2.DefaultVendor,
        vendorID: p2.DefaultVendor,
        sku: p2.Vendors.find(v => v.id === p2.DefaultVendor) ? p2.Vendors.find(v => v.id === p2.DefaultVendor).sku : p2.Vendors[0].sku
      }

      this.addLineItem(lineItem);
      this.setState({addingNew: false, productNameQuery: "", searchInProgress: false, orderChanged: true});
    });
  }

  showSearchResults() {
    if(this.state.searchInProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  changeAddress(id) {
    
    var x = this.state.order;
    x.shippingAddressID = id;
    this.setState({order: x}, () => {

      var addresses = this.state.addresses;
      for(var i = 0; i < addresses.length; i++) {
        
        if(addresses[i].address_id === this.state.order.shippingAddressID) {
          this.setState({address: addresses[i]}, () => {
            
            //testing
            //if(this.state.order.customerID === '1343284000000708001') {
              // if it's supposed to be an order for child company
              if(this.state.address.customer_id) { // only exists when a child company address
                console.log('forcing customer ID from ' + this.state.order.customerID + ' to ' + this.state.address.customer_id);
              }
            //}
          });
          
          break;
        }
      }
    });

    this.checkOrderChanged();
  }

  showZohoButton() {
    
    if( (this.state.order.status === 'NEW' || this.state.orderChanged) && this.state.order.customerID) {
      return 'inline-block';
    } else {
      return 'none';
    }
  }

  showODButton() {
    
    if( this.state.order.status === 'APPROVED' 
        && this.state.order.customerID 
        && !this.state.orderChanged 
        && this.state.address.zip 
        && !this.state.order.skuMissed) {

      var isODVendor = false;
      for(var i = this.state.order.items.length - 1; i > -1; i--) 
        if(this.state.order.items[i].vendorID === "7yOKSLSHr7AiyxmS4xa2") {
          isODVendor = true;
          break;
        }

      if(isODVendor)
        return 'inline-block';
      else
        return 'none';

    } else {
      return 'none';
    }
  }

  showODRetryButton() {
    if( this.state.order.status === 'VENDOR REJECTED' && this.state.order.customerID && !this.state.orderChanged && this.state.address.zip ) {

      var isODVendor = false;
      for(var i = this.state.order.items.length - 1; i > -1; i--) 
        if(this.state.order.items[i].vendorID === "7yOKSLSHr7AiyxmS4xa2") {
          isODVendor = true;
          break;
        }

      if(isODVendor)
        return 'inline-block';
      else
        return 'none';

    } else {
      return 'none';
    }
  }

  showVendors() {
    if( (this.state.order.status === 'NEW' || this.state.orderChanged) && this.state.order.customerID) {
      return 'table-cell';
    } else {
      return 'none';
    }
  }

  showEditableLineItems() {
    if(this.state.order.status !== 'SHIPPED' && this.state.order.status !== 'PARTIAL SHIPPED'
        && this.state.order.status !== 'DELIVERED' && this.state.order.status !== 'PARTIAL DELIVERED') {
      return 'table-cell';
    } else {
      return 'none';
    }
  }

  showShipment() {
    if(this.state.order.status === 'SHIPPED' 
        || this.state.order.status === 'PARTIAL SHIPPED'
        || this.state.order.status === 'DELIVERED'
        || this.state.order.status === 'PARTIAL DELIVERED') {
      return 'table-cell';
    } else {
      return 'none';
    }
  }

  showVendorButton() {
    
    return 'none';
  }

  showSaveButton() {
    if(this.state.orderChanged && !this.state.syncInProgress && !this.state.order.salesOrderID)
      return 'inline-block';
    else
      return 'none';
  }

  saveOrder() {
    var order = this.state.order;

    // we need PO numbers to be uniform in case since firebase will query with case sensitivity
    order.poNumber = order.poNumber.toUpperCase();
    
    db.updateOrder(order.id, order).then(() => {
      
      this.props.orderChanged(order);
      this.getBaselineOrder();
      this.setState({syncInProgress: false, orderChanged: false});
      this.handleClose("modal");
    });
  }

  retrySendToOfficeDepot() {
    
    this.sendToOfficeDepot();
  }

  sendToOfficeDepot() {

    this.setState({syncInProgress: true}, () => {
    
      var order = this.state.order;
      order.shippingAddress = this.state.address;

      // we need PO numbers to be uniform in case since firebase will query with case sensitivity
      order.poNumber = order.poNumber.toUpperCase();

      // set default terms that kick in when the sales order becomes and invoice
      order.terms = 15;

      var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendOrderToOfficeDepot";
      
      //console.log(order);
      
      fetch(url, {
        method: "POST", 
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(order)
      })
      .then((response) => {
          if(response.ok) {
            order.status = "SENT TO VENDOR";

            db.updateOrder(order.id, order).then(() => {
              this.props.orderChanged(order);
              this.setState({order:order, syncInProgress: false, orderChanged: false});
              this.getBaselineOrder();
            });
      
          } else {
            console.log("error1");  
            console.log(response);
            this.setState({syncInProgress:false})
          }
      })
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error2: " + error);
      });
      
    });
  }

  vendorChanged(productID, vendorID) {
    var order = this.state.order;

    for(var i = 0; i < order.items.length; i++) {
      if(order.items[i].id === productID) {
        order.items[i].vendorID = vendorID;
        break;
      }
    }

    this.setState({order: order}, () => {
      console.log('updated order:');
      console.log(this.state.order);
    });
  }

  getPurchaseOrderNumbers() {
    var numbers = "";
    if(this.state.order.purchaseOrders) {
      for(var i = 0; i < this.state.order.purchaseOrders.length; i++) {
        numbers = numbers + this.state.order.purchaseOrders[i].number + " ";
      }
    } else {
      numbers = this.state.order.purchaseOrderNumber;
    }
    return numbers;
  }

  showDeleteOrder() {
    if( this.state.order.status === 'NEW' ) {
      return 'inline-block';
    } else {
      return 'none';
    }
  }

  deleteOrder() {
    
    db.deleteOrder(this.state.order.id).then(() => {
      this.props.orderDeleted(this.state.order);
    });
  }

  cancel() {
    this.handleClose("modal");
  }

  showTracking() {
    if(this.state.order.trackingID) {
      return 'block';
    } else {
      return 'none';
    }
  }

  showExpected() {
    if(this.state.order.status === 'NEW') {
      return 'table-cell';
    } else {
      return 'none';
    }
  }

  showStatus() {
    if(this.state.order.status !== 'NEW' && this.state.order.status !== 'APPROVED') {
      return 'table-cell';
    } else {
      return 'none';
    }
  }

  showErrorColumn () {
    if(this.state.order.skuMissed) {
      return 'table-cell';
    } else {
      return 'none';
    }
  }

  getTrackingLink() {

    var link;

    if(this.state.order.carrier === 'UPSG') {
      link = "https://www.ups.com/track?loc=en_US&tracknum=" + this.state.order.trackingID + "&requester=WT/trackdetails";
      return <div onClick={() => window.open(link)} style={{color:'green', cursor:"pointer", fontSize:"12px"}}> {this.state.order.trackingID}</div>
    }
    else 
      return <div style={{color:'gray', fontSize:"12px"}}> {this.state.order.carrier} - {this.state.order.trackingID}</div>
  }

  getStatusColor(lineItem) {
    if(lineItem.listPrice === lineItem.expectedPrice) {
      return "green";
    } else {
      return "red";
    }
  } 

  getStatusIcon(status) {
    if(status === 'backordered')
      return <div style={{color:"orange"}} title='Backordered'><Backordered /></div>
    else if (status === 'rejected')
      return <div style={{color:"maroon"}} title='Rejected'><Rejected /></div>
    else if (status === 'confirmed')
      return <div style={{color:"green"}} title='Confirmed'><Confirmed /></div>
    else if (status === 'shipped')
      return <div style={{color:"green"}} title='Shipped'><Shipped /></div>
    else if (status === 'delivered')
      return <div style={{color:"green"}} title='Delivered'><Shipped /></div>
    else
      return <div></div>
  }

  getUserName() {
    const { order: { userName, shippingAddress } } = this.state;
    if (userName) return userName;
    if (shippingAddress) {
      const {FirstName, LastName} = shippingAddress;
      return `${FirstName} ${LastName}`;
    }
    return '';
  }

  getMicrositeShippingAddress() {
    const { order: { shippingAddress } } = this.state;
    if (shippingAddress) {
      const {Street1, Street2, City, State, Postal} = shippingAddress;
      return (
        <div>
          {Street1}<br />
          {Street2}<br />
          {`${City}, ${State} ${Postal}`}
        </div>
      );
    }
    return '';
  }

  isZohoSendDisabled() {
    const { order } = this.state;
    return order && order.skuMissed
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        
        <IconButton
          aria-label="Edit"
          onClick={() => this.handleClickOpen("modal")}>
          <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
        </IconButton>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          maxWidth="md"
          fullWidth
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Order Details</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Receipt,
                  tabContent: (
                    
                      <GridContainer direction="row" style={{minHeight:"369px"}}>
                        <GridItem xs={3}>
                          <br />
                          <b>Order</b><div style={{fontWeight:400}}>
                            {this.state.order.id}<br />
                            <span style={{color:"#3fa31b"}}>{this.state.order.orderPlaced}</span><br />
                            {this.state.order.status}<br />
                          </div>
                        </GridItem>
                        <GridItem xs={3}>
                          <br />
                          <b>Customer</b><div style={{fontWeight:400}}>
                            {this.state.order.customerName && (
                              <>
                                {this.state.order.customerName}<br />
                              </>
                            )}
                            <span style={{color:"#3fa31b"}}>{this.getUserName()}</span><br />
                            <input type="text" placeholder="PO Number" className={classes.modalTextBox} style={{textAlign:"left", marginLeft:"-3px"}}
                              onChange={(e) => this.updateOrder('poNumber', e.target.value)} value={this.state.order.poNumber} />
                            
                          </div>
                        </GridItem>
                        <GridItem xs={3}>
                          <br />
                          <b>Shipping Address</b>
                          {this.state.order.customerID ? (
                            <>
                              <AddressChooser locations={this.state.addresses} address={this.state.address} onAddressChosen={this.changeAddress} />
                              <div style={{display:this.showTracking()}}>
                                {this.getTrackingLink()}
                              </div>
                            </>
                          ) : (
                            this.getMicrositeShippingAddress()
                          )}
                        </GridItem>
                        <GridItem xs={3}>
                          <br />
                          <b>Sales / Purchase Order</b><div style={{fontWeight:400}}>
                            {this.state.order.salesOrderNumber}<br />
                            {this.getPurchaseOrderNumbers()}
                          </div>
                        </GridItem>
                        <GridItem xs={12}>
                          <br />
                          <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{padding:"0px", width:"40px"}}></TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                        Product ID
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                        Name
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showVendors()}}>
                                      
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                      Qty
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showShipment()}}>
                                      Shipped
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'center', display:this.showStatus()}}>
                                      Status
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                      Price
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showExpected()}}>
                                      Expected
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                      Total
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showErrorColumn()}}>
                                      <HelpIcon fontSize="small" color="disabled" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.order.items.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{padding:"0px", width:"40px"}}>
                                      <Tooltip id="tooltip-top-start" title="Delete Line Item" placement="top" classes={{ tooltip: classes.tooltip }} >
                                        <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this product? This action cannot be undone." confirmed={() => this.deleteLineItem(value.id)} />
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                      {value.productID}
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                        {this.trim(value.name,34)}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showVendors()}}>
                                      <VendorPopover product={value} changed={this.vendorChanged} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right", display:this.showEditableLineItems()}}>
                                      <input type="text" value={value.qty} placeholder="qty" 
                                        onChange={(e) => this.updateLineItem(value.id, "qty", e.target.value)} 
                                        className={classes.modalTextBox} style={{width:"37px"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right", display:this.showShipment()}}>
                                      {value.qty}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showShipment()}}>
                                      {value.qtyShipped}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'center', display:this.showStatus() }}>
                                      {this.getStatusIcon(value.status)}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right", display:this.showShipment() }}>
                                      ${value.listPrice}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right", display:this.showEditableLineItems() }}>
                                      <input type="text" value={value.listPrice} placeholder="price" 
                                        onChange={(e) => this.updateLineItem(value.id, "listPrice", e.target.value)} 
                                        className={classes.modalTextBox} style={{width:"66px"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right", color: this.getStatusColor(value), display:this.showExpected() }}>
                                      ${value.expectedPrice}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                        ${value.total}
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right', display:this.showErrorColumn()}}>
                                      <GridContainer alignItems="center" justify="flex-end">
                                        {!value.sku && (
                                          <Tooltip title="Missing Product SKU" placement="top" classes={{ tooltip: classes.tooltip }} >
                                            <HelpIcon fontSize="small" color="error" />
                                          </Tooltip>
                                        )}
                                      </GridContainer>
                                    </TableCell>
                                </TableRow>
                                ))}
                                <TableRow style={{display:this.showAddNew()}}>
                                    <TableCell style={{padding:"0px", width:"50px"}}>
                                      <IconButton onClick={this.addLineItem} style={{color:"green"}}>
                                          <Add />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                      <input type="text" placeholder="Product ID" className={classes.modalTextBox} style={{textAlign:"left"}}
                                          onChange={(e) => this.setSearchProductIDsTimer(e.target.value)} value={this.state.productIDQuery} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", position: "relative"}}>
                                      <input type="text" placeholder="Product Name" className={classes.modalTextBox} style={{textAlign:"left"}} 
                                        onChange={(e) => this.setSearchProductNamesTimer(e.target.value)} value={this.state.productNameQuery} />
                                      <div id="divSearchResults" style={{position: "absolute", height:"120px", width:"100%", backgroundColor:"#efefef", borderRadius:"6px", marginLeft:"6px", overflow:"auto", padding:"10px", fontSize:"11px", border:"solid 1px green", display:this.showSearchResults()}} >
                                        {this.state.searchResults.map((value, index) => (
                                          <div key={index} style={{cursor:"pointer", color:"232323"}} 
                                            onClick={() => this.addProduct(value)}><span style={{color:"green"}}>{value.productID}</span> - {this.trim(value.name,54)}</div>
                                        ))}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{padding:"6px", width:"50px" }}>
                                      <input type="text" placeholder="Qty" className={classes.modalTextBox} style={{display:"none"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"6px", width:"80px" }}>
                                      <input type="text" placeholder="Price" className={classes.modalTextBox} style={{display:"none"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                        $0.00
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{padding:"0px", width:"40px"}}>
                                      <IconButton onClick={this.addNew} style={{display:this.showAddNewButton()}}>
                                          <Add />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showVendors()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showEditableLineItems()}}></TableCell> 
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showStatus()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showEditableLineItems()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showExpected()}}></TableCell>
                                    <TableCell style={{padding:"3px", display:this.showErrorColumn()}}></TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                        <b>Tax</b>
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                        <b>${this.state.tax.toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{padding:"0px", width:"40px"}}></TableCell>
                                    <TableCell style={{padding:"3px"}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showVendors()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showEditableLineItems()}}></TableCell> 
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showStatus()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showEditableLineItems()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showExpected()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showErrorColumn()}}></TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                        <b>${this.state.order.orderTotal}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{padding:"0px", width:"40px"}}></TableCell>
                                    <TableCell style={{padding:"3px"}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showVendors()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showEditableLineItems()}}></TableCell> 
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showStatus()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showShipment()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showEditableLineItems()}}></TableCell>
                                    <TableCell style={{padding:"3px",display:this.showExpected()}}></TableCell>
                                    <TableCell style={{padding:"3px", display:this.showErrorColumn()}}></TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                      <div title={this.getAffiliateName()} style={{color:'maroon', cursor:"pointer"}}>
                                        Commission
                                      </div>
                                    </TableCell>
                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                      <div title={this.getAffiliateName()} style={{color:'maroon', cursor:"pointer"}}>
                                        ${this.getCommission()}
                                      </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </GridItem>
                        <GridItem xs={12}>
                          <br />
                          <b>Notes</b><div style={{fontWeight:400}}>
                            {this.getNotes()}
                          </div>
                        </GridItem>
                      </GridContainer>
                  )
                },
                {
                  tabName: "Invoices",
                  tabIcon: Event,
                  tabContent: (
                    <GridContainer direction="row" style={{minHeight:"369px"}}>
                      <GridItem xs={12}>
                          <br />
                          <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                  <TableCell style={{padding:"3px"}}>
                                    Date
                                  </TableCell>
                                  <TableCell style={{padding:"3px"}}>
                                    Vendor
                                  </TableCell>
                                  <TableCell style={{padding:"3px", textAlign:'center'}}>
                                    Bill #
                                  </TableCell>
                                  <TableCell style={{padding:"3px", textAlign:'center'}}>
                                    Status
                                  </TableCell>
                                  <TableCell style={{padding:"3px", textAlign:'right'}}>
                                    Amount
                                  </TableCell>
                                  <TableCell style={{padding:"3px", textAlign:'right'}}>
                                    
                                  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.bills.map((value, index) => (
                              <TableRow key={index}>
                                <TableCell style={{padding:"3px"}}>
                                  {dateConverter(value.created)}
                                </TableCell>
                                <TableCell style={{padding:"3px"}}>
                                    {value.vendorName}
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'center'}}>
                                  {value.invoiceID}
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'center'}}>
                                  {value.status}
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                  {value.amountGross}
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                  <Button color="success" small>Create Invoice</Button>
                                </TableCell>
                              </TableRow>
                              ))}
                              <TableRow>
                                <TableCell style={{padding:"0px"}}>
                                  <IconButton style={{color:"green"}}>
                                      <NewInvoice />
                                  </IconButton>
                                </TableCell>
                                <TableCell style={{padding:"3px"}}>
                                </TableCell>
                                <TableCell style={{padding:"3px"}}>
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                </TableCell>
                                <TableCell style={{padding:"3px", textAlign:'right'}}>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>

              <GridContainer>
                <GridItem xs={2} style={{textAlign:"left"}}>
                  <div style={{display:this.showDeleteOrder(), textAlign:"left"}}>
                    <Tooltip id="tooltip-top-start" title="Delete Order" placement="top" classes={{ tooltip: classes.tooltip }} >
                      <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this order? This action cannot be undone." confirmed={() => this.deleteOrder()} />
                    </Tooltip>
                  </div>
                </GridItem>
                <GridItem xs={10}>
                  <CircularProgress className={classes.progress} color="secondary" 
                    style={{display:this.showSyncProgressIndicator(), marginRight:"23px"}} />

                  <div style={{display:this.showActionButtons()}}>
                    <Button onClick={this.cancel} >
                      Cancel
                    </Button>
                    &nbsp;
                    <Button style={{display:this.showSaveButton()}}
                      onClick={() => { this.saveOrder(); }}
                      color="success">
                      Save
                    </Button>
                    &nbsp;
                    <Button style={{display:this.showZohoButton()}}
                      onClick={() => { this.sendToZoho(); }}
                      color="success"
                      disabled={this.isZohoSendDisabled()}
                    >
                      Send to Zoho
                    </Button>
                    &nbsp;
                    <Button style={{display:this.showODButton()}}
                      onClick={() => { this.sendToOfficeDepot(); }}
                      color="danger">
                      Send to Office Depot
                    </Button>
                    &nbsp;
                    <Button style={{display:this.showODRetryButton()}}
                      onClick={() => { this.retrySendToOfficeDepot(); }}
                      color="danger">
                      Retry Send to Office Depot
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

OrderDetails = connect(mapStateToProps)(OrderDetails);
export default withStyles(modalStyle)(OrderDetails);