//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PhotosLoaded } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Tooltip from "@material-ui/core/Tooltip";
//import IconButton from "@material-ui/core/IconButton";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//icons
//import Close from "@material-ui/icons/Close";
import PhotoCard from "components/PhotoCard/PhotoCard.jsx";
import PhotoUpload from "components/PhotoUpload/PhotoUpload.jsx";
import PhotoImporter from "components/PhotoImporter/PhotoImporter.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class PhotosList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: []
    }
    this.photoUpdated = this.photoUpdated.bind(this);
  }

  componentWillMount() {
    this.refreshPhotos();
  }

  photoUpdated() {
    //this.refreshPhotos();
  }

  refreshPhotos() {
    db.getPhotos().then(snapshot => {
           
      var photos = [];
      snapshot.forEach((doc) => {

          var obj = doc.data();
          obj.id = doc.id;
          photos.push(obj);    
      });

      this.setState({photos: photos});
      this.props.onPhotosLoaded(photos);
    });
  }

  addPhoto(url) {

  }

  importComplete() {
    console.log('import complete');
  }

  adminOnly() {
    if(this.props.user.userType === 'admin') 
      return "block";
    else
      return "none";
  }


  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    ReactGA.pageview("teacher.hanyu.co/photos");

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Photos</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={1} style={{textAlign:"right"}}>
                  <Tooltip
                        id="tooltip-top-start"
                        title="Add Photo"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                    >
                      <PhotoUpload onUploadSuccess={this.addPhoto} />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={1} style={{textAlign:"right", display:this.adminOnly()}}>
                  <Tooltip
                        id="tooltip-top-start"
                        title="Import Photos"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                    >
                      <PhotoImporter onImportComplete={this.importComplete} />
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {this.state.photos.map((photo,index) => (
                    <PhotoCard key={index} photo={photo} onPhotoUpdated={this.photoUpdated}  />
                ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onPhotosLoaded: PhotosLoaded }, dispatch);  
}

PhotosList = connect(mapStateToProps, mapDispatchToProps)(PhotosList);
export default withStyles(styles)(PhotosList);
