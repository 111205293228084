//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//algolia full text search
import { algolia } from 'assets/algolia';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import checkRadioStyle from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

//components
import Accordion from "components/Accordion/Accordion.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Check from "@material-ui/icons/Check";

const uuidv4 = require('uuid/v4');

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

function compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
}
  
class BudgetableItemsEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            results: [],
            products: [],
            searching: false,
            categories: [],
            subCat1: [],
            subCat2: [],
            subCat3: [],
            checked: [],
            checkedSubCat1: [],
            checkedSubCat2: [],
            checkedSubCat3: [],
            manufacturers: [],
            visibleManufacturers: [],
            resultsCount: 0,
            selectedCategory: "",
            selectedSubCat1:"",
            selectedSubCat2:"",
            selectedSubCat3:""
        };

        //lookups
        db.getManufacturers().then(manufacturers => {
            this.setState({manufacturers: manufacturers});
        });

        db.getCategories().then(categories => {
            this.setState({categories: categories.sort(compareName), selectedEnabled: categories[0].id}, () => {
              this.loadSubCategories();
            });
        });

        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.createRule = this.createRule.bind(this);
    }

    // dialog open/close

    handleClickOpen(modal) {
        
      if(this.props.itemExceptions) 
        this.setState({exceptions: this.props.itemExceptions}, () => {
            this.refreshProducts();
        });
      else 
        this.refreshProducts();

      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    // search filters

    loadSubCategories() {
        // show subcategories for the selected category
        for(var i = 0; i < this.state.categories.length; i++) {
          if(this.state.categories[i].id === this.state.selectedEnabled) {
    
            var subCat1 = this.state.categories[i].SubCat1.sort(compareName);
            var subCat2 = this.state.categories[i].SubCat2.sort(compareName);
            var subCat3 = this.state.categories[i].SubCat3.sort(compareName);
    
            this.setState({subCat1: subCat1});
            this.setState({subCat2: subCat2});
            this.setState({subCat3: subCat3});
            break;
          }
        }
    }

    handleToggle(value) { // manufacturers
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        this.setState({ checked: newChecked }, () => {
          this.refreshProducts();
        });
    }

    toggleSubCat1(value) {
        const checked = this.state.checkedSubCat1;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checkedSubCat1: newChecked }, () => {
            this.refreshProducts();
        });
    }

    toggleSubCat2(value) {
        const checked = this.state.checkedSubCat2;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checkedSubCat2: newChecked }, () => {
            this.refreshProducts();
        });
    }

    toggleSubCat3(value) {
        const checked = this.state.checkedSubCat3;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checkedSubCat3: newChecked }, () => {
            this.refreshProducts();
        });
    }

    handleChangeEnabled(event) { // category
        
        this.setState({ selectedEnabled: event.target.value, checkedSubCat1: [], checkedSubCat2: [], visibleManufacturers: [] }, () => {
            this.refreshProducts();
            this.loadSubCategories();
        }); 
    }

    getCategoryNames() {

        var id = this.state.selectedEnabled;
        for(var i = 0; i < this.state.categories.length; i++) {
            if(this.state.categories[i].id === id) {
                
                var category = this.state.categories[i];
                this.setState({selectedCategory: category.Name});

                var selectedSubCat1 = "";
                for(var j = 0; j < this.state.checkedSubCat1.length; j++) {
                    for(var k = 0; k < category.SubCat1.length; k++) {
                        if(this.state.checkedSubCat1[j] === category.SubCat1[k].id) {
                            selectedSubCat1 = selectedSubCat1 + category.SubCat1[k].Name + ", ";
                        }
                    }
                }
                if(selectedSubCat1 === "") { selectedSubCat1 = "(all)  " }
                this.setState({selectedSubCat1: selectedSubCat1.substr(0, selectedSubCat1.length - 2)});


                var selectedSubCat2 = "";
                for(var l = 0; l < this.state.checkedSubCat2.length; l++) {
                    for(var m = 0; m < category.SubCat2.length; m++) {
                        if(this.state.checkedSubCat2[l] === category.SubCat2[m].id) {
                            selectedSubCat2 = selectedSubCat2 + category.SubCat2[m].Name + ", ";
                        }
                    }
                }
                if(selectedSubCat2 === "") { selectedSubCat2 = "(all)  " }
                this.setState({selectedSubCat2: selectedSubCat2.substr(0, selectedSubCat2.length - 2)});


                var selectedSubCat3 = "";
                for(var n = 0; n < this.state.checkedSubCat3.length; n++) {
                    for(var o = 0; o < category.SubCat3.length; o++) {
                        if(this.state.checkedSubCat3[n] === category.SubCat3[o].id) {
                            selectedSubCat3 = selectedSubCat3 + category.SubCat3[o].Name + ", ";
                        }
                    }
                }
                if(selectedSubCat3 === "") { selectedSubCat3 = "(all)  " }
                this.setState({selectedSubCat3: selectedSubCat3.substr(0, selectedSubCat3.length - 2)});


                var manufacturers = "";
                for(var p = 0; p < this.state.checked.length; p++) {
                    for(var q = 0; q < this.state.manufacturers.length; q++) {
                        if(this.state.checked[p] === this.state.manufacturers[q].id) {
                            manufacturers = manufacturers + this.state.manufacturers[q].Name + ", ";
                        }
                    }
                }
                if(manufacturers === "") { manufacturers = "(all)  " }
                this.setState({selectedManufacturers: manufacturers.substr(0, manufacturers.length - 2)});


                break;
            }
        }
    }

    byProductID(a,b) {

        var valueA = a.productID;
        var valueB = b.productID;
      
        if (valueA < valueB)
          return -1;
        if (valueA > valueB)
          return 1;
        return 0;
    }

    refreshProducts() {

        this.getCategoryNames();

        algolia.searchProducts("", this.state.checked, this.state.selectedEnabled, this.state.checkedSubCat1, this.state.checkedSubCat2, this.state.checkedSubCat3).then(results => {
        
            var manufacturers = [];
            
            for(var i = 0; i < results.length; i++) {
            
                // ensure a value because react is picky about that
                if(!results[i].isHidden)
                    results[i].isHidden = false;

                // get manufacturers name
                for(var j = 0; j < this.state.manufacturers.length; j++) {
                    if(results[i].manufacturerID === this.state.manufacturers[j].id) {
                        results[i].manufacturer = this.state.manufacturers[j].Name;

                        var isFound = false;
                        for(var k=0; k < manufacturers.length; k++) {
                            if(manufacturers[k].id === results[i].manufacturerID) {
                                isFound = true;
                                break;
                            }
                        }
                        if(!isFound) 
                            manufacturers.push({id: results[i].manufacturerID, Name: results[i].manufacturer});
                    }
                }

                // get any existing exceptions
                for(var p=0; p < this.state.exceptions.length; p++) {
                    if(results[i].id === this.state.exceptions[p].productID) {
                        results[i].isHidden = this.state.exceptions[p].isHidden;
                        break;
                    }
                }
            }
            
            results.sort(this.byProductID);
            this.setState({resultsCount: results.length});

            if(this.state.checked.length > 0) { // if we are already filtering by manufacturers
                this.setState({products: results });
            } else { // otherwise update the manufacturer filters
                manufacturers.sort(compareName);
                this.setState({products: results, visibleManufacturers: manufacturers });
            }
        });
    }

    updateExceptions(product, value) {

        // update exemptions
        var exceptions = this.state.exceptions;

        var isFound = false;
        for(var i = 0; i < exceptions.length; i++) {
            if(exceptions[i].productID === product.id) {
                exceptions[i].isHidden = value;
                isFound = true;
                break;
            }
        }

        // update product results
        var products = this.state.products;
        for(var j = 0; j < products.length; j++) {
            if(products[j].id === product.id) {
                products[j].isHidden = value;
                if(!isFound) {
                    var obj = { id: uuidv4(), isHidden: true, productID: product.id, CustomerID: this.props.customer.id, modified: Math.round((new Date()).getTime() / 1000)}
                    obj.product = { ProductName: products[j].name, ProductID: products[j].productID };

                    products[j].exceptionID = obj.id; // update product in the list
                    exceptions.push(obj); // add to exceptions
                }                
                break;
            }
        }

        this.setState({products: products});
        this.setState({exceptions:exceptions});
    }

    resultsCount () {
        if(this.state.resultsCount === 50) {
          return <span>more than 50 matching products</span>
        } else {
          return <span>{this.state.resultsCount} matching products found</span>
        }
    }

    showIconButton() {
        if(this.props.iconButton) {
          return "block";
        } else {
          return "none";
        }
        
    }
  
    showButton() {
        if(this.props.button) {
            return "block";
        } else {
            return "none";
        }
    }

    trim(value, maxLength) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    }

    createRule() {
        var obj = { id: uuidv4(), CustomerID: this.props.customer.id, modified: Math.round((new Date()).getTime() / 1000)}
        
        obj.description = "Category: " + this.state.selectedCategory + ", SubCat1: " + this.state.selectedSubCat1 + ", SubCat2: " + this.state.selectedSubCat2 + ", SubCat3: " + this.state.selectedSubCat3 + ", Manufacturer: " + this.state.selectedManufacturers;

        obj.filter = { category: this.state.selectedEnabled, subCat1: this.state.checkedSubCat1, subCat2: this.state.checkedSubCat2, subCat3: this.state.checkedSubCat3, manufacturers: this.state.checked }
        
        this.props.onNewRule(obj);
    }

    close() {
        this.props.onChange(this.state.exceptions);
        this.handleClose("modal");
    }

    render() {

      const { classes } = this.props;

      return (
        <div>
          <Tooltip
            id="tooltip-top"
            title="Edit Budgetable Items"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton aria-label="Edit" onClick={() => this.handleClickOpen("modal")} style={{display:this.showIconButton()}}>
              <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
            </IconButton>
          </Tooltip>
          <Button aria-label="Edit" onClick={() => this.handleClickOpen("modal")} style={{display:this.showButton()}}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />Open the Budgetable Items Editor
          </Button>

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            maxWidth="md"
            fullWidth
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">


            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >
              <GridContainer>
                  <GridItem xs={6}>
                    <h4 className={classes.modalTitle}>Budgetable Items Editor</h4>
                  </GridItem>
                  <GridItem xs={6} style={{textAlign:"right"}}>
                    <Button onClick={() => this.close()} >
                        Close
                    </Button>
                  </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>

                <Card style={{marginTop:"-10px"}}>
                    <CardBody style={{marginTop:"-23px"}}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h5>Rule Definition</h5>
                            </GridItem>
                            <GridItem xs={2}>
                                <b>Category</b><div style={{fontSize:"13px", color:"#232323" }}>
                                    {this.state.selectedCategory}
                                </div>
                            </GridItem>
                            <GridItem xs={2}>
                                <b>Subcategories</b><div style={{fontSize:"13px", color:"#232323" }}>
                                    {this.state.selectedSubCat1}
                                </div>
                            </GridItem>
                            <GridItem xs={2}>
                                <b>Subcategories</b><div style={{fontSize:"13px", color:"#232323" }}>
                                    {this.state.selectedSubCat2}
                                </div>
                            </GridItem>
                            <GridItem xs={2}>
                                <b>Color / Scent</b><div style={{fontSize:"13px", color:"#232323" }}>
                                    {this.state.selectedSubCat3}
                                </div>
                            </GridItem>
                            <GridItem xs={2}>
                                <b>Manufacturers</b><br />
                                {this.state.selectedManufacturers}
                            </GridItem>
                            <GridItem xs={2} style={{textAlign:"right"}}>
                                <Button onClick={this.createRule}> Create Rule </Button>    
                            </GridItem>
                            <GridItem xs={12} style={{textAlign:"center", fontSize:"11px", color:"green", marginTop:"17px"}}>
                                {this.resultsCount()} 
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <GridContainer direction="row">
                            <GridItem xs={3} >
                                <Accordion
                                    active={0}
                                    activeColor="success"
                                    collapses={[
                                        {
                                        title: "Categories",
                                        content: (
                                            <div>
                                            {this.state.categories.map((category,index) => (
                                                <div key={index}>
                                                <FormControlLabel
                                                    control={
                                                    
                                                    <Radio
                                                        checked={this.state.selectedEnabled === category.id}
                                                        onChange={this.handleChangeEnabled}
                                                        value={category.id}
                                                        name={category.Name}
                                                        icon={<FiberManualRecord className={classes.radioUnchecked} style={{color:"#efefef"}} />}
                                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} style={{color:"green"}} />}
                                                        classes={{
                                                        checked: classes.radio,
                                                        root: classes.radioRoot
                                                        }}
                                                    />
                                                    }
                                                    classes={{ label: classes.label }}
                                                    label={category.Name}
                                                    style={{marginTop:"-17px", marginLeft:"0px"}}
                                                />
                                                </div>
                                            ))}
                                            </div>
                                        )
                                        },
                                        {
                                        title:"Subcategories",
                                        content: (
                                            <div className={classes.customExpandPanel}>
                                                <div className={ classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal }  >
                                                
                                                {this.state.subCat1.map((subcat,index) => (
                                                <div key={index} style={{marginTop:"-10px", marginLeft:"0px"}}>
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => this.toggleSubCat1(subcat.id)}
                                                                checked={
                                                                    this.state.checkedSubCat1.indexOf(subcat.id) !== -1
                                                                    ? true
                                                                    : false
                                                                }
                                                                checkedIcon={ <Check className={classes.checkedIcon} style={{color:"green"}} /> }
                                                                icon={ <Check className={classes.uncheckedIcon} style={{color:"#efefef"}} /> }
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{ label: classes.label }}
                                                        label={subcat.Name}
                                                    />
                                                </div>
                                                ))}
                                                
                                                </div>
                                            </div>
                                        )
                                        },
                                        {
                                        title:"Subcategories",
                                        content: (
                                            <div className={classes.customExpandPanel}>
                                            <div className={ classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal }  >
                                                
                                            {this.state.subCat2.map((subcat,index) => (
                                                <div key={index} style={{marginTop:"-10px", marginLeft:"0px"}}>
                                                <FormControlLabel key={index}
                                                    control={
                                                        <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => this.toggleSubCat2(subcat.id)}
                                                        checked={
                                                            this.state.checkedSubCat2.indexOf(subcat.id) !== -1
                                                            ? true
                                                            : false
                                                        }
                                                        checkedIcon={ <Check className={classes.checkedIcon} style={{color:"green"}} /> }
                                                        icon={ <Check className={classes.uncheckedIcon} style={{color:"#efefef"}} /> }
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                        />
                                                    }
                                                    classes={{ label: classes.label }}
                                                    label={subcat.Name}
                                                />
                                                </div>
                                                ))}
                                                
                                            </div>
                                            </div>
                                        )
                                        },
                                        {
                                        title:"Color / Scent / Other",
                                        content: (
                                            <div className={classes.customExpandPanel}>
                                            <div className={ classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal }  >
                                                
                                            {this.state.subCat3.map((subcat,index) => (
                                                <div key={index} style={{marginTop:"-10px", marginLeft:"0px"}}>
                                                <FormControlLabel key={index}
                                                    control={
                                                        <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => this.toggleSubCat3(subcat.id)}
                                                        checked={
                                                            this.state.checkedSubCat3.indexOf(subcat.id) !== -1
                                                            ? true
                                                            : false
                                                        }
                                                        checkedIcon={ <Check className={classes.checkedIcon} style={{color:"green"}} /> }
                                                        icon={ <Check className={classes.uncheckedIcon} style={{color:"#efefef"}} /> }
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                        />
                                                    }
                                                    classes={{ label: classes.label }}
                                                    label={subcat.Name}    
                                                />
                                                </div>
                                                ))}
                                            </div>
                                            </div>
                                        )
                                        },
                                        {
                                        title:"Manufacturers",
                                        content: (
                                            <div className={classes.customExpandPanel}>
                                            <div className={ classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal }  >
                                                {this.state.visibleManufacturers.map((manufacturer,index) => (
                                                <div key={index} style={{marginTop:"-10px", marginLeft:"0px"}}>
                                                <FormControlLabel key={index}
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            onClick={() => this.handleToggle(manufacturer.id)}
                                                            checked={
                                                                this.state.checked.indexOf(manufacturer.id) !== -1
                                                                ? true
                                                                : false
                                                            }
                                                            checkedIcon={ <Check className={classes.checkedIcon} style={{color:"green"}} /> }
                                                            icon={ <Check className={classes.uncheckedIcon} style={{color:"#efefef"}} /> }
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}
                                                        />
                                                    }
                                                    classes={{ label: classes.label }}
                                                    label={manufacturer.Name}
                                                />
                                                </div>
                                                ))}
                                            </div>
                                            </div>
                                        )
                                        }
                                    ]} 
                                />
                            </GridItem>
                            <GridItem xs={9}>
                                <GridItem xs={12} >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Name
                                                </TableCell>
                                                <TableCell style={{width:"69px", textAlign:"right"}}>
                                                    Excluded
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.products.map((value, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                <div title={value.name} style={{cursor:"pointer"}}><span style={{color:"green"}}>{value.productID}</span> - {this.trim(value.name,43)}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onChange={(e) => this.updateExceptions(value, e.target.checked)}
                                                        checked={value.isHidden}
                                                        checkedIcon={ <Check style={{color:"green"}} /> }
                                                        icon={ <Check style={{color:"#efefef"}} className={classes.iconCheckbox} /> }
                                                        
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </GridItem>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>

            </DialogContent>
          </Dialog>
        </div>
      );
    }
  }
  
  const mapStateToProps = state => {
    return state;
  }

  BudgetableItemsEditor = connect(mapStateToProps)(BudgetableItemsEditor);
  export default withStyles([modalStyle,checkRadioStyle])(BudgetableItemsEditor);