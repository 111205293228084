//react
import React from "react";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TasksLoaded } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSelect from "components/Select/Select.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
//import Accordion from "components/Accordion/Accordion.jsx";

//spiffy components
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import TaskEditor from "components/TaskEditor/TaskEditor.jsx";

// @material-ui/icons
import Calendar from "@material-ui/icons/CalendarToday";
import Task from "@material-ui/icons/AssignmentTurnedIn";

// dates
import moment from 'moment';

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class TaskList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      filtered: [],
      reminderedTasks: [],
      projects:[ ],
      filteredProjects: [],
      ownerFilter: "all",
      statusFilter: "openIP",
      categoryFilter: "all",
      projectFilter: "all",
      viewFilter: "priority",
      currentSort: 'importance',
      sizeFilter: 'all',
      queryText: '',
      timePeriods: [],
      timingView: 'days'
    }

    var people = [];
    people.push({key:'all',label:'All'});
    //for(var i = 0; i < this.props.people.length; i++) 
      
    
    people.push({ key: 17, label: 'Michael' });
    people.push({ key: 23, label: 'Bo' });

    this.state.people = people;

    this.loadTasks = this.loadTasks.bind(this);
    this.taskCreated = this.taskCreated.bind(this);
    this.taskUpdated = this.taskUpdated.bind(this);
    this.loadTimePeriods = this.loadTimePeriods.bind(this);

    console.log('constructor()')
  }

  componentWillMount() {
    
    console.log('componentWillMount()')
    
    this.loadProjects();
    this.loadTimePeriods(this.state.timingView);
    this.loadTasks();

    //setInterval(this.loadTasks,180000)
  }

  componentWillUnmount() {
    clearInterval(this.loadTasks);
  }

  componentDidUpdate(prevProps) {
        
    if(prevProps.searchQuery !== this.props.searchQuery) {
      this.setState({queryText: this.props.searchQuery}, () => {
        if(this.state.queryText.length > 0) {
          this.searchTasks();
        } else {
          this.refreshTasks();
        }
      });
    }
  }

  byDue(a,b) {

    var x = moment(a.DueDate).valueOf();
    var y = moment(b.DueDate).valueOf();

    if(isNaN(x)) x = 0;
    if(isNaN(y)) y = 0;
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  byReminder(a,b) {

    var x = moment(a.ReminderDate).valueOf();
    var y = moment(b.ReminderDate).valueOf();

    if(isNaN(x)) x = 0;
    if(isNaN(y)) y = 0;
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  byCategory(a,b) {

    var x = a.Category.toLowerCase();
    var y = b.Category.toLowerCase();
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  byName(a,b) {

    var x = a.Name.toLowerCase();
    var y = b.Name.toLowerCase();
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  byOwner(a,b) {

    var x = a.Owner.toLowerCase();
    var y = b.Owner.toLowerCase();
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  byImportance(a,b) {

    var x = Number(a.Priority) * Number(a.Impact);
    var y = Number(b.Priority) * Number(b.Impact);
  
    if (x < y)
      return 1;
    if (x > y)
      return -1;
    return 0;
  }

  bySize(a,b) {

    var x = 1;
    if (a.Size === 's')
      x = 2;
    else if (a.Size === 'm')
      x = 3;
    else if (a.Size === 'l')
      x = 4;
    else if (a.Size === 'xl')
      x = 5;
    else if (a.Size === 'xxl')
      x = 6;

    var y = 1;
    if (b.Size === 's')
      y = 2;
    else if (b.Size === 'm')
      y = 3;
    else if (b.Size === 'l')
      y = 4;
    else if (b.Size === 'xl')
      y = 5;
    else if (b.Size === 'xxl')
      y = 6;

    if (x < y)
      return 1;
    if (x > y)
      return -1;
    return 0;
  }

  byProject(a,b) {

    var x = a.ProjectName.toLowerCase();
    var y = b.ProjectName.toLowerCase();
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }

  loadProjects() {
    var projects = [];
    projects.push({key:"all", label:"All"});
    /*
    for(var i = 0; i < this.props.projects.length; i++) {
      if(this.props.projects[i].IsActive)
        projects.push({key:this.props.projects[i].id, label:this.props.projects[i].Name });
    }
    */
    projects.push({key:"general", label: "General"});
    this.setState({projects:projects});
  }

  loadTimePeriods(timingView) {
    var timePeriods = [];

    var today = moment();
    today.hour(0); today.minute(0); today.second(0); today.millisecond(0);
    
    if(timingView === 'days') {
      timePeriods.push({ label: "Today", startDate: 1000, endDate: today.add(1,'days').valueOf() });
      timePeriods.push({ label: "Tomorrow", startDate: today.valueOf(), endDate: today.add(1,'days').valueOf() });
      timePeriods.push({ label: today.format("dddd"), startDate: today.valueOf(), endDate: today.add(1,'days').valueOf() });
      timePeriods.push({ label: today.format("dddd"), startDate: today.valueOf(), endDate: today.add(1,'days').valueOf() });
    } else { // weeks
      timePeriods.push({ label: "Current", startDate: 1000, endDate: today.add(7,'days').valueOf() });
      timePeriods.push({ label: "7 Days", startDate: today.valueOf(), endDate: today.add(7,'days').valueOf() });
      timePeriods.push({ label: "14 Days", startDate: today.valueOf(), endDate: today.add(7,'days').valueOf() });
      timePeriods.push({ label: "21 Days", startDate: today.valueOf(), endDate: today.add(7,'days').valueOf() });
    }
    
    this.setState({timePeriods:timePeriods, timingView: timingView});
  }

  updateMetadata(tasks) {
    for(var i = tasks.length - 1; i > -1; i--) {

        for(var j = 0; j < this.state.projects.length; j++) {
            if(tasks[i].ProjectID === this.state.projects[j].id) {
                tasks[i].ProjectName = this.state.projects[j].Name;
                break;
            }
        }
      /*
      for(var j = 0; j < this.props.projects.length; j++) {

        if(tasks[i].ProjectID === this.props.projects[j].id) {
          if(!this.props.projects[j].IsActive) // don't load notes from inactive projects
            tasks.splice(i,1);
          else {
            // assign project name
            tasks[i].ProjectName = this.props.projects[j].Name;

            // make sure we have a people collection
            if(!tasks[i].People) {
              tasks[i].People = [];
            }
          }
            
          break;
        }
      }
      */

    }
  }

  updateSnoozedTasks(tasks) {
    
    for(var i = 0; i < tasks.length; i++) {
      if(tasks[i].SnoozeDate) { 
        if(tasks[i].SnoozeDate !== '' ) { 
          if(moment(tasks[i].SnoozeDate).valueOf() < moment().valueOf()) {
            tasks[i].SnoozeDate = '';
            db.updateTask(tasks[i].id, tasks[i]);
          }
        }
      }
    }
  }

  getReminderedTasks(tasks) {    
    var reminders = [];

    for(var i = 0; i < tasks.length; i++) {
      if(!tasks[i].ReminderDate) tasks[i].ReminderDate = "";
      
      if(tasks[i].ReminderDate !== '' || tasks[i].Pinned ) { 

        if(tasks[i].Pinned && tasks[i].Status !== 'complete' && tasks[i].Status !== 'cancelled') {
          tasks[i].header = 'pinned'
          reminders.push(tasks[i]);
        } else if( moment(tasks[i].ReminderDate).valueOf() <= moment().valueOf() && tasks[i].Status !== 'complete' && tasks[i].Status !== 'cancelled' ) {
          tasks[i].header = 'reminderDate'
          reminders.push(tasks[i]);
        } 
      }
    }

    this.setState({reminderedTasks: reminders})
  }

  loadTasks() {
    
    db.getTasks().then(tasks => {

      this.updateMetadata(tasks);
      this.updateSnoozedTasks(tasks);
      this.getReminderedTasks(tasks);

      this.props.onTasksLoaded(tasks);
      this.setState({tasks: tasks}, this.refreshTasks);
    });
  }

  refreshTasks() {

    this.setState({filteredProjects: this.state.projects});

    var filtered = [];
    var tasks = this.state.tasks;

    for(var i = 0; i < tasks.length; i++) {
      
    if(tasks[i].ProjectID === this.state.projectFilter || this.state.projectFilter === 'all') {
        if(tasks[i].Status === this.state.statusFilter 
            || (tasks[i].Status === 'open' && this.state.statusFilter === 'openIP') 
            || (tasks[i].Status === 'inProgress' && this.state.statusFilter === 'openIP') 
            || this.state.statusFilter === 'all') {
            if(tasks[i].Owner === this.state.ownerFilter || this.state.ownerFilter === 'all') {
                if(tasks[i].Category === this.state.categoryFilter || this.state.categoryFilter === 'all') {
                if(tasks[i].Size === this.state.sizeFilter || this.state.sizeFilter === 'all') {
                    if( (this.state.viewFilter === 'due' && (tasks[i].DueDate || tasks[i].ReminderDate ))  ||
                        (this.state.viewFilter === 'snoozed' && tasks[i].SnoozeDate) ||
                        (this.state.viewFilter === 'priority' && !tasks[i].SnoozeDate ) ||
                        (this.state.viewFilter === 'all')
                    ) 
                    {
                    if(!tasks[i].ExpirationDate) tasks[i].ExpirationDate = ""; 
                    if(!tasks[i].SnoozeDate) tasks[i].SnoozeDate = ""; 
                    if(!tasks[i].ReminderDate) tasks[i].ReminderDate = "";  
                    if(!tasks[i].Size) tasks[i].Size = "";  

                    tasks[i].dueDate = moment(tasks[i].DueDate).valueOf();
                    tasks[i].reminderDate = moment(tasks[i].ReminderDate).valueOf();

                    filtered.push(tasks[i]);
                    }
                }
                }
            }
        } 
    }
      
    }

    this.sortBy(this.state.currentSort, filtered);
  }

  searchTasks() {
    var filtered = [];
    var tasks = this.state.tasks;

    for(var i = 0; i < tasks.length; i++) {
      if(tasks[i].Name.toUpperCase().indexOf(this.state.queryText.toUpperCase()) > -1) {
        if(!tasks[i].ExpirationDate) tasks[i].ExpirationDate = ""; 
        if(!tasks[i].SnoozeDate) tasks[i].SnoozeDate = ""; 
        if(!tasks[i].ReminderDate) tasks[i].ReminderDate = "";  
        if(!tasks[i].Size) tasks[i].Size = "";  

        filtered.push(tasks[i]);
      } 
    }

    this.sortBy(this.state.currentSort, filtered);
  }

  taskCreated(task) {
    var tasks = this.state.tasks;

    for(var j = 0; j < this.state.projects.length; j++) {
      if(task.ProjectID === this.state.projects[j].id) {
        task.ProjectName = this.state.projects[j].Name;
        break;
      }
    }

    tasks.push(task);
    this.setState({tasks: tasks}, this.refreshTasks);
    this.props.onTasksLoaded(tasks);
  }

  taskUpdated(task) {
    var tasks = this.state.tasks;
    for(var i = 0; i < tasks.length; i++) {
      if(tasks[i].id === task.id) {
        tasks[i] = task;
        break;
      }
    }
    this.getReminderedTasks(tasks);
    this.setState({tasks: tasks}, () => {
      this.refreshTasks();
      this.props.onTasksLoaded(tasks);
    });
  }

  filterTasks(property, value) {

    // reset any text searches in progress
    this.setState({searchText:""});
    this.props.onSearch("");

    // update filters 
    if(property === 'Category') {
        this.setState({categoryFilter: value}, () => this.refreshTasks());
    } else if (property === 'Owner') { 
        this.setState({ownerFilter: value}, () => this.refreshTasks());
    } else if (property === 'Status') {
        this.setState({statusFilter: value}, () => this.refreshTasks());
    } else if (property === 'Project') {
      this.setState({projectFilter: value}, () => this.refreshTasks());
    } else if (property === 'View') {
      this.setState({viewFilter: value}, () => this.refreshTasks());
    } else if (property === 'Size') {
      this.setState({sizeFilter: value}, () => this.refreshTasks());
    }
  }

  columnSort(property) {

    if(property === this.state.currentSort) { // same sort, reverse order

      var reversed = [];
      for(var i = this.state.filtered.length -1; i > -1; i--) 
        reversed.push(this.state.filtered[i]);

      this.setState({filtered: reversed, currentSort: property});
    } else { // different sort
      this.sortBy(property);
    }
  }

  sortBy(property, tasks) {

    if(!tasks)
      tasks = this.state.filtered;

    if(property === 'name') 
      tasks.sort(this.byName);
      
    else if(property === 'category') 
      tasks.sort(this.byCategory);
    else if(property === 'due') 
      tasks.sort(this.byDue);
    else if(property === 'reminder') 
      tasks.sort(this.byReminder);
    else if(property === 'importance')
      tasks.sort(this.byImportance);
    else if(property === 'owner')
      tasks.sort(this.byOwner);
    else if(property === 'project')
      tasks.sort(this.byProject);
    else if(property === 'size')
      tasks.sort(this.bySize);

    this.setState({filtered: tasks, currentSort: property});
  }

  getCategoryName(task) {
    if(task.Category === 'admin') {
        return "Admin";
    } else if (task.Category === 'content') {
        return "Content";
    } else if (task.Category === 'legal') {
      return "Legal";
    } else if (task.Category === 'maintenance') {
      return "Maintenance";
    } else if (task.Category === 'marketing') {
        return "Marketing";
    } else if (task.Category === 'sales') {
        return "Sales";
    } else if (task.Category === 'bugs') {
        return "Bugs";
    } else if (task.Category === 'features') {
        return "Features";
    } else if (task.Category === 'other') {
      return "Other";
    }
  }

  getOwnerName(task) {
    for(var i = 0; i < this.state.people.length; i++)
      if(task.Owner === this.state.people[i].key) 
        return <span style={{color:"green"}}>{this.state.people[i].label}</span>;
  }

  getStatusText(task) {
    if(task.Status === 'open') {
        return <span style={{color:"maroon"}}>Open</span>;
    } else if (task.Status === 'inProgress') {
        return <span style={{color:"black"}}>In Progress</span>;
    } else if (task.Status === 'complete') {
      return <span style={{color:"green"}}>Complete</span>;
    }
  }

  getSizeText(task) {
    return task.Size.toUpperCase();
  }

  getImportance(task) {
    return Number(task.Priority) * Number(task.Impact);
  }

  deleteTask(task) {
    db.deleteTask(task.id).then(() => {

      var objs = this.state.tasks;
      for(var i = 0; i < objs.length; i++) {
        if(objs[i].id === task.id) {
          objs.splice(i,1);
          break;
        }
      }
      this.setState({tasks:objs}, this.refreshTasks());
    });
  }

  showProjectColumn() {

    //if(this.state.projectFilter === 'all' || this.state.queryText.length > 0)
      //return "table-cell";
    //else 
      return "none";
  }

  showCategoryColumn() {
    if(this.state.categoryFilter === 'all' || this.state.queryText.length > 0)
      return "table-cell";
    else 
      return "none";
  }

  showStatusColumn() {
    if(this.state.statusFilter === 'all' || this.state.statusFilter === 'openIP' || this.state.queryText.length > 0)
      return "table-cell";
    else 
      return "none";
  }

  showSizeColumn() {
    if( (this.state.sizeFilter === 'all' && this.state.viewFilter !== 'due') || this.state.queryText.length > 0 )
      return "table-cell";
    else 
      return "none";
  }

  showSnoozeColumn() {
    if(this.state.viewFilter === 'snoozed')
      return "table-cell";
    else 
      return "none";
  }

  showDueColumn() {
    if(this.state.viewFilter === 'due' || this.state.viewFilter === 'all' || this.state.queryText.length > 0)
      return "table-cell";
    else 
      return "none";
  }

  showReminderColumn() {
    if(this.state.viewFilter === 'due' || this.state.viewFilter === 'all' || this.state.queryText.length > 0)
      return "table-cell";
    else 
      return "none";
  }

  showOwnerColumn() {
    if(this.state.ownerFilter === 'all')
      return "table-cell";
    else 
      return "none";
  }

  showPriorityColumn() {
    if(this.state.viewFilter === 'priority' || this.state.viewFilter === 'snoozed' || this.state.viewFilter === 'all' || this.state.queryText.length > 0)
      return "table-cell";
    else 
      return "none";
  }

  filterOpacity() {
    if(this.state.queryText.length > 0) {
      return 0.5;
    } else {
      return 1.0;
    }
  }

  taskColor(priority) {
    if(priority > 15)
      return "#ff9800";
    else if(priority > 12)
      return "#000000";
    else if (priority > 6)
      return "#232323";
    else 
      return "#434343";
  }
  
  taskWeight(priority) {
    if(priority > 15)
      return 600;
    else if(priority > 12)
      return 500;
    else 
      return 400;
  }

  render() {
    const { classes } = this.props;
   
    return (
      <GridContainer>
        
        <GridItem xs={10}>
          &nbsp;
        </GridItem>
        <GridItem xs={2} style={{textAlign:"right"}}>
                        
          <TaskEditor onCreate={this.taskCreated}
            showButton
            task={{
              Name: "",
              Description: "",
              Status: "open",
              Category: "",
              Owner: "",
              DueDate: "",
              ProjectID: this.state.projectFilter,
              Priority:"",
              Impact:"",
              Details:"",
              ExpirationDate: "",
              SnoozeDate: "",
              ReminderDate:"",
              Size: "",
              People:[]
            }} />
                        
        </GridItem>
        <GridItem xs={12}>
        <Card>
                    <CardBody>
                      <GridContainer style={{opacity:this.filterOpacity()}} >
                        <GridItem xs={2} >
                            <CustomSelect
                                labelText="Project"
                                value={this.state.projectFilter}
                                inputProps={{ placeholder: "Project" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.filterTasks("Project", e.target.value)}
                                options={this.state.filteredProjects}
                            />
                        </GridItem>
                        <GridItem xs={3} >
                            <CustomSelect
                                labelText="Category"
                                value={this.state.categoryFilter}
                                inputProps={{ placeholder: "Category" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.filterTasks("Category", e.target.value)}
                                options={[
                                    {key:"all",label:"All"},
                                    {key:"admin",label:"Admin"},
                                    {key:"content",label:"Content"},
                                    {key:"legal",label:"Legal"},
                                    {key:"maintenance",label:"Maintenance"},
                                    {key:"marketing",label:"Marketing"},
                                    {key:"sales",label:"Sales"},
                                    {key:"bugs",label:"Website Bugs"},
                                    {key:"features",label:"Website Features"},
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={2} >
                            <CustomSelect
                                labelText="Size"
                                value={this.state.sizeFilter}
                                inputProps={{ placeholder: "Size" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.filterTasks("Size", e.target.value)}
                                options={[
                                  {key:"all",label:"All"},
                                  {key:"xs",label:"x-Small"},
                                  {key:"s",label:"Small"},
                                  {key:"m",label:"Medium"},
                                  {key:"l",label:"Large"},
                                  {key:"xl",label:"X-Large"},
                                  {key:"xxl",label:"XX-Large"}
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={2} >
                            <CustomSelect
                                labelText="Owner"
                                value={this.state.ownerFilter}
                                inputProps={{ placeholder: "Owner" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.filterTasks("Owner", e.target.value)}
                                options={this.state.people}
                            />
                        </GridItem>
                        <GridItem xs={3} >
                            <CustomSelect
                                labelText="Status"
                                value={this.state.statusFilter}
                                inputProps={{ placeholder: "Status" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.filterTasks("Status", e.target.value)}
                                options={[
                                    {key:"all",label:"All"},
                                    {key:"openIP",label:"Open / In Progress"},
                                    {key:"cancelled",label:"Cancelled"},
                                    {key:"complete",label:"Complete"}
                                ]}
                            />
                        </GridItem>
                        
                      </GridContainer>
                    </CardBody>
                  </Card>
                  {/*}
          <Accordion
            active={0}
            expanded={true}
            collapses={[
              {
                title: "Pinned and Remindered Tasks",
                content:
                <GridContainer>
                {this.state.reminderedTasks.map((value, index) => (
                  <GridItem xs={12} sm={6} md={4} lg={3} key={index} >
                    <TaskEditor task={value} onChange={this.taskUpdated} showCard header={value.header} />
                  </GridItem>
                ))}
                </GridContainer>
              },
              {
                title: "Filters",
                content:
                  
              }
            ]}
          />
          */}
        </GridItem>

        <GridItem xs={12}>
          
        </GridItem>
        
        <GridItem xs={12}>
          <CustomTabs
            headerColor="success"      
            tabs={[
              {
                tabName: "List",
                tabIcon: Task,
                tabContent: (
                  <GridContainer>
                    <GridItem xs={1} sm={9}>&nbsp;</GridItem>
                    <GridItem xs={11} sm={3}>
                      <CustomSelect
                        labelText="View"
                        value={this.state.viewFilter}
                        inputProps={{ placeholder: "View" }}
                        formControlProps={{ fullWidth: true }}
                        onChange={(e) => this.filterTasks("View", e.target.value)}
                        options={[
                            {key:"all",label:"All"},
                            {key:"priority",label:"Priority"},
                            {key:"due",label:"Date"},
                            {key:"snoozed",label:"Snoozed"}
                        ]}
                      />
                    </GridItem>  
                    <GridItem xs={12}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                              <TableCell style={{padding:"3px", maxWidth:"48px"}}>

                              </TableCell>
                              <TableCell onClick={() => this.columnSort('name')} style={{cursor:'pointer'}}>
                                Name
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('project')} style={{padding:"6px", cursor:'pointer', display:this.showProjectColumn()}}>
                                Project
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('category')} style={{padding:"6px", cursor:'pointer', display:this.showCategoryColumn()}}>
                                Category
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('owner')} style={{padding:"6px", cursor:'pointer', display:this.showOwnerColumn()}}>
                                Owner
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('snooze')} style={{padding:"6px", cursor:'pointer', display:this.showSnoozeColumn()}}>
                                Snooze
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('reminder')} style={{padding:"6px", cursor:'pointer', display:this.showReminderColumn()}}>
                                Reminder
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('due')} style={{padding:"6px", cursor:'pointer', display:this.showDueColumn()}}>
                                Due
                              </TableCell>
                              <TableCell style={{padding:"6px", display:this.showStatusColumn()}}>
                                Status
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('size')} style={{padding:"6px", cursor:'pointer', display:this.showSizeColumn()}}>
                                Size
                              </TableCell>
                              <TableCell onClick={() => this.columnSort('importance')} style={{padding:"6px", cursor:'pointer', display:this.showPriorityColumn()  }}>
                                Priority
                              </TableCell>
                              <TableCell style={{padding:"3px"}}>
                                  
                              </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.filtered.map(value => (
                            <TableRow key={value.id} className={classes.tableRow}>
                              <TableCell className={classes.tableActions} style={{padding:"3px", maxWidth:"48px"}}>
                                <Tooltip
                                  id="tooltip-top"
                                  title="Edit Task"
                                  placement="top"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                <TaskEditor task={value} onChange={this.taskUpdated}  />
                                </Tooltip>
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{color:this.taskColor(this.getImportance(value)),fontWeight:this.taskWeight(this.getImportance(value))}}>
                                {value.Name}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showProjectColumn()}}>
                                {value.ProjectName}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showCategoryColumn()}}>
                                {this.getCategoryName(value)}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showOwnerColumn()}}>
                                {this.getOwnerName(value)}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showSnoozeColumn() }}>
                                {value.SnoozeDate}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showReminderColumn() }}>
                                {value.ReminderDate}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showDueColumn() }}>
                                {value.DueDate}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showStatusColumn()}}>
                                {this.getStatusText(value)}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showSizeColumn()}}>
                                {this.getSizeText(value)}
                              </TableCell>
                              <TableCell className={classes.tableCell} style={{padding:"6px", display:this.showPriorityColumn() }}>
                                {this.getImportance(value)}
                              </TableCell>
                              <TableCell className={classes.tableActions} style={{padding:"3px"}}>
                                <Tooltip id="tooltip-top-start" title="Delete Task" placement="top" classes={{ tooltip: classes.tooltip }} >
                                  <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this task? This action cannot be undone." confirmed={() => this.deleteTask(value)} />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabName: "Timeline",
                tabIcon: Calendar,
                tabContent: (
                  <GridContainer>
                    <GridItem xs={1} sm={9}>&nbsp;</GridItem>
                    <GridItem xs={11} sm={3}>
                      <CustomSelect
                          labelText="View"
                          value={this.state.timingView}
                          inputProps={{ placeholder: "View" }}
                          formControlProps={{ fullWidth: true }}
                          onChange={(e) => this.loadTimePeriods(e.target.value)}  
                          options={[
                              {key:"days",label:"Days"},
                              {key:"weeks",label:"Weeks"},
                          ]}
                      />
                    </GridItem>
                    {this.state.timePeriods.map((value, index) => (
                      
                      <GridItem xs={12} sm={6} md={4} lg={3} key={index}>
                        <div style={{fontFamily:"Roboto Slab", fontSize:"16px", fontWeight:600, marginTop:"20px", marginBottom:"20px" }}>
                          {value.label}
                        </div>
                        {this.state.filtered.map((task) => {
                            if ( task.dueDate >= value.startDate && task.dueDate < value.endDate && !isNaN(task.dueDate) ) { 
                              return <TaskEditor task={task} onChange={this.taskUpdated} showCard key={task.id} header="dueDate" />
                            } else if (task.reminderDate >= value.startDate && task.reminderDate < value.endDate && !isNaN(task.reminderDate) && task.ReminderDate !== "") {
                              return <TaskEditor task={task} onChange={this.taskUpdated} showCard key={task.id} header="reminderDate" />
                            }
                            else return "";
                        })
                        }
                      </GridItem>
                    ))}
                  </GridContainer>
                )
              }
            ]}
          />
        </GridItem>
        
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({   
      onTasksLoaded: TasksLoaded
      }, dispatch);  
}

TaskList = connect(mapStateToProps, mapDispatchToProps)(TaskList);
export default withStyles(styles)(TaskList);
