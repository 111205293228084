//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSelect from "components/Select/Select.jsx";

//kekaha
import OrderDetails from "components/OrderDetails/OrderDetails.jsx";

//icons
import Refresh from "@material-ui/icons/Refresh";
import Renew from "@material-ui/icons/Autorenew";
import Cached from "@material-ui/icons/Cached";

import Backordered from "@material-ui/icons/DateRange";
import Confirmed from "@material-ui/icons/Done";
import Rejected from "@material-ui/icons/Warning";
import Shipped from "@material-ui/icons/Commute";

//unique id generator
//const uuidv4 = require('uuid/v4');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

class OrdersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      filteredOrders: [],
      filter: 'all',
      moreRows: false,
      lastModified: 0,
      sortOrder: 'created'
    }

    this.orderDeleted = this.orderDeleted.bind(this);
    this.refreshOrders = this.refreshOrders.bind(this);
    this.processRecurringOrders = this.processRecurringOrders.bind(this);
    this.getUpdatedOrders = this.getUpdatedOrders.bind(this);
  }

  componentDidMount() {
    this.refreshOrders();
    setInterval(this.getUpdatedOrders,30000)
  }

  orderChanged(order) {
   
    var orders = this.state.orders;
    for(var i = 0; i < orders.length; i++) {
      if(orders[i].id === order.id) {
        orders[i] = order;
        break;
      }
    }
    this.setState({orders:orders});
  }

  orderDeleted(order) {
    var orders = this.state.orders;
    for(var i = 0; i < orders.length; i++) {
      if(orders[i].id === order.id) {
        orders.splice(i,1);
        break;
      }
    }
    this.setState({orders:orders});
  }

  refreshOrders() {

    //console.log('refreshOrders()');

    var modified = 0;
    let orderId;
    if(this.state.orders.length > 0){
      modified = this.state.orders[this.state.orders.length - 1].modified;
      orderId = this.state.orders[this.state.orders.length - 1].id;
    }

    //console.log('modified: ' + modified);

    db.getRecentOrders(modified, orderId).then(orders => {

      var p1 = [];
      var p2 = [];

      var lastModified = this.state.lastModified;
      
      for(var i = 0; i < orders.length; i++) {

        if(orders[i].modified > lastModified)
          lastModified = orders[i].modified;

        if(!orders[i].status) {
          orders[i].status = 'NEW';
        }

        if(orders[i].customerID) // only managed customers
          p1.push(db.getCustomer(orders[i].customerID));
        
        if(orders[i].userID)
          p2.push(db.getUser(orders[i].userID));

        var orderTotal = 0.0;
        for(var o = 0; o < orders[i].items.length; o++) {

          var qty = 1;
          if(orders[i].items[o].qty)
            qty = orders[i].items[o].qty;
          else //temp for test orders
            orders[i].items[o].qty = 1;

          orders[i].items[o].listPrice = Number(orders[i].items[o].listPrice).toFixed(2);

          orders[i].items[o].total = Number(orders[i].items[o].listPrice * qty).toFixed(2);
          orderTotal = orderTotal + (orders[i].items[o].listPrice * qty);
        }

        if(orders[i].tax)
          orderTotal = orderTotal + Number(orders[i].tax);

        if(!orders[i].created)
          orders[i].created = orders[i].modified;


        orders[i].orderTotal = orderTotal.toFixed(2);
        orders[i].orderPlaced = timeConverter(orders[i].created);
        orders[i].orderModified = timeConverter(orders[i].modified);

      }

      Promise.all(p1).then((dbResults) => {
        
        for(var j = 0; j < dbResults.length; j++) {
          for(var k = 0; k < orders.length; k++) {
            if(dbResults[j].id === orders[k].customerID) {
              orders[k].customerName = dbResults[j].customer_name;
            }
          }
        }

        Promise.all(p2).then((userResults) => {
          for(var l = 0; l < userResults.length; l++) {
            var user = userResults[l].data();
            if(user) { // necessary to protect against deleted users
              for(var m = 0; m < orders.length; m++) {
                if(user.id === orders[m].userID) {
                  orders[m].userName = user.FirstName + ' ' + user.LastName;
                }
              }
            }
          }

          var loadedOrders = JSON.parse(JSON.stringify(this.state.orders));
          loadedOrders = loadedOrders.concat(orders)

          this.setState({orders:loadedOrders}, ()=>this.filterOrders(this.state.filter));

          if(orders.length < 1)
            this.setState({moreRows: false, lastModified: lastModified});
          else 
            this.setState({moreRows: true, lastModified: lastModified});

          //console.log('updated lastModified: ' + lastModified )
        });
      });
    }); 
  }

  getUpdatedOrders() {

    //console.log('getUpdatedOrders(' + this.state.lastModified + ')');

    var lastModified = this.state.lastModified;
    db.getModifiedOrders(lastModified).then(updatedOrders => {

      if(updatedOrders.length > 0) {

        //console.log('updated orders found: ' + updatedOrders.length);

        var promises = [];

        var orders = this.state.orders;
        
        updatedOrders.forEach(order => {

          // PREPARE ORDER WITH NECESSARY METADATA 

          if(!order.status) { order.status = 'NEW'; }
  
          if(order.customerID) {// only managed customers {
            for(var c = 0; c < this.props.customers.length; c++) {
              if(this.props.customers[c].contact_id === order.customerID) {
                order.customerName = this.props.customers[c].company_name;
                //console.log('assigned customer name:' + order.customerName);
                break;
              }
            }
          }
           
          if(order.userID)
            promises.push(db.getUser(order.userID));

          var orderTotal = 0.0;
          for(var o = 0; o < order.items.length; o++) {

            var qty = 1;
            if(order.items[o].qty)
              qty = order.items[o].qty;
            
            order.items[o].listPrice = Number(order.items[o].listPrice).toFixed(2);

            order.items[o].total = Number(order.items[o].listPrice * qty).toFixed(2);
            orderTotal = orderTotal + (order.items[o].listPrice * qty);
          }

          if(order.tax)
            orderTotal = orderTotal + Number(order.tax);

          order.orderTotal = orderTotal.toFixed(2);

          if(!order.created)
            order.created = order.modified;

          if(!order.orderPlaced)
            order.orderPlaced = timeConverter(order.created);
        });

  
        Promise.all(promises).then((userResults) => { // get the user names associated to the orders

          for(var l = 0; l < userResults.length; l++) {
            var user = userResults[l].data();
            if(user) { // necessary to protect against deleted users
              for(var m = 0; m < orders.length; m++) {
                if(user.id === orders[m].userID) {
                  orders[m].userName = user.FirstName + ' ' + user.LastName;
                }
              }
            }
          }

          updatedOrders.forEach(order => {

            var isFound = false;
            for(var i = 0; i < orders.length; i++) {
              if(orders[i].id === order.id) {
                orders[i] = order;
                //console.log('replacement:')
                //console.log(order);
                isFound = true;
                break;
              }
            }
  
            if(!isFound)
              orders.push(order);
  
            if(order.modified > lastModified)
              lastModified = order.modified;
          });

          this.setState({ orders: orders, lastModified: lastModified }, this.filterOrders(this.state.filter)); 
        });


      }
    });
  }

  compareCreated(a,b) {

    var x = a.created;
    var y = b.created;
    
    if (x < y)
        return 1;
    if (x > y)
        return -1;
    return 0;
  }

  compareModified(a,b) {

    var x = a.modified;
    var y = b.modified;
    
    if (x < y)
        return 1;
    if (x > y)
        return -1;
    return 0;
  }
  
  sortOrders(sortOrder) {
    this.setState( {sortOrder: sortOrder}, () => {
      this.filterOrders(this.state.filter) }
    );
  }

  filterOrders(filter) {
    //console.log('orders length: ' + this.state.orders.length);
    var filteredOrders = [];

    for(var i = 0; i < this.state.orders.length; i++) {
      if(this.state.orders[i].status === filter || filter === 'all') {

        var order = this.state.orders[i];

        order.computedStatus = '';
        for(var j = 0; j < order.items.length; j++) {
          if (order.items[j].status === 'rejected') {
            order.computedStatus = 'needsAttention';
            break;
          }
          else if(order.items[j].status === 'backordered') {
            order.computedStatus = 'backordered';
          }
          else if(order.items[j].status === 'confirmed' && order.computedStatus !== 'backordered') {
            order.computedStatus = 'confirmed';
          } 
          else if(order.items[j].status === 'shipped' && order.computedStatus === '') {
            order.computedStatus = 'shipped';
          }
          else if(order.items[j].status === 'delivered' && order.computedStatus === '') {
            order.computedStatus = 'delivered';
          }
        }
        
        filteredOrders.push(order);
      }
    }
    
    if(this.state.sortOrder === 'modified') 
      filteredOrders.sort(this.compareModified);
    else 
      filteredOrders.sort(this.compareCreated); 
    
    this.setState({filteredOrders: filteredOrders, filter: filter});
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  pad(value) {
    if (value < 10) {
        return '0' + value;
    } else {
        return value;
    }
  }

  isRecurring(value) {
    if(value)
      return <Renew style={{fontSize:"17px"}} />
    else
      return "";
  }

  showLoadMore() {
    if(this.state.moreRows) {
      return "block";
    } else {
      return "none";
    }
  }

  processRecurringOrders() {

    db.getRecurringOrders().then(orders => {

      for(var i = 0; i < orders.length; i++) {

        var ro = orders[i];

        if(ro.IsEnabled) {

          console.log("===============================================");
          console.log(ro.Name);

          var startDate = new Date(ro.StartDate);
          
          var endDate;
          if(ro.EndDate)
            if(ro.EndDate.length > 0)
              endDate = new Date(ro.EndDate);

          var processedDate;
          if(ro.ProcessedDate)
            processedDate = new Date(ro.ProcessedDate);

          var nextDate = new Date();
          if(ro.Frequency === 'm') {
            if(processedDate)
              nextDate = this.addDays(processedDate, 30);
          } else if (ro.Frequency === 'w') {
            if(processedDate)
              nextDate = this.addDays(processedDate, 7);
          }

          var rightNow = new Date();

          if(rightNow > startDate) {
            
            if(!endDate || rightNow < endDate) {

              if(rightNow.getTime() >= nextDate.getTime()) {

                var timeStamp = timeConverter(rightNow.getTime() / 1000)
                
                var customerName = "";
                for(var j = 0; j < this.props.customers.length; j++) {
                  if(this.props.customers[j].id === ro.customerID) {
                    customerName = this.props.customers[j].customer_name;
                    break;
                  }
                }

                var poNumber = ro.poNumber + "-" + this.pad(rightNow.getMonth() + 1) + rightNow.getFullYear().toString().substring(2);
                
                var order = {
                  contactID: ro.contactID,
                  customerID: ro.customerID,
                  customerName: customerName,
                  items: ro.Items,
                  modified: Math.round((new Date()).getTime() / 1000),
                  orderPlaced: timeStamp,
                  orderTotal: ro.orderTotal,
                  poNumber: poNumber,
                  shippingAddressID: ro.shippingAddressID,
                  tax: ro.tax,
                  userName: ro.userName,
                  isRecurring: true,
                  notes: ""
                }

                db.updateOrder(order.id, order);
                
                ro.ProcessedDate = timeStamp;
                db.updateRecurringOrder(ro.id, ro);
              }
            }
          }
        }
      }

    });
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getComputedStatusIcon(computedStatus) {
    if(computedStatus === 'needsAttention') 
      return <div style={{color:"maroon"}} title='Rejected'><Rejected /></div>
    else if(computedStatus === 'backordered')
      return <div style={{color:"orange"}} title='Backordered'><Backordered /></div>
    else if(computedStatus === 'shipped')
      return <div style={{color:"green"}} title='Shipped'><Shipped /></div>
    else if(computedStatus === 'confirmed')
      return <div style={{color:"green"}} title='Confirmed'><Confirmed /></div>
    else if(computedStatus === 'delivered')
      return <div style={{color:"green"}} title='Delivered'><Confirmed /></div>
    else 
      return <div></div>
  }

  getSortDescriptor() {
    if(this.state.sortOrder === 'modified')
      return "Modified";
    else
      return "Created";
  }

  getDateTimeStamp(value) {
    if(this.state.sortOrder === 'modified')
      return value.orderModified;
    else 
      return value.orderPlaced;
  }

  getUserName(value) {
    const { shippingAddress } = value;
    if (shippingAddress) {
      const {FirstName, LastName} = shippingAddress;
      return `${FirstName} ${LastName}`;
    }
    return '';
  }
  
  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
            <GridContainer>
                <GridItem xs={12} sm={11} >
                  <h4 className={classes.cardTitleWhite}>Orders</h4>
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right", display:"none"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Process Recurring Orders"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={this.processRecurringOrders}>
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Get Updated Orders"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={this.getUpdatedOrders}>
                      <Cached />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={false} sm={false} md={6}>
                    &nbsp;
                </GridItem>
                <GridItem xs={12} sm={12} md={3} >
                  <CustomSelect
                      labelText="Sort Order"
                      inputProps={{ placeholder: "Sort By" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(e) => this.sortOrders(e.target.value)}
                      options={[
                        { key: "created", label: "Created" },
                        { key: "modified", label: "Modified" }
                      ]}
                      value={this.state.sortOrder}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} >
                  <CustomSelect
                      labelText="Status"
                      inputProps={{ placeholder: "Filter by Status" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(e) => this.filterOrders(e.target.value)}
                      options={[
                        { key: "all", label: "All" },
                        { key: "NEW", label: "New" },
                        { key: "APPROVED", label: "Approved" },
                        { key: "CONFIRMED", label: "Confirmed" },
                        { key: "VENDOR REJECTED", label: "Vendor Rejected" },
                        { key: "PARTIAL SHIPPED", label: "Partial Shipped" },
                        { key: "SHIPPED", label: "Shipped" },
                      ]}
                      value={this.state.filter}
                    />
                </GridItem>
                <GridItem xs={12}>
                  <br />
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{padding:"4px", width:"54px"}}> 

                        </TableCell>
                        <TableCell style={{padding:"4px"}}> 
                          {this.getSortDescriptor()}
                        </TableCell>
                        <TableCell style={{padding:"4px"}}> 
                          Status
                        </TableCell>
                        <TableCell style={{padding:"0px"}}> 
                        </TableCell>
                        <TableCell style={{padding:"0px"}}> 
                        </TableCell>
                        <TableCell style={{padding:"4px"}}> 
                          Customer
                        </TableCell>
                        <TableCell style={{padding:"4px"}}> 
                          PO #
                        </TableCell>
                        <TableCell style={{padding:"4px", textAlign:"right"}}> 
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.filteredOrders.map((value, index) => (
                        <TableRow key={value.id + "-" + index} className={classes.tableRow} >
                          <TableCell className={classes.tableActions} style={{padding:"4px", width:"54px"}}> 
                            <Tooltip id="tooltip-top" title="Edit Order" placement="top" classes={{ tooltip: classes.tooltip }} >
                              <OrderDetails order={value} orderChanged={() => this.orderChanged(value)} orderDeleted={this.orderDeleted} />
                            </Tooltip>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                            {this.getDateTimeStamp(value)}<br />
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                            {value.status}
                          </TableCell>
                          <TableCell className={classes.tableCell} > 
                            {this.getComputedStatusIcon(value.computedStatus)}
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{padding:"0px", paddingTop:"8px", color:"green"}}> 
                            {this.isRecurring(value.isRecurring)}
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                            {value.customerName || this.getUserName(value)}
                          </TableCell>
                          <TableCell className={classes.tableActions} style={{padding:"4px"}}>  
                            {value.poNumber}
                          </TableCell>
                          <TableCell className={classes.tableActions} style={{padding:"4px", textAlign:"right"}}>  
                            ${value.orderTotal}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <br />
                  <div style={{textAlign:"center", cursor:"pointer", display:this.showLoadMore()}} onClick={this.refreshOrders} >
                    Load More
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

OrdersList = connect(mapStateToProps)(OrdersList);
export default withStyles(styles)(OrdersList);
