//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

//components
import CustomSelect from "components/Select/Select.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Copy from "@material-ui/icons/FileCopy";

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class CopyRules extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      sourceCustomer: "",
      rules: []
    };

    this.chooseCustomer = this.chooseCustomer.bind(this);
    this.appendProducts = this.appendProducts.bind(this);
    
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  chooseCustomer(e) {
    this.setState({sourceCustomer: e.target.value}, () => {
        if(this.props.type === 'visibility') {
            db.getVisibilityExceptionsByCustomer(this.state.sourceCustomer).then(rules => {    
                this.appendProducts(rules);
            });
        } else if (this.props.type === 'budget') {
            db.getBudgetExceptionsByCustomer(this.state.sourceCustomer).then(rules => {    
                this.appendProducts(rules);
            });
        } else if (this.props.type === 'pricing') {
            db.getPricingByCustomer(this.state.sourceCustomer).then(rules => {    
                this.appendProducts(rules);
            });
        } else if (this.props.type === 'tax') {
            db.getTaxExemptionsByCustomer(this.state.sourceCustomer).then(rules => {    
                this.appendProducts(rules);
            });
        }
    });
  }

  appendProducts(rules) {
    var promises = [];
    for(var i = 0; i < rules.length;i++) {
        if(rules[i].productID) {    
            var promise = db.getProduct(rules[i].productID);
            promises.push(promise);
        } 
    }
    
    Promise.all(promises).then((dbResults) => {

        for(var i = 0; i < rules.length; i++) {
          for(var j = 0; j < dbResults.length; j++) {
            if(rules[i].productID === dbResults[j].id) {
              rules[i].product = dbResults[j].data();
              break;
            }
          }
        }
    });
    
    this.setState({rules: rules});
  }

  copyRules() {
    
    var promises = [];
    var rules = this.state.rules;
    for(var i = 0; i < rules.length;i++) {

        rules[i].id = uuidv4();
        rules[i].profileID = this.props.profileID;
        rules[i].modified = Math.round((new Date()).getTime() / 1000);
        rules[i].type = this.props.type;
    }
    Promise.all(promises).then(() => {
        
        this.props.onChange(rules);
    }); 
  }

  ruleCount() {
      return this.state.rules.length + " rules found."
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        
        <IconButton
            aria-label="Copy Rules" 
            onClick={() => this.handleClickOpen("modal")}>
            <Copy className={ classes.tableActionButtonIcon + " " + classes.edit } />
        </IconButton>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.modalTitle}>{this.props.title}</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>

            <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12}>

                    {this.props.text}

                    <CustomSelect
                        labelText="Choose a Customer"
                        inputProps={{ placeholder: "Customer to Copy From" }}
                        formControlProps={{ fullWidth: true }}
                        onChange={this.chooseCustomer}
                        options={this.props.customers}
                        value={this.state.sourceCustomer}
                    />

                    {this.ruleCount()}
                </GridItem>
            </GridContainer>
          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.copyRules();
                    this.handleClose("modal");
                  }}
                color="success">
                Copy
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

CopyRules = connect(mapStateToProps)(CopyRules);
export default withStyles(modalStyle)(CopyRules);