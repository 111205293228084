import React from 'react';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import popoverStyles from "assets/jss/material-kit-pro-react/popoverStyles.jsx";
import checkRadioStyle from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

// controls
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

//icons
import Business from "@material-ui/icons/Business";

class VendorPopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            product: props.product,
            visible: false,
            selectedVendor: ""
        };

        if(!this.state.product.Vendors) //why?!
            this.state.product.Vendors = [];
        
        this.vendorSelected = this.vendorSelected.bind(this);
    }

    toggleVisible(e) {
        
        for(var i = 0; i < this.state.product.Vendors.length; i++) {
            if(this.state.product.Vendors[i].id === this.state.product.DefaultVendor) {
                this.setState({selectedVendor: this.state.product.Vendors[i].id});
                break;
            }
        }

        if(!this.state.visible)
            this.setState({anchorEl: e.currentTarget});
        else 
            this.setState({anchorEl: null});
        
        this.setState({visible: !this.state.visible}); 
    }

    vendorSelected(event) { // vendor
        
        this.setState({ selectedVendor: event.target.value }, () => {
            this.props.changed(this.state.product.id, this.state.selectedVendor);
        }); 
    }

    getLabel(vendor) {
        return vendor.Name + " - $" + vendor.Cost;
    }

    getIconColor() {
        
        if(this.state.product.Vendors.length > 1) 
            return 'green';
        else
            return 'gray';
    }

    render() {
        const { classes } = this.props;

        return (
        <div>
            <IconButton onClick={(e) => this.toggleVisible(e)} style={{color:this.getIconColor()}}><Business /></IconButton>
            <Popover 
                classes={{
                    paper: classes.popover
                }}
                open={this.state.visible} 
                onClose={() => this.toggleVisible()}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                anchorEl={this.state.anchorEl}
            >
                <div className={classes.popoverBody} style={{padding:"17px", backgroundColor:"white"}} >
                    <GridContainer style={{color:"#232323"}}>

                        <GridItem>
                            <b>Available Vendors</b>
                            <br /><br />
                        </GridItem>

                        {this.state.product.Vendors.map((vendor,index) => (
                        <GridItem xs={12} key={index} >

                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={this.state.selectedVendor === vendor.id}
                                        onChange={this.vendorSelected}
                                        value={vendor.id}
                                        name={vendor.Name}
                                        icon={<FiberManualRecord className={classes.radioUnchecked} style={{color:"#efefef"}} />}
                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} style={{color:"green"}} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{ label: classes.label }}
                                label={this.getLabel(vendor)}
                                style={{marginTop:"-17px", marginLeft:"0px"}}
                            />
                        </GridItem>
                        ))}
                    </GridContainer>
                </div>
            </Popover>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
}
  
VendorPopover = connect(mapStateToProps)(VendorPopover);
export default withStyles(popoverStyles, checkRadioStyle)(VendorPopover);