//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//kekaha
import RecurringOrder from "components/RecurringOrder/RecurringOrder.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";


//icons
import Warning from "@material-ui/icons/Warning";

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
};

class RecurringList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        orders: []
      }
  
      this.doSpecialStuff = this.doSpecialStuff.bind(this);
      this.recurringOrderSaved = this.recurringOrderSaved.bind(this);
    }
  
    componentDidMount() {
      this.refreshOrders();
    }

    refreshOrders() {
        db.getRecurringOrders().then(orders => {
            this.setState({orders:orders});

            for(var i = 0; i < orders.length; i++) {
                if(orders[i].IsEnabled) {
                    //console.log(orders[i])
                }
            }
        });
    }

    doSpecialStuff() {
        var orders = this.state.orders;
        var count = 0;
        for(var i = 0; i < orders.length; i++) {
          if(orders[i].IsEnabled && orders[i].customerID === '1343284000000063075') {
            
            orders[i].ProcessedDate = '01/1/2001';
            orders[i].StartDate = '02/11/2021';

            db.updateRecurringOrder(orders[i].id,orders[i]);
            console.log(orders[i]);
            count = count + 1;
          }
        }
        this.setState({orders: orders});
        console.log("count:" + count);
    }

    getFrequencyDescriptor(frequency) {
        if(frequency === 'w')
            return "Weekly"; 
        else if(frequency === 'm')
            return "Monthly";
        else if (frequency === 'eom')
            return "Every Other Month";
        else if (frequency === 'q')
            return "Quarterly";
    }

    getEnabledDescriptor(enabled) {
        if(enabled)
            return <div style={{color:'green'}}>ENABLED</div>
        else 
            return <div style={{color:'maroon'}}>DISABLED</div>
    }

    recurringOrderSaved(value) {
    
        var orders = this.state.orders;
        for(var i = 0; i < orders.length; i++) {
          if(orders[i].id === value.id) {
            orders[i] = value;
            break;
          }
        }
        this.setState({orders: orders});
    }

    deleteRecurringOrder(order) {
        var orders = this.state.orders;
        for(var i = 0; i < orders.length; i++) {
            if(orders[i].id === order.id) {
                db.deleteRecurring(order.id);
                orders.splice(i,1);
                break;
            }
        }
        this.setState({orders:orders});
    }

    render() {
        const { classes } = this.props;
    
        if (!this.props.user.authenticated) {
          return <Redirect to='/login' />
        }
    
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="success">
                  <GridContainer>
                    <GridItem xs={12} sm={11} >
                      <h4 className={classes.cardTitleWhite}>Recurring Orders</h4>
                    </GridItem>
                    <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                      <Tooltip
                          id="tooltip-top-start"
                          title="DO NOT PUSH THIS BUTTON"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                        <IconButton onClick={this.doSpecialStuff}>
                          <Warning />
                        </IconButton>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            <br />
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{padding:"4px", width:"54px"}}> 

                                        </TableCell>
                                        <TableCell style={{padding:"4px"}}> 
                                            Customer
                                        </TableCell>
                                        <TableCell style={{padding:"4px"}}> 
                                            Name
                                        </TableCell>
                                        <TableCell style={{padding:"4px"}}> 
                                            PO #
                                        </TableCell>
                                        <TableCell style={{padding:"4px"}}> 
                                            Frequency
                                        </TableCell>
                                        <TableCell style={{padding:"0px"}}> 
                                            Last Processed
                                        </TableCell>
                                        <TableCell style={{padding:"4px", textAlign:"right"}}> 
                                            Enabled
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.orders.map(value => (
                                    <TableRow key={value.id} className={classes.tableRow} >
                                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                                            <RecurringOrder iconButton order={value} orderSaved={this.recurringOrderSaved} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                                            {value.customerName}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                                            {value.Name}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                                            {value.poNumber}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                                            {this.getFrequencyDescriptor(value.Frequency)}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                                            {value.ProcessedDate}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{padding:"4px", textAlign:"right"}}> 
                                            {this.getEnabledDescriptor(value.IsEnabled)}
                                        </TableCell>
                                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                                            <Tooltip id="tooltip-top-start" title="Delete Recurring Order" placement="top" classes={{ tooltip: classes.tooltip }} >
                                                <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this recurring order? This action cannot be undone." confirmed={() => this.deleteRecurringOrder(value)} />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </GridItem>
                    </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
    );
  }
}

const mapStateToProps = state => {
    return state;
}

RecurringList = connect(mapStateToProps)(RecurringList);
export default withStyles(styles)(RecurringList);