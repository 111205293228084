//react
import React from 'react';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BlogPostCreated } from 'redux/actions';

//firebase
import { db, storage } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
//import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PhotoCard from "components/PhotoCard/PhotoCard.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Subject from "@material-ui/icons/Subject";
import Photos from "@material-ui/icons/AddAPhotoOutlined";

//fileUploader
import FileUploader from 'react-firebase-file-uploader';

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
    return <Slide direction="down" {...props} />;
  }
  
  class BlogPostEditor extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
        blogPost: props.blogPost
      };

      if(!this.state.blogPost.Photos) {
          this.state.blogPost.Photos = [];
      }

      this.saveBlogPost = this.saveBlogPost.bind(this);
      this.updateBlogPost = this.updateBlogPost.bind(this);

      this.deletePhoto = this.deletePhoto.bind(this);
      this.updatePhoto = this.updatePhoto.bind(this);
      this.makeCoverPhoto = this.makeCoverPhoto.bind(this);
      this.makeThumbPhoto = this.makeThumbPhoto.bind(this);
    
    }

    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    updateBlogPost(property, value) {
      var blogPost = this.state.blogPost;
      blogPost[property] = value;
      this.setState({blogPost: blogPost});
    }

    addPhoto(url) {

      var blogPost = this.state.blogPost;
      if(!blogPost.Photos) {
        blogPost.Photos = [];
      }

      var photo = { ID: uuidv4(), Name: "New Photo", Description: "description", URL: url, Attribution:"", License:"", isCover: false, isThumb: false};

      blogPost.Photos.push(photo);
      this.setState({blogPost:blogPost});
    }

    updatePhoto(photo) {
      var blogPost = this.state.blogPost;
      
      for(var i = 0; i < blogPost.Photos.length; i++) {
        if(blogPost.Photos[i].ID === photo.ID) {
            blogPost.Photos[i] = photo;
          break;
        }
      }
    }

    deletePhoto(photo) {
      var blogPost = this.state.blogPost;

      for(var i = blogPost.Photos.length - 1; i > -1; i--) {
        if(blogPost.Photos[i].ID === photo.ID) {
            blogPost.Photos.splice(i,1);
          break;
        }
      }

      this.setState({blogPost:blogPost});
    }

    makeCoverPhoto(photo) {
      var blogPost = this.state.blogPost;
      
      for(var i = 0; i < blogPost.Photos.length; i++) {
        if(blogPost.Photos[i].ID === photo.ID) {
            blogPost.Photos[i].isCover = true;
        } else {
            blogPost.Photos[i].isCover = false;
        }
      }
    }

    makeThumbPhoto(photo) {
      var blogPost = this.state.blogPost;
      
      for(var i = 0; i < blogPost.Photos.length; i++) {
        if(blogPost.Photos[i].ID === photo.ID) {
            blogPost.Photos[i].isThumb = true;
        } else {
            blogPost.Photos[i].isThumb = false;
        }
      }
    }

    saveBlogPost() {
    
      db.updateBlogPost(this.state.blogPost.id, this.state.blogPost).then(doc => {
        
        if(!this.state.blogPost.id) { // i.e. if it's a new blogPost
          this.updateBlogPost("id",doc.id);
          this.props.onBlogPostCreated(this.state.blogPost);

          ReactGA.event({
            category: 'BlogPost',
            action: 'Created a New Blog Post'
          });
        } else { // i.e. an existing blog post

          ReactGA.event({
            category: 'BlogPost',
            action: 'Updated a Blog Post'
          });
        }
      });
    }
        
    // photos
    photoUploadStart = () => this.setState({isUploading: true, progress: 0});
    photoUploadProgress = (progress) => this.setState({progress});
    photoUploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
    }
    photoUploadSuccess = (filename) => {
        this.setState({progress: 100, isUploading: false});
        
        storage.images.child(filename).getDownloadURL().then(url => this.addPhoto(url));
    };


    trim(value, maxLength) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    }

    render() {

      const { classes } = this.props;

      return (
        <div>
          
          <IconButton aria-label="Edit" onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            fullScreen
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={6}>
                  <h4 className={classes.modalTitle}>Edit Blog Post</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.blogPost.IsVisible}
                        onChange={(e) => this.updateBlogPost("IsVisible", e.target.checked)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Visible"
                  />
                  <Button
                    onClick={() => this.handleClose("modal")}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => {
                        this.saveBlogPost();
                        this.handleClose("modal");
                      }}
                    color="success">
                    Save
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
             <CustomTabs
                headerColor="success"
                tabs={[
                  {
                    tabName: "General",
                    tabIcon: Subject,
                    tabContent: (
                      
                        <GridContainer direction="row">
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Title"
                              value={this.state.blogPost.Title}
                              inputProps={{
                                  placeholder: "Title"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateBlogPost("Title", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText="Subtitle"
                                value={this.state.blogPost.Subtitle}
                                inputProps={{
                                    placeholder: "Subtitle",
                                    multiline:true
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateBlogPost("Subtitle", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} >
                            
                          </GridItem>
                          
                        </GridContainer>
                    )
                  },
                  {
                    tabName: "Photos",
                    tabIcon: Photos,
                    tabContent: (

                      <div>
                        <div style={{marginTop:"10px"}}>

                          <label style={{color: "#696969", cursor:"pointer"}}>
                            Upload a New Photo  
                          
                            <FileUploader
                              hidden 
                              accept="image/*"
                              name="photo"
                              randomizeFilename
                              storageRef={storage.images}
                              onUploadStart={this.photoUploadStart}
                              onUploadError={this.photoUploadError}
                              onUploadSuccess={this.photoUploadSuccess}
                              onProgress={this.photoUploadProgress}
                              />
                              
                          </label>
                        </div>

                        <GridContainer justify="flex-start">
                          {this.state.blogPost.Photos.map(photo => (
                              <PhotoCard key={photo.id} photo={photo} 
                                onPhotoUpdated={this.updatePhoto} onPhotoMakeCover={this.makeCoverPhoto} 
                                onPhotoMakeThumb={this.makeThumbPhoto} onPhotoDelete={this.deletePhoto} />
                          ))}
                        </GridContainer>
                      </div>
                    )
                  }
                ]}
              />

            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
              
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onBlogPostCreated: BlogPostCreated }, dispatch);  
}

BlogPostEditor = connect(mapStateToProps, mapDispatchToProps)(BlogPostEditor);
export default withStyles(modalStyle)(BlogPostEditor);