//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//kekaha
import EstimateDetails from "components/EstimateDetails/EstimateDetails.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

class EstimatesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estimates: [],
      moreRows: false
    }

    this.refreshEstimates = this.refreshEstimates.bind(this);
  }

  componentDidMount() {
    this.refreshEstimates();
  }

  estimateChanged(estimate) {
    var estimates = this.state.estimates;
    for(var i = 0; i < estimates.length; i++) {
      if(estimates[i].id === estimate.id) {
        estimates[i] = estimate;
        break;
      }
    }
    this.setState({estimates:estimates});
  }

  refreshEstimates() {

    
    var modified = 0;
    if(this.state.estimates.length > 0)
      modified = this.state.estimates[this.state.estimates.length - 1].modified;

    db.getEstimates(modified).then(estimates => {
      
        for(var i = 0; i < estimates.length; i++) {

            if(!estimates[i].status) {
                estimates[i].status = 'NEW';
            }

            var estimateTotal = 0.0;
            for(var o = 0; o < estimates[i].Items.length; o++) {

                var qty = 1;
                if(estimates[i].Items[o].qty)
                    qty = estimates[i].Items[o].qty;
                else //temp for test orders
                    estimates[i].Items[o].qty = 1;

                estimates[i].Items[o].listPrice = Number(estimates[i].Items[o].listPrice).toFixed(2);

                estimates[i].Items[o].total = Number(estimates[i].Items[o].customPrice * qty).toFixed(2);
                estimateTotal = estimateTotal + (estimates[i].Items[o].customPrice * qty);
            }

            if(estimates[i].tax)
                estimateTotal = estimateTotal + Number(estimates[i].tax);

            estimates[i].estimateTotal = estimateTotal.toFixed(2);
            estimates[i].estimateCreated = timeConverter(estimates[i].created);
        }
        
        var loadedEstimates = this.state.estimates;
        for(var j = 0; j < estimates.length; j++)
            loadedEstimates.push(estimates[j]);

        this.setState({estimates:loadedEstimates}, () => {
            
        });

        if(estimates.length < 20)
            this.setState({moreRows: false});
        else 
            this.setState({moreRows: true});

    });
  }

  deleteEstimate(estimate) {

    db.deleteEstimate(estimate.id).then(() => {
      var estimates = this.state.estimates;
      for(var i = 0; i < estimates.length; i++) {
        if(estimates[i].id === estimate.id) {
          estimates.splice(i,1);
          break;
        }
      }
      this.setState({estimates: estimates});
    });
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  pad(value) {
    if (value < 10) {
        return '0' + value;
    } else {
        return value;
    }
  }

  showLoadMore() {
    if(this.state.moreRows) {
      return "block";
    } else {
      return "none";
    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
            <GridContainer>
                <GridItem xs={12} sm={12} >
                  <h4 className={classes.cardTitleWhite}>Estimates</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"4px", width:"54px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        Date
                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        Status
                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        Customer
                      </TableCell>
                      <TableCell style={{padding:"4px", textAlign:"right"}}> 
                        Total
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.estimates.map(value => (
                      <TableRow key={value.id} className={classes.tableRow} >
                        <TableCell className={classes.tableActions} style={{padding:"4px", width:"54px"}}> 
                          <Tooltip id="tooltip-top" title="Edit Order" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <EstimateDetails estimate={value} estimateChanged={() => this.estimateChanged(value)} />
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                          {value.estimateCreated}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                          {value.status}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                          {value.CustomerName}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"4px", textAlign:"right"}}>  
                          ${value.estimateTotal}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Estimate" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this estimate? This action cannot be undone." confirmed={() => this.deleteEstimate(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
                <div style={{textAlign:"center", cursor:"pointer", display:this.showLoadMore()}} onClick={this.refreshEstimates} >
                  Load More
                </div>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onGlimpseCreated: GlimpseCreated, onGlimpseUpdated: GlimpseUpdated }, dispatch);  
}
*/

EstimatesList = connect(mapStateToProps)(EstimatesList);
export default withStyles(styles)(EstimatesList);
