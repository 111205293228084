import React, { Component } from 'react'

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core components
import { CircularProgress } from '@material-ui/core';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// materialKit components
import ContentEditor from 'components/ContentEditor/ContentEditor.jsx';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

// @material-ui/icons
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';

class LandingPageCustomizations extends Component {

  constructor() {
    super()
    this.state = {
      content: '',
      mainContent: '',
      microContent: '',
      specials: [],
      loading: false,
      isReady: false,
      isMainReady: false,
      isMicroReady: false,
      isLandingPageEnabled: false
    }
  }

  componentDidMount() {

    db.getLandingPageConfigs('webConfig').then(config => {

      console.log(config);
      this.setState({mainContent: config.landingPageContent, isLandingPageEnabled: config.landingPageEnabled, isMainReady: true})

    });

    db.getLandingPageConfigs('adminConfig').then(config => {

      this.setState({microContent: config.landingPageContent, isMicroReady: true})

    });
  }

  saveContent = ()=> {
    db.updateLandingPageConfigs('webConfig', {landingPageContent: this.state.mainContent, landingPageEnabled: this.state.isLandingPageEnabled});
    db.updateLandingPageConfigs('adminConfig', {landingPageContent: this.state.microContent});
  }

  render() {

    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <div>
        <CustomTabs
          headerColor="success"
          tabs={[
            {
              tabName: "Main Site",
              tabIcon: AddShoppingCartOutlinedIcon,
              tabContent: (
                
                  <GridContainer direction="row">
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom:"20px"}}>
                      <FormControlLabel
                            control={
                            <Switch
                                checked={this.state.isLandingPageEnabled}
                                onChange={(e) => this.setState({isLandingPageEnabled: e.target.checked})}
                                classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                icon: classes.switchIcon,
                                iconChecked: classes.switchIconChecked,
                                bar: classes.switchBar
                                }}
                            />
                            }
                            classes={{
                            label: classes.label
                            }}
                            label="Enable Landing Page"
                        />
                        
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom:"20px"}}>
                      {
                        this.state.isMainReady?
                        <div>
                          <ContentEditor 
                            value={this.state.mainContent} 
                            onChange={(value)=>{this.setState({mainContent: value})}} />
                          
                        </div>
                        :
                          <GridContainer justify="center">
                            <CircularProgress />
                          </GridContainer>  
                      }
                    </GridItem>                    
                  </GridContainer>
              )
            },
            {
              tabName: "Micro Site",
              tabIcon: AccountBalanceOutlinedIcon,
              tabContent: (

                <GridContainer direction="row">
                    <GridItem xs={12} sm={12} md={12}>
                      {
                        this.state.isMicroReady?
                        <div>
                          <ContentEditor 
                            value={this.state.microContent} 
                            onChange={(value)=>{this.setState({microContent: value})}} />
                          
                        </div>
                        :
                          <GridContainer justify="center">
                            <CircularProgress />
                          </GridContainer>  
                      }
                    </GridItem>                    
                  </GridContainer>
              )
            }
          ]}
        />
        <GridContainer justify="flex-end">
          <GridItem xs={12} style={{marginTop:"20px"}}>
            <Button variant="contained" color="success" disabled={this.state.loading} onClick={this.saveContent}>Save</Button>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          {this.state.loading && <CircularProgress />}
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = state => state

LandingPageCustomizations = connect(mapStateToProps)(LandingPageCustomizations);
export default withStyles(modalStyle)(LandingPageCustomizations);