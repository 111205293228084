
// @material-ui/icons
import Affiliates from "@material-ui/icons/SupervisedUserCircle";
import Archive from "@material-ui/icons/Archive";
import AutoRenew from "@material-ui/icons/Autorenew";
import Book from "@material-ui/icons/ClassOutlined";
import Category from "@material-ui/icons/Category";
import Customer from "@material-ui/icons/AssignmentInd";
import Dashboard from "@material-ui/icons/Dashboard";
import House from "@material-ui/icons/House";
import Inbox from "@material-ui/icons/Inbox";
import List from "@material-ui/icons/ListAlt";
import Logout from "@material-ui/icons/ChevronLeft";
import Logs from "@material-ui/icons/List";
import Manufacturer from "@material-ui/icons/Business";
import Order from "@material-ui/icons/ViewList";
import Person from "@material-ui/icons/Person";
import Profile from "@material-ui/icons/PersonAdd";
import Receipt from "@material-ui/icons/Receipt";
import Special from "@material-ui/icons/FolderSpecial";
import UserAccount from "@material-ui/icons/AccountBox"
import Vendor from "@material-ui/icons/LocalShipping";
import Payment from "@material-ui/icons/Payment";
import Task from "@material-ui/icons/AssignmentTurnedIn";

var dashboardRoutes = [
  
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    isAdminOnly: false
  },
  {
    path: "/tasks",
    sidebarName: "Tasks",
    navbarName: "Tasks",
    icon: Task
  },
  {
    path: "/orders",
    sidebarName: "Orders",
    navbarName: "Orders",
    icon: Order,
    isAdminOnly: false
  },
  {
    path: "/invoices",
    sidebarName: "Invoices",
    navbarName: "Invoices",
    icon: Receipt,
    isAdminOnly: false
  },
  {
    path: "/customers",
    sidebarName: "Customers",
    navbarName: "Customer List",
    icon: Customer,
    isAdminOnly: false
  },
  {
    path: "/bills",
    sidebarName: "Bills",
    navbarName: "Bills",
    icon: Inbox,
    isAdminOnly: false
  },
  {
    path: "/rebates",
    sidebarName: "Rebates",
    navbarName: "Rebates",
    icon: Payment,
    isAdminOnly: false
  },
  {
    path: "/users",
    sidebarName: "Users",
    navbarName: "User List",
    icon: UserAccount,
    isAdminOnly: false
  },
  {
    path: "/affiliates",
    sidebarName: "Affiliates",
    navbarName: "Affiliates List",
    icon: Affiliates,
    isAdminOnly: false
  },
  {
    path: "/estimates",
    sidebarName: "Estimates",
    navbarName: "Estimates List",
    icon: List,
    isAdminOnly: false
  },
  {
    path: "/recurring",
    sidebarName: "Recurring Orders",
    navbarName: "Recurring Orders",
    icon: AutoRenew,
    isAdminOnly: false
  },
  {
    path: "/profiles",
    sidebarName: "Profiles",
    navbarName: "Customization Profiles",
    icon: Profile,
    isAdminOnly: false
  },
  {
    path: "/products",
    sidebarName: "Products",
    navbarName: "Product List",
    icon: Book,
    isAdminOnly: false
  },
  {
    path: "/landingPageCustomizations",
    sidebarName: "Landing Page",
    navbarName: "Landing Page Customization",
    icon: House,
    isAdminOnly: false
  },
  {
    path: "/specials",
    sidebarName: "Specials",
    navbarName: "Specials List",
    icon: Special,
    isAdminOnly: false
  },
  {
    path: "/categories",
    sidebarName: "Categories",
    navbarName: "Categories",
    icon: Category,
    isAdminOnly: false
  },
  {
    path: "/vendors",
    sidebarName: "Vendors",
    navbarName: "Vendors",
    icon: Vendor,
    isAdminOnly: false
  },
  {
    path: "/manufacturers",
    sidebarName: "Manufacturers",
    navbarName: "Manufacturers",
    icon: Manufacturer, 
    isAdminOnly: false
  },
  {
    path: "/user",
    sidebarName: "User Profile",
    navbarName: "Profile",
    icon: Person,
    isAdminOnly: true
  },
  {
    path: "/logs",
    sidebarName: "Logs",
    navbarName: "Logs",
    icon: Logs,
    isAdminOnly: false
  },
  {
    path: "/backups",
    sidebarName: "Backups",
    navbarName: "Database Backups",
    icon: Archive,
    isAdminOnly: false
  },
  {
    path: "/logout",
    sidebarName: "Logout",
    navbarName: "Logout",
    icon: Logout,
    isAdminOnly: false
  }
];

export default dashboardRoutes;
