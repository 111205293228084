//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BlogPostsLoaded } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//hanyu
import BlogPostEditor from "components/BlogPostEditor/BlogPostEditor.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class BlogPostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogPosts: []
    }

    this.blogPostUpdated = this.blogPostUpdated.bind(this);
  }

  componentDidMount() {
    this.refreshBlogPosts();
  }

  blogPostUpdated() {
    this.refreshBlogPosts();
  }

  refreshBlogPosts() {

    if (this.props.user.authenticated) {
      
      db.getBlogPosts().then(snapshot => {

        var blogPosts = [];
        snapshot.forEach((doc) => {
          
          var blogPost = doc.data();
          blogPost.id = doc.id;
          if(!blogPost.Photos)
            blogPost.Photos = [];
          blogPosts.push(blogPost);
        });
        
        this.props.onBlogPostsLoaded(blogPosts);
        this.setState({blogPosts:blogPosts});
      });
    }
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    ReactGA.pageview("teacher.hanyu.co/blogPosts");

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Blog Posts</h4>
                  <p className={classes.cardCategoryWhite}>
                    Managing the official 汉语.CO blog!
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  <Tooltip
                        id="tooltip-top-start"
                        title="Add Blog Post"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                      <BlogPostEditor onChange={this.blogPostUpdated}
                       blogPost={{
                          Title: "",
                          Subtitle: "",
                          PublishDate: "",
                          Body: ""
                        }} 
                      />
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <Table className={classes.table}>
                <TableBody>
                  {this.state.blogPosts.map((value,index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.tableActions}>
                        <Tooltip
                          id="tooltip-top"
                          title="Edit BlogPost"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                         <BlogPostEditor blogPost={value} onChange={this.blogPostUpdated}  />
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.Title}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.Subtitle}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onBlogPostsLoaded: BlogPostsLoaded }, dispatch);  
}

BlogPostList = connect(mapStateToProps, mapDispatchToProps)(BlogPostList);
export default withStyles(styles)(BlogPostList);
