//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//affiliate
import { affiliate } from 'assets/affiliate';

//components
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import CustomSelect from "components/Select/Select.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Button from "components/CustomButtons/Button.jsx";

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//icons
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/ListAlt";

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
    var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
    return time;
}

function Transition(props) {
    return <Slide direction="down" {...props} />;
}
    
class EstimateDetails extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        modal: false,
        estimate: { Items: [], customerID:"" },
        customers:[],
        affiliate: {},
        exceptions: []
      }

      this.createExceptions = this.createExceptions.bind(this);
      this.getAffiliate = this.getAffiliate.bind(this);
      this.getCustomers = this.getCustomers.bind(this);
      this.refreshCommissions = this.refreshCommissions.bind(this);
    }


  handleClickOpen(modal) {
    this.getCustomers();
    this.getAffiliate();
    

    var estimate = this.props.estimate;
    if(!estimate.customerID)
        estimate.customerID = "";
    this.setState({estimate: this.props.estimate}, () => {
        this.getCurrentExceptions();
    });

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  getCustomers() {
    var customers = [];
    for(var i = 0; i < this.props.customers.length; i++) {
        customers.push({key: this.props.customers[i].id, label: this.props.customers[i].customer_name});
    }
    this.setState({customers:customers});
  }

  getAffiliate() {
    db.getAffiliate(this.props.estimate.userID).then(affiliate => {

        var floorPercentage = 1.15;
        
        for(var c = 0; c < affiliate.Customers.length; c++) {
            if(affiliate.Customers[c].id === this.state.estimate.customerID) {

              if(affiliate.Customers[c].FloorPercentage) 
                floorPercentage = (Number(1) + (Number(affiliate.Customers[c].FloorPercentage) / 100)).toFixed(2);
                
              break;
            }
          }

        this.setState({affiliate:{
            
            baseCommission: Number(affiliate.baseCommission),
            customers: affiliate.Customers,
            commissionType: affiliate.commissionType,
            firstName: affiliate.FirstName,
            floorCommission: Number(affiliate.floorCommission),
            floorPercentage: floorPercentage,
            id: affiliate.id,
            lastName: affiliate.lastName
            
        }});
    })
  }

  getCurrentExceptions() {
      db.getPricingByCustomer(this.state.estimate.customerID).then(exceptions => {
        this.setState({exceptions:exceptions});
      });
  }

  updateLineItem(id, property, value) {
    var x = this.state.estimate;

    for(var i = 0; i < x.Items.length; i++) {

      if(x.Items[i].id === id) {
        x.Items[i][property] = value;

        //recalculate commission
        x.Items[i].commission = affiliate.calculateCommission(x.Items[i], x.Items[i].customPrice, this.state.affiliate);
        console.log(this.state.affiliate);

        break;
      }
    }

    this.setState({estimate: x});
  }

  deleteLineItem(id) {

    var x = this.state.estimate;

    for(var i = 0; i < x.Items.length; i++) {

      if(x.Items[i].id === id) {
        x.Items.splice(i,1);
        break;
      }    
    }

    this.setState({estimate: x});
  }

  refreshCommissions() {
    var x = this.state.estimate;

    for(var i = 0; i < x.Items.length; i++) 
        x.Items[i].commission = affiliate.calculateCommission(x.Items[i], x.Items[i].customPrice, this.state.affiliate);

    this.setState({estimate: x});
  }

  updateCustomer(id) {

    var estimate = this.state.estimate;
    estimate.customerID = id;

    var affiliate = this.state.affiliate;
    for(var i = 0; i < affiliate.customers.length; i++) {
        if(affiliate.customers[i].id === this.state.estimate.customerID) {
            console.log('customer found:')
            
            if(affiliate.customers[i].FloorPercentage) {  
                affiliate.floorPercentage = (Number(1) + (Number(affiliate.customers[i].FloorPercentage) / 100)).toFixed(2);
                console.log('custom floor %: ' + affiliate.floorPercentage);
            } 

            break;
        }
    }

    this.setState({estimate: estimate, affiliate: affiliate}, () => {
        this.refreshCommissions();
    });
  }

  updateEstimate(property, value) {
    var x = this.state.estimate;
    x[property] = value;
    this.setState({estimate: x});
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  createExceptions() {

    var promises = [];
    for(var i = 0; i < this.state.estimate.Items.length; i++) {

        var existingException = false;
        for(var j = 0; j < this.state.exceptions.length; j++) {
            if(this.state.exceptions[j].productID === this.state.estimate.Items[i].id) { // update existing exception

                var e = this.state.exceptions[j];
                e.Price = this.state.estimate.Items[i].customPrice;
                promises.push(db.updatePricing(e.id, e));

                existingException = true;
            }
        }

        if(!existingException) { // create a new exception

            var item = this.state.estimate.Items[i];
            var exception = { 
                CustomerID: this.state.estimate.customerID,
                Price: item.customPrice,
                productID: item.id,
                modified: Math.round((new Date()).getTime() / 1000)
            }

            promises.push(db.updatePricing(exception.id, exception));
        }
    }

    Promise.all(promises).then(() => {
        var estimate = this.state.estimate;
        estimate.status = "COMPLETE";

        db.updateEstimate(estimate.id, estimate).then(() => {
            this.props.estimateChanged(estimate);
            this.setState({estimate:estimate});
            this.handleClose("modal");
        });
    });
  }

  disableCreate() {
    var estimate = this.state.estimate;
    
    if(estimate.customerID.length > 0) {
            return false;
        }
    else return true;
  }

  showCreate() {
      if(this.state.estimate.status === 'NEW') 
          return "block";
      else
        return "none";
  }

  render() {

    const { classes } = this.props;

    return (
        <div>
            <IconButton
                aria-label="Edit"
                onClick={() => this.handleClickOpen("modal")}>
                <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
            </IconButton>
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                maxWidth="md"
                fullWidth
                open={this.state.modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("modal")}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">

                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    style={{backgroundColor:"#efefef"}} >

                    <GridContainer direction="row">
                    <GridItem xs={12} sm={12} md={6}>
                        <h4 className={classes.modalTitle}>Estimate Details</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                        
                    </GridItem>
                    </GridContainer>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                    style={{backgroundColor:"#efefef"}}>

                    <CustomTabs
                        headerColor="success"
                        tabs={[
                            {
                            tabName: "General",
                            tabIcon: List,
                            tabContent: (
                    
                                <GridContainer direction="row" style={{minHeight:"189px"}}>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Name</b><div style={{fontWeight:400}}>
                                            {this.state.estimate.Name}<br />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Prospect</b><div style={{fontWeight:400}}>
                                            {this.state.estimate.CustomerName}
                                        </div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Created By</b><div style={{fontWeight:400}}>
                                            {this.state.estimate.userName}
                                        </div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Created</b><div style={{fontWeight:400}}>
                                            {timeConverter(this.state.estimate.created)}
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} >
                                        <Table className={classes.table} style={{marginTop:"20px", marginBottom:"30px"}} >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{padding:"0px", width:"50px"}}></TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        Product ID
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        Name
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Cost
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Custom Price
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        List Price
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Estimated Commission
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.estimate.Items.map((value, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{padding:"0px", width:"50px"}}>
                                                    <Tooltip id="tooltip-top-start" title="Delete Line Item" placement="top" classes={{ tooltip: classes.tooltip }} >
                                                        <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this product? This action cannot be undone." confirmed={() => this.deleteLineItem(value.id)} />
                                                    </Tooltip>
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {value.productID}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {this.trim(value.name,54)}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right', color:"gray"}}>
                                                        ${value.cost}
                                                    </TableCell>
                                                    <TableCell style={{padding:"6px", width:"80px", textAlign:"right" }}>
                                                        <input type="text" value={value.customPrice} placeholder="price" 
                                                            onChange={(e) => this.updateLineItem(value.id, "customPrice", e.target.value)} 
                                                            className={classes.modalTextBox}  />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right', color:"gray"}}>
                                                        ${value.listPrice}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right', color:"#3fa31b"}}>
                                                        ${value.commission}
                                                    </TableCell>
                                                </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </GridItem>
                                </GridContainer>
                            )
                            }
                        ]}
                    />
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter }
                    style={{backgroundColor:"#efefef"}}>

                    <GridContainer>
                        <GridItem xs={3} style={{textAlign:"left"}}>
                            <Button onClick={() => this.handleClose("modal")} >
                                Close
                            </Button>
                        </GridItem>
                        <GridItem xs={5} style={{display:this.showCreate()}}>
                            <div style={{marginTop:"-30px", textAlign:"left"}}>
                            <CustomSelect
                                labelText="Customer"
                                inputProps={{ placeholder: "Customer" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.updateCustomer(e.target.value)}
                                options={this.state.customers}
                                value={this.state.estimate.customerID}
                            />
                            </div>
                        </GridItem>
                        <GridItem xs={4} style={{display:this.showCreate()}}>
                            <Button 
                                onClick={this.createExceptions}
                                disabled={this.disableCreate()}
                                color="success">
                                Create Pricing Exceptions
                            </Button>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
            </Dialog>
        </div>
    )
  }
}

const mapStateToProps = state => {
    return state;
  }
  
EstimateDetails = connect(mapStateToProps)(EstimateDetails);
export default withStyles(modalStyle)(EstimateDetails);