//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//zoho
import { zoho } from 'assets/zoho';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

//components
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
import Check from "@material-ui/icons/Check";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class ProfileEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      user: {
          FirstName: "",
          LastName: ""
      },
      customers: []
    };

    this.getCustomers = this.getCustomers.bind(this);
    this.getUser = this.getUser.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);

    this.getUser(() => {
        this.getCustomers();
    });
    
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  getCustomers() {
    var customers = this.props.customers;
    for(var i = 0; i < this.state.user.Customers.length; i++) {
        for(var j = 0; j < customers.length; j++) {
            if(this.state.user.Customers[i].id === customers[j].id ) {
                customers[j].isChecked = true;
                break;
            }
        }
    }
    this.setState({customers: customers});
  }

  getUser(callback) {
      db.getUser(this.props.user.id).then(doc => {
        var user = doc.data();
        this.setState({user:user}, () => {
            callback();
        });
      });
  }

  updateUser(property, value) {
    var obj = this.state.user;
    obj[property] = value;
    this.setState({user: obj});
  }

  toggleCustomer(customer) {
      var customers = this.state.customers;
      for(var i = 0; i < customers.length; i++) {
          if(customers[i].id === customer.id) {
            if(customers[i].isChecked)
                customers[i].isChecked = false;
            else 
                customers[i].isChecked = true;

            break;
          }
      }
      this.setState({customers:customers});
  }

  async saveProfile() {

    this.setState({saving: true});

    var customers = this.state.customers;
    var user = this.state.user;
    
    for(var i = 0; i < customers.length; i++) {
        
        var isFound = false;
        for(var j = 0; j < user.Customers.length; j++) {
            if(customers[i].id === user.Customers[j].id) {

                if(!customers[i].isChecked) {
                    await zoho.deleteZohoContact(user.Customers[j].contactID);
                    user.Customers.splice(j,1);
                }

                isFound = true;
                break;
            }
        }

        if(!isFound && customers[i].isChecked) { // add a new one
            var contactID = await zoho.createZohoContact(customers[i].id, this.state.user);
            user.Customers.push({ id: customers[i].id, Commission: 0, Name: customers[i].company_name, contactID: contactID });
        }
    }

    db.updateUser(user.id, user);
    this.setState({saving: false});
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        
        <Button justIcon
            aria-label="Edit Profile"
            color={window.innerWidth > 959 ? "transparent" : "white"}
            onClick={() => this.handleClickOpen("modal")}
            className={classes.buttonLink}>
            <Person className={ classes.tableActionButtonIcon + " " + classes.edit } />
        </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.modalTitle}>Profile Editor</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>

            <CustomTabs
                headerColor="success"
                tabs={[
                  {
                    tabName: "General",
                    tabIcon: Person,
                    tabContent: (
                      
                        <GridContainer direction="row" style={{minHeight:"369px"}}>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="First Name"
                              value={this.state.user.FirstName}
                              inputProps={{
                                  placeholder: "First Name",
                                  disabled: true
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateUser("FirstName", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Last Name"
                              value={this.state.user.LastName}
                              inputProps={{
                                  placeholder: "Last Name",
                                  disabled: true
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateUser("LastName", e.target.value)}
                            />
                          </GridItem>
                        </GridContainer>
                    )
                  },
                  {
                    tabName: "Customers",
                    tabIcon: Group,
                    tabContent: (
                      
                        <GridContainer direction="row">
                          <GridItem xs={12} sm={12} md={12}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{paddingRight:"3px"}}>
                                            Manage
                                        </TableCell>
                                        <TableCell>
                                            Company Name
                                        </TableCell>
                                        <TableCell>
                                            Contact
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.customers.map(value => (
                                    <TableRow key={value.id} className={classes.tableRow} >
                                        <TableCell className={classes.tableActions} style={{paddingRight:"3px"}}>
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.toggleCustomer(value)}
                                                checked={ value.isChecked }
                                                checkedIcon={ <Check className={classes.checkedIcon} style={{color:"green"}} /> }
                                                icon={ <Check className={classes.uncheckedIcon} style={{color:"#efefef"}} /> }
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {value.customer_name}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} >
                                            {value.first_name} {value.last_name}  
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                          </GridItem>
                          
                        </GridContainer>
                    )
                  },
                ]} />
          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveProfile();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

ProfileEditor = connect(mapStateToProps)(ProfileEditor);
export default withStyles(modalStyle)(ProfileEditor);