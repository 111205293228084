//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "components/Card/Card.jsx";

//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

//icons
import Search from "@material-ui/icons/Search";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class AddressChooser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      shipToAddress: { name: "" },
      filtered: []
    };

    //this.getAddresses = this.getAddresses.bind(this);
    this.searchAddresses = this.searchAddresses.bind(this);
    this.chooseAddress = this.chooseAddress.bind(this);
    
    
  }

  // initializing with a chosen address
  componentWillReceiveProps(nextProps){
    if(nextProps.address) {
      this.setState({shipToAddress: nextProps.address});
    }
    if(nextProps.locations) {
      this.setState({filtered: nextProps.locations});
    }
  }

  // dialog open/close
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  // return a filter of a single address or all addresses
  chooseAddress(address) {

    for(var i = 0; i < this.state.filtered.length; i++) {
      if(this.state.filtered[i].address_id === address.address_id) {
        this.setState({shipToAddress: this.state.filtered[i]});
        break;
      }
    }
    
    if(this.props.onAddressChosen)
      this.props.onAddressChosen(address.address_id);

    this.handleClose("modal");
  }

  // search text of addresses 
  searchAddresses(e) {
    var query = e.target.value.toLowerCase();
    var results = [];

    if(!query || query === "")
      results = this.props.locations;
    else {
      for(var i = 0; i < this.props.locations.length; i++) {
        if(this.props.locations[i].address.toLowerCase().indexOf(query) > -1
        || this.props.locations[i].city.toLowerCase().indexOf(query) > -1
        || this.props.locations[i].name.toLowerCase().indexOf(query) > -1
        || this.props.locations[i].state.toLowerCase().indexOf(query) > -1
        || this.props.locations[i].jobCode.indexOf(query) > -1
        || this.props.locations[i].zip.indexOf(query) > -1
        ) {
          results.push(this.props.locations[i]);
        }
      }
    }

    results.sort(this.sortByLabel);
    this.setState({filtered: results});
  }


  // all display tweak functions 

  // hide search if only a handful of addresses are available
  showSearch() {
    if(this.props.locations.length > 4)
      return "block";
    else 
      return "none";
  }

  getWidth() {
    if(this.props.showNewAddressForm)
      return "sm"
    else 
      return "md"
  }

  addressName() {
    if(this.state.shipToAddress.name) {
      return <b>{this.state.shipToAddress.name}<br /></b>
    } else {
      return "";
    }
  }

  // finally
  render() {

    const { classes } = this.props;

    return (
        <div>
          
          <div style={{cursor:"pointer", fontWeight:400}} onClick={() => this.handleClickOpen("modal")}>
            {this.addressName()}
            {this.state.shipToAddress.address}
            <br />{this.state.shipToAddress.city}, {this.state.shipToAddress.state} {this.state.shipToAddress.zip} 
            <br />{this.state.shipToAddress.country}
          </div>
           
          <Dialog
            classes={{ root: classes.center, paper: classes.modal }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={this.getWidth()}
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={6} >
                  <h4 className={classes.modalTitle}>Shipping Addresses</h4>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef", minHeight:"432px"}}>
              
              <GridContainer style={{overflow:"auto", maxHeight:"432px"}}>
                
                <GridItem xs={12} style={{display:this.showSearch()}}>
                  <CustomInput
                      labelText="Search"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControl
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search className={classes.icon} />
                          </InputAdornment>
                        ),
                        placeholder: "Address Search"
                      }}
                      onChange={this.searchAddresses}
                    />
                </GridItem>
                {this.state.filtered.map((address,index) => (
                <GridItem xs={12} sm={6} key={index} >
                  <Card style={{cursor:"pointer", marginTop:"10px", marginBottom:"10px", paddingTop:"10px", paddingBottom:"10px"}} onClick={() => this.chooseAddress(address)}>
                      <div style={{minHeight:"100px", padding:"6px", paddingLeft:"15px", paddingRight:"15px", fontSize:"13px"}}>
                          <h4 style={{fontFamily:'"Roboto Slab", "Times New Roman", serif', fontSize:"15px", marginBottom:"0px"}}>{address.name}</h4>
                          {address.address}
                          <br />{address.city}, {address.state} {address.zip} 
                          <br />{address.jobCode}
                      </div>
                  </Card>
                </GridItem>
                ))}
              </GridContainer>
            </DialogContent>
          </Dialog>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

AddressChooser = connect(mapStateToProps)(AddressChooser);
export default withStyles(modalStyle)(AddressChooser);