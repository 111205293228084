//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//algolia
import { algolia } from 'assets/algolia';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

//components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Copy from "@material-ui/icons/FileCopy";

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class StaticProfile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      sourceCustomer: "",
      name: "",
      rules: [],
      profile: { Name: "", id: uuidv4(), type: 'pricing' },
      loading: true
    };
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);

    this.loadPricing();
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  // pricing profiles

  getExceptions() {
    return new Promise((resolve, reject) => {

        var profileID = this.props.company.pricingProfile;
        
        if(profileID !== "none") {
            db.getExceptionsByProfile(profileID).then((exceptions) => {
                resolve(exceptions);
            });
        } else {
            resolve();
        }
    });
  }

  processExceptions(exceptions) {
    return new Promise((resolve, reject) => {


        if(exceptions) {   

            console.log(exceptions);

            var processed = [];
            var promises = [];

            for(var i = 0; i < exceptions.length; i++) {
                        
                if(exceptions[i].productID) { // individual exception

                    processed.push({ productID: exceptions[i].productID, Price: exceptions[i].Price });
                    
                } else { // rules
                
                    var filter = exceptions[i].filter;
                    var promise = algolia.getProductsIDs("", filter.manufacturers, filter.category, filter.subCat1, filter.subCat2, filter.subCat3, exceptions[i]);
                    promises.push(promise);
                }
            }
        
            if(promises.length > 0) { // if they have any category rules
        
                Promise.all(promises).then((queries) => {
        
                    for(var j = 0; j < queries.length; j++) {
                        for(var k = 0; k < queries[j].length; k++) {
                            var rule = queries[j][k].referenceData;

                            var percentage = 0;
                            var ruleType = "discount";
                            if(rule.discount)
                                percentage = Number(rule.discount);
                            if(rule.percentage) {
                                percentage = Number(rule.percentage);
                                ruleType = rule.ruleType;
                            }
                                
                            processed.push({ productID: queries[j][k].id, percentage: percentage, ruleType: ruleType });
                            
                        }
                    }

                    resolve(processed);
                });

            } else { // otherwise we're done

                resolve(processed);
                
            }
        } else resolve();
    });
    
  }

  loadPricing() {
    return new Promise((resolve, reject) => {

        this.getExceptions().then(pbe => { // profile based exceptions
            this.processExceptions(pbe).then((profileExceptions) => {

                if(!profileExceptions) profileExceptions = [];
                //console.log('profile exceptions (p): ' + profileExceptions.length);

                db.getPricingByCustomer(this.props.customer.id).then((cse) => { // customer specific exceptions
                    this.processExceptions(cse).then((customerExceptions) => {
                    
                        if(customerExceptions) { // overwrite profile exceptions if we have conflicting customer specific exceptions
                            //console.log('customer exceptions (p): ' + customerExceptions.length);

                            for(var i=0; i < customerExceptions.length; i++) {
                                
                                var isFound = false;
                                for(var j = 0; j < profileExceptions.length; j++) {
                                    if(customerExceptions[i].productID === profileExceptions[j].productID) {
                                        profileExceptions[j] = customerExceptions[i];
                                        isFound = true;

                                        break;
                                    }
                                }
                                if(!isFound)
                                    profileExceptions.push(customerExceptions[i]);
                            }
                        }
    
                        //console.log('total exceptions (p): ' + profileExceptions.length);
                        //console.log(profileExceptions);

                        this.createStaticRules( profileExceptions );

                        resolve();                    
                    });
                });
            });
        })
    });
  }

  createStaticRules(rules) {

    var promises = [];
    for(var i = 0; i < rules.length;i++) 
        if(!rules[i].Price)     
            promises.push(db.getProduct(rules[i].productID));
        
    Promise.all(promises).then((dbResults) => {

        for(var i = 0; i < rules.length; i++) {

            if(!rules[i].Price) { // if they have a price they are done already

                for(var j = 0; j < dbResults.length; j++) {

                    if(rules[i].productID === dbResults[j].id) {

                        var product = dbResults[j].data();
                        //console.log(product)

                        //for debugging 
                        //rules[i].originalPrice = product.ListPrice;
                        //rules[i].cost = product.WholesalePrice;
                        //rules[i].ProductID = product.ProductID;
                        //rules[i].name = product.ProductName;

                        product.originalPrice = product.ListPrice;
                        product.cost = product.WholesalePrice;
  
                        if (rules[i].ruleType === 'discount') {

                          var p1 = Number(
                            (100 - rules[i].percentage) / 100,
                          );
                          rules[i].Price = (
                            product.originalPrice * p1
                          ).toFixed(2);

                        } else if (rules[i].ruleType === 'margin') {

                          var p2 = Number(rules[i].percentage / 100);
                          var margin = product.originalPrice - product.cost;
                          rules[i].Price = (
                            Number(product.cost) +
                            margin * p2
                          ).toFixed(2);

                        } else if (rules[i].ruleType === 'cost') {

                          var p3 = Number(rules[i].percentage / 100);
                          rules[i].Price = (
                            Number(product.cost) +
                            product.cost * p3
                          ).toFixed(2);

                        }

                        delete rules[i].ruleType;
                        delete rules[i].percentage;

                        break;
                    }
                }
            }
        }

        //console.log(rules);

        this.setState({rules: rules, loading: false});
    });
    
  }

  createProfile() {
      var profile = this.state.profile;
      profile.created = new Date().getTime();
      profile.Name = this.state.name;

      db.updateProfile(profile.id, profile).then(() => {

        for(var m=0; m < this.state.rules.length; m++) {

            var rule = this.state.rules[m];
            rule.id = uuidv4();
            rule.profileID = profile.id;
            rule.modified = new Date().getTime();
            rule.type = "pricing";
            
            db.updateExceptions(rule.id, rule);
            
        }

      });
  }

  ruleCount() {
      if(this.state.loading)
        return "Please wait, generating static profile ...";
      else 
        return this.state.rules.length + " static prices will be created."
  }

  showCreateButton() {
      if(this.state.rules.length > 0 && this.state.name.length > 0) 
        return "block";
    else 
        return "none";
  }
  

  render() {

    const { classes } = this.props;

    return (
      <div>
        
        <IconButton
            aria-label="Create Static Profile" 
            onClick={() => this.handleClickOpen("modal")}>
            <Copy className={ classes.tableActionButtonIcon + " " + classes.edit } />
        </IconButton>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.modalTitle}>Create Static Profile</h4>
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>

            <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={12}>

                    This will create a new pricing profile by combining
                    all of the rules and pricing exceptions from the currently
                    selected pricing profile as well as any rules and exceptions 
                    assigned specifically to the customer. All rules will be converted
                    into static prices.

                    <CustomInput
                        id="Name"
                        labelText="New Profile Name"
                        value={this.state.name}
                        inputProps={{
                            placeholder: "Name"
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={(e) => this.setState({"name": e.target.value})}
                    />

                    {this.ruleCount()}
                    
                </GridItem>
            </GridContainer>
          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <div style={{display:this.showCreateButton()}}>
                <Button 
                    onClick={() => {
                        this.createProfile();
                        this.handleClose("modal");
                    }}
                    color="success">
                    Create Profile
                </Button>
              </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

StaticProfile = connect(mapStateToProps)(StaticProfile);
export default withStyles(modalStyle)(StaticProfile);