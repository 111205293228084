/* eslint-disable */

//react
import React from "react";

//redux
import { connect } from 'react-redux'

//developer
import PropTypes from "prop-types";

//routing
import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

//routing
import dashboardRoutes from "routes/dashboard.jsx";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import AffiliatesList from "views/AffiliatesList/AffiliatesList.jsx";
import BackupsPage from "views/BackupsPage/BackupsPage.jsx";
import BillsList from "views/BillsList/BillsList.jsx";
import BlogPostList from "views/BlogPostList/BlogPostList.jsx";
import CategoriesList from "views/CategoriesList/CategoriesList.jsx";
import CustomerList from "views/CustomerList/CustomerList.jsx";
import EstimatesList from "views/EstimatesList/EstimatesList.jsx";
import InvoicesList from "views/InvoicesList/InvoicesList.jsx";
import LandingPageCustomizations from "views/LandingPageCustomizations/LandingPageCustomizations.jsx";
import LogsPage from "views/LogsPage/LogsPage.jsx";
import ManufacturerList from "views/ManufacturerList/ManufacturerList.jsx";
import ProductList from "views/ProductList/ProductList.jsx";
import OrganizationPage from "views/Organization/OrganizationPage";
import OrdersList from "views/OrdersList/OrdersList.jsx";
import PhotosList from "views/PhotosList/PhotosList.jsx";
import ProfileList from "views/ProfileList/ProfileList.jsx";
import PurchaseList from "views/PurchaseList/PurchaseList.jsx";
import RebatesList from "views/RebatesList/RebatesList.jsx";
import RecurringList from "views/RecurringList/RecurringList.jsx";
import SpecialList from "views/SpecialList/SpecialList";
import Stripe from "views/Stripe/Stripe.jsx";
import TaskList from "views/TaskList/TaskList.jsx";
import UserList from "views/UserList/UserList.jsx";
import UserProfile from "views/UserProfile/UserProfile";
import VendorList from "views/VendorList/VendorList.jsx";

//styles
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

//images
import image from "assets/img/sidebar-4.jpg";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      searchQuery: ""
    };
    this.resizeFunction = this.resizeFunction.bind(this);

    if(this.props.user.authenticated) {

      if(this.props.user.userType === "admin")
        this.state.filteredRoutes = dashboardRoutes;
      else
        this.state.filteredRoutes = dashboardRoutes.filter(this.isAdminOnly);

    } else {
      console.log('user NOT authenticated, wtf');
      window.location = '/login';
    };
  }
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  isAdminOnly(navItem) {
    return !navItem.isAdminOnly;
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  searchText(query) {
    this.setState({searchQuery: query});
  }

  render() {
    const { classes, ...rest } = this.props;

    var params = {};
    if(this.props.location.search.length>0) {
      var values = this.props.location.search.substr(1).split("&");
      for(var i = 0; i < values.length; i++) {
        var keyValue = values[i].split("=");
        params[keyValue[0]] = keyValue[1];
      }
    }
    
    const switchRoutes = (
      <Switch>
        {this.state.filteredRoutes.map((prop, key) => {

          if (this.props.location.pathname == "/dashboard") {
            return <Route key="{key}" path={prop.path} render={(props) => (
              <DashboardPage authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/affiliates") {
            return <Route key={key} path={prop.path} render={(props) => (
              <AffiliatesList authUser={this.state.authUser} />
            )} />
          }
          else if (this.props.location.pathname == "/backups") {
            return <Route key={key} path={prop.path} render={(props) => (
              <BackupsPage authUser={this.state.authUser} />
            )} />
          }
          else if (this.props.location.pathname == "/bills") {
            return <Route key={key} path={prop.path} render={(props) => (
              <BillsList authUser={this.state.authUser} />
            )} />
          }
          else if (this.props.location.pathname == "/blogposts") {
            return <Route key={key} path={prop.path} render={(props) => (
              <BlogPostList authUser={this.state.authUser} />
            )} />
          }
          
          else if (this.props.location.pathname == "/categories") {
            return <Route key={key} path={prop.path} render={(props) => (
              <CategoriesList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/customers") {
            return <Route key={key} path={prop.path} render={(props) => (
              <CustomerList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/estimates") {
            return <Route key={key} path={prop.path} render={(props) => (
              <EstimatesList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/invoices") {
            return <Route key={key} path={prop.path} render={(props) => (
              <InvoicesList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/logs") {
            return <Route key={key} path={prop.path} render={(props) => (
              <LogsPage authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/manufacturers") {
            return <Route key={key} path={prop.path} render={(props) => (
              <ManufacturerList authUser={this.state.authUser} />
            )} />
          }
          else if (this.props.location.pathname == "/orders") {
            return <Route key={key} path={prop.path} render={(props) => (
              <OrdersList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/products") {
            return <Route key={key} path={prop.path} render={(props) => (
              <ProductList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/specials") {
            return <Route key={key} path={prop.path} render={(props) => (
              <SpecialList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/landingPageCustomizations") {
            return <Route key={key} path={prop.path} render={(props) => (
              <LandingPageCustomizations authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/org") {
            return <Route key={key} path={prop.path} render={(props) => (
              <OrganizationPage authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/photos") {
            return <Route key={key} path={prop.path} render={(props) => (
              <PhotosList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/profiles") {
            return <Route key={key} path={prop.path} render={(props) => (
              <ProfileList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/purchases") {
            return <Route key={key} path={prop.path} render={(props) => (
              <PurchaseList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/stripe") {
            return <Route key={key} path={prop.path} render={(props) => (
              <Stripe authUser={this.state.authUser} params={params} />
            )} />
          }

          else if (this.props.location.pathname == "/rebates") {
            return <Route key={key} path={prop.path} render={(props) => (
              <RebatesList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/recurring") {
            return <Route key={key} path={prop.path} render={(props) => (
              <RecurringList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/tasks") {
            console.log('going to tasks ... ')
            return <Route key={key} path={prop.path} render={(props) => (
              <TaskList authUser={this.state.authUser} onSearch={this.searchText} searchQuery={this.state.searchQuery} />
            )} />
          }

          else if (this.props.location.pathname == "/user") {
            return <Route key={key} path={prop.path} render={(props) => (
              <UserProfile authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/users") {
            return <Route key={key} path={prop.path} render={(props) => (
              <UserList authUser={this.state.authUser} />
            )} />
          }

          else if (this.props.location.pathname == "/vendors") {
            return <Route key={key} path={prop.path} render={(props) => (
              <VendorList authUser={this.state.authUser} />
            )} />
          }

          /*
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.to} key={key} />;
          return <Route path={prop.path} render={(props) => (
            
          )} /> // component={prop.component} key={key} authUser={this.state.authUser} />;
          */
          
        })}
      </Switch>
    );

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.state.filteredRoutes}
          logoText={"Ecclesia"}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="green"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            routes={this.state.filteredRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />

          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : (
            <div className={classes.map}>{switchRoutes}</div>
          )}

          {this.getRoute() ? <Footer /> : null}

        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state;
}

App = connect(mapStateToProps)(App);
export default withStyles(dashboardStyle)(App);
