import { db } from './firebase';

const settings = {timestampsInSnapshots: true};
db.settings(settings);

export const deleteCategory = (id) =>
  db.collection('Categories').doc(id).delete();

export const deleteDocument = (collection, id) =>
  db.collection(collection).doc(id).delete();

export const deleteEstimate = (id) =>
  db.collection('Estimates').doc(id).delete();

export const deleteExceptions = (id) =>
  db.collection('Exceptions').doc(id).delete();

export const deleteInvoice = (id) =>
  db.collection('Invoices').doc(id).delete();

export const deleteManufacturer = (id) =>
  db.collection('Manufacturers').doc(id).delete();

export const deleteOrder = (id) =>
  db.collection('Orders').doc(id).delete();

export const deleteProduct = (id) =>
  db.collection('Products').doc(id).delete();

export const deleteProfile = (id) =>
  db.collection('Profiles').doc(id).delete();

export const deletePricing = (id) =>
  db.collection('Pricing').doc(id).delete();

export const deleteRecurring = (id) =>
  db.collection('Recurring').doc(id).delete();

export const deleteSpecial = (id) =>
  db.collection('Specials').doc(id).delete();

export const deleteTask = (id) =>
  db.collection('Tasks').doc(id).delete();


export const deleteTaxExemptions = (id) =>
  db.collection('TaxExemptions').doc(id).delete();

export const deleteUser = (id) =>
  db.collection('Users').doc(id).delete();

export const deleteVendor = (id) =>
  db.collection('Vendors').doc(id).delete();

export const deleteVisibilityExceptions = (id) =>
  db.collection('VisibilityExceptions').doc(id).delete();





export const getActivity = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Activity');
    baseRef.orderBy("modified","desc").get().then(snapshot => {
      var activity = [];
      snapshot.forEach((doc) => {
        var a = doc.data();
        a.id = doc.id;
        activity.push(a);
      })
      resolve(activity);
    });
  });
}

export const getAddressesByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    db.collection('Addresses').doc(customerID).get().then((doc) => {

      var obj = doc.data();
      
      var addresses = obj.addresses;

      for(var k = 0; k < addresses.length; k++) {
        addresses[k].customer_id = customerID;
      }

      // special case DTK + DTK2
      if(customerID === '1343284000000063075') {

        

        db.collection('Addresses').doc('1343284000001266431').get().then((doc2) => {
          var obj2 = doc2.data();

          for(var i = 0; i < obj2.addresses.length; i++) {
            obj2.addresses[i].customer_id = '1343284000001266431';
            addresses.push(obj2.addresses[i]);
          }
          
          db.collection('Addresses').doc('1343284000001915048').get().then((doc3) => {
            var obj3 = doc3.data();

            for(var i = 0; i < obj3.addresses.length; i++) {
              obj3.addresses[i].customer_id = '1343284000001915048';
              addresses.push(obj3.addresses[i]);
            }

            getShipTos(addresses).then(addresses => {

              resolve(addresses);
            });
            
          });
        });
      } else {

        getShipTos(addresses).then(addresses => {

          

          resolve(addresses);
        });
      }
    });
  });
}

function getShipTos(addresses) {

  return new Promise(function(resolve, reject) {
    
    var promises = [];
    addresses.forEach(address => {
      address.shipToName = address.attention;
      promises.push(db.collection('ShipTo').doc(address.address_id).get());
    });
    
    Promise.all(promises).then(results => {

      results.forEach(result => {
        
        var shipTo = result.data();
        if(shipTo) {
         
          for(var i = 0; i < addresses.length; i++) {
            if(addresses[i].address_id === result.id) {
              addresses[i].shipToName = shipTo.Name;
              break;
            }
          }
        }
      });

      resolve(addresses);
    });
  });
}

export const getAffiliate = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Users').doc(id).get().then(doc => {
      var x = doc.data();
      if(x) x.id = doc.id;
      resolve(x);
    });
  });
}

export const getAffiliates = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Users');
    var queryRef = baseRef.where('userType','==','Affiliate');
    queryRef.orderBy("LastName","desc").get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getAffiliatesByCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Users');
    var queryRef = baseRef.where('userType','==','Affiliate');
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();

        for(var i = 0; i < obj.Customers.length; i++) {
          if(obj.Customers[i].id === id) {
            obj.id = doc.id;
            objs.push(obj);
            break;
          }
        }
      });
      resolve(objs);
    });
  });
}

export const getAlerts = (orgID) => {
  var baseRef = db.collection('Alerts');
  var queryRef = baseRef.where('orgId','==',orgID);
  return queryRef.orderBy("dateTimeSent","desc").get();
}

export const getBackups = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Backups');
    return baseRef.orderBy("modified","desc").get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getBillsByOrder = (orderID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Bills');
    var queryRef = baseRef.where('orderID','==',orderID);
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getBlogPosts = () => {
  return db.collection('BlogPosts').get();
}

export const getBudgetExceptionsByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('BudgetExceptions');
    var queryRef = baseRef.where('CustomerID','==',customerID);
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getCategories = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Categories');
    baseRef.orderBy("Name","asc").get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        d.key = d.id;
        d.label = d.Name;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getClasses = () => {
  return db.collection('Classes').get();
}

export const getCompany = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Companies').doc(id).get().then(doc => {
      var x = doc.data();
      if(x) x.id = doc.id;
      resolve(x);
    });
  });
}

export const getCompanies = () => {
  return new Promise(function(resolve, reject) {
    db.collection('Companies').get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    })
  });
}

export const getContacts = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Contacts');
    baseRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getContactsByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Contacts');
    var queryRef = baseRef.where('customerID','==',customerID);
    queryRef.orderBy("last_name","desc").get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getCourses = () => {
  return db.collection('Courses').orderBy("SortIndex","desc").get();
}

export const getCustomer = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Customers').doc(id).get().then(doc => {
      var customer = doc.data();
      customer.id = doc.id;
      resolve(customer);
    });
  });
}

export const getCustomers = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Customers');
    var queryRef = baseRef.where('contact_type','==','customer');
    queryRef.orderBy("customer_name","asc").get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        d.key = d.id;
        d.label = d.customer_name;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getCustomerWithAddresses = (customerID) => {
  return new Promise(function(resolve, reject) {

    getCustomer(customerID).then(customer => {
      getAddressesByCustomer(customerID).then(addresses => {
        resolve( {customer: customer, addresses: addresses } );
      });
    })
  });
};

export const getEstimates = (startAfter) => {
  
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Estimates');
    var queryRef = baseRef.orderBy('lastModifiedOn','desc');
    if(startAfter) {
      queryRef = queryRef.startAfter(startAfter);
    }
    
    return queryRef.limit(180).get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      
      resolve(objs);
    });
  });
}

export const getExceptionsByProfile = (profileID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Exceptions');
    var queryRef = baseRef.where('profileID','==',profileID);
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getInvoices = (startAfter) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Invoices');
      var queryRef = baseRef.orderBy('invoice_id','desc');
      if(startAfter) {
        queryRef = queryRef.startAfter(startAfter);
      }
      return queryRef.limit(50).get().then(snapshot => {
        var objs = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          objs.push(d);
        });
        resolve(objs);
      });
    }
    catch(exception) {
      console.log('db.getInvoices() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getInvoicesForRebates = (startDate, endDate) => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Invoices');

      // To get any from prior months that is paid in the month being viewed
      var queryRef1 = baseRef
        .where('last_payment_date', '>=', startDate)
        .where('last_payment_date', '<=', endDate)
        .get()

      // To get any from prior months that have been not been paid
      var queryRef2 = baseRef
        .where('status', '>', 'paid')
        .where('status', '<', 'paid')
        .get()

      // To get all invoices issued in the month being viewed - whether they are paid or not
      var queryRef3 = baseRef
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .get()

      var promises = [queryRef1, queryRef2, queryRef3];

      Promise.all(promises).then(results => {

        var invoices = [];
        
        // any from prior months that is paid in the month being viewed
        results[0].forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          if (d.status === 'paid' && d.date < startDate) {
            invoices.push(d);
          } 
        });

        // any from prior months that have been not been paid
        results[1].forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          if (d.date < startDate) {
            invoices.push(d);
          } 
        });

        // all invoices issued in the month being viewed - whether they are paid or not
        results[2].forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          invoices.push(d);
        });

        resolve(invoices)
        
      });
    }
    catch(exception) {
      console.log('db.getInvoicesForRebates() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getLandingPageConfigs = (configName) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Others');
    baseRef.doc(configName).get().then(doc => {
      var d = doc.data();
      if(d)
        d.id = doc.id;
      
      resolve(d);
    });
  });
}

export const getLessons = (classID) => {
  var baseRef = db.collection('Lessons');
  var queryRef = baseRef.where('ClassID','==',classID);
  return queryRef.orderBy("Quarter","desc").orderBy("Week","desc").get();
}

export const getLogs = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Logs');
    baseRef.orderBy("created","desc").get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getManufacturers = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Manufacturers');
    baseRef.orderBy("Name","asc").get().then(snapshot => {
      var manufacturers = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        d.key = d.id;
        d.label = d.Name;
        manufacturers.push(d);
      });
      resolve(manufacturers);
    });
  });
}

export const getModifiedOrders = (startAfter) => {
  
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Orders');
    var queryRef = baseRef.orderBy('modified','asc');
    if(startAfter) {
      queryRef = queryRef.startAfter(startAfter);
    }
    
    return queryRef.get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      
      resolve(objs);
    });
  });
}

export const getOrder = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Orders').doc(id).get().then(doc => {
      var obj = doc.data();
      obj.id = doc.id;
      resolve(obj);
    });
  });
}

export const getOrders = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Orders');
    return baseRef.orderBy("modified","desc").get().then(snapshot => {
      var orders = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        orders.push(d);
      });
      resolve(orders);
    });
  });
}

export const getOrganization = (id) =>
  db.collection('Organizations').doc(id).get();

export const getPhotos = () => {
  return db.collection('Photos').get();
}

export const getProduct = (id) =>
  db.collection('Products').doc(id).get();

export const getProductByID = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Products').doc(id).get().then(doc => {
      var obj = doc.data();
      obj.id = doc.id;
      resolve(obj);
    });
  });
}
  
export const getProducts = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Products');
    baseRef.orderBy("ProductName","asc").get().then(snapshot => {
      var products = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        products.push(d);
      });
      resolve(products);
    });
  });
}


export const getProductsByManufacturer = (manufacturerID) => {
  var baseRef = db.collection('Products');
  var queryRef = baseRef.where('ManufacturerID','==',manufacturerID);
  return queryRef.orderBy("ProductName","asc").get();
}

export const getPurchases = (orgID) => {

  var baseRef = db.collection('Purchases');
  var queryRef = baseRef.where('orgId','==',orgID);
  return queryRef.orderBy("created","desc").get();
}

export const getPricingByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Pricing');
    var queryRef = baseRef.where('CustomerID','==',customerID);
    queryRef.orderBy("modified","desc").get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getProfiles = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Profiles');
    return baseRef.orderBy("Name","asc").get().then(snapshot => {
      var profiles = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        profiles.push(d);
      });
      resolve(profiles);
    });
  });
}

export const getPricingProfiles = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Profiles');
    return baseRef.where('type','==','pricing').orderBy("Name","asc").get().then(snapshot => {
      var profiles = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        profiles.push(d);
      });
      resolve(profiles);
    });
  });
}

export const getRecentBills = (startAfter) => {
  
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Bills');
    var queryRef = baseRef.orderBy('created','desc');
    if(startAfter) {
      queryRef = queryRef.startAfter(startAfter);
    }
    
    return queryRef.limit(180).get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      
      resolve(objs);
    });
  });
}

export const getRecentLogins = (startAfter) => {
  
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Activity');
    var queryRef = baseRef.where('ActivityType','==','Login-App').orderBy('modified','desc');
    if(startAfter) {
      queryRef = queryRef.startAfter(startAfter);
    }
    
    return queryRef.limit(180).get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      
      resolve(objs);
    });
  });
}

export const getRecentLogs = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Logs');
    baseRef.orderBy("created","desc").limit(123).get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getRecentOrders = (startAfterModified, startAfterId) => {
  
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Orders');
    var queryRef = baseRef.orderBy('modified','desc');
    if(startAfterModified) {
      if(startAfterId) {
        queryRef = queryRef.orderBy('id', 'asc').startAfter(startAfterModified, startAfterId);
      } else {
        queryRef = queryRef.startAfter(startAfterModified);
      }
    }
    return queryRef.limit(100).get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      
      resolve(objs);
    });
  });
}

export const getRecurringOrders = () => {
  return new Promise(function(resolve, reject) {
    try
    {
      var baseRef = db.collection('Recurring');
      //var queryRef = baseRef.where('IsEnabled','==',true);
      return baseRef.orderBy("Name","asc").get().then(snapshot => {
        var orders = [];
        snapshot.forEach((doc) => {
          var d = doc.data();
          d.id = doc.id;
          orders.push(d);
        });
        resolve(orders);
      });
    }
    catch(exception) {
      console.log('db.getRecurringOrders() EXCEPTION');
      console.log(exception);
    }
  });
}

export const getSpecials = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Specials');
    return baseRef.orderBy("from","asc").get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getTasks = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Tasks');
    return baseRef.get().then(snapshot => {
      var objs = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        objs.push(d);
      });
      resolve(objs);
    });
  });
}

export const getTaxExemption = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('TaxExemptions').doc(id).get().then((doc) => {
  
        var obj = doc.data();
        if(obj) {
          obj.id = doc.id;
          resolve (obj);
        } else {
          resolve(null);
        }
    });
  });
}

export const getUsers = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Users');
    var queryRef = baseRef.orderBy("LastName","desc");
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getUsersByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Users');
    var queryRef = baseRef.where('customerID','==',customerID);
    queryRef.orderBy("LastName","desc").get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}

export const getVendor = (id) => {
  return new Promise(function(resolve, reject) {
    db.collection('Vendors').doc(id).get().then(doc => {
      var obj = doc.data();
      obj.id = doc.id;
      resolve(obj);
    });
  });
}

export const getVendors = () => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('Vendors');
    return baseRef.orderBy("Name","asc").get().then(snapshot => {
      var vendors = [];
      snapshot.forEach((doc) => {
        var d = doc.data();
        d.id = doc.id;
        vendors.push(d);
      });
      resolve(vendors);
    });
  });
}

export const getVisibilityExceptionsByCustomer = (customerID) => {
  return new Promise(function(resolve, reject) {
    var baseRef = db.collection('VisibilityExceptions');
    var queryRef = baseRef.where('CustomerID','==',customerID);
    queryRef.get().then(snapshot => {
      
      var objs = [];
      snapshot.forEach((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        objs.push(obj);
      });
      resolve(objs);
    });
  });
}



export const getStripeAccount = (id) =>
  db.collection('StripeAccounts').doc(id).get();

  
export const getUser = (id) => 
  db.collection('Users').doc(id).get();

export const getVideos = (orgID) => {
  var baseRef = db.collection('Videos');
  //var queryRef = baseRef.where('ClassID','==',classID);
  return baseRef.orderBy("Name","desc").get();
}


export const logActivity = (userID, orgID, schoolID, classID, activityType) => {

  var activity = {
    UserID: userID,
    SchoolID: schoolID,
    ClassID: classID,
    ActivityType: activityType,
    orgID: orgID,
    modified: Math.round((new Date()).getTime() / 1000)
  }

  return db.collection('Activity').add(activity);
}

export const setUserIsDisabled = async (userID, isDisabled) => {
  return db.collection('Users').doc(userID).update({isDisabled})
}


export const updateAddress = (id, value) => {
  
  // note: not updating addresses right now, just ship tos
  // ship tos are "internal" and only refered by address

  var shipTo = { id: id, Name: value.shipToName };

  if(typeof id === 'undefined')
    return db.collection('ShipTo').add(shipTo);
  else 
    return db.collection('ShipTo').doc(id).set(shipTo);
}

export const updateAffiliate = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Affiliates').add(value);
  else 
    return db.collection('Affiliates').doc(id).set(value);
}

export const updateBill = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Bills').add(value);
  else 
    return db.collection('Bills').doc(id).set(value);
}

export const updateBlogPost = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('BlogPosts').add(value);
  else 
    return db.collection('BlogPosts').doc(id).set(value);
}
  
export const updateCategory = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Categories').add(value);
  else 
    return db.collection('Categories').doc(id).set(value);
}

export const updateCompany = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Companies').add(value);
  else 
    return db.collection('Companies').doc(id).set(value);
}

export const updateCustomer = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Customers').add(value);
  else 
    return db.collection('Customers').doc(id).set(value);
}

export const updateCourse = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Courses').add(value);
  else 
    return db.collection('Courses').doc(id).set(value);
}

export const updateDocument = (collection, id, value) => {
  if(typeof id === 'undefined')
    return db.collection(collection).add(value);
  else 
    return db.collection(collection).doc(id).set(value);
}

export const updateExceptions = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Exceptions').add(value);
  else 
    return db.collection('Exceptions').doc(id).set(value);
}

export const updateEstimate = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Estimates').add(value);
  else 
    return db.collection('Estimates').doc(id).set(value);
}

export const updateLandingPageConfigs = (configName, value) => {
  return db.collection('Others').doc(configName).set(value);
}

export const updateLesson = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Lessons').add(value);
  else 
    return db.collection('Lessons').doc(id).set(value);
}
  
export const updateLocation = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Locations').add(value);
  else 
    return db.collection('Locations').doc(id).set(value);
}

export const updateManufacturer = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Manufacturers').add(value);
  else 
    return db.collection('Manufacturers').doc(id).set(value);
}

export const updateOrder = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Orders').add(value);
  else 
    return db.collection('Orders').doc(id).set(value);
}

export const updateOrganization = (id, value) => {
  if(id === 0) 
    return db.collection('Organizations').add(value);
  else 
    return db.collection('Organizations').doc(id).set(value);
}

export const updatePhoto = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Photos').add(value);
  else 
    return db.collection('Photos').doc(id).set(value);
}

export const updateProduct = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Products').add(value);
  else 
    return db.collection('Products').doc(id).set(value);
}

export const updatePricing = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Pricing').add(value);
  else 
    return db.collection('Pricing').doc(id).set(value);
}

export const updateProfile = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Profiles').add(value);
  else 
    return db.collection('Profiles').doc(id).set(value);
}

export const updateRecurringOrder = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Recurring').add(value);
  else 
    return db.collection('Recurring').doc(id).set(value);
}

export const updateSchool = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Schools').add(value);
  else 
    return db.collection('Schools').doc(id).set(value);
}

export const updateSpecial = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Specials').add(value);
  else 
    return db.collection('Specials').doc(id).set(value);
}

export const updateTask = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Tasks').add(value);
  else 
    return db.collection('Tasks').doc(id).set(value);
}

export const updateTaxExemptions = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('TaxExemptions').add(value);
  else 
    return db.collection('TaxExemptions').doc(id).set(value);
}

export const updateUser = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Users').add(value);
  else 
    return db.collection('Users').doc(id).set(value);
}

export const updateVendor = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('Vendors').add(value);
  else 
    return db.collection('Vendors').doc(id).set(value);
}

export const updateVisibilityExceptions = (id, value) => {
  if(typeof id === 'undefined')
    return db.collection('VisibilityExceptions').add(value);
  else 
    return db.collection('VisibilityExceptions').doc(id).set(value);
}
