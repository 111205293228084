
// alerts
export const ALERT_CREATED = 'ALERT_CREATED';

export function AlertCreated(alertObj) {
  return {
    type: ALERT_CREATED,
    alert: alertObj
  }
}

// blog posts 
export const BLOGPOST_CREATED = 'BLOGPOST_CREATED';
export const BLOGPOSTS_LOADED = 'BLOGPOSTS_LOADED';
export const BLOGPOST_UPDATED = 'BLOGPOST_UPDATED';

export function BlogPostCreated(obj) {
  return {
    type: BLOGPOST_CREATED,
    blogPost: obj
  }
}

export function BlogPostsLoaded(objs) {
  return {
    type: BLOGPOSTS_LOADED,
    blogPosts: objs
  }
}

export function BlogPostUpdated(obj) {
  return {
    type: BLOGPOST_UPDATED,
    blogPost: obj
  }
}

// categories
export const CATEGORY_CREATED = 'CATEGORY_CREATED';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';

export function CategoryCreated(obj) {
  return {
    type: CATEGORY_CREATED,
    category: obj
  }
}

export function CategoriesLoaded(objs) {
  return {
    type: CATEGORIES_LOADED,
    categories: objs
  }
}

export function CategoryUpdated(obj) {
  return {
    type: CATEGORY_UPDATED,
    category: obj
  }
}


// customers 
export const CUSTOMER_CREATED = 'CUSTOMER_CREATED';
export const CUSTOMERS_LOADED = 'CUSTOMERS_LOADED';
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';

export function CustomerCreated(obj) {
  return {
    type: CUSTOMER_CREATED,
    customer: obj
  }
}

export function CustomersLoaded(objs) {
  return {
    type: CUSTOMERS_LOADED,
    customers: objs
  }
}

export function CustomerUpdated(obj) {
  return {
    type: CUSTOMER_UPDATED,
    customer: obj
  }
}

// events
export const EVENT_CREATED = 'EVENT_CREATED';
export function EventCreated(eventObj) {
  return {
    type: EVENT_CREATED,
    alert: eventObj
  }
}

// manufacturers
export const MANUFACTURER_CREATED = 'MANUFACTURER_CREATED';
export const MANUFACTURERS_LOADED = 'MANUFACTURERS_LOADED';
export const MANUFACTURER_UPDATED = 'MANUFACTURER_UPDATED';

export function ManufacturerCreated(obj) {
  return {
    type: MANUFACTURER_CREATED,
    manufacturer: obj
  }
}

export function ManufacturersLoaded(objs) {
  return {
    type: MANUFACTURERS_LOADED,
    manufacturers: objs
  }
}

export function ManufacturerUpdated(obj) {
  return {
    type: MANUFACTURER_UPDATED,
    manufacturer: obj
  }
}

// org
export const ORG_LOADED = 'ORG_LOADED';
export const ORG_UPDATED = 'ORG_UPDATED';

export function OrgLoaded(orgObj) {
  return {
    type: ORG_LOADED,
    org: orgObj
  }
}

export function OrgUpdated(orgObj) {
  return {
    type: ORG_UPDATED,
    org: orgObj
  }
}


// photos
export const PHOTO_CREATED = 'PHOTO_CREATED';
export const PHOTOS_LOADED = 'PHOTOS_LOADED';
export const PHOTO_UPDATED = 'PHOTO_UPDATED';

export function PhotoCreated(obj) {
  return {
    type: PHOTO_CREATED,
    photo: obj
  }
}

export function PhotosLoaded(objs) {
  return {
    type: PHOTOS_LOADED,
    photos: objs
  }
}

export function PhotoUpdated(obj) {
  return {
    type: PHOTO_UPDATED,
    photo: obj
  }
}

// profiles 
export const PROFILE_CREATED = 'PROFILE_CREATED';
export const PROFILES_LOADED = 'PROFILES_LOADED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';

export function ProfileCreated(profile) {
  return {
    type: PROFILE_CREATED,
    profile: profile
  }
}

export function ProfilesLoaded(profiles) {
  return {
    type: PROFILES_LOADED,
    profiles: profiles
  }
}

export function ProfileUpdated(profile) {
  return {
    type: PROFILE_UPDATED,
    profile: profile
  }
}

// tasks
export const TASK_CREATED = 'TASK_CREATED';
export const TASKS_LOADED = 'TASKS_LOADED';
export const TASK_UPDATED = 'TASK_UPDATED';

export function TaskCreated(obj) {
  return {
    type: TASK_CREATED,
    task: obj
  }
}

export function TasksLoaded(objs) {
  return {
    type: TASKS_LOADED,
    tasks: objs
  }
}

export function TaskUpdated(obj) {
  return {
    type: TASK_UPDATED,
    task: obj
  }
}

// user
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';

export function UserLoggedIn(userObj) {
  return {
    type: USER_LOGGEDIN,
    user: userObj
  }
}

export function UserLoggedOut() {
  return {
    type: USER_LOGGEDOUT,
    user: null
  }
}

export const USER_CREATED = 'USER_CREATED';
export const USERS_LOADED = 'USERS_LOADED';
export const USER_UPDATED = 'USER_UPDATED';

export function UserCreated(user) {
  return {
    type: USER_CREATED,
    user: user
  }
}

export function UsersLoaded(users) {
  return {
    type: USERS_LOADED,
    users: users
  }
}

export function UserUpdated(user) {
  return {
    type: USER_UPDATED,
    user: user
  }
}

// vendors 
export const VENDOR_CREATED = 'VENDOR_CREATED';
export const VENDORS_LOADED = 'VENDORS_LOADED';
export const VENDOR_UPDATED = 'VENDOR_UPDATED';

export function VendorCreated(vendor) {
  return {
    type: VENDOR_CREATED,
    vendor: vendor
  }
}

export function VendorsLoaded(vendors) {
  return {
    type: VENDORS_LOADED,
    vendors: vendors
  }
}

export function VendorUpdated(vendor) {
  return {
    type: VENDOR_UPDATED,
    vendor: vendor
  }
}

