//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/Select/Select.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FileUpload from "components/FileUpload/FileUpload.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.jsx";
import PhotoCard from "components/PhotoCard/PhotoCard.jsx";
import PhotoUpload from "components/PhotoUpload/PhotoUpload.jsx";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

// @material-ui/icons
import Add from "@material-ui/icons/PlaylistAdd";
import Edit from "@material-ui/icons/Edit";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Money from "@material-ui/icons/AttachMoney"
import Photos from "@material-ui/icons/PhotoCameraOutlined";
import Attachment from "@material-ui/icons/AttachFile";
import Code from "@material-ui/icons/Code";
import HelpIcon from "@material-ui/icons/Help";

//draft html editor
import ContentEditor from "components/ContentEditor/ContentEditor.jsx";

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
  class ProductEditor extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        product: {
          ProductName: "", 
          BriefDescription: "", 
          ManufacturerID: "", 
          BrandID: "", 
          CategoryID:"",
          SubCat1ID:"",
          SubCat2ID:"",
          SubCat3ID:"",
          DefaultVendor:"",
          WholesalePrice: 0,
          Price: 0,
          ListPrice: 0,
          ProductID: "",
          Size:"",
          CasePack:"",
          Photos: [],
          IsVisible: false,
          Vendors: [],
          Microsite: false
        },
        manufacturers: props.manufacturers,
        brands:[],
        categories: props.categories,
        subCat1: [],
        subCat2: [],
        subCat3: [],
        photos:[],
        attachments:[],
        uploadControls:[uuidv4()],
        addVendorVisible: false,
        selectedVendor: {id:"", cost:0},
        vendors: [],
        chosenVendors: []
      };

      this.getBrands = this.getBrands.bind(this);
      this.saveProduct = this.saveProduct.bind(this);
      this.updateProduct = this.updateProduct.bind(this);

      this.addPhoto = this.addPhoto.bind(this);
      this.deletePhoto = this.deletePhoto.bind(this);
      this.updatePhoto = this.updatePhoto.bind(this);
      this.makeCoverPhoto = this.makeCoverPhoto.bind(this);
      this.makeThumbPhoto = this.makeThumbPhoto.bind(this);

      this.fileUploaded = this.fileUploaded.bind(this);

      this.addVendor = this.addVendor.bind(this);
      this.updateDefaultVendor = this.updateDefaultVendor.bind(this);
    }

    handleClickOpen(modal) {
      this.getBrands(this.props.product.ManufacturerID).then(() => {
        
        this.getVendors().then(() => {

          if(this.props.product.CategoryID) {

            this.getSubCategories(this.props.product.CategoryID).then(() => {

              var product = this.props.product;

              if(!product.WholesalePrice)
                product.WholesalePrice = 0;
              if(!product.ListPrice)
                product.ListPrice = 0;
              if(!product.Price)
                product.Price = 0;
              if(!product.BrandID)
                product.BrandID = "";
              if(!product.Photos) 
                product.Photos = [];
              if(!product.IsVisible)
                product.IsVisible = false;
              if(!product.SubCat1ID)
                product.SubCat1ID = "";
              if(!product.SubCat2ID)
                product.SubCat2ID = "";
              if(!product.SubCat3ID)
                product.SubCat3ID = "";
              if(!product.Size)
                product.Size = "";
              if(!product.CasePack)
                product.CasePack = "";
              if(!product.Attachments)
                product.Attachments = [];
              if(!product.DefaultVendor)
                product.DefaultVendor = "";

              if(!product.Vendors)
                product.Vendors = [];
              else {
                var chosenVendors = [];
                for(var i = 0; i < product.Vendors.length; i++) 
                  chosenVendors.push({ key: product.Vendors[i].id, label: product.Vendors[i].Name });
                  this.setState({chosenVendors: chosenVendors});
              }
  
              this.setState({product: product, photos: product.Photos, attachments: product.Attachments}, () => {
                
                var x = [];
                x[modal] = true;
                this.setState(x);
              });
            });

          } else {
              var x = [];
              x[modal] = true;
              this.setState(x);
          }
        });
      });
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    chooseManufacturer(value) {
      this.updateProduct("ManufacturerID",value);
      this.getBrands(value);
    }

    getBrands(manufacturerID) {
      return new Promise((resolve, reject) => {
        for(var i = 0; i < this.state.manufacturers.length; i++) {
          if(this.state.manufacturers[i].key === manufacturerID) {
            
            var brands = [];
            for(var j = 0; j < this.state.manufacturers[i].Brands.length; j++) {
              brands.push({key: this.props.manufacturers[i].Brands[j].id, label: this.props.manufacturers[i].Brands[j].Name});
            }
      
            this.setState({brands: brands}, () => {
              resolve();
            });   
          }
        }
      });
    }

    chooseCategory(value) {
      this.updateProduct("CategoryID",value);
      this.getSubCategories(value);
    }

    chooseVendor(id) {
      for(var i = 0; i < this.props.vendors.length; i++) {
          if(this.props.vendors[i].id === id) {
              this.setState({selectedVendor: this.props.vendors[i]});
              break;
          }
      }
  }

    getSubCategories(categoryID) {
      return new Promise((resolve, reject) => {
        
        for(var i = 0; i < this.state.categories.length; i++) {
          if(this.state.categories[i].key === categoryID) {
            
            var subCat1 = [];
            for(var j = 0; j < this.state.categories[i].SubCat1.length; j++) {
              subCat1.push({key: this.state.categories[i].SubCat1[j].id, label: this.state.categories[i].SubCat1[j].Name});
            }
            var subCat2 = [];
            for(var k = 0; k < this.state.categories[i].SubCat2.length; k++) {
              subCat2.push({key: this.state.categories[i].SubCat2[k].id, label: this.state.categories[i].SubCat2[k].Name});
            }
            var subCat3 = [];
            for(var l = 0; l < this.state.categories[i].SubCat3.length; l++) {
              subCat3.push({key: this.state.categories[i].SubCat3[l].id, label: this.state.categories[i].SubCat3[l].Name});
            }
      
            this.setState({subCat1: subCat1, subCat2: subCat2, subCat3: subCat3}, () => {
              
              resolve();
            });   
          }
        }
      });
    }

    getVendors() {
      return new Promise((resolve, reject) => {
          
        var vendors = [];
        for(var j = 0; j < this.props.vendors.length; j++) {
          vendors.push({key: this.props.vendors[j].id, label: this.props.vendors[j].Name});
        }
  
        this.setState({vendors: vendors}, () => {
          resolve();
        });   
      
      });
    }

    updateProduct(property, value) {
      var obj = this.state.product;
      obj[property] = value;
      this.setState({product: obj});
    }

    addVendor() {
      var obj = this.state.product;
      obj.Vendors.push({id: this.state.selectedVendor.id, Name: this.state.selectedVendor.Name, Cost: this.state.cost });
      
      var chosenVendors = this.state.chosenVendors;
      chosenVendors.push({ key: this.state.selectedVendor.id, label: this.state.selectedVendor.Name });
      if(chosenVendors.length === 1) this.updateDefaultVendor(this.state.selectedVendor.id); //this.updateProduct("DefaultVendor", this.state.selectedVendor.id);

      this.setState({product: obj, addVendorVisible: false, cost: 0, chosenVendors: chosenVendors}, () => {
          this.refreshVendorList();
      });
    }

    async deleteVendor(vendor) {
      var product = this.state.product;
      for(var i = 0; i < product.Vendors.length; i++) {
        if(product.Vendors[i].id === vendor.id) {
          
          product.Vendors.splice(i,1);
          break;
        }
      }
      
      this.setState({product: product});
      this.refreshVendorList();
    }

    updateVendor(vendor, property, value) {
      var d = this.state.product;
  
      for(var i = 0; i < d.Vendors.length; i++) {
        if(d.Vendors[i].id === vendor.id) {
          d.Vendors[i][property] = value;

          if(d.Vendors[i].id === d.DefaultVendor) {
            d.WholesalePrice = d.Vendors[i].Cost;
            console.log('cost set to: ' + d.WholesalePrice);
          }

          break;
        }
      }

      this.setState({product: d});
    }

    updateDefaultVendor(vendor) {
      //this.updateProduct('DefaultVendor', vendor);
      var product = this.state.product;

      console.log('updateDefaultVendor(' + vendor + ')');
      console.log(this.state.product.Vendors);

      //this.state.product.Vendors
      for(var i = 0; i < this.state.product.Vendors.length; i++) {
        if(this.state.product.Vendors[i].id === vendor) {
          product.WholesalePrice = this.state.product.Vendors[i].Cost;
          product.DefaultVendor = vendor;
          console.log('cost set to: ' + product.WholesalePrice);
          break;
        }
      }

      this.setState({product: product});
    }
  
    refreshVendorList() {
      
      var vendors = [];

      for(var i = 0; i < this.props.vendors.length; i++) {
          var isFound = false;
          for(var j = 0; j < this.state.product.Vendors.length; j++) {
              if(this.props.vendors[i].id === this.state.product.Vendors[j].id) {
                  isFound = true;
                  break;
              }
          }
          if(!isFound) {
            var vendor = this.props.vendors[i];
            vendor.key = vendor.id;
            vendor.label = vendor.Name;
            vendors.push(vendor);
          }
      }

      this.setState({vendors:vendors});
    }

    saveProduct() {
    
      var product = this.state.product;
      product.Photos = this.state.photos;
      product.Attachments = this.state.attachments;

      //get colorScentOther
      var categories = this.state.categories;
      for(var j=0; j < categories.length; j++) {
        if(categories[j].id === product.CategoryID) {
          for(var k=0; k < categories[j].SubCat3.length;k++) {
            if(categories[j].SubCat3[k].id === product.SubCat3ID) {
              product.colorScentOther = categories[j].SubCat3[k].Name;
              break;
            }
          }
          break;
        }
      }
      
      db.updateProduct(product.id, product).then(doc => {
        
        // update product to include field for numeric-only productID
        if(product.ProductID) {

          var numericOnlyPattern = /[0-9]/g;
          var numbers = product.ProductID.match(numericOnlyPattern);

          product.productIDNumber = "";
          if (numbers)
            for(var i = 0; i < numbers.length; i++) 
              product.productIDNumber = product.productIDNumber + numbers[i];
        }

        if(!product.id) { // i.e. if it's new

          product.id = doc.id;
          
          this.props.onNew(product);

          // update search index
          algolia.upsertProduct(product);

          // reset to blank
          this.setState({product:{
            ProductID: "",
            ProductName: "",
            BriefDescription: "",
            Price: 0,
            WholesalePrice: 0,
            ListPrice: 0,
            Photos: [],
            SubCat1ID: "",
            SubCat2ID: "",
            SubCat3ID: "",
            Size:"",
            CasePack:"",
            Attachments: [],
            Vendors: []
          }, photos:[], attachments:[]});

        } else { // i.e. an existing obj

          this.props.onChange(product);

          console.log('updated product');
          console.log(product);
          // update search index
          algolia.upsertProduct(product);

        }
      });
    }

    addPhoto(id, url) {

      var photos = this.state.photos;
      var photo = { ID: id, Name: "New Photo", Description: "description", URL: url, isCover: false, isThumb: false};
      photos.push(photo);
      this.setState({photos:photos}, () => {
        this.setState({ uploadControls : [uuidv4()] });
      });
    }

    updatePhoto(photo) {
      var photos = this.state.photos;
      
      for(var i = 0; i < photos.length; i++) {
        if(photos[i].ID === photo.ID) {
          photos[i] = photo;
          break;
        }
      }
    }

    deletePhoto(photo) {
      var photos = this.state.photos;

      for(var i = 0; i < photos.length; i++) {
        if(photos[i].ID === photo.ID) {
          photos.splice(i,1);
          break;
        }
      }

      this.setState({photos:[]}, () => { // reset first necessary, seems like a bug
        this.setState({photos:photos}, () => {
          
        });
      });
    }

    makeCoverPhoto(photo) {
      var photos = this.state.photos;
      
      for(var i = 0; i < photos.length; i++) {
        if(photos[i].ID === photo.ID) {
          photos[i].isCover = true;
        } else {
          photos[i].isCover = false;
        }
      }
    }

    makeThumbPhoto(photo) {
      var photos = this.state.photos;
      
      for(var i = 0; i < photos.length; i++) {
        if(photos[i].ID === photo.ID) {
          photos[i].isThumb = true;
        } else {
          photos[i].isThumb = false;
        }
      }
    }

    fileUploaded(url) {
      var attachments = this.state.attachments;
      attachments.push( { Name:"New Attachment", URL: url } );
      this.setState({attachments: attachments });
    }

    updateAttachment(attachment, property, value) {
      var attachments = this.state.attachments;
  
      for(var i = 0; i < attachments.length; i++) {
        if(attachments[i].URL === attachment.URL) {
          attachments[i][property] = value;
          break;
        }
      }
      this.setState({attachments: attachments });
    }

    deleteAttachment(attachment) {
      var attachments = this.state.attachments;
      for(var i = 0; i < attachments.length; i++) {
        if(attachments[i].URL === attachment.URL) {
          attachments.splice(i,1);
          //todo, delete file from storage
        }
      }
      this.setState({attachments: attachments });
    }

    showAddVendor() {
      if(this.state.addVendorVisible) {
          return "block";
      } else {
          return "none";
      }
    }

    trim(value, maxLength) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    }

    isSaveDisabled() {
      const vendors = this.state.product.Vendors;
      if (vendors && vendors.length > 0) {
        return vendors.reduce((result, vendor) => {
          return result || !vendor.sku
        }, false)
      }
      return false;
    }

    render() {

      const { classes } = this.props;

      return (
        <div>
          
          <IconButton aria-label="Edit" onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={6}>
                  <h4 className={classes.modalTitle}>Edit Product</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                  
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
             <CustomTabs
                headerColor="success"
                tabs={[
                  {
                    tabName: "General",
                    tabIcon: ShoppingCart,
                    tabContent: (
                      
                        <GridContainer direction="row">
                          <GridItem xs={12} sm={6} md={3}>
                            <CustomSelect
                              labelText="Manufacturer"
                              inputProps={{ placeholder: "Manufacturer" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.chooseManufacturer(e.target.value)}
                              options={this.state.manufacturers}
                              value={this.state.product.ManufacturerID}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={3}>
                            <CustomSelect
                              labelText="Brand"
                              inputProps={{ placeholder: "Choose a Brand" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.updateProduct("BrandID",e.target.value)}
                              options={this.state.brands}
                              value={this.state.product.BrandID}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Product ID"
                              value={this.state.product.ProductID}
                              inputProps={{
                                  placeholder: "Product ID"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("ProductID", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Name"
                              value={this.state.product.ProductName}
                              inputProps={{
                                  placeholder: "ProductName"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("ProductName", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              labelText="Description"
                              value={this.state.product.BriefDescription}
                              inputProps={{
                                  placeholder: "Description"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("BriefDescription", e.target.value)}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                              labelText="Category"
                              inputProps={{ placeholder: "Category" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.chooseCategory(e.target.value)}
                              options={this.state.categories}
                              value={this.state.product.CategoryID}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                              labelText="SubCategory"
                              inputProps={{ placeholder: "SubCategory" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.updateProduct("SubCat1ID", e.target.value)}
                              options={this.state.subCat1}
                              value={this.state.product.SubCat1ID}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                              labelText="SubCategory"
                              inputProps={{ placeholder: "SubCategory" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.updateProduct("SubCat2ID", e.target.value)}
                              options={this.state.subCat2}
                              value={this.state.product.SubCat2ID}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                              labelText="SubCategory"
                              inputProps={{ placeholder: "SubCategory" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.updateProduct("SubCat3ID", e.target.value)}
                              options={this.state.subCat3}
                              value={this.state.product.SubCat3ID}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                              labelText="Vendor"
                              inputProps={{ placeholder: "Default Vendor" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.updateDefaultVendor(e.target.value)}
                              options={this.state.chosenVendors}
                              value={this.state.product.DefaultVendor}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="List Price"
                              value={this.state.product.ListPrice}
                              inputProps={{
                                  placeholder: "List Price",
                                  endAdornment: (
                                    <InputAdornment position="end" color="success">
                                      <Money />
                                    </InputAdornment>
                                  )
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("ListPrice", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Size"
                              value={this.state.product.Size}
                              inputProps={{
                                  placeholder: "Size"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("Size", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Case/Pack"
                              value={this.state.product.CasePack}
                              inputProps={{
                                  placeholder: "Case/Pack"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("CasePack", e.target.value)}
                            />
                          </GridItem>
                          
                          <GridItem xs={12} sm={12} md={6} style={{display:"none"}}>
                            <CustomInput
                              labelText="Cost"
                              value={this.state.product.WholesalePrice}
                              inputProps={{
                                  placeholder: "Wholesale Price",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Money />
                                    </InputAdornment>
                                  )
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateProduct("WholesalePrice", e.target.value)}
                            />
                          </GridItem>

                          <GridItem xs={12}>
                            <br />
                            <Table className={classes.table}>
                              <TableHead>
                                  <TableRow>
                                      <TableCell style={{padding:"3px"}}>
                                          Vendor
                                      </TableCell>
                                      <TableCell style={{padding:"3px", textAlign:"right"}}>
                                          SKU
                                      </TableCell>
                                      <TableCell style={{padding:"3px", textAlign:"right"}}>
                                          Cost
                                      </TableCell>
                                      <TableCell style={{padding:"3px", width:"89px"}}>
                                          
                                      </TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {this.state.product.Vendors.map((value, index) => (
                                  <TableRow key={index}>
                                      <TableCell style={{padding:"3px"}}>
                                          {value.Name}
                                      </TableCell>
                                      <TableCell style={{padding:"3px", textAlign:"right"}}>
                                        <GridContainer alignItems="center" justify="flex-end">
                                          {!value.sku && (
                                            <Tooltip title="SKU is required" placement="top" classes={{ tooltip: classes.tooltip }} >
                                              <HelpIcon fontSize="small" color="error" />
                                            </Tooltip>
                                          )}
                                          <input 
                                            type="text" 
                                            value={value.sku} 
                                            placeholder="sku" 
                                            onChange={(e) => this.updateVendor(value, "sku", e.target.value)} 
                                            className={classes.modalTextBox} 
                                            style={{
                                              maxWidth:"100px", 
                                              border: value.sku ? undefined : `1px solid #f44336`
                                            }} 
                                          />
                                        </GridContainer>
                                      </TableCell>
                                      <TableCell style={{padding:"3px", textAlign:"right"}}>
                                          <input type="text" value={value.Cost} placeholder="cost" 
                                            onChange={(e) => this.updateVendor(value, "Cost", e.target.value)} 
                                            className={classes.modalTextBox} style={{maxWidth:"100px"}} />
                                      </TableCell>
                                      <TableCell style={{padding:"3px", width:"89px", textAlign:"right"}}>
                                          <Tooltip id="tooltip-top-start" title="Remove Vendor" placement="top" classes={{ tooltip: classes.tooltip }} >
                                              <ConfirmDialog header="Confirm Remove Vendor" description="Are you sure you want to remove this vendor? This action cannot be undone." confirmed={() => this.deleteVendor(value)} />
                                          </Tooltip>
                                      </TableCell>
                                  </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                            <Tooltip
                            id="tooltip-top-start"
                            title="Add Vendor"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={() => this.setState({addVendorVisible:true})}>
                              <Add />
                            </IconButton>
                          </Tooltip>
                          </GridItem>
                          <GridItem xs={12} style={{backgroundColor:"#efefef", borderRadius:"6px", display:this.showAddVendor()}}>
                           <GridContainer style={{marginTop:"-6px"}}>
                            <GridItem xs={7} >
                                <CustomSelect
                                  labelText="Vendor"
                                  inputProps={{ placeholder: "Vendor" }}
                                  formControlProps={{ fullWidth: true }}
                                  onChange={(e) => this.chooseVendor(e.target.value)}
                                  options={this.state.vendors}
                                  value={this.state.selectedVendor.id}
                                />
                            </GridItem>
                            <GridItem xs={3} >
                              <CustomInput
                                labelText="Cost"
                                value={this.state.cost}
                                inputProps={{ placeholder: "Cost" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.setState({cost: e.target.value})}
                              />
                            </GridItem>
                            <GridItem xs={2} >
                                <div style={{paddingTop:"37px"}}>
                                    <Button onClick={this.addVendor} size="sm" >Add</Button>
                                </div>
                            </GridItem>
                           </GridContainer>
                          </GridItem>
                        </GridContainer>
                    )
                  },
                  {
                    tabName: "Photos",
                    tabIcon: Photos,
                    tabContent: (

                      <GridContainer direction="row" style={{minWidth:"800px", minHeight:"200px"}}>
                        <GridItem xs={12} style={{height:"54px"}}>
                          {this.state.uploadControls.map((controlID) => (
                            <PhotoUpload onUploadSuccess={this.addPhoto} key={controlID} />
                          ))}
                          
                        </GridItem>
                          {this.state.photos.map((photo,index) => (
                              <PhotoCard key={index} photo={photo} 
                                onPhotoUpdated={this.updatePhoto} onPhotoMakeCover={this.makeCoverPhoto} 
                                onPhotoMakeThumb={this.makeThumbPhoto} onPhotoDelete={this.deletePhoto} />
                          ))}
                        </GridContainer>
                    )
                  },
                  {
                    tabName:"HTML",
                    tabIcon: Code,
                    tabContent: (
                      <GridContainer style={{minHeight:"369px"}}>
                        <ContentEditor 
                          value={this.state.product.Content}
                          onChange={(e) => this.updateProduct("Content", e)}
                        />
                      </GridContainer>
                    )
                  },
                  {
                    tabName: "Attachments",
                    tabIcon: Attachment,
                    tabContent: (

                      <GridContainer direction="row" style={{minWidth:"800px", minHeight:"200px"}}>
                        <FileUpload fileUploaded={this.fileUploaded} />
                        <Table>
                          <TableBody>
                          {this.state.attachments.map((attachment,index) => ( 
                            <TableRow key={index}>
                              <TableCell>
                                <input type="text" value={attachment.Name} placeholder="name" 
                                  onChange={(e) => this.updateAttachment(attachment, "Name", e.target.value)} 
                                  style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"21px", borderRadius:"6px", fontSize:"12px", width:"100%"}} /> 
                              </TableCell>
                              <TableCell>
                                <a href={attachment.URL} target="_new">Download</a>
                              </TableCell>
                              <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                                <Tooltip id="tooltip-top-start" title="Delete Attachment" placement="top" classes={{ tooltip: classes.tooltip }} >
                                  <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this attachment? This action cannot be undone." confirmed={() => this.deleteAttachment(attachment)} />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                          </TableBody>
                        </Table>
                      </GridContainer>
                    )
                  }
                ]}
              />

            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
                <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.product.Microsite}
                        onChange={(e) => this.updateProduct("Microsite", e.target.checked)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Microsite"
                />
                <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.product.IsVisible}
                        onChange={(e) => this.updateProduct("IsVisible", e.target.checked)}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Visible"
                />
                <Button onClick={() => this.handleClose("modal")} >
                  Cancel
                </Button>
                &nbsp;
                <Button
                  onClick={() => {
                    this.saveProduct();
                    this.handleClose("modal");
                  }}
                  disabled={this.isSaveDisabled()}
                  color="success"
                >
                  Save
                </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

ProductEditor = connect(mapStateToProps)(ProductEditor);
export default withStyles(modalStyle)(ProductEditor);