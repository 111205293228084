//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//kekaha
import BillDetails from "components/BillDetails/BillDetails.jsx";

//icons
//?

//unique id generator
//const uuidv4 = require('uuid/v4');

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

class BillsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: [],
      moreRows: false
    }

    this.billDeleted = this.billDeleted.bind(this);
    this.refreshBills = this.refreshBills.bind(this);

    console.log(this.props)
  }

  componentDidMount() {
    this.refreshBills();
  }

  billChanged(bill) {
   
    var bills = this.state.bills;
    for(var i = 0; i < bills.length; i++) {
      if(bills[i].id === bill.id) {
        bills[i] = bill;
        break;
      }
    }
    this.setState({bills:bills});
  }

  billDeleted(bill) {
    var bills = this.state.bills;
    for(var i = 0; i < bills.length; i++) {
      if(bills[i].id === bill.id) {
        bills.splice(i,1);
        break;
      }
    }
    this.setState({bills:bills});
  }

  refreshBills() {

    var modified = 0;
    if(this.state.bills.length > 0)
      modified = this.state.bills[this.state.bills.length - 1].modified;

    db.getRecentBills(modified).then(bills => {
  
        for(var i=0; i < bills.length; i++) {
            for(var j=0; j < this.props.customers.length; j++) {
                if(bills[i].customerID === this.props.customers[j].id) {
                    bills[i].customer = this.props.customers[j];
                    break;
                }
            }
        }

        this.setState({bills:bills});

        if(bills.length < 20)
            this.setState({moreRows: false});
        else 
            this.setState({moreRows: true});      
    }); 
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  pad(value) {
    if (value < 10) {
        return '0' + value;
    } else {
        return value;
    }
  }

  showLoadMore() {
    if(this.state.moreRows) {
      return "block";
    } else {
      return "none";
    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
            <GridContainer>
                <GridItem xs={12} sm={11} >
                  <h4 className={classes.cardTitleWhite}>Bills</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"4px", width:"54px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        Date
                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        Status
                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        Customer
                      </TableCell>
                      <TableCell style={{padding:"4px"}}> 
                        PO #
                      </TableCell>
                      <TableCell style={{padding:"4px", textAlign:"right"}}> 
                        Amount Due
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.bills.map(value => (
                      <TableRow key={value.id} className={classes.tableRow} >
                        <TableCell className={classes.tableActions} style={{padding:"4px", width:"54px"}}> 
                          <Tooltip id="tooltip-top" title="Edit Bill" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <BillDetails bill={value} billChanged={() => this.billChanged(value)} billDeleted={this.billDeleted} />
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                          {timeConverter(value.created)}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                          {value.status}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"4px"}}> 
                          {value.customer.customer_name}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"4px"}}>  
                          {value.poNumber}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"4px", textAlign:"right"}}>  
                          ${value.amountDue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
                <div style={{textAlign:"center", cursor:"pointer", display:this.showLoadMore()}} onClick={this.refreshBills} >
                  Load More
                </div>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onGlimpseCreated: GlimpseCreated, onGlimpseUpdated: GlimpseUpdated }, dispatch);  
}
*/

BillsList = connect(mapStateToProps)(BillsList);
export default withStyles(styles)(BillsList);
