//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

//components
import Card from 'components/Card/Card.jsx';
//import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
//import CardFooter from 'components/Card/CardFooter.jsx';
import CustomSelect from "components/Select/Select.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PhotoUpload from "components/PhotoUpload/PhotoUpload.jsx";

//draft html editor
import ContentEditor from "components/ContentEditor/ContentEditor.jsx";
import Parser from 'html-react-parser';

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Special from "@material-ui/icons/FolderSpecial";

import moment from 'moment';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class SpecialEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      special: { 
        name: "", 
        description: "",
        profile:"", 
        site: "",
        from: moment().valueOf(), 
        to: moment().valueOf(), 
        content:"", 
        image: { url: "" } 
      },
      pricingProfiles: []
    };

    this.addPhoto = this.addPhoto.bind(this);
    this.saveSpecial = this.saveSpecial.bind(this);
    this.updateSpecial = this.updateSpecial.bind(this);

  }

  handleClickOpen(modal) {
    

    if(this.props.special) {
      var special = this.props.special;
      if(!special.image)
        special.image = {url: ""};
      if(!special.image.url)
        special.image.url = "";
      this.setState({special: special});
    }
      

    if(this.props.profiles)
        this.setState({pricingProfiles: this.props.pricingProfiles});
        
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateSpecial(property, value) {

    if(property === 'to' || property === 'from') {
        value = moment(value).valueOf(); // convert date string to unix timestamp
    }

    var d = this.state.special;
    d[property] = value;
    this.setState({special: d});
  }

  saveSpecial() {
  
    var d = this.state.special;
    
    console.log(d)

    db.updateSpecial(d.id, d).then(doc => {
    
      if(!d.id) { // i.e. if it's a new obj

          d.id = doc.id; 
          this.props.onCreated(d);
          
          // reset to blank
          this.setState( { special: {
            name: "", profile:"", from: moment().valueOf(), to: moment().valueOf(), content:""
          }} );

      } else { // i.e. an existing vendor

          this.props.onChanged(d);   
      }
    });
  }

  formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  addPhoto(id, url) {
    console.log('addPhoto(' + id + ', ' + url + ')')

    var special = this.state.special;
    special.image = { id: id, url: url };
    this.setState({special:special}, () => { });
  }

  htmlContent(content) {
    if (content) return Parser(content);
    else return '';
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title={this.props.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="lg"
          fullWidth
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Special</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Special,
                  tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={8}>
                          <GridContainer>
                            <GridItem xs={11} >
                              <CustomInput
                                labelText="Name"
                                value={this.state.special.name}
                                inputProps={{
                                    placeholder: "Name"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateSpecial("name", e.target.value)}
                              />
                            </GridItem>
                            <GridItem xs={1} >
                              <PhotoUpload onUploadSuccess={this.addPhoto}  />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                labelText="Description"
                                value={this.state.special.description}
                                inputProps={{
                                    placeholder: "Description"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateSpecial("description", e.target.value)}
                              />
                            </GridItem>
                            <GridItem xs={12} md={6} lg={3}>
                              <div style={{paddingTop:"0px"}}>
                                <CustomSelect
                                  labelText="Pricing Profile"
                                  inputProps={{ placeholder: "Pricing Profile" }}
                                  formControlProps={{ fullWidth: true }}
                                  onChange={(e) => this.updateSpecial("profile", e.target.value)}
                                  options={this.state.pricingProfiles}
                                  value={this.state.special.profile}
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} md={6} lg={3}>
                              <div style={{paddingTop:"0px"}}>
                                <CustomSelect
                                  labelText="Site"
                                  inputProps={{ placeholder: "Site" }}
                                  formControlProps={{ fullWidth: true }}
                                  onChange={(e) => this.updateSpecial("site", e.target.value)}
                                  options={this.props.siteOptions}
                                  value={this.state.special.site}
                                />
                              </div>
                            </GridItem>
                            <GridItem xs={12} md={6} lg={3}>
                              <div style={{paddingTop:"26px"}}>
                                <TextField type="date" value={this.formatDate(this.state.special.from)} label="From" onChange={(e)=>this.updateSpecial("from", e.target.value)} />
                              </div>
                            </GridItem>
                            <GridItem xs={12} md={6} lg={3}>
                              <div style={{paddingTop:"26px"}}>
                                <TextField type="date" value={this.formatDate(this.state.special.to)} label="To" onChange={(e)=>this.updateSpecial("to", e.target.value)} />
                              </div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <Card>
                            <div
                              style={{
                                backgroundSize: "cover", 
                                height:"180px", 
                                width:"100%", 
                                backgroundColor:"#aaaaaa", 
                                borderTopRightRadius:"6px", 
                                borderTopLeftRadius:"6px",
                                backgroundImage: this.state.special.image ? `url("${this.state.special.image.url}")` : undefined, 
                                backgroundRepeat:"no-repeat", 
                                backgroundPosition: "center"
                              }}
                            />
                            <CardBody>
                              <h4 className={classes.title} style={{marginTop:"0px", marginBottom:"0px", paddingTop:"0px", paddingBottom:"0px"}}>{this.state.special.name}</h4>
                              {this.state.special.description}
                            </CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={12}>
                          <div style={{paddingTop:"23px"}}>
                            <ContentEditor 
                              value={this.state.special.content}
                              onChange={(e) => this.updateSpecial("content", e)}
                              style={{minHeight:"200px"}}
                            />
                          </div>  
                        </GridItem>
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveSpecial();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

SpecialEditor = connect(mapStateToProps)(SpecialEditor);
export default withStyles(modalStyle)(SpecialEditor);