
import { combineReducers } from 'redux';
import { 
    ALERT_CREATED,
    BLOGPOST_CREATED, BLOGPOSTS_LOADED, BLOGPOST_UPDATED,
    CATEGORY_CREATED, CATEGORIES_LOADED, CATEGORY_UPDATED,
    CUSTOMER_CREATED, CUSTOMERS_LOADED, CUSTOMER_UPDATED,
    EVENT_CREATED,
    ORG_LOADED, ORG_UPDATED,
    MANUFACTURER_CREATED, MANUFACTURERS_LOADED, MANUFACTURER_UPDATED,
    PHOTO_CREATED, PHOTOS_LOADED, PHOTO_UPDATED,
    PROFILE_CREATED, PROFILES_LOADED, PROFILE_UPDATED,
    TASK_CREATED, TASKS_LOADED, TASK_UPDATED,
    USER_LOGGEDIN, USER_LOGGEDOUT, USER_CREATED, USERS_LOADED, USER_UPDATED,
    VENDOR_CREATED, VENDORS_LOADED, VENDOR_UPDATED
} from './actions';

// generic sort comparer
function compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
}

function compareTitle(a,b) {

var aName = a.Title?.toString().toLowerCase();
var bName = b.Title?.toString().toLowerCase();

if (aName < bName)
    return -1;
if (aName > bName)
    return 1;
return 0;
}

function compareCompanyName(a,b) {

    var aName = a.company_name?.toString().toLowerCase();
    var bName = b.company_name?.toString().toLowerCase();
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
}

function comparePublishDate(a,b) {

    var aName = a.PublishDate;
    var bName = b.PublishDate;
    
    if (aName < bName)
        return -1;
    if (aName > bName)
        return 1;
    return 0;
}

//

function alerts(state = [], action) {
    
    switch (action.type) {
        case ALERT_CREATED:
            return action.alert;

        default:
            return state;
    }
}

function blogPosts(state = [], action) {
    
    switch (action.type) {
        
        case BLOGPOST_CREATED:
            var g1 = state.splice();
            g1.push(action.blogPost);
            return g1.sort(comparePublishDate);

        case BLOGPOSTS_LOADED:
            return action.blogPosts.sort(comparePublishDate);

        case BLOGPOST_UPDATED:
            var g2 = state.splice();
            for(var i = 0; i < g2.length; i++) {
                if(g2[i].id === action.blogPost.id) {
                    g2[i] = action.blogPost;
                    break;
                }                
            }
            return g2.sort(comparePublishDate);

        default:
            return state.sort(comparePublishDate);
    }
}

function categories(state = [], action) {
    
    switch (action.type) {
        
        case CATEGORY_CREATED:
            var c1 = state.slice();
            c1.push(action.category);
            return c1;

        case CATEGORIES_LOADED:
            return action.categories.sort(compareName);

        case CATEGORY_UPDATED:
            var c2 = state.slice();
            for(var i = 0; i < c2.length; i++) {
                if(c2[i].id === action.category.id) {
                    c2[i] = action.category;
                    break;
                }                
            }
            return c2.sort(compareName);

        default:
            return state.sort(compareName);
    }
}

function customers(state = [], action) {
    
    switch (action.type) {
        
        case CUSTOMER_CREATED:
            var c1 = state.slice();
            c1.push(action.customer);
            return c1;

        case CUSTOMERS_LOADED:
            return action.customers.sort(compareCompanyName);

        case CUSTOMER_UPDATED:
            var c2 = state.slice();
            for(var i = 0; i < c2.length; i++) {
                if(c2[i].id === action.customer.id) {
                    c2[i] = action.customer;
                    break;
                }                
            }
            return c2.sort(compareCompanyName);

        default:
            return state.sort(compareCompanyName);
    }
}

function events(state = [], action) {
    
    switch (action.type) {
        case EVENT_CREATED:
            return action.event;

        default:
            return state;
    }
}

function manufacturers(state = [], action) {
    
    switch (action.type) {
        
        case MANUFACTURER_CREATED:
            var g1 = state.splice();
            g1.push(action.manufacturer);
            return g1.sort(compareTitle);

        case MANUFACTURERS_LOADED:
            return action.manufacturers.sort(compareTitle);

        case MANUFACTURER_UPDATED:
            var g2 = state.splice();
            for(var i = 0; i < g2.length; i++) {
                if(g2[i].id === action.manufacturer.id) {
                    g2[i] = action.manufacturer;
                    break;
                }                
            }
            return g2.sort(compareTitle);

        default:
            return state.sort(compareTitle);
    }
}

function org(state = null, action) {
    
    switch (action.type) {
        case ORG_LOADED:

            return {
                id: action.org.id,
                name: action.org.name,
                description: action.org.description,
                website: action.org.website,
                latitude: action.org.latitude,
                longitude: action.org.longitude
            }

        case ORG_UPDATED:

            return {
                id: action.org.id,
                name: action.org.name,
                description: action.org.description,
                website: action.org.website,
                latitude: action.org.latitude,
                longitude: action.org.longitude
            }

        default:
            return state;
    }
}

function photos(state = [], action) {
    
    switch (action.type) {
        
        case PHOTO_CREATED:
            var g1 = state.splice();
            g1.push(action.photo);
            return g1;

        case PHOTOS_LOADED:
            return action.photos;

        case PHOTO_UPDATED:
            var g2 = state.splice();
            for(var i = 0; i < g2.length; i++) {
                if(g2[i].id === action.photo.id) {
                    g2[i] = action.photo;
                    break;
                }                
            }
            return g2;

        default:
            return state;
    }
}

function profiles(state = [], action) {
    
    switch (action.type) {
        
        case PROFILE_CREATED:
            var g1 = state.splice();
            g1.push(action.profile);
            return g1;

        case PROFILES_LOADED:
            return action.profiles;

        case PROFILE_UPDATED:
            var g2 = state.splice();
            for(var i = 0; i < g2.length; i++) {
                if(g2[i].id === action.profile.id) {
                    g2[i] = action.profile;
                    break;
                }                
            }
            return g2;

        default:
            return state;
    }
}

function tasks(state = [], action) {
    
    switch (action.type) {
        
        case TASK_CREATED:
            state.push(action.task);
            return state.sort(compareName);

        case TASKS_LOADED:
            return action.tasks;

        case TASK_UPDATED:
            for(var i = 0; i < state.length; i++) {
                if(state[i].id === action.task.id) {
                    state[i] = action.task;
                    break;
                }                
            }
            return state.sort(compareName);

        default:
            return state.sort(compareName);
    }
}

function user(state = {authenticated: false}, action) {
    
    switch (action.type) {
        case USER_LOGGEDIN:
            return {
                    authenticated: true,
                    id: action.user.id,
                    email: action.user.email,
                    firstName: action.user.firstName,
                    lastName: action.user.lastName,
                    userType: action.user.userType,
                    customers: action.user.customers
                }

        case USER_LOGGEDOUT:

            return {
                authenticated: false
            }

        default:
            return state;
    }
}

function users(state = [], action) {
    
    switch (action.type) {
        
        case USER_CREATED:
            state.push(action.user);
            return state.sort(compareName);

        case USERS_LOADED:
            state = action.users;
            return state;

        case USER_UPDATED:
            for(var i = 0; i < state.length; i++) {
                if(state[i].id === action.user.id) {
                    state[i] = action.user;
                    break;
                }                
            }
            return state.sort(compareName);

        default:
            return state.sort(compareName);
    }
}


function vendors(state = [], action) {
    
    switch (action.type) {
        
        case VENDOR_CREATED:
            state.push(action.vendor);
            return state.sort(compareName);

        case VENDORS_LOADED:
            state = action.vendors;
            return state;

        case VENDOR_UPDATED:
            for(var i = 0; i < state.length; i++) {
                if(state[i].id === action.vendor.id) {
                    state[i] = action.vendor;
                    break;
                }                
            }
            return state.sort(compareName);

        default:
            return state.sort(compareName);
    }
}


const Reducers = combineReducers({ 
    alerts,
    blogPosts,
    categories,
    customers,
    events,
    manufacturers,
    org, 
    photos,
    profiles,
    tasks,
    user,
    users,
    vendors
})

const appState = (Reducers)

export default appState
