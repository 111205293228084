//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserCreated, UsersLoaded, UserUpdated } from 'redux/actions';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

//spiffy
import UserActivity from "components/UserActivity/UserActivity.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      lastMonth: Math.round(new Date().getTime() / 1000) - 2592000,
    }

    this.UserCreated = this.UserCreated.bind(this);
    this.UserUpdated = this.UserUpdated.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentWillMount() {
    this.refreshUsers();
  }

  refreshUsers() {
      
    db.getUsers().then(users => {
      
      for(var x = 0; x < users.length; x++) {
        users[x].activities = [];
      }
      
      db.getActivity().then(activities => {
        
        for(var y = 0; y < activities.length; y++) {
          for(var i = 0; i < users.length; i++) {
            if(activities[y].UserID === users[i].id) {
              users[i].activities.push(activities[y]);
              break;
            }
          }
        };

        for(var z = 0; z < users.length; z++) {
          users[z].activities.sort(this.compareModified);
          if(users[z].activities.length > 0)
            users[z].lastActivity = users[z].activities[0].modified;
          else 
            users[z].lastActivity = 0;
        };

        users.sort(this.compareName);
        this.setState({users: users});
        this.props.onUsersLoaded(users); 
      })
    });
  }

  UserUpdated(User) {
    
    var Users = this.state.Users;
    for(var i = 0; i < Users.length; i++) {
      if(Users[i].id === User.id) {
        Users[i] = User;
        break;
      }
    }
    Users = Users.sort(this.compareName);
    this.setState({Users: Users});
    this.props.onUsersLoaded(Users); 
  }

  UserCreated(User) {
    
    var Users = this.state.Users;
    Users.push(User);
    Users.sort(this.compareName);
    this.setState({Users: Users});
    this.props.onUsersLoaded(Users); 
  }

  deleteUser(User) {
    db.deleteUser(User.id).then(() => {
      var Users = this.state.Users;
      console.log('Users')
      console.log(Users)
      for(var i = 0; i < Users.length; i++) {
        if(Users[i].id === User.id) {
          Users.splice(i,1);
          break;
        }
      }

      Users.sort(this.compareName);
      this.setState({Users: Users});
      this.props.onUsersLoaded(Users); 
    });
  }

  compareName(a,b) {

    var aName = a.LastName?.toString().toLowerCase();
    var bName = b.LastName?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

  compareModified(a,b) {

    var y = a.modified;
    var z = b.modified;
  
    if (y > z)
      return -1;
    if (y < z)
      return 1;
    return 0;
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  toggleUserActiveState = (user) => {
    console.log(user)
    const isDisabledState = !(user.isDisabled)
    db.setUserIsDisabled(user.id, isDisabledState)
    .then(()=>{
      const updatedUsers = this.state.users.reduce((acc, currentUser)=>{
        if(currentUser.id === user.id) user.isDisabled = isDisabledState;
        acc.push(currentUser)
        return acc;
      }, [])
      this.setState({users: updatedUsers})
    })
    .catch((err)=>console.error(err))
  }

  getActivityDescriptor(lastActivity) {
    if(lastActivity === 0)
      return <div style={{color:'maroon'}}>No Activity</div>
    else if (lastActivity > this.state.lastMonth) 
      return <div style={{color:'green'}}>{timeConverter(lastActivity)}</div>
    else
      return timeConverter(lastActivity);
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Users</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  {/*<UserEditor title="Add User" onCreated={this.UserCreated} />*/}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Last Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        First Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Last Activity
                      </TableCell>
                      <TableCell style={{padding:"6px", textAlign: "center"}}> 
                        Enabled/Disabled
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"17px", display:"none"}}> 
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.users.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          {/*<UserEditor title="Edit User" User={value} onChanged={this.UserUpdated}  />*/}
                          <UserActivity User={value} />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.LastName}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.FirstName}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {this.getActivityDescriptor(value.lastActivity)}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px", display: "flex", justifyContent: "center"}} onClick={()=>this.toggleUserActiveState(value)}> 
                          <Button color={value.isDisabled? 'danger' : 'success'}>{value.isDisabled? 'Disabled' : 'Active'}</Button>
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"17px", display:"none"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete User" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this user? This action cannot be undone." confirmed={() => this.deleteUser(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserCreated: UserCreated, 
    onUsersLoaded: UsersLoaded,
    onUserUpdated: UserUpdated }, dispatch);  
}

UserList = connect(mapStateToProps, mapDispatchToProps)(UserList);
export default withStyles(styles)(UserList);
