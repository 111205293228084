//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSelect from "components/Select/Select.jsx";

function getDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    return month + ' ' + date + ', ' + year;
}
  
function getTime(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var hours = a.getHours();
    var minutes = a.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+ minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class LogsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      filteredLogs: [],
      filter: 'all'
    }
  }

  componentWillMount() {
    
    db.getRecentLogs().then(logs => {
      this.setState({logs:logs, filteredLogs: logs});
  })
  }

  refreshLogs(filter) {
    var filteredLogs = [];

    for(var i = 0; i < this.state.logs.length; i++) {
      if(this.state.logs[i].type === filter || filter === 'all' ) {
        filteredLogs.push(this.state.logs[i]);
      }
    }
    this.setState({filteredLogs: filteredLogs, filter: filter });
  }

  debugLog(value) {
    console.log(value);
  }

  statusType(value) {
    if(value.statusType) {
      return <div>{value.statusType}</div>;
    } else {
      return <div></div>
    }
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 className={classes.cardTitleWhite}>Logs</h4>
                  <p className={classes.cardCategoryWhite}>
                    This shows all of the notices received from vendors via our API.
                  </p>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              
                <GridItem xs={false} sm={false} md={8}>
                    &nbsp;
                </GridItem>
                <GridItem xs={12} sm={12} md={4} >
                  <CustomSelect
                      labelText="Type"
                      inputProps={{ placeholder: "Filter by Log Type" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(e) => this.refreshLogs(e.target.value)}
                      options={[
                        { key: "all", label: "All" },
                        { key: "officeDepotInvoice", label: "Invoices" },
                        { key: "officeDepotStatus", label: "Status" },
                      ]}
                      value={this.state.filter}
                    />
                </GridItem>
              
                <GridItem xs={12}>
                  <br />
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{padding:"6px"}}> 
                          Date / Time
                        </TableCell>
                        <TableCell style={{padding:"6px"}}> 
                          XML
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.filteredLogs.map(value => (
                        <TableRow key={value.id} className={classes.tableRow}>
                          
                          <TableCell className={classes.tableCell} style={{padding:"12px", verticalAlign:"top"}}> 
                            <div style={{color:"green"}}>
                                Received
                            </div>
                            <div style={{color:"#232323"}}>
                              {getDate(value.created)} &nbsp;
                              {getTime(value.created)}
                            </div>
                            <br />
                            <div style={{color:"green"}}>
                              Type
                            </div>
                            <div style={{color:"#232323"}}>
                              {value.type}
                              {this.statusType(value)}
                            </div>
                            <br />
                            <div style={{color:"green"}}>
                              Order ID
                            </div>
                            <div style={{color:"232323"}}>
                              {value.orderID}
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableActions} style={{padding:"6px"}}> 
                              <div style={{wordBreak: 'break-word', whiteSpace: 'break-spaces'}}>{value.xml}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}


LogsPage = connect(mapStateToProps)(LogsPage);
export default withStyles(styles)(LogsPage);
