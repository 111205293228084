//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
//import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { ManufacturerCreated, ManufacturersLoaded, ManufacturerUpdated } from 'redux/actions';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import AffiliateEditor from "components/AffiliateEditor/AffiliateEditor.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class AffiliatesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        affiliates: []
    }

    this.AffiliateCreated = this.AffiliateCreated.bind(this);
    this.AffiliateUpdated = this.AffiliateUpdated.bind(this);
    this.deleteAffiliate = this.deleteAffiliate.bind(this);
  }

  componentWillMount() {
    this.refreshAffiliates();
  }

  refreshAffiliates() {
    db.getAffiliates().then(affiliates => {
      affiliates.sort(this.compareName);
      this.setState({affiliates: affiliates});
      
    });
  }

  AffiliateUpdated(affiliate) {
    
    var affiliates = this.state.affiliates;
    for(var i = 0; i < affiliates.length; i++) {
      if(affiliates[i].id === affiliate.id) {
        affiliates[i] = affiliate;
        break;
      }
    }
    affiliates = affiliates.sort(this.compareName);
    this.setState({affiliates: affiliates});
  }

  AffiliateCreated(affiliate) {
    
    var affiliates = this.state.affiliates;
    affiliates.push(affiliate);
    affiliates = affiliates.sort(this.compareName);
    this.setState({affiliates: affiliates});
  }

  deleteAffiliate(affiliate) {
    db.deleteUser(affiliate.id).then(() => {
      var affiliates = this.state.affiliates;
      for(var i = 0; i < affiliates.length; i++) {
        if(affiliates[i].id === affiliate.id) {
            affiliates.splice(i,1);
          break;
        }
      }

      affiliates = affiliates.sort(this.compareName);
      this.setState({affiliates: affiliates});
    });
  }

  compareName(a,b) {

    var aName = a.FirstName?.toString().toLowerCase();
    var bName = b.FirstName?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Affiliates</h4>
                  <p className={classes.cardCategoryWhite}>
                    Affiliates can review and place orders on behalf of their customers. 
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  <AffiliateEditor addNew title="Add an Affiliate" onCreated={this.AffiliateCreated} />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        First Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Last Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Email
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"23px"}}> 
                          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.affiliates.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <AffiliateEditor title="Edit Affiliate" affiliate={value} onChanged={this.AffiliateUpdated} affiliates={this.state.affiliates} />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.FirstName}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.LastName}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.Email}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Affiliate" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this affiliate? This action cannot be undone." confirmed={() => this.deleteAffiliate(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onManufacturerCreated: ManufacturerCreated, 
    onManufacturersLoaded: ManufacturersLoaded,
    onManufacturerUpdated: ManufacturerUpdated }, dispatch);  
}
*/
AffiliatesList = connect(mapStateToProps)(AffiliatesList);
export default withStyles(styles)(AffiliatesList);
