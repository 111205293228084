//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Vendor from "@material-ui/icons/LocalShipping";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class VendorEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      vendor: { Name: "", NotificationEmail: "" }
    };

    this.saveVendor = this.saveVendor.bind(this);
    this.updateVendor = this.updateVendor.bind(this);
  }

  handleClickOpen(modal) {
    if(this.props.vendor)
        this.setState({vendor: this.props.vendor});

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateVendor(property, value) {
    var d = this.state.vendor;
    d[property] = value;
    this.setState({vendor: d});
  }

  saveVendor() {
  
    var d = this.state.vendor;
    
    db.updateVendor(d.id, d).then(doc => {
    
      if(!d.id) { // i.e. if it's a new obj

          d.id = doc.id; 
          this.props.onCreated(d);
          
          // reset to blank
          this.setState( { vendor: {
              Name: ""
          }} );

      } else { // i.e. an existing vendor

          this.props.onChanged(d);   
      }
    });
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title={this.props.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Vendor</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Vendor,
                  tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={12} md={8}>
                          <CustomInput
                            labelText="Name"
                            value={this.state.vendor.Name}
                            inputProps={{
                                placeholder: "Name",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateVendor("Name", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            labelText="Notification Email"
                            value={this.state.vendor.NotificationEmail}
                            inputProps={{
                                placeholder: "Notification Email"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateVendor("NotificationEmail", e.target.value)}
                          />
                        </GridItem>
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveVendor();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

VendorEditor = connect(mapStateToProps)(VendorEditor);
export default withStyles(modalStyle)(VendorEditor);