//react
import React from 'react';

//redux
//import { bindActionCreators } from 'redux';
//import { connect } from 'react-redux';
//import { StudentCreated, StudentUpdated } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import Face from "@material-ui/icons/FaceOutlined";

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function compareName(a,b) {

  var aName = a.name?.toString().toLowerCase();
  var bName = b.name?.toString().toLowerCase();

  if (aName < bName)
    return -1;
  if (aName > bName)
    return 1;
  return 0;
}

class UserEditor extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        user: props.userAccount,
        addresses: []
      };

      this.getAddresses = this.getAddresses.bind(this);
      this.saveUser = this.saveUser.bind(this);
      this.updateUser = this.updateUser.bind(this);
      this.sendInvitation = this.sendInvitation.bind(this);
      this.selectAll = this.selectAll.bind(this);
    }
    
    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);

      this.setState({user: this.props.userAccount}, () => {
        this.getAddresses();
      });
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    getAddresses() {
      db.getAddressesByCustomer(this.state.user.customerID).then(addresses => {
        
        if(this.state.user.Addresses) {
          for(var i = 0; i < addresses.length; i++) {
            for(var j = 0; j < this.state.user.Addresses.length; j++) {
              if(addresses[i].address_id === this.state.user.Addresses[j]) {
                addresses[i].isEnabled = true;
                break;
              }
            }
          }
        }

        addresses.sort(compareName);
        this.setState({addresses: addresses});
      })
    }

    selectAll() {
      var addresses = this.state.addresses;
      this.setState({addresses: []}, () => {
        for(var i = 0; i < addresses.length; i++) {
          addresses[i].isEnabled = true;
        }
        this.setState({addresses: addresses},() => {
          console.log(this.state.addresses);
        });
      });
    }

    updateAddresses(address, value) {
      var addresses = this.state.addresses;
      for(var i = 0; i < addresses.length; i++) {
        if(addresses[i].address_id === address.address_id) {
          addresses[i].isEnabled = value;
          break;
        }
      }
      this.setState({addresses: addresses});
    }

    updateUser(property, value) {
      var obj = this.state.user;
      obj[property] = value;
      this.setState({user: obj});
    }

    saveUser(callback) {
      
      var user = this.state.user;
      delete user.isMatched;

      if(!user.id) // assign an id to new users
        user.id = uuidv4();
      
      // add/update checked addresses to user record
      var addresses = [];
      for(var j = 0; j < this.state.addresses.length; j++) {
        if(this.state.addresses[j].isEnabled) {
          addresses.push(this.state.addresses[j].address_id);
        }
      }
      user.Addresses = addresses;
      
      db.updateUser(user.id, user).then(doc => {

        this.setState({user: user});
        this.props.onChange(user);
        if(callback)
          callback();
      });
    }

    sendEmail(email, link) {
    
      var text = "You are invited to use our new website to quickly and easily place your orders! Use the following link to sign up: " + link + " Thank you!";
      
      var html = '<html>';
      html = html + 'You are invited to use our new website to quickly and easily place your orders! Use the following link to sign up:';
      html = html + '<br /><br />';
      html = html + link + ' <br /><br />';
      html = html + 'Thank you!';
      html = html + '<br /><br />';
      html = html + '- The Ecclesia Team';
      html = html + '</html>';
  
      var subject = "Ecclesia Invitation";
      
      // email
      const json = {
        to: email,
        bcc: 'rbj@eccjan.com',
        subject: subject,
        text: text,
        html: html
      }
  
      fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail", {
          method: "POST", 
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(json)
        })
        .then((response) => {
            
            if(response.ok) {
                return response.json();
            } else {
              console.log("error (1): ");  
              console.log(response);
            }
  
            //callback();
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error (2):  " + error);
            //callback();
        });
    }

    sendInvitation() {
      this.saveUser(() => {
        this.sendEmail(this.state.user.Email,'https://www.eccjan.com/signup/' + this.state.user.id);
        this.handleClose("modal");
      });
    }
    
    trim(value, maxLength) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    }

    render() {

      const { classes } = this.props;
      

      return (
        <div>
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit
                className={
                    classes.tableActionButtonIcon + " " + classes.edit
                }
            />
          </IconButton>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            fullWidth
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={6}>
                  <h4 className={classes.modalTitle}>Edit User</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                  
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
             <CustomTabs
                headerColor="success"
                tabs={[
                  {
                    tabName: "General",
                    tabIcon: Face,
                    tabContent: (
                      
                        <GridContainer direction="row">
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="First Name"
                              value={this.state.user.FirstName}
                              inputProps={{
                                  placeholder: "First Name"
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.updateUser("FirstName", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Last Name"
                                value={this.state.user.LastName}
                                inputProps={{
                                    placeholder: "Last Name"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateUser("LastName", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Email Address"
                                value={this.state.user.Email}
                                inputProps={{
                                    placeholder: "Email Address"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateUser("Email", e.target.value)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={2}>
                            <div style={{marginTop:"24px"}}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.user.BudgetingPermissions}
                                    onChange={(e) => this.updateUser("BudgetingPermissions", e.target.checked)}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{ label: classes.label }}
                                label="Budgeting"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={2}>
                            <div style={{marginTop:"24px"}}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.user.InvoicePermissions}
                                    onChange={(e) => this.updateUser("InvoicePermissions", e.target.checked)}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{ label: classes.label }}
                                label="Invoicing"
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={2}>
                            <div style={{marginTop:"24px"}}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.user.OrderingPermissions}
                                    onChange={(e) => this.updateUser("OrderingPermissions", e.target.checked)}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{ label: classes.label }}
                                label="Ordering"
                              />
                            </div>
                          </GridItem>
                          
                          <GridItem xs={12} sm={12} >
                            <br />
                            <Table className={classes.table}>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{padding:"3px"}}>
                                    <Tooltip id="tooltip-top"
                                      title="Select All"
                                      placement="top"
                                      classes={{ tooltip: classes.tooltip }}>
                                      <IconButton onClick={() => this.selectAll()}>
                                        <Check style={{color:"gray"}} />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell style={{padding:"3px"}}>
                                      Name
                                  </TableCell>
                                  <TableCell style={{padding:"3px"}}>
                                    Address
                                  </TableCell>
                                  <TableCell>
                                    Job Code
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.addresses.map((value, index) => (
                                  <TableRow key={index}>
                                    <TableCell style={{padding:"3px"}}>
                                      <Checkbox
                                          tabIndex={-1}
                                          onChange={(e) => this.updateAddresses(value, e.target.checked)}
                                          checked={value.isEnabled}
                                          checkedIcon={ <Check style={{color:"green"}} /> }
                                          icon={ <Check style={{color:"#efefef"}} className={classes.iconCheckbox} /> }
                                      />
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                      {value.name}
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                      {value.address}<br />
                                      {value.city}, {value.state} {value.zip}
                                    </TableCell>
                                    <TableCell style={{padding:"3px"}}>
                                      {value.jobCode}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </GridItem>
                        </GridContainer>
                    )
                  }
                ]}
              />

            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
                <Button
                  onClick={() => this.handleClose("modal")}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button
                  onClick={this.sendInvitation}
                >
                  Save and Send Invitation
                </Button>
                &nbsp;
                <Button
                  onClick={() => {
                      this.saveUser();
                      this.handleClose("modal");
                    }}
                  color="success">
                  Save
                </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
}
  /*
const mapStateToProps = state => {
  return state;
}
*/
//UserEditor = connect(mapStateToProps)(UserEditor);
export default withStyles(modalStyle)(UserEditor);