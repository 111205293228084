//react
import React from "react";

//redux
//import { bindActionCreators } from 'redux';
//import { connect } from 'react-redux';
//import { LocationCreated } from 'redux/actions';

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import IconButton from "@material-ui/core/IconButton";
import Button from "components/CustomButtons/Button.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import cardStyle from "assets/jss/material-kit-react/components/cardStyle.jsx";

//icons
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Pano from "@material-ui/icons/PanoramaOutlined";
import Thumb from "@material-ui/icons/PhotoOutlined";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class PhotoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            photo: props.photo
        }; 

        this.makeThumb = this.makeThumb.bind(this);
        this.makeCover = this.makeCover.bind(this);
      }

      handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
      }
  
      handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
      }

      trim(value, maxLength) {
        if(value) {
            if(value.length > maxLength)
                return value.substring(0,maxLength) + "...";
            else
                return value;
        } else return "";
      }

      getColor(selected) {
        if(selected)
            return "#3fa31b";
        else
            return "gray";
      }

      updatePhoto(property, value) {
        var photo = this.state.photo;
        photo[property] = value;
        this.setState({photo: photo});
      }

      makeThumb() {
          this.updatePhoto("isThumb", true);
          this.props.onPhotoMakeThumb(this.state.photo);
      }

      makeCover() {
        this.updatePhoto("isCover", true);
        this.props.onPhotoMakeCover(this.state.photo);
      }

      render() {
        const { classes } = this.props;
    
        return (
            <GridItem xs={12} sm={6} md={4}>
                <Card>
                    <div style={{backgroundSize: "cover", height:"169px", width:"100%", backgroundColor:"#aaaaaa", borderTopRightRadius:"6px", borderTopLeftRadius:"6px",
                        backgroundImage:`url(${this.state.photo.URL})`, backgroundRepeat:"no-repeat", backgroundPosition: "center"}}>
                    </div>
                    <CardFooter>
                        <Tooltip
                          id="tooltip-top-start"
                          title="Set as Thumbnail Photo"
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                            <IconButton
                                aria-label="Set as Thumbnail Photo"
                                style={{color:this.getColor(this.state.photo.isThumb)}}
                                onClick={this.makeThumb}>
                                <Thumb className={ classes.tableActionButtonIcon + " " + classes.edit } />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-top-start"
                          title="Set as Cover Photo"
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                            <IconButton
                                aria-label="Set as Cover Photo"
                                style={{color:this.getColor(this.state.photo.isCover)}}
                                onClick={this.makeCover}>
                                <Pano className={ classes.tableActionButtonIcon + " " + classes.edit } />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            aria-label="Edit"
                            onClick={() => this.handleClickOpen("modal")}>
                            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
                        </IconButton>
                        <IconButton
                            aria-label="Delete"
                            onClick={() => { this.props.onPhotoDelete(this.state.photo); }}>
                            <Delete className={ classes.tableActionButtonIcon + " " + classes.edit } />
                        </IconButton>
                    </CardFooter>
                </Card>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    maxWidth="md"
                    fullWidth
                    open={this.state.modal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                            
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}
                        style={{backgroundColor:"#efefef"}}>
            
                        <Card>
                            <CardHeader color="success">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={10}>
                                    <h4 className={classes.cardTitleWhite}>Edit Photo</h4>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                            
                                        <CustomInput
                                            labelText="Description"
                                            value={this.state.photo.Description}
                                            inputProps={{
                                                placeholder: "Description"
                                            }}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            onChange={(e) => this.updatePhoto("Description", e.target.value)}
                                        />

                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                        <Button
                            onClick={() => {
                                //this.saveLocation();
                                this.props.onPhotoUpdated(this.state.photo);
                                this.handleClose("modal");
                            }}
                            color="success">
                            Save
                        </Button>
                        &nbsp;
                        <Button
                            onClick={() => this.handleClose("modal")}
                            >
                            Cancel
                        </Button>
                    </DialogContent>
                </Dialog>
            </GridItem>
        )
    }
}

export default withStyles(cardStyle)(PhotoCard);