import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/css/material-dashboard-react.css?v=1.4.0";
import indexRoutes from "routes/index.jsx";

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import appState from './redux/reducers';
import { loadState, saveState } from './redux/localStorage';

import $ from 'jquery';
window.$ = $;

const persistedState = loadState();
const store = createStore(
  appState,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
store.subscribe(() => {
  saveState(store.getState());
});

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} component={prop.component} key={key} />;
        })}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
