//react
import React from 'react';

//firebase
import { storage } from 'assets/firebase';

//fileUploader
import FileUploader from 'react-firebase-file-uploader';


class FileUpload extends React.Component{
    
    attachmentUploadStart = () => this.setState({isUploading: true, progress: 0});
    attachmentUploadProgress = (progress) => this.setState({progress});
    attachmentUploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
    }
    attachmentUploadSuccess = (filename) => {
        this.setState({progress: 100, isUploading: false});
        
        storage.attachments.child(filename).getDownloadURL().then(url => this.props.fileUploaded(url));
    };


    render() {
        
        return (
            
            <div style={{marginTop:"10px"}}>

                <label style={{color: "#696969", cursor:"pointer"}}>
                Upload a New File  

                <FileUploader
                    hidden 
                    name="attachment"
                    randomizeFilename
                    storageRef={storage.attachments}
                    onUploadStart={this.attachmentUploadStart}
                    onUploadError={this.attachmentUploadError}
                    onUploadSuccess={this.attachmentUploadSuccess}
                    onProgress={this.attachmentUploadProgress}
                    />
                    
                </label>
            </div>
        )
    }
}

export default FileUpload;
