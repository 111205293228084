//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Checkbox from "@material-ui/core/Checkbox";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";


import FormControlLabel from "@material-ui/core/FormControlLabel";

import Check from "@material-ui/icons/Check";

import { db } from 'assets/firebase';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };

class StripePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configured: false
        };
    }

    componentWillMount() {
        
        if (this.props.user.authenticated) {

            if(this.props.params["code"]) {

                var url = 'https://us-central1-haleakala-42bd0.cloudfunctions.net/getStripeCredentials?code=' + this.props.params["code"] + '&org=' + this.props.org.id;
                
                fetch(url)
                    .then((response) => {
                        if(response.ok) {
                            return response.json()
                        } else {
                            console.log(response.statusText);
                        }
                    })
                    .then((json) => {
                        this.setState({configured:true});
                    })
                    .catch((error) => {
                        // If there is any error you will catch them here
                        console.log("error: " + error);
                    });
            }

            db.getStripeAccount(this.props.org.id).then(stripeDoc => {
                if(stripeDoc.exists) {
                    this.setState({configured:true})   
                }
            })
        }
    }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
        return <Redirect to='/login' />
    }

    function configureStripe() {
        var stripeClientID = "ca_DPtud1GDZ87BfRCG4FdeL9akBJgYKMaD";
        window.location.href = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + stripeClientID + '&scope=read_write';
    }

    function StripeConfiguration(param) {
        
        if(param.configured) {
            
            return <FormControlLabel
                disabled
                control={
                    <Checkbox
                        tabIndex={-1}
                        checked={true}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked }}
                    />
                }
                classes={{
                    label: classes.label,
                    disabled: classes.disabledCheckboxAndRadio
                }}
                label="Stripe is Connected"
            />    
        }

        return <Button onClick={configureStripe} >Configure Payments with Stripe</Button>
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Stripe Configuration</h4>
              <p className={classes.cardCategoryWhite}>
                
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    Stripe is an industry leading payments processor that we use to process credit card payments from your users
                    on your behalf. 

                    <br /><br />
                    <StripeConfiguration configured={this.state.configured} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
    return state;
}

StripePage = connect(mapStateToProps)(StripePage);
export default withStyles(styles)(StripePage);
