//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CustomersLoaded } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

//
import CurrencyFormat from 'react-currency-format';

// core components
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//kekaha
import CustomerEditor from "components/CustomerEditor/CustomerEditor.jsx";
import OdCsvUpload from "components/OdCsvUpload/OdCsvUpload.jsx";

//icons
import Refresh from "@material-ui/icons/Sync";
import Download from "@material-ui/icons/CloudDownload";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  dropzoneContainer: {
    display:"none"
  }
};

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      customers: [],
      syncInProgress: false
    }

    
    this.customerUpdated = this.customerUpdated.bind(this);
    this.filterCustomers = this.filterCustomers.bind(this);
    this.getODAddresses = this.getODAddresses.bind(this);
    this.getXML = this.getXML.bind(this);
    this.syncZoho = this.syncZoho.bind(this);

    this.filterCustomers();
  }

  filterCustomers() {

      db.getCustomers().then(customers => {
        
        this.setState({customers: customers});
        this.props.onCustomersLoaded(customers);
      });
  }

  customerUpdated() {
    this.filterCustomers();
  }

  syncZoho() {

    this.setState({syncInProgress: true}, () => {
      var uploadURL = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/syncZohoCustomers";

      fetch(uploadURL, {
          method: "GET", 
          cache: "no-cache"
      })
      .then((response) => {
          if(response.ok) {
              this.filterCustomers();
              this.setState({syncInProgress: false});
          } else {
              console.log(response.statusText);
          }
      })
      .catch((error) => {
          // If there is any error you will catch them here
          console.log("error: " + error);
      });
    });
  }

  showSyncProgressIndicator() {
    if(this.state.syncInProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  getXML() {

    var promises = [];
    var customers = this.state.customers;
    var exportData = "ID, Company, Address Name, Address, Address2, City, State, Postal\n";

    for(var i = 0; i < customers.length; i++) 
      promises.push(db.getCustomerWithAddresses(customers[i].id))
    
    Promise.all(promises).then((results) => {

      console.log('results');
      console.log(results);

      for(var j = 0; j < results.length; j++) {
        for(var k = 0; k < results[j].addresses.length; k++) {

          var address = results[j].addresses[k];

          if(address.name.length > 30)
            console.log('FIELD EXCEEDED -> name: ' + address.name);
          if(address.address.length > 25)
            console.log('FIELD EXCEEDED -> address1: ' + address.address + ' (' + address.name + ')');
          if(address.street2.length > 25)
            console.log('FIELD EXCEEDED -> address2: ' + address.street2 + ' (' + address.name + ')');
          if(address.city.length > 25)
            console.log('FIELD EXCEEDED -> city: ' + address.city + ' (' + address.name + ')');
          if(address.state.length > 2) {
            console.log('FIELD EXCEEDED -> state: ' + address.state + ' (' + address.name + ')');
            console.log(address)
          }
            
          if(address.zip.length > 11)
            console.log('FIELD EXCEEDED -> zip: ' + address.zip + ' (' + address.name + ')');

          var addressl1 = address.address.replace(/(\r\n|\n|\r)/gm, "");
          exportData = exportData + ( address.address_id + ", " + results[j].customer.customer_name.trim() + ", " + address.name.trim() + ", " + addressl1.trim() + ", " + address.street2.trim() + ", " + address.city.trim() + ", " + address.state.trim() + ", " + address.zip.trim() ) + "\n"; 
        }
          
      }

      console.log('exportData');
      console.log(exportData);

    });
  }

  getODAddresses() {
    console.log('getODAddresses()');

   // var addresses = [];
    var promises = [];

    db.getCustomers().then(customers => {

      customers.forEach(customer => {
        promises.push(db.getAddressesByCustomer(customer.id));
      });

      Promise.all(promises).then((results) => {
        console.log(results);

        let filename = "addresses.csv";
        let contentType = "text/csv;charset=utf-8;";
      
        var content = "addressID, name, address, address2, city, state, zip \n";
        for(var i = 0; i < results.length; i++) {
          for(var j = 0; j < results[i].length; j++) {

            if(results[i][j].address_id === '1343284000000108310')
              console.log(results[i][j])

            if(results[i][j].zip)
              if(results[i][j].zip.length > 0)
                if(results[i][j].shipToName.length > 0)
                  content = content + results[i][j].address_id + ', ' + results[i][j].shipToName + ', ' + results[i][j].address.replace(/[,\r\n]+/g, "") + ', ' + results[i][j].street2.replace(/[,\r\n]+/g, "") + ', ' + results[i][j].city.replace(/[,]+/g, "") + ', ' + results[i][j].state.replace(/[,]+/g, "") + ', ' + results[i][j].zip + '\n';
          }
        }
      
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var a = document.createElement('a');
          a.download = filename;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      });
    });
  }

  

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }
    
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={7} >
                  <h4 className={classes.cardTitleWhite}>Customers</h4>
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                  <CircularProgress className={classes.progress} color="inherit" style={{display:this.showSyncProgressIndicator()}} />
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right", display:"none"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Get Office Depot XML"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={this.getXML}>
                      <Download style={{color:"white"}} />
                    </IconButton>
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                  <OdCsvUpload />
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Export Addresses for Office Depot"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={this.getODAddresses}>
                      <Download style={{color:"white"}} />
                    </IconButton>
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Sync w/ Zoho"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={this.syncZoho}>
                      <Refresh style={{color:"white"}} />
                    </IconButton>
                  </Tooltip>
                </GridItem>
                {/* <GridItem xs={12} sm={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Add"
                      placement="left"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <CustomerEditor customer={{ Name: "" }} />
                  </Tooltip>
                </GridItem> */}
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <Table className={classes.table}>
                  <TableHead>
                      <TableRow>
                        <TableCell style={{paddingRight:"3px"}}>
                          &nbsp;
                        </TableCell>
                        <TableCell>
                            Company Name
                        </TableCell>
                        <TableCell>
                            Contact
                        </TableCell>
                        <TableCell style={{textAlign:"right"}}>
                            Outstanding Receivables
                        </TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.customers.map(value => (
                      <TableRow key={value.id} className={classes.tableRow} >
                        <TableCell style={{padding:"6px"}}>
                          <CustomerEditor customer={value} onChange={this.customerUpdated} />
                        </TableCell>
                        <TableCell style={{padding:"6px"}}>
                          {value.customer_name}
                        </TableCell>
                        <TableCell style={{padding:"6px"}}>
                          {value.first_name} {value.last_name}  
                        </TableCell>
                        <TableCell style={{padding:"6px", textAlign:"right"}}>
                          <CurrencyFormat value={value.outstanding_receivable_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onCustomersLoaded: CustomersLoaded }, dispatch);  
}


CustomerList = connect(mapStateToProps, mapDispatchToProps)(CustomerList);
export default withStyles(styles)(CustomerList);
