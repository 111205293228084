//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { VendorCreated, VendorsLoaded, VendorUpdated } from 'redux/actions';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import VendorEditor from "components/VendorEditor/VendorEditor.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class VendorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: []
    }

    this.VendorCreated = this.VendorCreated.bind(this);
    this.VendorUpdated = this.VendorUpdated.bind(this);
    this.deleteVendor = this.deleteVendor.bind(this);
  }

  componentWillMount() {
    this.refreshVendors();
  }

  refreshVendors() {
      
    db.getVendors().then(vendors => {
      this.setState({vendors: vendors});
      this.props.onVendorsLoaded(vendors); 
    });
  }

  VendorUpdated(vendor) {
    
    var vendors = this.state.vendors;
    for(var i = 0; i < vendors.length; i++) {
      if(vendors[i].id === vendor.id) {
        vendors[i] = vendor;
        break;
      }
    }
    vendors = vendors.sort(this.compareName);
    this.setState({vendors: vendors});
    this.props.onVendorsLoaded(vendors); 
  }

  VendorCreated(vendor) {
    
    var vendors = this.state.vendors;
    vendors.push(vendor);
    vendors.sort(this.compareName);
    this.setState({vendors: vendors});
    this.props.onVendorsLoaded(vendors); 
  }

  deleteVendor(vendor) {
    db.deleteVendor(vendor.id).then(() => {
      var vendors = this.state.vendors;
      
      for(var i = 0; i < vendors.length; i++) {
        if(vendors[i].id === vendor.id) {
          vendors.splice(i,1);
          break;
        }
      }

      vendors.sort(this.compareName);
      this.setState({vendors: vendors});
      this.props.onVendorsLoaded(vendors); 
    });
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Vendors</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  <VendorEditor title="Add Vendor" onCreated={this.VendorCreated} />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"23px"}}> 
                          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.vendors.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <VendorEditor title="Edit Vendor" vendor={value} onChanged={this.VendorUpdated}  />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.Name}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Vendor" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this vendor? This action cannot be undone." confirmed={() => this.deleteVendor(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onVendorCreated: VendorCreated, 
    onVendorsLoaded: VendorsLoaded,
    onVendorUpdated: VendorUpdated }, dispatch);  
}

VendorList = connect(mapStateToProps, mapDispatchToProps)(VendorList);
export default withStyles(styles)(VendorList);
