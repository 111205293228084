//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/PlaylistAdd";
import Delete from "@material-ui/icons/DeleteOutline";
import Category from "@material-ui/icons/Category";


//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}
  
class CategoryEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    if(props.category) {
      var category = props.category;
      category.SubCat1 = category.SubCat1.sort(this.compareName);
      category.SubCat2 = category.SubCat2.sort(this.compareName);
      category.SubCat3 = category.SubCat3.sort(this.compareName);
      this.state.category = category;
    }
    else {
      this.state.category = { Name: "", SubCat1:[], SubCat2:[], SubCat3:[] };
    }
    
    this.addSubCat1 = this.addSubCat1.bind(this);
    this.updateSubCat1 = this.updateSubCat1.bind(this);
    this.deleteSubCat1 = this.deleteSubCat1.bind(this);

    this.addSubCat2 = this.addSubCat2.bind(this);
    this.updateSubCat2 = this.updateSubCat2.bind(this);
    this.deleteSubCat2 = this.deleteSubCat2.bind(this);

    this.addSubCat3 = this.addSubCat3.bind(this);
    this.updateSubCat3 = this.updateSubCat3.bind(this);
    this.deleteSubCat3 = this.deleteSubCat3.bind(this);

    this.saveCategory = this.saveCategory.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  addSubCat1() {
    var obj = this.state.category;
    const subCategory = {id: uuidv4(), Name: "" };
    obj.SubCat1.push(subCategory);
    
    this.setState({category: obj});
  }

  deleteSubCat1(subCategory) {
    var d = this.state.category;
    for(var i = 0; i < d.SubCat1.length; i++) {
      if(d.SubCat1[i].id === subCategory.id) {
        d.SubCat1.splice(i,1);
        break;
      }
    }
    this.setState({category: d});
  }

  updateSubCat1(subCategory, property, value) {
    var d = this.state.category;

    for(var i = 0; i < d.SubCat1.length; i++) {
      if(d.SubCat1[i].id === subCategory.id) {
        d.SubCat1[i][property] = value;
        break;
      }
    }
    this.setState({category: d});
  }

  addSubCat2() {
    var obj = this.state.category;
    const subCategory = {id: uuidv4(), Name: "" };
    obj.SubCat2.push(subCategory);
    
    this.setState({category: obj});
  }

  deleteSubCat2(subCategory) {
    var d = this.state.category;
    for(var i = 0; i < d.SubCat2.length; i++) {
      if(d.SubCat2[i].id === subCategory.id) {
        d.SubCat2.splice(i,1);
        break;
      }
    }
    this.setState({category: d});
  }

  updateSubCat2(subCategory, property, value) {
    var d = this.state.category;

    for(var i = 0; i < d.SubCat2.length; i++) {
      if(d.SubCat2[i].id === subCategory.id) {
        d.SubCat2[i][property] = value;
        break;
      }
    }
    this.setState({category: d});
  }

  addSubCat3() {
    var obj = this.state.category;
    const subCategory = {id: uuidv4(), Name: "" };
    obj.SubCat3.push(subCategory);
    
    this.setState({category: obj});
  }

  deleteSubCat3(subCategory) {
    var d = this.state.category;
    for(var i = 0; i < d.SubCat3.length; i++) {
      if(d.SubCat3[i].id === subCategory.id) {
        d.SubCat3.splice(i,1);
        break;
      }
    }
    this.setState({category: d});
  }

  updateSubCat3(subCategory, property, value) {
    var d = this.state.category;

    for(var i = 0; i < d.SubCat3.length; i++) {
      if(d.SubCat3[i].id === subCategory.id) {
        d.SubCat3[i][property] = value;
        break;
      }
    }
    this.setState({category: d});
  }

  updateCategory(property, value) {
    var d = this.state.category;
    d[property] = value;
    this.setState({category: d});
  }

  saveCategory() {
  
    var d = this.state.category;
    d.SubCat1 = d.SubCat1.sort(this.compareName);
    
    db.updateCategory(d.id, d).then(doc => {
    
      if(!d.id) { // i.e. if it's a new obj

          d.id = doc.id; 
          this.props.onCreated(d);
          
          // reset to blank
          this.setState( { category: {
              Name: "", SubCat1: [], SubCat2: [], SubCat3: []
          }} );

      } else { // i.e. an existing Vocabulary

          this.props.onChanged(d);
          
      }
    });
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Tooltip
            id="tooltip-top-start"
            title={this.props.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            >
          <IconButton
            aria-label="Edit"
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
          </IconButton>
        </Tooltip>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Category</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Category,
                  tabContent: (
                    
                      <GridContainer direction="row" style={{minWidth:"800px"}}>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Name"
                            value={this.state.category.Name}
                            inputProps={{
                                placeholder: "Name"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateCategory("Name", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <br />
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                  <TableCell style={{padding:"3px"}}>
                                      SubCategory A
                                  </TableCell>
                                  <TableCell style={{padding:"3px", width:"69px"}}>
                                    
                                  </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.category.SubCat1.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{padding:"3px"}}>
                                      <input type="text" value={value.Name} placeholder="name" 
                                        onChange={(e) => this.updateSubCat1(value, "Name", e.target.value)} 
                                        style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"21px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", width:"69px"}}>
                                      <Tooltip id="tooltip-top-start" title="Remove SubCategory" placement="top" classes={{ tooltip: classes.tooltip }} >
                                        <IconButton aria-label="Close" className={classes.tableActionButton} onClick={() => this.deleteSubCat1(value)} >
                                          <Delete className={ classes.tableActionButtonIcon + " " + classes.close } />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Tooltip
                            id="tooltip-top-start"
                            title="Add SubCategory"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={this.addSubCat1}>
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <br />
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                  <TableCell style={{padding:"3px"}}>
                                      SubCategory B
                                  </TableCell>
                                  <TableCell style={{padding:"3px", width:"69px"}}>
                                    
                                  </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.category.SubCat2.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{padding:"3px"}}>
                                      <input type="text" value={value.Name} placeholder="name" 
                                        onChange={(e) => this.updateSubCat2(value, "Name", e.target.value)} 
                                        style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"21px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", width:"69px"}}>
                                      <Tooltip id="tooltip-top-start" title="Remove SubCategory" placement="top" classes={{ tooltip: classes.tooltip }} >
                                        <IconButton aria-label="Close" className={classes.tableActionButton} onClick={() => this.deleteSubCat2(value)} >
                                          <Delete className={ classes.tableActionButtonIcon + " " + classes.close } />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Tooltip
                            id="tooltip-top-start"
                            title="Add SubCategory"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={this.addSubCat2}>
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <br />
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                  <TableCell style={{padding:"3px"}}>
                                      SubCategory C
                                  </TableCell>
                                  <TableCell style={{padding:"3px", width:"69px"}}>
                                    
                                  </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.category.SubCat3.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{padding:"3px"}}>
                                      <input type="text" value={value.Name} placeholder="name" 
                                        onChange={(e) => this.updateSubCat3(value, "Name", e.target.value)} 
                                        style={{borderStyle:"none", backgroundColor:"#efefef", padding:"6px", height:"21px", borderRadius:"6px", fontSize:"12px", width:"100%"}} />
                                    </TableCell>
                                    <TableCell style={{padding:"3px", width:"69px"}}>
                                      <Tooltip id="tooltip-top-start" title="Remove SubCategory" placement="top" classes={{ tooltip: classes.tooltip }} >
                                        <IconButton aria-label="Close" className={classes.tableActionButton} onClick={() => this.deleteSubCat3(value)} >
                                          <Delete className={ classes.tableActionButtonIcon + " " + classes.close } />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Tooltip
                            id="tooltip-top-start"
                            title="Add SubCategory"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton onClick={this.addSubCat3}>
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveCategory();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
  //return {
  //  vocabulary: state.vocabulary
  //}
}

CategoryEditor = connect(mapStateToProps)(CategoryEditor);
export default withStyles(modalStyle)(CategoryEditor);