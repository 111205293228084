//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
//import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { LessonsLoaded } from 'redux/actions';

//firebase
import { db } from 'assets/firebase';

//algolia full text search
import { algolia } from 'assets/algolia';

// @material-ui/style
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import CustomSelect from "components/Select/Select.jsx";
import IconButton from "@material-ui/core/IconButton";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//icons
import Visibility from "@material-ui/icons/Visibility";
import Refresh from "@material-ui/icons/YoutubeSearchedFor";
import Export from "@material-ui/icons/ImportExport";

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import ProductEditor from "components/ProductEditor/ProductEditor.jsx";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

/*
function exportToJson(objectData) {
  let filename = "export.json";
  let contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement('a');
    a.download = filename;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
*/

function exportToCSV(products) {
  let filename = "products.csv";
  let contentType = "text/csv;charset=utf-8;";

  var content = "Name, SKU, Product ID, Cost, List \n";
  for(var i = 0; i < products.length; i++) {
    content = content + products[i].ProductName.replace(/,/g, "") + ', ' + skuList(products[i]) + ', ' + products[i].ProductID  + ', ' + products[i].WholesalePrice + ', ' + products[i].ListPrice + '\n';
  }

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement('a');
    a.download = filename;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

function skuList(product) {
  let res = "";
  product.Vendors.map(item => {
    if(item.sku) {
      if(res !== "")
        res += ", ";
      res += item.sku;
    }
    return item;
  })
  return res;
}

function compareName(a,b) {

  var x = a.ProductName?.toString().toLowerCase();
  var y = b.ProductName?.toString().toLowerCase();
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

function compareManufacturerName(a,b) {

  var y = b.Name?.toString().toLowerCase();
  var x = a.Name?.toString().toLowerCase();
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

function compareProductID(a,b) {

  var x = a.ProductID?.toString().toLowerCase();
  var y = b.ProductID?.toString().toLowerCase();
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

function compareSKU(a,b) {

  var x = skuList(a);
  var y = skuList(b);
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

function compareCost(a,b) {

  var x = Number(a.Price);
  var y = Number(b.Price);
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

function compareListPrice(a,b) {

  var x = Number(a.ListPrice);
  var y = Number(b.ListPrice);
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

function compareVisibility(a,b) {

  var x = a.IsVisible;
  var y = b.IsVisible;
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedManufacturer: props.manufacturers[0].id,
      manufacturers: props.manufacturers.sort(compareManufacturerName),
      currentSort: 'name',
      selectedView: "pricing",
      categories: []
    }
    
    this.getCategories = this.getCategories.bind(this);
    this.productAdded = this.productAdded.bind(this);
    this.productUpdated = this.productUpdated.bind(this);
    
  }

  componentDidMount() {
    this.getCategories();
    this.refreshProducts(this.state.selectedManufacturer);
  }

  getCategories() {
    db.getCategories().then(categories => {
      this.setState({categories: categories});
    })
  }

  getSubCat1(categoryID) {
    
    var options = [];

    for(var i = 0; i < this.state.categories.length; i++) {
      if(this.state.categories[i].id === categoryID) {
        for(var j = 0; j < this.state.categories[i].SubCat1.length; j++) {
          options.push({key: this.state.categories[i].SubCat1[j].id, label:this.state.categories[i].SubCat1[j].Name});
        }
      }
    }

    return options;
  }

  getSubCat2(categoryID) {
    
    var options = [];

    for(var i = 0; i < this.state.categories.length; i++) {
      if(this.state.categories[i].id === categoryID) {
        for(var j = 0; j < this.state.categories[i].SubCat2.length; j++) {
          options.push({key: this.state.categories[i].SubCat2[j].id, label:this.state.categories[i].SubCat2[j].Name});
        }
      }
    }

    return options;
  }

  getSubCat3(categoryID) {
    
    var options = [];

    for(var i = 0; i < this.state.categories.length; i++) {
      if(this.state.categories[i].id === categoryID) {
        for(var j = 0; j < this.state.categories[i].SubCat3.length; j++) {
          options.push({key: this.state.categories[i].SubCat3[j].id, label:this.state.categories[i].SubCat3[j].Name});
        }
      }
    }

    return options;
  }

  saveProduct(product) {
    db.updateProduct(product.id,product).then(() => {
      algolia.upsertProduct(product);
    });
  }

  updateCategory(id, value) {
    var products = this.state.products;

    for(var i = 0; i < products.length; i++) {
      if(products[i].id === id) {
        products[i].CategoryID = value;

        this.saveProduct(products[i]);
        this.setState(products);

        break;
      }
    }
  }

  updateSubCat1(id, value) {
    var products = this.state.products;

    for(var i = 0; i < products.length; i++) {
      if(products[i].id === id) {
        products[i].SubCat1ID = value;

        this.saveProduct(products[i]);
        this.setState(products);
        
        break;
      }
    }
  }

  updateSubCat2(id, value) {
    var products = this.state.products;

    for(var i = 0; i < products.length; i++) {
      if(products[i].id === id) {
        products[i].SubCat2ID = value;

        this.saveProduct(products[i]);
        this.setState(products);
        
        break;
      }
    }
  }

  updateSubCat3(id, value) {
    var products = this.state.products;

    for(var i = 0; i < products.length; i++) {
      if(products[i].id === id) {
        products[i].SubCat3ID = value;

        this.saveProduct(products[i]);
        this.setState(products);
        
        break;
      }
    }
  }

  productAdded(product) {
    
    if(product.ManufacturerID === this.state.selectedManufacturer) {
      var products = this.state.products;
      products.push(product);
      this.setState({products: products});
    }
  }

  productUpdated(product) {

    var products = this.state.products;
    for(var i = 0; i < products.length; i++) {
      if(products[i].id === product.id) {
        products[i] = product;
        break;
      }
    }
    this.setState({products: products});
  }

  deleteProduct(product) {
    
    db.deleteProduct(product.id).then(() => {
      algolia.deleteProduct(product);

      var products = this.state.products;
      for(var i = 0; i < products.length; i++) {
        if(products[i].id === product.id) {
          products.splice(i,1);
          break;
        }
      }
      this.setState({products:products});
      
    });
  }

  refreshProducts(manufacturer) {
    
    this.setState({selectedManufacturer: manufacturer, products: []}, function() {
        
      db.getProductsByManufacturer(manufacturer).then(snapshot => {
            
        var products = [];
        snapshot.forEach((doc) => {

            var obj = doc.data();
            obj.id = doc.id;
            if(!obj.Photos)
              obj.Photos = [];

            try {
              obj.Price = Number(obj.WholesalePrice).toFixed(2);
              obj.ListPrice = Number(obj.ListPrice).toFixed(2);
            }
            catch(exception) {

            }
            
            products.push(obj);
        });

        this.setState({products: products, currentSort:'name'});   
      });
    });
  }

  /*
  setupVendors() {

    db.getProducts().then(products => {
      
      var promises = [];
      for(var i = 0; i < products.length; i++) {

        var product = products[i];
        product.DefaultVendor = "7yOKSLSHr7AiyxmS4xa2";
        product.Vendors = [ { Cost: product.WholesalePrice, Name:"Office Depot", id:"7yOKSLSHr7AiyxmS4xa2" } ];

        promises.push(db.updateProduct(product.id, product));
        
      }

      Promise.all(promises).then(() => {

        console.log('setupVendors complete');
      });
    })
  }
  */

  refreshIndex() {
    
    //algolia.applySettings();
    
    db.getProducts().then(products => {

      for(var i = 0; i < products.length; i++) {

        var product = products[i];

        //var isChanged = false;

        // removing TM symbols from product names
        if(product.ProductName.indexOf('™') > -1) {
          product.ProductName = product.ProductName.replace('™','');
          //isChanged = true;
        }
        if(product.ProductName.indexOf('™') > -1) {
          product.ProductName = product.ProductName.replace('™','');
          //isChanged = true;
        }
        
        // creating numeric Product IDs
        if(product.ProductID) {

          var numericOnlyPattern = /[0-9]/g;
          var numbers = product.ProductID.match(numericOnlyPattern);

          if(numbers) {
            product.productIDNumber = "";
            for(var j = 0; j < numbers.length; j++) 
              product.productIDNumber = product.productIDNumber + numbers[j];
          }
        }

        //if(isChanged) {
          //setTimeout(() => {
            console.log(product.id);
            //db.updateProduct(product.id,product);
            algolia.upsertProduct(product);
          //}, i*100);
          
        //}
      }

      console.log('refreshIndex complete');
    })
  }
  
  exportProducts() {
    db.getProducts().then(products => {
      exportToCSV(products);
    }); 
  }

  visibleText(isVisible) {
    if(isVisible)
      return "Yes";
    else
      return "No";
  }

  iconColor(value) {
    if(value)
      return "#3fa31b";
    else
      return "silver";
  }

  adminOnly() {
    if(this.props.user.userType === 'admin') 
      return "block";
    else
      return "none";
  }

  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  sortBy(property) {

    var products = this.state.products;

    if(this.state.currentSort === property) { //reverse array

      var reversed = [];
      for(var i = products.length -1; i > -1; i--) 
        reversed.push(products[i]);

      products = reversed;

    } else {

      if(property === 'name')
        products.sort(compareName);
      else if(property === 'productID')
        products.sort(compareProductID);
      else if(property === 'sku')
        products.sort(compareSKU);
      else if(property === 'cost')
        products.sort(compareCost);
      else if(property === 'list')
        products.sort(compareListPrice);
      else if(property === 'visible')
        products.sort(compareVisibility);
    }

    this.setState({products: products, currentSort: property});
  }

  formattedCurrency(value) {
    if(!value)
      return "";
    else {
      return "$" + Number(value).toFixed(2);
    }
  }

  showCategoryColumn() {
    if(this.state.selectedView === 'categories') {
      return "table-cell";
    } else {
      return "none";
    }
  }

  showPricingColumn() {
    if(this.state.selectedView === 'pricing') {
      return "table-cell";
    } else {
      return "none";
    }
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitleWhite}>Products</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem> 
                <GridItem xs={12} sm={12} md={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Refresh Product Index"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                    <IconButton onClick={this.refreshIndex}>
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                </GridItem> 
                <GridItem xs={12} sm={12} md={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Export Products"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton onClick={this.exportProducts}>
                        <Export />
                      </IconButton>
                  </Tooltip>
                </GridItem>
                {/*
                <GridItem xs={12} sm={12} md={1} style={{textAlign:"right"}}>
                  <Tooltip
                      id="tooltip-top-start"
                      title="Import Products"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <ProductImporter />
                  </Tooltip>
                </GridItem>
                */}
                <GridItem xs={12} sm={12} md={1} style={{textAlign:"right"}}>
                  <Tooltip id="tooltip-top-start" title="Add Product" placement="top" classes={{ tooltip: classes.tooltip }} >
                      <ProductEditor onNew={this.productAdded}
                        manufacturerID={this.state.selectedManufacturer}
                        product={{
                          ManufacturerID: this.state.selectedManufacturer,
                          ProductName: "", 
                          BriefDescription: "", 
                          BrandID: "", 
                          CategoryID:"",
                          SubCat1ID:"",
                          SubCat2ID:"",
                          SubCat3ID:"",
                          Price: 0,
                          ListPrice: 0,
                          ProductID: "",
                          Photos: [],
                          IsVisible: false
                        }} />
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={false} sm={false} md={4}>
                    &nbsp;
                </GridItem>
                <GridItem xs={12} sm={12} md={4} >
                  <CustomSelect
                      labelText="Manufacturer"
                      inputProps={{ placeholder: "Choose a Manufacturer" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(e) => this.refreshProducts(e.target.value)}
                      options={this.state.manufacturers}
                      value={this.state.selectedManufacturer}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} >
                  <CustomSelect
                      labelText="View"
                      inputProps={{ placeholder: "Choose a View" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(e) => this.setState({selectedView:e.target.value})}
                      options={[ {key:"categories",label:"Categories"}, {key:"pricing", label:"Pricing"} ]}
                      value={this.state.selectedView}
                    />
                </GridItem>
              </GridContainer>
              <Table className={classes.table} style={{marginTop:"28px"}}>
                <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"3px"}}>

                      </TableCell>
                      <TableCell style={{padding:"3px", color:"green", cursor:"pointer"}} onClick={() => this.sortBy("name")}>
                          Name
                      </TableCell>
                      <TableCell style={{padding:"3px", color:"green", cursor:"pointer", display:this.showPricingColumn()}} onClick={() => this.sortBy("productID")}>
                          Product ID
                      </TableCell>
                      <TableCell style={{padding:"3px", color:"green", cursor:"pointer", display:this.showPricingColumn()}} onClick={() => this.sortBy("sku")}>
                          SKU
                      </TableCell>
                      <TableCell style={{padding:"3px", color:"green", cursor:"pointer", textAlign:"right", display:this.showPricingColumn()}} onClick={() => this.sortBy("cost")}>
                          Cost
                      </TableCell>
                      <TableCell style={{padding:"3px", color:"green", cursor:"pointer", textAlign:"right", display:this.showPricingColumn()}} onClick={() => this.sortBy("list")}>
                          List
                      </TableCell>
                      <TableCell style={{padding:"3px", color:"green", cursor:"pointer", textAlign:"right", display:this.showPricingColumn()}} onClick={() => this.sortBy("visible")}>
                          Status
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showPricingColumn()}}>
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                          Category
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                          Subcategory
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                          Subcategory
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                          Subcategory
                      </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.products.map(value => (
                    <TableRow key={value.id} className={classes.tableRow}>
                      <TableCell className={classes.tableActions} style={{padding:"3px"}}>
                        <Tooltip
                          id="tooltip-top"
                          title="Edit Product"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <ProductEditor product={value} onChange={this.productUpdated} manufacturers={this.state.manufacturers} />
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{padding:"3px"}} title={value.ProductName}>
                        {this.trim(value.ProductName,50)}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{padding:"3px", display:this.showPricingColumn()}}>
                        {value.ProductID}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{padding:"3px", display:this.showPricingColumn()}}>
                        {skuList(value)}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{padding:"3px", textAlign:"right", display:this.showPricingColumn()}}>
                        {this.formattedCurrency(value.WholesalePrice)}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{padding:"3px", textAlign:"right", display:this.showPricingColumn()}}>
                        {this.formattedCurrency(value.ListPrice)}
                      </TableCell>
                      <TableCell style={{padding:"3px", textAlign:"right", display:this.showPricingColumn()}}>
                        <Visibility style={{color:this.iconColor(value.IsVisible)}} />
                      </TableCell>
                      <TableCell style={{padding:"3px", marginTop:"-20px",display:this.showCategoryColumn()}}>
                        <CustomSelect
                          inputProps={{ placeholder: "Choose a Category" }}
                          formControlProps={{ fullWidth: true }}
                          options={this.state.categories}
                          value={value.CategoryID}
                          style={{marginTop:"-15px"}}
                          onChange={(e) => this.updateCategory(value.id, e.target.value)}
                        />
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                        <CustomSelect
                          inputProps={{ placeholder: "Choose a Category" }}
                          formControlProps={{ fullWidth: true }}
                          options={this.getSubCat1(value.CategoryID)}
                          value={value.SubCat1ID}
                          style={{marginTop:"-15px"}}
                          onChange={(e) => this.updateSubCat1(value.id, e.target.value)}
                        />
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                        <CustomSelect
                          inputProps={{ placeholder: "Choose a Category" }}
                          formControlProps={{ fullWidth: true }}
                          options={this.getSubCat2(value.CategoryID)}
                          value={value.SubCat2ID}
                          style={{marginTop:"-15px"}}
                          onChange={(e) => this.updateSubCat2(value.id, e.target.value)}
                        />
                      </TableCell>
                      <TableCell style={{padding:"3px", display:this.showCategoryColumn()}}>
                        <CustomSelect
                          inputProps={{ placeholder: "Choose a Category" }}
                          formControlProps={{ fullWidth: true }}
                          options={this.getSubCat3(value.CategoryID)}
                          value={value.SubCat3ID}
                          style={{marginTop:"-15px"}}
                          onChange={(e) => this.updateSubCat3(value.id, e.target.value)}
                        />
                      </TableCell>
                      <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px", display:this.showPricingColumn()}}> 
                        <Tooltip id="tooltip-top-start" title="Delete Product" placement="top" classes={{ tooltip: classes.tooltip }} >
                          <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this product? This action cannot be undone." confirmed={() => this.deleteProduct(value)} />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}
/*
function mapDispatchToProps(dispatch) {
  return bindActionCreators({onLessonsLoaded: LessonsLoaded }, dispatch);  
}
*/
ProductList = connect(mapStateToProps)(ProductList);
export default withStyles(styles)(ProductList);
