//react
import React from "react";
import PropTypes from "prop-types";

//router
import { Redirect } from "react-router-dom";

//redux
import { connect } from 'react-redux'

//firebase
import { db } from 'assets/firebase';

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import ChatBubble from "@material-ui/icons/ChatBubbleOutline";
import CreditCard from "@material-ui/icons/CreditCardOutlined"
import Customer from "@material-ui/icons/InsertEmoticon"
import Receipt from "@material-ui/icons/Receipt";
import Book from "@material-ui/icons/ClassOutlined";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
//import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import moment from 'moment';
import { bugs, website } from "variables/general";

import {
  dailySalesChart,
  completedTasksChart
} from "variables/charts";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-1');

var Chartist = require("chartist");

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = (a.getMinutes()<10?'0':'') + a.getMinutes()
  var time = month + ' ' + date + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

function compareModified(a,b) {

  var x = a.modified;
  var y = b.modified;
  
  if (x < y)
      return -1;
  if (x > y)
      return 1;
  return 0;
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countCustomers: 0,
      countProducts: 0,
      newOrders: 0,
      purchases: [],
      totalOrders: 0,
      totalSales: 0.00,
      last24Revenue: 0,
      visitsThisWeek: 0,
      amountSold: {data: {labels: [], series:[] }, highValue: 1000},
      ordersPlaced: {data: {labels: [], series:[] }, highValue: 10},
      siteVisits: {data: {labels: [], series:[] }, highValue: 20},
      loginActivity: []
    };
  }

  componentDidMount() {

    if (this.props.user.authenticated) {

      // prepare dailySalesChart
      var dailySalesSeries = [];
      //dailySalesChart.data.labels = [];

      var days = moment().subtract(7,"days");

      this.getCustomerCount();
      this.getProductCount();
      this.summarizeOrders();
      
      for(var i = 1; i < 8; i++) {
        var day = days.add(1, "day");
        dailySalesChart.data.labels.push(day.format("D"));
        dailySalesSeries.push(0);
      }

      db.getActivity().then(activity => {
        this.summarizeActivityRecords(activity);
      });

    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  incrementArray(array, labels, date) {
    for(var i = 0; i < labels.length; i++) {
      if(labels[i].getMonth() === date.getMonth()
        && labels[i].getFullYear() === date.getFullYear()
        && labels[i].getDate() === date.getDate()
      ) {
        array[i] = array[i] + 1;
      }
    }
  }

  addToArrayValue(array, labels, date, value) {
    for(var i = 0; i < labels.length; i++) {
      if(labels[i].getMonth() === date.getMonth()
        && labels[i].getFullYear() === date.getFullYear()
        && labels[i].getDate() === date.getDate()
      ) {
        array[i] = Number(array[i]) + Number(value);
      }
    }
  }

  getCustomerCount() {
      db.getCustomers().then(customers => {
        this.setState({countCustomers: customers.length})
      });
  }

  getProductCount() {
    db.getProducts().then(products => {
      this.setState({countProducts: products.length})
    });
  }

  summarizeActivityRecords(records) {

    var labels = [];
    var dates = [];
    var siteVisits = [];
    var userActivity = [];
  
    var oneWeekAgo = this.addDays(new Date(), -7).getTime();
    var totalVisits = 0;
    
    var today = Date();
    for(var h = 6; h > -1; h--) {
      var calculatedDate = this.addDays(today, -h);
      labels.push(calculatedDate.getMonth() + 1 + "/" + calculatedDate.getDate());
      dates.push(calculatedDate);

      siteVisits.push(0);
    }

    db.getUsers().then(users => {

      //console.log(users);
      for(var i = 0; i < records.length; i++) {
        var ts = new Date(records[i].modified*1000);
        if((records[i].modified * 1000) > oneWeekAgo) {

          if(records[i].ActivityType === 'Login-App') {
              this.incrementArray(siteVisits, dates, ts);
              totalVisits = totalVisits + 1;

              var isFound = false;
              for(var j = 0; j < userActivity.length; j++) {
                if(records[i].UserID === userActivity[j].userID) {
                  isFound = true;
                  break;
                }
              }
              if(!isFound) {

                var firstName = "";
                var lastName = "";
                var customerID;
                for(var u = 0; u < users.length; u++) {
                  if(users[u].id === records[i].UserID) {
                    firstName = users[u].FirstName;
                    lastName = users[u].LastName;
                    customerID = users[u].customerID;
                    break;
                  }
                }

                var companyName;
                if(!customerID)
                  companyName = 'Affiliate';
                else { 
                  for(var k = 0; k < this.props.customers.length; k++) {
                    if(this.props.customers[k].id === customerID) {
                      companyName = this.props.customers[k].customer_name;
                      break;
                    }
                  }
                }
                

                userActivity.push({
                  userID: records[i].UserID,
                  customerID: records[i].customerID,
                  companyName: companyName,
                  firstName: firstName,
                  lastName: lastName,
                  timeStamp: records[i].modified,
                  lastLogin: timeConverter(records[i].modified)
                });
              }

          } else {
            //console.log('missing activity: ' + records[i].ActivityType);
          } 
        }
      }

      userActivity.sort(compareModified);
      //console.log(userActivity);
      var loginActivity = [];
      for(var x = 0; x < userActivity.length; x++) {
        var row = [];
        row.push(userActivity[x].firstName);
        row.push(userActivity[x].lastName);
        row.push(userActivity[x].companyName);
        row.push(userActivity[x].lastLogin);
        loginActivity.push(row);        
      }
      this.setState({loginActivity: loginActivity});
    })

    this.setState({visitsThisWeek: totalVisits});    

    var visitsHigh = 0;
    for(var y = 0; y < siteVisits.length; y++)
      if(siteVisits[y] > visitsHigh)
        visitsHigh = siteVisits[y];
      
    this.setState({siteVisits:          {data: { labels: labels, series: [siteVisits] }, highValue: visitsHigh * 1.1 }});
    
  }

  summarizeOrders() {

    var labels = [];
    var dates = [];
    
    var amountSold = [];
    var ordersPlaced = [];
    
    var newOrders = 0;
    var totalOrders = 0;
    var totalSales = 0.00;
    

    var lastWeek = moment().subtract(7,"days");
    var today = Date();
    for(var h = 6; h > -1; h--) {
      var calculatedDate = this.addDays(today, -h);
      labels.push(calculatedDate.getMonth() + 1 + "/" + calculatedDate.getDate());
      dates.push(calculatedDate);

      amountSold.push(0);
      ordersPlaced.push(0);
    }
    
    db.getOrders().then(orders => {
    
      for(var i = 0; i < orders.length; i++) {

        if(!orders[i].status)
          newOrders = newOrders + 1;

        var orderPlaced = new Date(orders[i].orderPlaced);
        if(orderPlaced > lastWeek) {
          
          //console.log(orders[i].id + ":" + orders[i].poNumber + ":" + orderPlaced + ":" + orders[i].orderTotal)
          this.addToArrayValue(amountSold, dates, orderPlaced, orders[i].orderTotal);
          this.incrementArray(ordersPlaced, dates, orderPlaced);

          totalOrders = Number(totalOrders) + 1;
          totalSales = Number(totalSales) + Number(orders[i].orderTotal);
        }
      }
      
      //console.log('totalSales: ' + totalSales);
      this.setState({newOrders: newOrders});
      this.setState({totalSales: totalSales.toFixed(2)});
      this.setState({totalOrders: totalOrders});
      
      var amountSoldHigh = 0;
      for(var v = 0; v < amountSold.length; v++)
        if(amountSold[v] > amountSoldHigh)
          amountSoldHigh = amountSold[v];

      this.setState({amountSold:        {data: { labels: labels, series: [amountSold] }, highValue: amountSoldHigh * 1.1 }});

      var ordersPlacedHigh = 0;
      for(var w = 0; w < ordersPlaced.length; w++)
        if(ordersPlaced[w] > ordersPlacedHigh)
          ordersPlacedHigh = ordersPlaced[w];

      this.setState({ordersPlaced:        {data: { labels: labels, series: [ordersPlaced] }, highValue: ordersPlacedHigh * 1.1 }});
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <div>
        <GridContainer>      
          <GridItem xs={12} sm={6} md={4} lg={4} > 
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Customer />
                </CardIcon>
                <p className={classes.cardCategory}>Customers</p>
                <h3 className={classes.cardTitle}>
                  {this.state.countCustomers}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Current # Customers
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4} > 
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Book />
                </CardIcon>
                <p className={classes.cardCategory}>Products</p>
                <h3 className={classes.cardTitle}>
                  {this.state.countProducts}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Current # Products Offered
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={4} > 
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Receipt />
                </CardIcon>
                <p className={classes.cardCategory}>New Orders</p>
                <h3 className={classes.cardTitle}>
                  {this.state.newOrders}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Current # of Orders Not Approved
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{display:"none"}}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={this.state.siteVisits.data}
                  type="Line"
                  options={{
                    lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
                    low: 0,
                    high: this.state.siteVisits.highValue, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0
                    }
                  }}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Daily Logins</h4>
                <p className={classes.cardCategory}>
                  Last 7 Days: {this.state.visitsThisWeek} logins
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card chart>
              <CardHeader color="info">
                <ChartistGraph
                  className="ct-chart"
                  data={this.state.ordersPlaced.data}
                  type="Line"
                  options={{
                    lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
                    low: 0,
                    high: this.state.ordersPlaced.highValue, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0
                    }
                  }}
                  listener={completedTasksChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Total Orders</h4>
                <p className={classes.cardCategory}>
                  Last 7 Days: {numberWithCommas(this.state.totalOrders)}.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} >
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={this.state.amountSold.data}
                  type="Line"
                  options={{
                    lineSmooth: Chartist.Interpolation.cardinal({ tension: 0 }),
                    low: 0,
                    high: this.state.amountSold.highValue, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0
                    }
                  }}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Gross Sales</h4>
                <p className={classes.cardCategory}>
                  Last 7 Days: ${numberWithCommas(this.state.totalSales)}.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem xs={12} sm={12} md={6} style={{display:"none"}}>
            <CustomTabs
              title="Issues:"
              headerColor="primary"
              tabs={[
                {
                  tabName: "Chat",
                  tabIcon: ChatBubble,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={bugs}
                    />
                  )
                },
                {
                  tabName: "Payment",
                  tabIcon: CreditCard,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0]}
                      tasksIndexes={[0, 1]}
                      tasks={website}
                    />
                  )
                }
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>Customer Activity</h4>
                <p className={classes.cardCategoryWhite}>
                  
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableHead={["First", "Last Name", "Company", "Last Login"]}
                  tableData={this.state.loginActivity}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state;
}

Dashboard = connect(mapStateToProps)(Dashboard);
export default withStyles(dashboardStyle)(Dashboard);
