import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import Login from "views/LoginPage/LoginPage.jsx";
import Logout from "views/LogoutPage/LogoutPage.jsx";

const indexRoutes = [
    { path: "/affiliates", component: Dashboard },
    { path: "/backups", component: Dashboard },
    { path: "/bills", component: Dashboard },
    { path: "/blogPosts", component: Dashboard },
    { path: "/categories", component: Dashboard },
    { path: "/customers", component: Dashboard },
    { path: "/dashboard", component: Dashboard },
    { path: "/estimates", component: Dashboard },
    { path: "/invoices", component: Dashboard },
    { path: "/landingPageCustomizations", component: Dashboard },
    { path: "/logs", component: Dashboard },
    { path: "/manufacturers", component: Dashboard },
    { path: "/orders", component: Dashboard },
    { path: "/products", component: Dashboard },
    { path: "/profiles", component: Dashboard },
    { path: "/purchases", component: Dashboard },
    { path: "/rebates", component: Dashboard },
    { path: "/recurring", component: Dashboard },
    { path: "/stripe", component: Dashboard },
    { path: "/specials", component: Dashboard },
    { path: "/tasks", component: Dashboard },
    { path: "/user", component: Dashboard },
    { path: "/users", component: Dashboard },
    { path: "/vendors", component: Dashboard },
    
    { path: "/login", component: Login },
    { path: "/logout", component: Logout },
    { path: "/", component: Login }
];

export default indexRoutes;
