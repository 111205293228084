//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserLoggedIn, CustomersLoaded, ManufacturersLoaded, CategoriesLoaded, ProfilesLoaded, VendorsLoaded } from 'redux/actions';

//firebase
import { auth, db } from 'assets/firebase';

// @material-ui styles
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password:"",
      error:null,
      goToDashboard: false
    };

    //ReactGA.pageview("teacher.hanyu.co/login");
  }

  emailChange(e) {
    this.setState({email: e.target.value});
  }
  passwordChange(e) {
    this.setState({password: e.target.value});
  }
  
  onUserLoggedIn(id, email) {

    db.getUser(id).then(doc => {

      var user = doc.data();
      this.props.onUserLoggedIn({id:id, email: email, firstName: user.FirstName, lastName: user.LastName, userType: user.UserType, customers: user.Customers });

      db.getManufacturers().then(manufacturers => {
        
        this.props.onManufacturersLoaded(manufacturers); 
        
        db.getCategories().then(categories => {

          this.props.onCategoriesLoaded(categories);

          db.getCustomers().then(customers => {
            this.props.onCustomersLoaded(customers);

            db.getProfiles().then(profiles => {
              this.props.onProfilesLoaded(profiles);

              db.getVendors().then(vendors => {
                this.props.onVendorsLoaded(vendors); 

                //done preloading
                this.setState({goToDashboard: true});
              })
            });
          });
        })
      });
    });
  }

  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  componentWillMount() {
    //this.props.onUserLoggedOut();
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  
  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    document.getElementById('divErrors').style.display='none';

    auth.doSignInWithEmailAndPassword(email, password)
      .then(authUser => {

        this.onUserLoggedIn(authUser.user.uid, authUser.user.email);
    
      })
      .catch(error => {
        console.log('login error: ' + error);

        if(error.code === "auth/user-not-found") {
          this.setState({ error: "This email and password combination was not found." });
        }
        else {
          this.setState({ error: error.message });  
        }
        document.getElementById('divErrors').style.display='block';
      });

    event.preventDefault();
  }

  render() {
    const { classes } = this.props; // ...rest

    if (this.state.goToDashboard === true) {
      return <Redirect to='/dashboard' />
    }

    return (
      <div style={{position:"fixed", left:"0px", right:"0px", top:"0px", bottom:"0px", zIndex:"10000"}}>
        
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor: "#898989"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <CardHeader color="success" className={classes.cardHeader}>
                      <h4>Login</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={this.state.email}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.emailChange}
                      />
                      <CustomInput
                        labelText="Password"
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={this.state.password}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.passwordChange}
                      />
                    </CardBody>
                    <div id='divErrors' className={classes.divider} >
                      {this.state.error}
                    </div>
                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" type="submit">
                        Login
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onUserLoggedIn: UserLoggedIn, 
      onManufacturersLoaded: ManufacturersLoaded,
      onCategoriesLoaded: CategoriesLoaded,
      onCustomersLoaded: CustomersLoaded,
      onProfilesLoaded: ProfilesLoaded,
      onVendorsLoaded: VendorsLoaded
   }, dispatch);  
}

LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default withStyles(loginPageStyle)(LoginPage);
