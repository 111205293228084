//react
import React from 'react';

//firebase
import { storage } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import 'react-image-crop/dist/ReactCrop.css';

//icons
import AddPhoto from "@material-ui/icons/AddAPhotoOutlined";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";

//file chooser
import Files from 'react-files'

// image cropper
import ReactCrop from 'react-image-crop';

//unique id generator
const uuidv4 = require('uuid/v4');

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    dropzoneContainer: {
        minHeight:"360px", width:"96%", backgroundColor:"#8fc088", padding:"20px", borderRadius:"6px"
    }
};

class PhotoUpload extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
        crop: {
            x: 0,
            y: 0,
            height: 100,
            width: 100
          },
          pixelCrop: {},
          imageURL: "",
          image: null,
          showUpload: true
      };

      this.imageLoaded = this.imageLoaded.bind(this);
      this.cropChanged = this.cropChanged.bind(this);
      this.cropComplete = this.cropComplete.bind(this);

      this.onFilesChange = this.onFilesChange.bind(this);
      this.onFilesError = this.onFilesError.bind(this);
      
      this.savePhoto = this.savePhoto.bind(this);
    }

    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);

      this.setState({showUpload:true});
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
      
      //reset
      this.setState({ image:null, pixelCrop:{}, imageURL: '', crop: { x: 0, y: 0, height: 100, width: 100 }});
    }

    imageLoaded(image, pixelCrop) {
        this.setState({ image, pixelCrop });
    }

    cropChanged(crop) {
        this.setState({ crop });  
    }

    cropComplete(crop, pixelCrop) {
        this.setState({ pixelCrop: crop });  
    }

    onFilesChange (files) {
        document.getElementById('divFileUpload').style.display = 'none';

        this.setState({ imageURL: files[0].preview.url, showUpload: false });
    }
     
    onFilesError (error, file) {
        console.log('onFilesError()');
        console.log(error);
        console.log('error code ' + error.code + ': ' + error.message)
    }

    savePhoto() {
        
        const canvas = document.createElement('canvas');
        canvas.width = this.state.pixelCrop.width;
        canvas.height = this.state.pixelCrop.height;
        const ctx = canvas.getContext('2d');
       
        ctx.drawImage(
          this.state.image,
          this.state.pixelCrop.x,
          this.state.pixelCrop.y,
          this.state.pixelCrop.width,
          this.state.pixelCrop.height,
          0,
          0,
          this.state.pixelCrop.width,
          this.state.pixelCrop.height
        );
        
        var uuid = uuidv4();
        var url = canvas.toDataURL('image/jpeg', 1.0);

        this.setState({result:url});

        fetch(url)
        .then(res => res.blob())
        .then(blob => {

            var fileName = uuid + ".jpg";
            
            storage.uploadImage(fileName, blob)
            .then(downloadUrl => {
                
                var fileUrl = encodeURIComponent(downloadUrl);
                var uploadURL = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/imageShrinkAndSave?url=" + fileUrl + "&id=" + uuid;
            
                fetch(uploadURL, {
                    method: "GET", 
                    cache: "no-cache"
                })
                .then((response) => {
                    if(response.ok) {
                        console.log('complete')
                    } else {
                        console.log(response.statusText);
                    }
                })
                .catch((error) => {
                    // If there is any error you will catch them here
                    console.log("error: " + error);
                });
                
                this.props.onUploadSuccess(uuid, downloadUrl);
                this.handleClose("modal");
            });
        });
    }

    showUpload() {
        if(this.state.showUpload)
            return "block";
        else
            return "none";
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <IconButton
                    aria-label="Add a New Photo"
                    onClick={() => this.handleClickOpen("modal")}>
                    <AddPhoto className={ classes.tableActionButtonIcon + " " + classes.edit } />
                </IconButton>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    maxWidth="md"
                    fullWidth
                    open={this.state.modal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        style={{backgroundColor:"#efefef"}} >
                        <h4 className={classes.modalTitle}>Upload Photo</h4>
                    
                    </DialogTitle>
                    <DialogContent>
                        <ReactCrop 
                            src={this.state.imageURL}
                            onImageLoaded={this.imageLoaded}
                            crop={this.state.crop}
                            onChange={this.cropChanged}
                            onComplete={this.cropComplete}
                            />

                        <img src={this.state.result} alt="preview" />

                        <div id="divFileUpload" className="files" style={{display:this.showUpload()}} >
                            <Files
                                className='files-dropzone'
                                onChange={this.onFilesChange}
                                onError={this.onFilesError}
                                accepts={['image/png', 'image/jpg', 'image/jpeg', 'image/svg']}
                                maxFiles={100}
                                maxFileSize={5000000}
                                minFileSize={0}
                                clickable
                                style={styles.dropzoneContainer}
                            >
                                Drop your photo here or click to upload
                            </Files>
                        </div>
                        
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter }
                        style={{backgroundColor:"#efefef"}}>
                        <Button onClick={() => this.handleClose("modal")}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.savePhoto();
                            }}
                            color="success">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(modalStyle)(PhotoUpload);
