//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ProfileCreated, ProfilesLoaded, ProfileUpdated } from 'redux/actions';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import ProfileEditor from "components/ProfileEditor/ProfileEditor.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class ProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: []
    }

    this.ProfileCreated = this.ProfileCreated.bind(this);
    this.ProfileUpdated = this.ProfileUpdated.bind(this);
    this.deleteProfile = this.deleteProfile.bind(this);
  }

  componentWillMount() {
    this.refreshProfiles();
  }

  refreshProfiles() {
      
    db.getProfiles().then(profiles => {
      this.setState({profiles: profiles});
      this.props.onProfilesLoaded(profiles); 
    });
  }

  ProfileUpdated(profile) {
    
    var profiles = this.state.profiles;
    for(var i = 0; i < profiles.length; i++) {
      if(profiles[i].id === profile.id) {
        profiles[i] = profile;
        break;
      }
    }
    profiles = profiles.sort(this.compareName);
    this.setState({profiles: profiles});
    this.props.onProfilesLoaded(profiles); 
  }

  ProfileCreated(profile) {
    
    var profiles = this.state.profiles;
    profiles.push(profile);
    profiles.sort(this.compareName);
    this.setState({profiles: profiles});
    this.props.onProfilesLoaded(profiles); 
  }

  deleteProfile(profile) {
    db.deleteProfile(profile.id).then(() => {
      var profiles = this.state.profiles;
      for(var i = 0; i < profiles.length; i++) {
        if(profiles[i].id === profile.id) {
          profiles.splice(i,1);
          break;
        }
      }

      profiles.sort(this.compareName);
      this.setState({profiles: profiles});
      this.props.onProfilesLoaded(profiles); 
    });
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  getTypeDescriptor(type) {
    if(type === 'visibility')
      return "Visibility";
    else if (type === 'pricing')
      return "Pricing";
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Customization Profiles</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} style={{textAlign:"right"}} >
                  <br />
                  <div style={{display:"inline-block", marginRight:"10px"}}>
                    <ProfileEditor title="Add Profile" type="visibility" onCreated={this.ProfileCreated} />
                  </div>
                  <div style={{display:"inline-block"}}>
                    <ProfileEditor title="Add Profile" type="pricing" onCreated={this.ProfileCreated} />
                  </div>
                </GridItem>
              <GridItem xs={12}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Type
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"23px"}}> 
                          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.profiles.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <ProfileEditor title="Edit Profile" profile={value} onChanged={this.ProfileUpdated}  />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.Name}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {this.getTypeDescriptor(value.type)}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Profile" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this profile? This action cannot be undone." confirmed={() => this.deleteProfile(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({onProfileCreated: ProfileCreated, 
    onProfilesLoaded: ProfilesLoaded,
    onProfileUpdated: ProfileUpdated }, dispatch);  
}

ProfileList = connect(mapStateToProps, mapDispatchToProps)(ProfileList);
export default withStyles(styles)(ProfileList);
