import React, { Component } from 'react'

//router
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import { db } from "assets/firebase";

import moment from 'moment';

import { 
  withStyles, createStyles,
  Table, TableHead, TableBody, TableCell, TableRow,
  Tooltip
 } from '@material-ui/core';

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import SpecialEditor from "components/SpecialEditor/SpecialEditor.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";


const styles = createStyles({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
})

const siteOptions = [
  {
    key: "all",
    label: "All"
  },
  {
    key: "customer-site",
    label: "Main Customer Site"
  },
  {
    key: "micro-site",
    label: "Microsite"
  }
]

class Specials extends Component {

  constructor() {
    super()
    this.state = {
      specials: [],
      pricingProfiles: [],
      loading: false,
      isReady: false,
    }

    this.loadPricingProfiles = this.loadPricingProfiles.bind(this);
    this.specialCreated = this.specialCreated.bind(this);
    this.specialUpdated = this.specialUpdated.bind(this);    
  }

  componentDidMount() {
    
    this.loadPricingProfiles().then(() => {
      this.refreshSpecialsList();
    });
  }

  loadPricingProfiles() {

    return db.getPricingProfiles().then(profiles => {
      
      var p = [];
      profiles.forEach(profile => {
          p.push( { label: profile.Name, key: profile.id })
      });
      
      this.setState({pricingProfiles:p})
    });
  }

  refreshSpecialsList = async () => {
    db.getSpecials().then(specials => {
      this.setState({specials: specials});
    });  
  }

  deleteSpecial(special) {

    db.deleteSpecial(special.id).then(() => {

      var specials = this.state.specials;
      
      for(var i = 0; i < specials.length; i++) {
        if(specials[i].id === special.id) {
          specials.splice(i,1);
          break;
        }
      }

      this.setState({specials: specials});      
    });
  }

  specialUpdated(special) {
    var specials = this.state.specials;
    for(var i = 0; i < specials.length; i++) {
      if(specials[i].id === special.id) {
        specials[i] = special;
        break;
      }
    }
    specials.sort(this.compareFrom);
    this.setState({specials: specials});
  }

  specialCreated(special) {
    var specials = this.state.specials;
    specials.push(special);
    specials.sort(this.compareFrom);
    this.setState({specials: specials});
  }

  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY")
  }

  matchProfile = (profileID) => {
    return this.state.pricingProfiles.find((profile)=>profile.key === profileID)
  }

  matchSite = (siteKey) => {
    return siteOptions.find((siteOption) => siteOption.key === siteKey)
  }

  compareFrom(a,b) {

    var x = a.from;
    var y = b.from;
  
    if (x < y)
      return -1;
    if (x > y)
      return 1;
    return 0;
  }  

  render() {

    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Specials List</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  <SpecialEditor
                    title="Add Special"
                    pricingProfiles={this.state.pricingProfiles}
                    siteOptions={siteOptions}
                    onCreated={this.specialCreated} 
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Name
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Profile
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Site
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        From
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        To
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"23px"}}> 
                          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.specials.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <SpecialEditor
                            title="Edit Special"
                            special={value}
                            pricingProfiles={this.state.pricingProfiles}
                            siteOptions={siteOptions}
                            onChanged={this.specialUpdated}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.name}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.profile ? this.matchProfile(value.profile).label : ""}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.site ? this.matchSite(value.site).label : ""}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {this.formatDate(value.from)}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {this.formatDate(value.to)}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Special" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this special? This action cannot be undone." confirmed={() => {this.deleteSpecial(value); }} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = state => state

export default withStyles(styles)(connect(mapStateToProps)(Specials))