//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

import Restore from "@material-ui/icons/RestoreFromTrash";
import backupFile from "data/2019.11.26-recurringOrders";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class BackupsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backups: []
    }
  }

  componentWillMount() {
    this.refreshBackups();
  }

  refreshBackups() {
    db.getBackups().then(backups => {
        this.setState({backups:backups});
    })
  }

  deleteBackup() {

  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }

  restoreRecurringOrders() {
    console.log('RESTORING RECURRING ORDERS');
    console.log('backup array length: ' + backupFile.length);

    db.getRecurringOrders().then(orders => {
      console.log('db array length:' + orders.length);

      var matchCount = 0;
      var changedCount = 0;

      for(var i = 0; i < backupFile.length; i++) {
        for(var j = 0; j < orders.length; j++) {
          if(backupFile[i].id === orders[j].id) {
            
            if(backupFile[i].ProcessedDate !== orders[j].ProcessedDate) {
              changedCount = changedCount + 1;
              var status = " no match (" + backupFile[i].ProcessedDate + " vs " + orders[j].ProcessedDate + ")";
              console.log( status );
              if(backupFile[i].ProcessedDate) { 
                orders[j].ProcessedDate = backupFile[i].ProcessedDate;
                db.updateRecurringOrder(orders[j].id, orders[j]);
              }
            }
            else 
              matchCount = matchCount + 1;
          }
        }
      }

      console.log('match count: ' + matchCount);
      console.log('changed count: ' + changedCount);

    });
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} style={{display:"none"}}>
          <IconButton onClick={this.restoreRecurringOrders}><Restore /></IconButton>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 className={classes.cardTitleWhite}>Backups</h4>
                  <p className={classes.cardCategoryWhite}>
                    This shows all of the database backups currently archived.
                  </p>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px"}}> 
                        Date / Time
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Size (b)
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"23px"}}> 
                          
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.backups.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.dateTimeStamp}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                        {Number(value.totalSize).toLocaleString()}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px", display:"none"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Backup" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this backup? This action cannot be undone." confirmed={() => this.deleteBackup(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}


BackupsPage = connect(mapStateToProps)(BackupsPage);
export default withStyles(styles)(BackupsPage);
