//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Inbox from "@material-ui/icons/Inbox";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function dateConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + date + ' ' + year;
  return time;
}
  
class BillDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      bill: { items: [] },
      order: { }
    };
  }

  handleClickOpen(modal) {

    // get bill details
    var bill = this.props.bill;

    db.getOrder(bill.orderID).then(order => {

        this.setState({order: order});
        console.log('order');
        console.log(order);

        for(var i = 0; i < bill.items.length; i++) {
            for(var j = 0; j < order.items.length; j++) {

                if(bill.items[i].sku.toString() === order.items[j].sku.toString()) {
                    
                    if(order.items[j].qtyShipped)
                        bill.items[i].qtyShipped = order.items[j].qtyShipped;
                    else 
                        bill.items[i].qtyShipped = 0;
                }
            }

            // get shipped quantities from db
            bill.items[i].cost = Number(bill.items[i].cost).toFixed(2);
            //bill.items[i].total = bill.items[i].qty * bill.items[i].cost;
        }
    
        this.setState({bill: bill});

        console.log('bill');
        console.log(bill)
    })

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  cancel() {
    this.handleClose("modal");
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  showZohoButton() {
    if( this.state.bill.status === 'RECEIVED' || this.state.billChanged) {
      return 'inline-block';
    } else {
      return 'none';
    }
  }

  showDeleteBill() {
    if( this.state.bill.status === 'RECEIVED' ) {
      return 'inline-block';
    } else {
      return 'none';
    }
  }

  deleteLineItem(id) {

    var x = this.state.bill;

    for(var i = 0; i < x.items.length; i++) {

      if(x.items[i].id === id) {
        x.items.splice(i,1);
        break;
      }    
    }

    this.setState({bill: x});
    //todo: recalculate?
  }

  updateLineItem(id, property, value) {
    var x = this.state.bill;

    for(var i = 0; i < x.items.length; i++) {

      if(x.items[i].id === id)
        x.items[i][property] = value;
      }

    this.setState({bill: x}, () => {
      //this.calculateTax();
    });
  }

  showSaveButton() {
    if(this.state.billChanged && !this.state.syncInProgress && !this.state.bill.invoiceID)
      return 'inline-block';
    else
      return 'none';
  }

  sendToZoho() { // in zoho

    // get Zoho PO ID
    var zohoPOID;
    for(var h = 0; h < this.state.order.purchaseOrders.length; h++) {
        if(this.state.order.purchaseOrders[h].vendorID === '7yOKSLSHr7AiyxmS4xa2') { // if it's office depot, this is short term
            zohoPOID = this.state.order.purchaseOrders[h].id;
            break;
        }
    }

    // get PO from Zoho
    var url = 'https://us-central1-kekaha-ebf1f.cloudfunctions.net/getZohoPurchaseOrder?id=' + zohoPOID;

    fetch(url, {
        method: "POST", 
        cache: "no-cache"
    })
    .then((response) => {
        if(response.ok) {
            return response.json()
        } else {
            console.log("create bill error1");  
            console.log(response);
            //reject('error');
        }
    })
    .then((json) => {

        var purchaseOrder = json.purchaseorder;

        //console.log('purchaseOrder:');
        //console.log(purchaseOrder);
        //console.log('bill:');
        //console.log(this.state.bill);

        var lineItems = [];

        for(var i = 0; i < this.state.bill.items.length; i++) {

            var item;
            
            for(var j = 0; j < this.state.order.items.length; j++) {

                if(this.state.order.items[j].sku.toString() === this.state.bill.items[i].sku.toString()) {
                    
                    for(var k = 0; k < purchaseOrder.line_items.length; k++) {
                        
                        //console.log("a:" + purchaseOrder.line_items[k].name);
                        //console.log("b:" + this.state.order.items[j].productID);

                        if(purchaseOrder.line_items[k].name === this.state.order.items[j].productID ) { // just sku 
                             
                            item = purchaseOrder.line_items[k];
                            break;
                        }
                    }
                }
            }
            
            if(item) {
                lineItems.push({ 
                    "quantity": this.state.bill.items[i].qty,
                    "rate": this.state.bill.items[i].cost,
                    "name": item.description,
                    "item_id": item.item_id
                })
            }
        }

        var data = {
            "vendor_id" : "1343284000000063184", //hard-coded Office Depot short term
            "is_update_customer": false,
            "due_date" : this.state.bill.dueDate,
            "bill_number": this.state.bill.invoiceID,
            "line_items" : lineItems,
            //"purchaseorder_id" : [ purchaseOrder.purchaseorder_id ]
            "reference_number": this.state.order.poNumber
        }

        console.log(data);
    
        if(zohoPOID)
            data.purchaseorder_ids = [ zohoPOID ];
    
        url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/createBill";

        fetch(url, {
            method: "POST", 
            cache: "no-cache",
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
            } else {
                console.log("create bill error1");  
                console.log(response);
                //reject('error');
            }
        })
        .then((json) => {
            console.log('json');
            console.log(json);

            var bill = this.state.bill;

            bill.status = "APPROVED";

              db.updateBill(bill.id, bill).then(() => {
                this.props.billChanged(bill);
                this.setState({bill:bill, syncInProgress: false, billChanged: false});
                //this.getBaselineOrder();
              });
        });
        
    })
    .catch((error) => {
        // If there is any error you will catch them here
        console.log("error getting PO from Zoho:  " + error);
    });


    

    

    
    /*  
    if(zohoID) {
        url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/updatePurchaseOrder";
        poData.id = zohoID;
    }
    */

    
    
/*
        console.log('json');
        console.log(json);

        var zohoBillID = json.bill.bill_number;
        var zohoID;

        //debug 
        //vendor.NotificationEmail = 'michael@spiffyventures.com';

        var bill = this.state.bill;
        
        var text = "You have a new Bill From Office Depot";
        if(zohoID)
            text = "You have an UPDATED Bill from Office Depot!";
        
        var html = '<html>';
        
        if(zohoID)
            html = html + 'You have an UPDATED Bill from Office Depot!';
        else 
            html = html + 'You have a new Bill from Office Depot!';

        html = html + '<br /><br />';
        
        html = html + '<b>Bill Number</b><br />';
        html = html + zohoBillID;

        html = html + '<br /><br />';
        html = html + '<b>Order ID</b><br />';
        html = html + bill.orderID;

        html = html + '<br /><br />';

        html = html + 'Thank you!';
        html = html + '<br /><br />';
        html = html + '- The Ecclesia Team';
        html = html + '</html>';

        var subject = "Purchase Order - Ecclesia ";
        
        // email
        const emailJson = {
            to: 'michael@spiffyventures.com',
            bcc: 'michael@spiffyventures.com',
            subject: subject,
            text: text,
            html: html
        }

        fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendEmail", {
            method: "POST", 
            cache: "no-cache",
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify(emailJson)
        })
        .then((response) => {
            
            if(!response.ok) {
                console.log("error (1): ");  
                console.log(response);
            } else {
                console.log('new bill notification email SENT');
            }
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error sending bill notification email:  " + error);
        });
*/
    
  }

  saveBill() {

  }

  showActionButtons() {
    if(!this.state.syncInProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  showSyncProgressIndicator() {
    if(this.state.syncInProgress) {
      return "block";
    } else {
      return "none";
    }
  }

  sendToOfficeDepot() {
    this.setState({syncInProgress: true}, () => {
        var bill = this.state.bill;
        console.log(bill);

        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/sendBillToOfficeDepot";
        
        fetch(url, {
        method: "POST", 
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(bill)
        })
        .then((response) => {
            if(response.ok) {
            bill.status = "SENT TO VENDOR";

            db.updateBill(bill.id, bill).then(() => {
                this.props.billChanged(bill);
                this.setState({bill:bill, syncInProgress: false, billChanged: false});
                //this.getBaselineOrder();
            });
        
            } else {
            console.log("error1");  
            console.log(response);
            }
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error2: " + error);
        });
    });
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        
        <IconButton
          aria-label="Edit"
          onClick={() => this.handleClickOpen("modal")}>
          <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
        </IconButton>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          maxWidth="md"
          fullWidth
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
                style={{backgroundColor:"#efefef"}} >

                <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={6}>
                    <h4 className={classes.modalTitle}>Bill Details</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                    
                </GridItem>
                </GridContainer>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
                style={{backgroundColor:"#efefef"}}>
                    <CustomTabs
                        headerColor="success"
                        tabs={[
                            {
                            tabName: "General",
                            tabIcon: Inbox,
                            tabContent: (
                                
                                <GridContainer direction="row" style={{minHeight:"369px"}}>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Invoice</b><div style={{fontWeight:400}}>
                                            {this.state.bill.invoiceID}<br />
                                            <span style={{color:"#3fa31b"}}>{dateConverter(this.state.bill.created)}</span><br />
                                            {this.state.bill.status}<br />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Order</b><div style={{fontWeight:400}}>
                                            {this.state.order.id}<br />
                                            <span style={{color:"#3fa31b"}}>{this.state.order.orderPlaced}</span>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <br />
                                        <b>Customer</b><div style={{fontWeight:400}}>
                                            {this.state.order.customerName}<br />
                                            <span style={{color:"#3fa31b"}}>{this.state.order.userName}</span><br />
                                            <span>{this.state.order.poNumber} </span>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={3}>

                                    </GridItem>
                                    <GridItem xs={12}>
                                        <br />
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{padding:"0px", width:"40px"}}></TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        Product ID
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        Name
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Qty
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Shipped
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Price
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        Total
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.bill.items.map((value, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{padding:"0px", width:"40px"}}>
                                                    <Tooltip id="tooltip-top-start" title="Delete Line Item" placement="top" classes={{ tooltip: classes.tooltip }} >
                                                        <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this product? This action cannot be undone." confirmed={() => this.deleteLineItem(value.id)} />
                                                    </Tooltip>
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {value.productID}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        {this.trim(value.description,54)}
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                        <input type="text" value={value.qty} placeholder="qty" 
                                                            onChange={(e) => this.updateLineItem(value.id, "qty", e.target.value)} 
                                                            className={classes.modalTextBox} style={{width:"37px"}} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        <input type="text" value={value.qtyShipped} placeholder="qty shipped" 
                                                            onChange={(e) => this.updateLineItem(value.id, "qtyShipped", e.target.value)} 
                                                            className={classes.modalTextBox} style={{width:"66px"}} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:"right" }}>
                                                        <input type="text" value={value.cost} placeholder="cost" 
                                                            onChange={(e) => this.updateLineItem(value.id, "cost", e.target.value)} 
                                                            className={classes.modalTextBox} style={{width:"66px"}} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        ${value.subTotal}
                                                    </TableCell>
                                                </TableRow>
                                                ))}
                                                {/*}
                                                <TableRow>
                                                    <TableCell style={{padding:"0px", width:"50px"}}>
                                                        <IconButton onClick={this.addLineItem} style={{color:"green"}}>
                                                            <Add />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px"}}>
                                                        <input type="text" placeholder="Product ID" className={classes.modalTextBox} style={{textAlign:"left"}}
                                                            onChange={(e) => this.setSearchProductIDsTimer(e.target.value)} value={this.state.productIDQuery} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", position: "relative"}}>
                                                        <input type="text" placeholder="Product Name" className={classes.modalTextBox} style={{textAlign:"left"}} 
                                                            onChange={(e) => this.setSearchProductNamesTimer(e.target.value)} value={this.state.productNameQuery} />
                                                        <div id="divSearchResults" style={{position: "absolute", height:"120px", width:"100%", backgroundColor:"#efefef", borderRadius:"6px", marginLeft:"6px", overflow:"auto", padding:"10px", fontSize:"11px", border:"solid 1px green", display:this.showSearchResults()}} >
                                                            {this.state.searchResults.map((value, index) => (
                                                            <div key={index} style={{cursor:"pointer", color:"232323"}} 
                                                                onClick={() => this.addProduct(value)}><span style={{color:"green"}}>{value.productID}</span> - {this.trim(value.name,54)}</div>
                                                            ))}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell style={{padding:"6px", width:"50px" }}>
                                                        <input type="text" placeholder="Qty" className={classes.modalTextBox} style={{display:"none"}} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"6px", width:"80px" }}>
                                                        <input type="text" placeholder="Price" className={classes.modalTextBox} style={{display:"none"}} />
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        $0.00
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{padding:"0px", width:"50px"}}>
                                                        <IconButton onClick={this.addNew} style={{display:this.showAddNewButton()}}>
                                                            <Add />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{display:this.showVendors()}}></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        <b>Tax</b>
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        <b>${this.state.tax.toFixed(2)}</b>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{display:this.showVendors()}}></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        <b>Total</b>
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:'right'}}>
                                                        <b>${this.state.bill.orderTotal}</b>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{display:this.showVendors()}}></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                    <div title={this.getAffiliateName()} style={{color:'maroon', cursor:"pointer"}}>
                                                        Commission
                                                    </div>
                                                    </TableCell>
                                                    <TableCell style={{padding:"3px", textAlign:"right"}}>
                                                    <div title={this.getAffiliateName()} style={{color:'maroon', cursor:"pointer"}}>
                                                        ${this.getCommission()}
                                                    </div>
                                                    </TableCell>
                                                </TableRow>
                                                */}
                                            </TableBody>
                                        </Table>
                                    </GridItem>
                                </GridContainer>
                            )
                            }
                        ]}
                    />
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter }
                    style={{backgroundColor:"#efefef"}}>

                <GridContainer>
                    <GridItem xs={2} style={{textAlign:"left"}}>
                    <div style={{display:this.showDeleteBill(), textAlign:"left"}}>
                        <Tooltip id="tooltip-top-start" title="Delete Bill" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this bill? This action cannot be undone." confirmed={() => this.deleteBill()} />
                        </Tooltip>
                    </div>
                    </GridItem>
                    <GridItem xs={10}>
                    <CircularProgress className={classes.progress} color="secondary" 
                        style={{display:this.showSyncProgressIndicator(), marginRight:"23px"}} />

                    <div style={{display:this.showActionButtons()}}>
                        <Button onClick={() => this.cancel() } >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button style={{display:this.showSaveButton()}}
                            onClick={() => { this.saveBill(); }}
                            color="success">
                            Save
                        </Button>
                        &nbsp;
                        <Button style={{display:this.showZohoButton()}}
                            onClick={() => { this.sendToZoho(); }}
                            color="success">
                        Send to Zoho
                        </Button>
                    </div>
                    </GridItem>
                </GridContainer>
            </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

BillDetails = connect(mapStateToProps)(BillDetails);
export default withStyles(modalStyle)(BillDetails);