//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from '@material-ui/core/CircularProgress';

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class InvoicesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: true,
      moreRows: false
    }

    this.deleteInvoice = this.deleteInvoice.bind(this);
    this.loadMore = this.loadMore.bind(this);

    console.log(props)
  }

  componentWillMount() {
    this.refreshInvoices();
  }

  refreshInvoices() {
      
    db.getInvoices().then(invoices => {
    
        invoices = this.addCustomerNames(invoices);

        this.setState({invoices: invoices, isLoading: false});
        if(invoices.length === 50)
            this.setState({moreRows: true});
    });
  }

  loadMore() {
    var invoices = this.state.invoices;

    var index = invoices.length - 1;
    var startAt = invoices[index].invoice_id;
    db.getInvoices(startAt).then(newSet => {

        newSet = this.addCustomerNames(newSet);

        for(var i = 0; i < newSet.length; i++) 
            invoices.push(newSet[i]);
        
        this.setState({invoices: invoices});
        if(newSet.length < 50)
            this.setState({moreRows: false});
    });
}

addCustomerNames(invoices) {
    for(var i = 0; i < invoices.length; i++) {
        for(var j = 0; j < this.props.customers.length; j++) {
            if(invoices[i].customer_id === this.props.customers[j].id) {
                invoices[i].companyName = this.props.customers[j].company_name;
                break;
            }
        }
    }
    return invoices;
}

  deleteInvoice(invoice) {
    db.deleteInvoice(invoice.id).then(() => {
      var invoices = this.state.invoices;
      for(var i = 0; i < invoices.length; i++) {
        if(invoices[i].id === invoice.id) {
          invoices.splice(i,1);
          break;
        }
      }

      this.setState({invoices: invoices}); 
    });
  }

  trim(value, maxLength) {
    if(value) {
        if(value.length > maxLength)
            return value.substring(0,maxLength) + "...";
        else
            return value;
    } else return "";
  }

  getStatusDescriptor(value) {
    if(value.toUpperCase() === 'OVERDUE') {
        return <div style={{color:"#ef5350"}}>PAST DUE</div>
    } else if (value.toUpperCase()=== 'SENT') {
        return <div style={{color:"green"}}>OPEN</div>
    } else if (value.toUpperCase()=== 'PAID') {
        return <div style={{color:"green"}}>PAID</div>
    }
  }

  getDueDateColor(value) {
    if(value.toUpperCase() === 'OVERDUE') {
        return "#ef5350";
    } else {
        return "black";
    }
  }

  showLoading() {
    if(this.state.isLoading) {
        return "block";
    } else {
        return "none";
    }
  }

  showLoadMore() {
    if(this.state.moreRows) {
        return "block";
    } else {
        return "none";
    }
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Invoices</h4>
                  <p className={classes.cardCategoryWhite}>
                    
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{textAlign:"right"}}>
                  
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <GridItem xs={12}>
                <br />
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{padding:"6px", width:"23px"}}> 

                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Date
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Number
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Customer
                      </TableCell>
                      <TableCell style={{padding:"6px"}}> 
                        Status
                      </TableCell>
                      <TableCell style={{padding:"6px", textAlign:'right'}}> 
                        Total
                      </TableCell>
                      <TableCell style={{padding:"6px", width:"17px"}}> 
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.invoices.map(value => (
                      <TableRow key={value.id} className={classes.tableRow}>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                          
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px", color:this.getDueDateColor(value.status)}}> 
                          {value.date}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.invoice_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}}> 
                          {value.companyName}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px"}} > 
                            {this.getStatusDescriptor(value.status)}
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{padding:"6px", textAlign:'right'}}> 
                            ${numberWithCommas(value.total.toFixed(2))}
                        </TableCell>
                        <TableCell className={classes.tableActions} style={{padding:"6px", width:"17px"}}> 
                          <Tooltip id="tooltip-top-start" title="Delete Invoice" placement="top" classes={{ tooltip: classes.tooltip }} >
                            <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this invoice? This action cannot be undone." confirmed={() => this.deleteInvoice(value)} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
              <GridItem xs={12}>
                <div onClick={this.loadMore} style={{color:"green", textAlign:"center", width:"100%", padding:"23px", cursor:"pointer", display:this.showLoadMore()}}>
                    Load More
                </div>
                <div style={{display:this.showLoading(), textAlign:"center", marginTop:"89px", marginBottom:"89px"}}>
                    <CircularProgress />
                </div>
              </GridItem>
            </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

InvoicesList = connect(mapStateToProps)(InvoicesList);
export default withStyles(styles)(InvoicesList);
