


export const createZohoContact = (contactID, user) => {
    
    return new Promise(function(resolve, reject) {
    
        var data = {
            "contact_id": contactID,
            "first_name" : user.FirstName,
            "last_name" : user.LastName,
            "email" : user.Email,
            "enable_portal": false,
            "designation": "Ecclesia Rep"
        }

        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/createCustomerContact";

        fetch(url, {
            method: "POST", 
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
            } else {
                console.log("error1");  
                console.log(response);
            }
        })
        .then((json) => {
            
            if(json) {  
                resolve(json.contact_person.contact_person_id);
            }
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error creating contact in zoho: " + error);
            reject(error);
        });
    });
}

export const deleteZohoContact = (contactID) => {
    
    return new Promise(function(resolve, reject) {
    
        var data = {
            "contact_id": contactID
        }

        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/deleteCustomerContact";

        fetch(url, {
            method: "POST", 
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if(response.ok) {
                resolve();
            } else {
                console.log("error1");  
                console.log(response);
                reject();
            }
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error upserting product in zoho: " + error);
            reject(error);
        });
    });
}

export const syncContacts = () => {

    return new Promise(function(resolve, reject) {
    
        
        var url = "https://us-central1-kekaha-ebf1f.cloudfunctions.net/syncZohoCustomerContacts";

        fetch(url, {
            method: "GET", 
            cache: "no-cache"
        })
        .then((response) => {
            if(response.ok) {
                console.log('zoho contacts refreshed');
                resolve();
            } else {
                console.log("error1");  
                console.log(response);
                reject();
            }
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error syncing contacts from zoho: " + error);
            reject(error);
        });
    });
}