//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/Select/Select.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

//spiffy
import ContentEditor from "components/ContentEditor/ContentEditor.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Task from "@material-ui/icons/AssignmentTurnedIn";
import List from "@material-ui/icons/List";
import PinOn from "@material-ui/icons/Report";
import PinOff from "@material-ui/icons/ReportOff";

// dates
import moment from 'moment';

//google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-54225103-2');


function Transition(props) {
    return <Slide direction="down" {...props} />;
  }
  
  class TaskEditor extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        task: props.task,
        projects:[],
        people:[],
        color:"#cdcdcd"
      };

      this.loadPeople = this.loadPeople.bind(this);
      this.loadProjects = this.loadProjects.bind(this);
      this.saveTask = this.saveTask.bind(this);
      this.togglePin = this.togglePin.bind(this);
      this.updateTask = this.updateTask.bind(this);
      this.newPerson = this.newPerson.bind(this);
      this.updatedPerson = this.updatedPerson.bind(this);
    }

    componentDidUpdate(prevProps) {
      
      if(prevProps.task !== this.props.task) {
        this.setState({task: this.props.task});
      }
    }

    handleClickOpen(modal) {      
      this.loadPeople();
      this.loadProjects();
      
      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    formatDate = (date) => {
      return moment(date).format("YYYY-MM-DD");
    }

    loadProjects() {
        var projects = [];
        //projects.push({key:"all", label:"All"});
        /*
        for(var i = 0; i < this.props.projects.length; i++) {
          if(this.props.projects[i].IsActive)
            projects.push({key:this.props.projects[i].id, label:this.props.projects[i].Name });
        }
        */
        projects.push({key:"general", label: "General"});
        this.setState({projects:projects});
      }

    loadPeople() {
      var people = [];
      
      /*
      // find the right project
      for(var j = 0; j < this.props.projects.length; j++) {
        
        if(this.props.projects[j].id === this.state.task.ProjectID) {
          
          // find the people in the project
          for(var k = 0; k < this.props.projects[j].People.length; k++) {
            for(var i = 0; i < this.props.people.length; i++) {
             // console.log(this.props.people[i]);
              if(this.props.projects[j].People[k].PersonID === this.props.people[i].id) {
                
                people.push({ key: this.props.people[i].id, label: this.props.people[i].FirstName + " " + this.props.people[i].LastName });
                break;
              }
            }
          }
        }
      }
      */

     people.push({ key: 17, label: 'Michael' });
     people.push({ key: 23, label: 'Bo' });

      people.sort(this.byLabel);
      this.setState({people:people});
    }

    byLabel(a,b) {

      var valueA = a.label;
      var valueB = b.label;
    
      if (valueA < valueB)
        return -1;
      if (valueA > valueB)
        return 1;
      return 0;
    }

    updateTask(property, value) {
      var obj = this.state.task;
      obj[property] = value;
      this.setState({task: obj});

      if(property === 'ProjectID') {
        this.loadPeople();
        console.log('refreshing people')
      }
    }

    saveTask() {
    
      var task = this.state.task;
      if(!task.created)
        task.created = Date.now();
      task.modified = Date.now();
      if(task.Status === 'complete' && !task.completed)
        task.completed = Date.now();


      db.updateTask(task.id, task).then(doc => {
        
        //finally...
        if(!task.id) { // i.e. if it's a new task

          task.id = doc.id;
          this.props.onCreate(task);

          ReactGA.event({
            category: 'Task',
            action: 'Created a New Task'
          });

          // reset to blank
          this.setState(
            {task:{
              Name: "",
              Description: "",
              Status: "open",
              Category: "",
              Owner: "",
              DueDate: "",
              ProjectID: this.props.ProjectID,
              Priority:"",
              Impact:"",
              Details:"",
              ExpirationDate:"",
              SnoozeDate: "",
              ReminderDate:"",
              Size: "",
              People:[],
            }
          });

        } else { // i.e. an existing school

          this.props.onChange(task);

          ReactGA.event({
            category: 'Task',
            action: 'Updated a Task'
          });
        }
      });
    }

    newPerson(personRole) {
      var task = this.state.task;
      task.People.push(personRole);
      this.setState({task:task});
    }

    updatedPerson(personRole) {
      var task = this.state.task;
      for(var i = 0; i < task.People.length; i++) {
        if(task.People[i].PersonID === personRole.PersonID) {
          task.People[i] = personRole;
          break;
        }
      }
      this.setState({task:task});
    }
        
    trim(value, maxLength) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    }

    showIcon() {
      if(!this.props.showCard &!this.props.showButton) {
        return "block";
      } else {
        return "none";
      }
    }

    showButton() {
      if(this.props.showButton) {
        return "block";
      } else {
        return "none";
      }
    }

    showCard() {
      if(this.props.showCard) {
        return "block";
      } else {
        return "none";
      }
    }

    getPinIcon() {
      if(this.state.task.Pinned) 
        return <PinOn style={{color:"maroon"}} />;
      else
        return <PinOff style={{color:"silver"}} />;
    }

    togglePin() {
      if(this.state.task.Pinned) 
        this.updateTask("Pinned", false);
      else 
        this.updateTask("Pinned", true);
    }

    getCardHeaderText() {
      if(this.props.header === "dueDate")
        return "DUE";
      else if (this.props.header === "reminderDate")
        return "REMINDER";
      else if(this.props.header === "pinned")
        return "PINNED";
      else 
        return "";
        
    }
 
    render() {

      const { classes } = this.props;


      return (
        <div>
          
          <Button
            aria-label="Edit" color="success"
            style={{display:this.showButton()}}
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } /> New Task
          </Button>

          <IconButton
            aria-label="Edit" 
            style={{display:this.showIcon()}}
            onClick={() => this.handleClickOpen("modal")}>
            <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } /> 
          </IconButton>
          
          <Card 
                style={{minHeight:"89px", cursor:"pointer", marginTop:"0px", display:this.showCard()}} 
                onClick={() => this.handleClickOpen("modal")}>
            <CardHeader style={{backgroundColor:this.state.color, color:"#ffffff", fontSize:"11px", fontWeight:600, padding:"3px", paddingLeft:"10px"}}>
              <GridContainer>
                <GridItem xs={6}>
                  {this.getCardHeaderText()}
                </GridItem>
                <GridItem xs={6} style={{textAlign:"right"}}>
                  {this.state.task.ReminderDate}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <div>{this.state.task.Name}</div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={6}>
                  <h4 className={classes.modalTitle}>Edit Task</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                  
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
             <CustomTabs
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "General",
                    tabIcon: Task,
                    tabContent: (
                        <GridContainer direction="row" style={{paddingBottom:"21px"}}>

                          <GridItem xs={12} >
                            <CustomInput
                              labelText="Name"
                              value={this.state.task.Name}
                              inputProps={{ placeholder: "Name" }}
                              formControlProps={{ fullWidth: true }}
                              onChange={(e) => this.updateTask("Name", e.target.value)}
                            />
                          </GridItem>
                          
                          <GridItem xs={12} sm={6} md={4}>
                            <CustomSelect
                                labelText="Project"
                                value={this.state.task.ProjectID}
                                inputProps={{
                                    placeholder: "Project"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateTask("ProjectID", e.target.value)}
                                options={this.state.projects}
                               />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={4}>
                            <CustomSelect
                                labelText="Category"
                                value={this.state.task.Category}
                                inputProps={{
                                    placeholder: "Category"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={(e) => this.updateTask("Category", e.target.value)}
                                options={[
                                  {key:"admin",label:"Admin"},
                                  {key:"bugs",label:"Bugs"},
                                  {key:"content",label:"Content"},
                                  {key:"features",label:"Features"},
                                  {key:"legal",label:"Legal"},
                                  {key:"maintenance",label:"Maintenance"},
                                  {key:"marketing",label:"Marketing"},
                                  {key:"other",label:"Other"},
                                  {key:"sales",label:"Sales"},
                                ]}
                               />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <CustomSelect
                                labelText="Owner"
                                value={this.state.task.Owner}
                                inputProps={{ placeholder: "Owner" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.updateTask("Owner", e.target.value)}
                                options={this.state.people}
                               />
                          </GridItem>
                          
                          <GridItem xs={12} sm={3} >
                            <CustomSelect
                                labelText="Status"
                                value={this.state.task.Status}
                                inputProps={{ placeholder: "Status" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.updateTask("Status", e.target.value)}
                                options={[
                                  {key:"open",label:"Open"},
                                  {key:"inProgress",label:"In Progress"},
                                  {key:"cancelled",label:"Cancelled"},
                                  {key:"complete",label:"Complete"}
                                ]}
                               />
                          </GridItem>
                          <GridItem xs={12} sm={3} >
                            <CustomSelect
                                labelText="Size"
                                value={this.state.task.Size}
                                inputProps={{ placeholder: "Size" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.updateTask("Size", e.target.value)}
                                options={[
                                  {key:"xs",label:"x-Small"},
                                  {key:"s",label:"Small"},
                                  {key:"m",label:"Medium"},
                                  {key:"l",label:"Large"},
                                  {key:"xl",label:"X-Large"},
                                  {key:"xxl",label:"XX-Large"}
                                ]}
                               />
                          </GridItem>
                          <GridItem xs={6} sm={3} >
                            <CustomSelect
                                labelText="Urgency"
                                value={this.state.task.Priority}
                                inputProps={{ placeholder: "Urgency" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.updateTask("Priority", e.target.value)}
                                options={[
                                  {key:"1",label:"Low"},
                                  {key:"2",label:"Medium"},
                                  {key:"3",label:"High"},
                                  {key:"4",label:"Critical"}
                                ]}
                               />
                          </GridItem>
                          <GridItem xs={6} sm={3} >
                            <CustomSelect
                                labelText="Impact"
                                value={this.state.task.Impact}
                                inputProps={{ placeholder: "Impact" }}
                                formControlProps={{ fullWidth: true }}
                                onChange={(e) => this.updateTask("Impact", e.target.value)}
                                options={[
                                  {key:"1",label:"Low"},
                                  {key:"2",label:"Medium"},
                                  {key:"4",label:"High"},
                                  {key:"5",label:"Critical"}
                                ]}
                               />
                          </GridItem>

                          <GridItem xs={12} sm={3} >
                            <div style={{marginTop:"27px"}}>
                              <InputLabel style={{fontSize:"11px", color:"#aaaaaa"}}
                                className={classes.labelRoot}>
                                Due Date
                              </InputLabel>
                              <TextField type="date" value={this.formatDate(this.state.task.DueDate)}  onChange={(e)=>this.updateTask("DueDate", e.target.value)} style={{width:"100%"}} />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={3} >
                            <div style={{marginTop:"27px"}}>
                              <InputLabel style={{fontSize:"11px", color:"#aaaaaa"}}
                                className={classes.labelRoot}>
                                Reminder Date
                              </InputLabel>
                              <TextField type="date" value={this.formatDate(this.state.task.ReminderDate)} onChange={(e)=>this.updateTask("ReminderDate", e.target.value)} style={{width:"100%"}}  />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={3} >
                            <div style={{marginTop:"27px"}}>
                              <InputLabel style={{fontSize:"11px", color:"#aaaaaa"}}
                                className={classes.labelRoot}>
                                Snooze Until
                              </InputLabel>
                              <TextField type="date" value={this.formatDate(this.state.task.SnoozeDate)} onChange={(e)=>this.updateTask("SnoozeDate", e.target.value)} style={{width:"100%"}}  />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={3} >
                            <div style={{marginTop:"27px"}}>
                              <InputLabel style={{fontSize:"11px", color:"#aaaaaa"}}
                                className={classes.labelRoot}>
                                Expire On
                              </InputLabel>
                              <TextField type="date" value={this.formatDate(this.state.task.ExpirationDate)} onChange={(e)=>this.updateTask("ExpirationDate", e.target.value)} style={{width:"100%"}}  />
                            </div>
                          </GridItem>
                        </GridContainer>
                    )
                  },
                  {
                    tabName: "Details",
                    tabIcon: List,
                    tabContent: (
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={12} md={12} style={{minHeight:"400px"}} >
                          <ContentEditor 
                            value={this.state.task.Details}
                            onChange={(e) => this.updateTask("Details", e)}
                          />
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  
                ]}
              />

            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
                <GridContainer style={{width:"100%"}}>
                  <GridItem xs={4} style={{textAlign:"left"}}>
                    <IconButton onClick={this.togglePin}>
                      {this.getPinIcon()}
                    </IconButton>
                  </GridItem> 
                  <GridItem xs={8}>
                    <Button onClick={() => this.handleClose("modal")}>
                      Cancel
                    </Button>
                    &nbsp;
                    <Button onClick={() => {
                          this.saveTask();
                          this.handleClose("modal");
                        }} color="success">
                      Save
                    </Button>
                  </GridItem> 
                </GridContainer>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

TaskEditor = connect(mapStateToProps)(TaskEditor);
export default withStyles(modalStyle)(TaskEditor);