//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";

//icons
import Import from "@material-ui/icons/ImportExport";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class PhotosImporter extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
        photos: []
      };

      this.importJSON = this.importJSON.bind(this);
      this.importPhotos = this.importPhotos.bind(this);
    }

    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);
    }
    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }
    
    importJSON(json) {
        var photos = JSON.parse(json);
        this.setState({photos});
    }

    importPhotos() {
        for(var i = 0; i < this.state.photos.length; i++ ) {
            console.log('saving #' + i);
            db.updatePhoto(this.state.photos[i].id, this.state.photos[i]);
        }
        this.handleClose("modal");
        this.props.onImportComplete();
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <IconButton
                    aria-label="Import Photos"
                    onClick={() => this.handleClickOpen("modal")}>
                    <Import
                        className={
                            classes.tableActionButtonIcon + " " + classes.edit
                        }
                    />
                </IconButton>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    maxWidth="md"
                    fullWidth
                    open={this.state.modal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        style={{backgroundColor:"#efefef"}} >
                        <h4 className={classes.modalTitle}>Import Photos</h4>
                    
                    </DialogTitle>
                    <DialogContent>
                      
                        <GridContainer direction="row">
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              id="json"
                              labelText="Paste your JSON here."
                              inputProps={{
                                  placeholder: ""
                              }}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={(e) => this.importJSON(e.target.value)}
                              style={{minHeight:"200px"}}
                            />
                          </GridItem>
                        </GridContainer>
                        
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter }
                        style={{backgroundColor:"#efefef"}}>
                        <Button onClick={() => this.handleClose("modal")}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                this.importPhotos();
                            }}
                            color="success">
                            Import
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(modalStyle)(PhotosImporter);
