//react
import React from 'react';

// 3rd party editor
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import FroalaEditor from 'react-froala-wysiwyg';

class ContentEditor extends React.Component{
    constructor(props) {
      super(props);

      this.state = {
        model: props.value
      };

      this.handleModelChange = this.handleModelChange.bind(this);
    }

    componentDidUpdate(prevProps) {
       
      if(prevProps.value !== this.props.value) {
        
          this.setState({model: this.props.value}, () => {
        
          });
      }
    }

    handleModelChange (updatedModel) {
        
        this.setState({
          model: updatedModel
        });

        this.props.onChange(updatedModel);
      }

    render() {

      let FroalaConfig = {
        placeholderText: '',
        heightMin: 289,
        width: 840,
        //key: '1D4B2A6B5E5A4gC3E3G3C2B7A4D4F4D2B2zfldceF6B-9C-8fvwkeqB-11bbjI-8iA4D-16rs=='
        //key: 'FB3E1G1G1A5A4wB1B1B1F1E1E4H4B2A9D8jvG-10tsuA5nnvfgH-9UakrrzlyE4obc=='
        //key: 'cJC7bC5D2G2D2H2A1ygkdD-11bbbjA-21jaD1qG-10yiB3B9B6B5C2C4C3H3G3B2=='
        key: 'wA5A6D6F4hB3D3C10D7C3B4A4F3G3G3cebaiekH4dgd==',
      }

        return (
          <FroalaEditor
            model={this.state.model}
            onModelChange={this.handleModelChange}
            config={FroalaConfig}
          />
        );
    }
}


export default ContentEditor;
