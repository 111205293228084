//react
import React from 'react';

//pdf viewer
import { Document, Page, pdfjs } from 'react-pdf'

//core
import IconButton from "@material-ui/core/IconButton";

//icons
import Download from "@material-ui/icons/CloudDownload";

class PDFViewer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      pdf: { data: props.pdf },
      numPages: 0
    };

    this.downloadFile = this.downloadFile.bind(this);
  }

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  getPages() {
    var pages = [];

    for (var i = 0; i < this.state.numPages; i++) {
      pages.push(<Page key={"pdfPage" + i} pageNumber={i + 1} />)
    }

    return pages;
  }

  downloadFile() {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([this.props.pdf], { type: "application/pdf" })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = this.props.fileName;
    link.click();
  }


  render() {

    return (
      <div>
        <div style={{ fontColor: "green", fontSize: "13px" }}>
          {this.props.fileName}
          <IconButton onClick={this.downloadFile}>
            <Download />
          </IconButton>
        </div>
        <div style={{ overflow: "auto", borderColor: "#696969", borderStyle: "solid", borderWidth: "1px", borderRadius: "5px", marginBottom: "17px" }}>
          <Document file={this.state.pdf}
            onLoadError={(e) => { console.log('attachment load err:' + e) }}
            onLoadSuccess={(pdf) => { this.setState({ numPages: pdf.numPages }) }}
            onSourceError={(e) => { console.log('attachment source err:' + e) }}
            onSourceSuccess={() => { }}
          >
            {this.getPages()}
          </Document>
        </div>
      </div>
    )
  }

}

export default PDFViewer;