//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import 'react-image-crop/dist/ReactCrop.css';

//icons
import Upload from "@material-ui/icons/CloudUpload";

//components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";

//file chooser
import Files from 'react-files'

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    dropzoneContainer: {
        minHeight:"360px", width:"96%", backgroundColor:"#8fc088", padding:"20px", borderRadius:"6px"
    }
};

class OdCsvUpload extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
      };

      this.onFilesChange = this.onFilesChange.bind(this);
      this.onFilesError = this.onFilesError.bind(this);
      
    }

    handleClickOpen(modal) {
      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    onFilesChange (files) {
        
        // hide the file drop area
        document.getElementById('divFileUpload').style.display = 'none';

        var promises = [];
        this.fileReader = new FileReader();
        var content = "actionCode, customerID, addrSeq, addrKey, name, address, address2, city, state, zip \n";

        db.getCustomers().then(customers => {

            console.log("customers.length");
            console.log(customers.length);

            customers.forEach(customer => {
                promises.push(db.getAddressesByCustomer(customer.id));
            });

            Promise.all(promises).then((results) => {

                //console.log("results.length");
                //console.log(results.length);
                
                this.fileReader.onload = (event) => {

                    var text = event.target.result;
                    var lines = text.split(/[\r\n]+/g);
                    //console.log(lines);
        
                    lines.forEach(line => {
        
                        var columns = line.split(',');
                        
                        var id = columns[3];

                        for(var i = 0; i < results.length; i++) {
                            for(var j = 0; j < results[i].length; j++) {
            
                                if(results[i][j].address_id === id) {

                                    if(results[i][j].shipToName.replace(/[.]+/g, "").toUpperCase() !== columns[4] 
                                        || results[i][j].address.replace(/[,.\r\n]+/g, "").toUpperCase() !== columns[5] 
                                        ) {
                                            if(results[i][j].zip)
                                                if(results[i][j].zip.length > 0)
                                                    if(results[i][j].shipToName.length > 0) {

                                                        //console.log("'" + results[i][j].shipToName.replace(/[.]+/g, "").toUpperCase() + "' - '" + columns[4] + "'")
                                                        content = content + columns[0] + "," + columns[1] + "," + columns[2] + "," + columns[3] + ', ' + results[i][j].shipToName.replace(/[.]+/g, "").toUpperCase() + ', ' + results[i][j].address.replace(/[,.\r\n]+/g, "").toUpperCase() + ', ' + results[i][j].street2.replace(/[,.\r\n]+/g, "").toUpperCase() + ', ' + results[i][j].city.replace(/[,.]+/g, "").toUpperCase() + ', ' + results[i][j].state.replace(/[,.]+/g, "").toUpperCase() + ', ' + results[i][j].zip + '\n';
                                                        
                                                    }
                                    }
                                    id=89;
                                    break;
                                }
                            }
                        }

                    });

                    //console.log(content);  

                    let filename = "addresses.csv";
                    let contentType = "text/csv;charset=utf-8;";
                    
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
                        navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        var a = document.createElement('a');
                        a.download = filename;
                        a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    
                };

                this.fileReader.readAsText(files[0]);     
            });
        });

             
    }
     
    onFilesError (error, file) {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <IconButton
                    aria-label="Upload Office Depot Addresses and Find Changes"
                    onClick={() => this.handleClickOpen("modal")}>
                    <Upload className={ classes.tableActionButtonIcon + " " + classes.edit } style={{color:"white"}} />
                </IconButton>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    maxWidth="md"
                    fullWidth
                    open={this.state.modal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("modal")}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                        style={{backgroundColor:"#efefef"}} >
                        <h4 className={classes.modalTitle}>Office Depot Address Checker</h4>
                    
                    </DialogTitle>
                    <DialogContent>
                        
                        <div id="divFileUpload" className="files" >
                            <Files
                                className='files-dropzone'
                                onChange={this.onFilesChange}
                                onError={this.onFilesError}
                                accepts={['text/csv']}
                                maxFiles={10}
                                maxFileSize={5000000}
                                minFileSize={0}
                                clickable
                                style={styles.dropzoneContainer}
                            >
                                Drop your CSV here or click to upload
                            </Files>
                        </div>
                        
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter }
                        style={{backgroundColor:"#efefef"}}>
                        <Button onClick={() => this.handleClose("modal")}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                
                            }}
                            color="success">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(modalStyle)(OdCsvUpload);
