import { storage } from './firebase';

export const images = storage.ref('images');


export const uploadImage = (fileName, blob) => {

    var fileRef = images.child(fileName);

    return new Promise((resolve, reject) => {
        fileRef.put(blob).then(function(snapshot) {
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                resolve( downloadURL );
            });
        })
    });
  }

  export const attachments = storage.ref('attachments');

