//react
import React from 'react';

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

//components
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

//kekaha
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import RulesExceptionsEditor from "components/RulesExceptionsEditor/RulesExceptionsEditor.jsx";
import CopyRules from "components/CopyRules/CopyRules.jsx";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Profile from "@material-ui/icons/PersonAdd";
import Visibility from "@material-ui/icons/Visibility";
import Pricing from "@material-ui/icons/CreditCard";

const uuidv4 = require('uuid/v4');

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function exportToCSV(exceptions) {
  let filename = "products.csv";
  let contentType = "text/csv;charset=utf-8;";

  var content = "Name, SKU, Product ID, Cost, Exception, List \n";
  for(var i = 0; i < exceptions.length; i++) {
    var product = exceptions[i].product;
    content = content + product.ProductName.replace(/,/g, "") + ', ' + skuList(product) + ', ' + product.ProductID  + ', ' + product.WholesalePrice + ', ' + exceptions[i].Price + ', ' + product.ListPrice + '\n';
  }

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(content))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement('a');
    a.download = filename;
    a.href = 'data:' + contentType + ',' + encodeURIComponent(content);
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

function skuList(product) {
  let res = "";
  product.Vendors.map(item => {
    if(item.sku) {
      if(res !== "")
        res += ", ";
      res += item.sku;
    }
    return item;
  })
  return res;
}
  
class ProfileEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      profile: { Name: "", id: uuidv4(), isNew: true, type: this.props.type },
      exceptions:[],
      rules: [],
    };

    this.newRule = this.newRule.bind(this);
    this.newRules = this.newRules.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.updateExceptions = this.updateExceptions.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  handleClickOpen(modal) {
    if(this.props.profile) {
      this.setState({profile: this.props.profile}, () => {
        this.getExceptions();
      });
    }

    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  getExceptions() {

    var rules = [];
    var exceptions = [];

    db.getExceptionsByProfile(this.state.profile.id).then(results => {
      
      var promises = [];
      for(var i = 0; i < results.length;i++) {
        if(!results[i].productID) {
          
          rules.push(results[i]);
        } else { 
          exceptions.push(results[i]);

          var promise = db.getProduct(results[i].productID);
          promises.push(promise);
        } 
      }

      rules.sort(this.byDescription);
      this.setState({rules : rules});
      
      Promise.all(promises).then((dbResults) => {

        for(var i = 0; i < exceptions.length; i++) {
          for(var j = 0; j < dbResults.length; j++) {
            if(exceptions[i].productID === dbResults[j].id) {
              exceptions[i].product = dbResults[j].data();
              break;
            }
          }
        }
        
        exceptions.sort(this.byProductID);
        this.setState({exceptions : exceptions});
        console.log(exceptions);
      });
    });
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  updateProfile(property, value) {
    var d = this.state.profile;
    d[property] = value;
    this.setState({profile: d});
  }

  saveProfile() {
  
    var d = this.state.profile;

    var isNew = d.isNew;
    delete d.isNew;

    db.updateProfile(d.id, d).then(doc => {
    
      // update rules
      for(var m=0; m < this.state.rules.length; m++) {
        var rule = this.state.rules[m];
        if(rule.isDeleted) {
          db.deleteExceptions(rule.id);
        } else {
          db.updateExceptions(rule.id, rule);
        }
      }
      
      // update exceptions
      for(var n=0; n < this.state.exceptions.length; n++) {
        var exception = this.state.exceptions[n];
        if((!exception.isHidden && this.state.profile.type === 'visibility') || exception.isDeleted) {
          db.deleteExceptions(exception.id);
          console.log('deleting exception: ' + exception.id);
        } else {
          console.log('saving exception')
          var product = exception.product;
          delete exception.product;
          db.updateExceptions(exception.id, exception);
          exception.product = product; // save as not to save product to DB
        }
      }
      
      if(isNew) { // i.e. if it's a new obj

        this.props.onCreated(d);
        
        // reset to blank
        this.setState( 
          { 
            profile: {
              Name: "", 
              id: uuidv4(), 
              isNew: true
            }, 
            exceptions: [], 
            rules: []
          });

      } else { // i.e. an existing profile

        this.props.onChanged(d);   
      }  
    
    });
  }

  newRule(rule) { // RulesExceptionEditor Event
    
    var rules = this.state.rules;
    rules.push(rule);
    this.setState({rules: rules});
  }

  newRules(newRules) { // event from copy rules control
    var rules = this.state.rules;
    var exceptions = this.state.exceptions;

    for(var i = 0; i < newRules.length; i++)
      if(newRules[i].product)
        exceptions.push(newRules[i]);
      else 
        rules.push(newRules[i]);

    rules.sort(this.byDescription);
    exceptions.sort(this.byProductID);

    this.setState({rules: rules});
    this.setState({exceptions: exceptions});
  }

  updateExceptions(exceptions) { // RulesExceptionEditor Event

    for(var i = this.state.exceptions.length - 1; i > -1 ; i--) {

      if(this.state.profile.type === 'visibility') {
        if(!this.state.exceptions[i].isHidden) {
          this.deleteException(this.state.exceptions[i]);
        }
      } 

      else if(this.state.profile.type === 'pricing') {
        if(Number(this.state.exceptions[i].Price) === Number(this.state.exceptions[i].product.ListPrice)) {    
          this.deleteException(this.state.exceptions[i]);
          console.log('pricing exception deleted')
        }
      }

    }

    this.setState({exceptions: exceptions});
  }

  deleteRule(rule) {
    var rules = this.state.rules;
    for(var i = 0; i < rules.length; i++) {
      if(rules[i].id === rule.id) {
        rules[i].isDeleted = true;
      }
    }
    this.setState({rules: rules});
  }

  deleteRules() {
    var rules = this.state.rules;
    for(var i = 0; i < rules.length; i++) {
      rules[i].isDeleted = true;
    }
    this.setState({rules: rules});
  }

  deleteException(exception) {
    var exceptions = this.state.exceptions;
    for(var i = 0; i < exceptions.length; i++) {
      if(exceptions[i].id === exception.id) {
        exceptions[i].isDeleted = true;
      }
    }
    this.setState({exceptions: exceptions});
  }

  deleteExceptions() {
    var exceptions = this.state.exceptions;
    for(var i = 0; i < exceptions.length; i++) {
      exceptions[i].isDeleted = true;
    }
    this.setState({exceptions: exceptions});
  }

  isDeleted(row) {
    if(row.isDeleted) {
      return "none";
    } else {
      return "table-row";
    }
  }

  compareName(a,b) {

    var aName = a.Name?.toString().toLowerCase();
    var bName = b.Name?.toString().toLowerCase();
  
    if (aName < bName)
      return -1;
    if (aName > bName)
      return 1;
    return 0;
  }
    
  trim(value, maxLength) {
    if(value.length > maxLength)
        return value.substring(0,maxLength) + "...";
    else
        return value;
  }

  getProductName(product) {
    if(product) 
      return this.trim(product.ProductName,69);
    else 
      return "MISSING PRODUCT INFORMATION";
  }

  getProductID(product) {
    if(product) 
      return product.ProductID;
    else 
      return "";
  }

  getRuleTypeDescriptor(type) {
    if(type === 'discount')
      return "% Discount";
    else if (type === 'margin')
      return "% of Margin"
    else if (type === 'cost')
      return "% Above Cost"
  }

  showEditIcon() {
    if(!this.props.type)
      return "block";
    else
      return "none";
  }

  showNewVisibility() {
    if(this.props.type === 'visibility')
      return "block";
    else
      return "none";
  }

  showNewPricing() {
    if(this.props.type === 'pricing')
      return "block";
    else
      return "none";
  }

  showRules() {
    if(this.state.rules.length > 0)
      return "block";
    else
      return "none";
  }

  showNoRules() {
    if(this.state.rules.length === 0)
      return "block";
    else
      return "none";
  }

  showExceptions() {
    if(this.state.exceptions.length > 0)
      return "block";
    else
      return "none";
  }

  showNoExceptions() {
    if(this.state.exceptions.length === 0)
      return "block";
    else
      return "none";
  }

  showPricing() {
    if(this.state.profile.type === 'pricing')
        return 'table-cell';
    else 
        return 'none';
  }

  rulesTitle() {
    if(this.state.profile.type === 'visibility') 
      return "Hidden Product Rules";
    else if (this.state.profile.type === 'pricing')
      return "Pricing Rules"
  }

  rulesSubtitle() {
    if(this.state.profile.type === 'visibility') 
      return "All products that fit the specified criteria will be hidden.";
    else if (this.state.profile.type === 'pricing')
      return "These pricing rules will be applied to all products that fit the specified criteria."
  }

  exceptionsTitle() {
    if(this.state.profile.type === 'visibility') 
      return "Hidden Products";
    else if (this.state.profile.type === 'pricing')
      return "Product Specific Pricing"
  }

  exceptionsSubtitle() {
    if(this.state.profile.type === 'visibility') 
      return "These specific products will always be hidden.";
    else if (this.state.profile.type === 'pricing')
      return "These product specific pricing adjustments will override any pricing rules that may also be applicable."
  }

  formattedCurrency(value) {
    if(!value)
      return "";
    else {
      return "$" + Number(value).toFixed(2);
    }
  }


  render() {

    const { classes } = this.props;

    return (
      <div>
        <div style={{display:this.showEditIcon()}}>
          <Tooltip
              id="tooltip-top-start"
              title={this.props.title}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              >
            <IconButton
              aria-label="Edit"
              onClick={() => this.handleClickOpen("modal")}>
              <Edit className={ classes.tableActionButtonIcon + " " + classes.edit } />
            </IconButton>
          </Tooltip>
        </div>
        <Button style={{display:this.showNewVisibility()}} onClick={() => this.handleClickOpen("modal")} ><Visibility /> New Visibility Profile </Button>
        <Button style={{display:this.showNewPricing()}} onClick={() => this.handleClickOpen("modal")} ><Pricing />New Pricing Profile </Button>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">

          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
            style={{backgroundColor:"#efefef"}} >

            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.modalTitle}>Edit Customization Profile</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                
              </GridItem>
            </GridContainer>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
            style={{backgroundColor:"#efefef"}}>
            <CustomTabs
              headerColor="success"
              tabs={[
                {
                  tabName: "General",
                  tabIcon: Profile,
                  tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={7} >
                          <CustomInput
                            labelText="Name"
                            value={this.state.profile.Name}
                            inputProps={{
                                placeholder: "Name"
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateProfile("Name", e.target.value)}
                          />
                        </GridItem>
                        
                        <GridItem xs={12} sm={5}  style={{textAlign:"right"}} >
                          <br />
                          <div style={{display:"inline-block"}}>
                            <RulesExceptionsEditor button profileID={this.state.profile.id} type={this.state.profile.type} onChange={this.updateExceptions} onNewRule={this.newRule} rules={this.state.rules} exceptions={this.state.exceptions} /> 
                          </div>
                          <div style={{display:"inline-block"}}>
                            <CopyRules 
                              type={this.state.profile.type}
                              profileID={this.state.profile.id}
                              title="Copy From Customer" 
                              text="All rules and product exceptions from the chosen customer will be imported to this profile." 
                              onChange={this.newRules}
                              />
                            </div>
                        </GridItem>
                        
                        <GridItem xs={12} sm={12} md={12}>
                          <br />
                          <h4>{this.rulesTitle()}</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            {this.rulesSubtitle()}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{display:this.showRules()}}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Filter</TableCell>
                                <TableCell style={{display:this.showPricing() }} >Type</TableCell>
                                <TableCell style={{padding:"6px", width:"23px", textAlign:"right"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL RULES? This action cannot be undone." confirmed={() => this.deleteRules()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.rules.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell>
                                  {value.description}
                                </TableCell>
                                <TableCell style={{display:this.showPricing() }} >
                                  {value.percentage}{this.getRuleTypeDescriptor(value.ruleType)}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}>   
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this rule? This action cannot be undone." confirmed={() => this.deleteRule(value)} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>
                        <GridItem xs={12} style={{color:"maroon", fontSize:"12px", display:this.showNoRules()}}>
                          There are no rules defined.
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <br />
                          <h4>{this.exceptionsTitle()}</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            {this.exceptionsSubtitle()}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{display:this.showExceptions()}}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px"}}>ID</TableCell>
                                <TableCell style={{padding:"3px"}}>Product</TableCell>
                                <TableCell style={{textAlign:"right", padding:"3px", display:this.showPricing() }} >
                                  Cost
                                </TableCell>
                                <TableCell style={{textAlign:"right", padding:"3px", display:this.showPricing() }} >
                                  Custom Price
                                </TableCell>
                                <TableCell style={{maxWidth:"23px", padding:"3px", textAlign:"right"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL EXCEPTIONS? This action cannot be undone." confirmed={() => this.deleteExceptions()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.exceptions.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px",color:"#78be7b"}}>
                                  {this.getProductID(value.product)}
                                </TableCell>
                                <TableCell style={{padding:"3px"}}>
                                  {this.getProductName(value.product)}
                                </TableCell>
                                <TableCell style={{textAlign:"right", color:"#898989", padding:"3px", display:this.showPricing() }} >
                                    {this.formattedCurrency(value.product.WholesalePrice)}
                                </TableCell>
                                <TableCell style={{textAlign:"right", padding:"3px", display:this.showPricing() }} >
                                    {this.formattedCurrency(value.Price)}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"3px", width:"23px"}}> 
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to remove this rule and show the product? This action cannot be undone." confirmed={() => this.deleteException(value)} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>
                        <GridItem xs={12} style={{color:"maroon", fontSize:"12px", display:this.showNoExceptions()}}>
                          There are no exceptions defined.
                        </GridItem>
                      
                        <br /><br />
                      </GridContainer>
                  )
                },
              ]}
            />

          </DialogContent>
          
          <DialogActions
            className={classes.modalFooter }
            style={{backgroundColor:"#efefef"}}>
              <Button
                onClick={() => exportToCSV(this.state.exceptions)}
              >
                Export to CSV
              </Button>
              &nbsp;
              <Button
                onClick={() => this.handleClose("modal")}
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                onClick={() => {
                    this.saveProfile();
                    this.handleClose("modal");
                  }}
                color="success">
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

ProfileEditor = connect(mapStateToProps)(ProfileEditor);
export default withStyles(modalStyle)(ProfileEditor);