//react
import React from 'react';

//redux
import { connect } from 'react-redux';

//firebase
import { db } from 'assets/firebase';

//styles
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
//import styles from "assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx";

//core
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import IconButton from "@material-ui/core/IconButton";

//components
import CustomSelect from "components/Select/Select.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//kekaha
import BudgetableItemsEditor from "components/BudgetableItemsEditor/BudgetableItemsEditor.jsx";
import PricingEditor from "components/PricingEditor/PricingEditor.jsx";
import VisibilityEditor from "components/VisibilityEditor/VisibilityEditor.jsx";
import UserEditor from "components/UserEditor/UserEditor.jsx";
import AddressEditor from "components/AddressEditor/AddressEditor.jsx";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.jsx";
import StaticProfile from "components/StaticProfile/StaticProfile.jsx";

// @material-ui/icons
import Budget from "@material-ui/icons/PieChart"; 
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import School from "@material-ui/icons/LocationCityOutlined";
import Location from "@material-ui/icons/LocationOn";
import Pricing from "@material-ui/icons/CreditCard";
import UserAccount from "@material-ui/icons/AccountBox"
import Refresh from "@material-ui/icons/Refresh";

function Transition(props) {
    return <Slide direction="down" {...props} />;
  }
  
  class CustomerEditor extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: false,
        company: {
          pricingProfile: "none",
          visibilityProfile: "none",
          affiliate: ""
        },
        budgetExceptions: [],
        budgetRules: [],
        customer: props.customer,
        pricingExceptions:[],
        pricingRules: [],
        visibilityExceptions:[],
        visibilityRules: [],
        addresses: [],
        users: [],
        isTaxExempt: false,
        pricingProfiles: [],
        visibilityProfiles: [],
        affiliates:[]
      };      
      
      this.getTaxExemption = this.getTaxExemption.bind(this);

      this.getAffiliates = this.getAffiliates.bind(this);
      this.getBudgetExceptions = this.getBudgetExceptions.bind(this);
      this.getPricing = this.getPricing.bind(this);
      this.getProductVisibility = this.getProductVisibility.bind(this);

      this.newBudgetRule = this.newBudgetRule.bind(this); // single row updates
      this.newPricingRule = this.newPricingRule.bind(this);
      this.newVisibilityRule = this.newVisibilityRule.bind(this);

      this.newBudgetRules = this.newBudgetRules.bind(this); // bulk updates
      this.newPricingRules = this.newPricingRules.bind(this); 
      this.newVisibilityRules = this.newVisibilityRules.bind(this); 

      this.updateAddress = this.updateAddress.bind(this);
      this.updateBudgetExceptions = this.updateBudgetExceptions.bind(this);
      this.updatePricing = this.updatePricing.bind(this);
      this.updateVisibilityExceptions = this.updateVisibilityExceptions.bind(this);

      this.saveCustomer = this.saveCustomer.bind(this);
      this.updateCustomer = this.updateCustomer.bind(this);

      this.syncContacts = this.syncContacts.bind(this);
    }

    handleClickOpen(modal) {
      this.getProfiles();
      this.getAddresses();
      this.getBudgetExceptions();
      this.getCompany();
      this.getTaxExemption();
      this.getPricing();
      this.getProductVisibility();
      this.getUsers();
      this.getAffiliates();

      var x = [];
      x[modal] = true;
      this.setState(x);
    }

    handleClose(modal) {
      var x = [];
      x[modal] = false;
      this.setState(x);
    }

    byDescription(a,b) {

      var valueA = a.description;
      var valueB = b.description;
    
      if (valueA < valueB)
        return -1;
      if (valueA > valueB)
        return 1;
      return 0;
    }

    byProductID(a,b) {

      var valueA = a.product.ProductID;
      var valueB = b.product.ProductID;
    
      if (valueA < valueB)
        return -1;
      if (valueA > valueB)
        return 1;
      return 0;
    }
  
    getAddresses() {
      db.getAddressesByCustomer(this.state.customer.id).then(addresses => {
        this.setState({addresses: addresses })
      }); 
    }

    getAffiliates() {
      db.getAffiliatesByCustomer(this.state.customer.id).then(affiliates => {

        var objs = [];
        for(var i = 0; i < affiliates.length; i++)
          objs.push({ key: affiliates[i].id, label: affiliates[i].FirstName + " " + affiliates[i].LastName });

        this.setState({affiliates: objs});
      });
    }

    getBudgetExceptions() {
      var budgetRules = [];
      var budgetExceptions = [];

      db.getBudgetExceptionsByCustomer(this.state.customer.id).then(results => {
        
        var promises = [];
        for(var i = 0; i < results.length;i++) {
          if(!results[i].productID) {
            budgetRules.push(results[i]);
          } else { 
            budgetExceptions.push(results[i]);

            var promise = db.getProduct(results[i].productID);
            promises.push(promise);
          } 
        }

        budgetRules.sort(this.byDescription);
        this.setState({budgetRules : budgetRules}, () => {
          
        });
        
        Promise.all(promises).then((dbResults) => {

          for(var i = 0; i < budgetExceptions.length; i++) {
            for(var j = 0; j < dbResults.length; j++) {
              if(budgetExceptions[i].productID === dbResults[j].id) {
                budgetExceptions[i].product = dbResults[j].data();
                break;
              }
            }
          }
          
          budgetExceptions.sort(this.byProductID);
          this.setState({budgetExceptions : budgetExceptions},() => {
            
          });
        });
      });
    }

    getCompany() {
      db.getCompany(this.state.customer.id).then(company => {
        if(company)
          this.setState({company: company});
      });
    }

    getPricing() {

      var pricingRules = [];
      var pricingExceptions = [];

      db.getPricingByCustomer(this.state.customer.id).then(pricing => {

        var promises = [];
        for(var i = 0; i < pricing.length;i++) {
          if(!pricing[i].productID) {
            
            pricingRules.push(pricing[i]);
          } else { 
            pricingExceptions.push(pricing[i]);

            var promise = db.getProduct(pricing[i].productID);
            promises.push(promise);
          } 
        }

        pricingRules.sort(this.byDescription);
        this.setState({pricingRules : pricingRules}, () => {
          
        });
        
        Promise.all(promises).then((dbResults) => {

          for(var i = 0; i < pricingExceptions.length; i++) {
            for(var j = 0; j < dbResults.length; j++) {
              if(pricingExceptions[i].productID === dbResults[j].id) {
                pricingExceptions[i].product = dbResults[j].data();
                break;
              }
            }
          }

          // check for exceptions on deleted products
          for(var k = pricingExceptions.length -1; k > -1; k--) {
            if(typeof pricingExceptions[k].product === 'undefined') {
              console.log('removing defunct exception: ' + pricingExceptions[k].id);
              db.deletePricing(pricingExceptions[k].id);
              pricingExceptions.splice(k,1);
            }
          }
          
          pricingExceptions.sort(this.byProductID);
          this.setState({pricingExceptions : pricingExceptions},() => {
            
          });
        });
      });
    }

    getProductVisibility() {
      var visibilityRules = [];
      var visibilityExceptions = [];

      db.getVisibilityExceptionsByCustomer(this.state.customer.id).then(visibility => {

        var promises = [];
        for(var i = 0; i < visibility.length;i++) {
          if(!visibility[i].productID) {
            
            visibilityRules.push(visibility[i]);
          } else { 
            visibilityExceptions.push(visibility[i]);

            var promise = db.getProduct(visibility[i].productID);
            promises.push(promise);
          } 
        }

        visibilityRules.sort(this.byDescription);
        this.setState({visibilityRules : visibilityRules}, () => {
          
        });
        
        Promise.all(promises).then((dbResults) => {

          for(var i = 0; i < visibilityExceptions.length; i++) {
            for(var j = 0; j < dbResults.length; j++) {
              if(visibilityExceptions[i].productID === dbResults[j].id) {
                visibilityExceptions[i].product = dbResults[j].data();
                break;
              }
            }
          }
          
          visibilityExceptions.sort(this.byProductID);
          this.setState({visibilityExceptions : visibilityExceptions},() => {
            
          });
        });
      });
    }

    getProfiles() {
      var pricing = [];
      var visibility = [];
      
      for(var i = 0; i < this.props.profiles.length; i++) {
        var option = {key: this.props.profiles[i].id, label:this.props.profiles[i].Name}

        if(this.props.profiles[i].type === 'pricing')
          pricing.push(option);
        else if (this.props.profiles[i].type === 'visibility')
          visibility.push(option);
      }

      this.setState({pricingProfiles: pricing, visibilityProfiles: visibility});
    }

    getTaxExemption() {
            
      db.getTaxExemption(this.state.customer.id).then(exemption => {

        var isExempt = false;
        if(exemption)
          if(exemption.isExempt)
            isExempt = true;

        this.setState({isTaxExempt: isExempt});
      });
    }

    syncContacts() {
      console.log('sync contacts starting');

      var data = { id: this.state.customer.id }

      fetch("https://us-central1-kekaha-ebf1f.cloudfunctions.net/syncZohoCustomerContactsByCompany", {
          method: "POST", 
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then((response) => {
            if(response.ok) {
              console.log('sync contacts done');
              this.getUsers();
            } else {
              console.log("error1");  
              console.log(response);
            }
        })
        .catch((error) => {
            // If there is any error you will catch them here
            console.log("error2: " + error);
        });
    }

    getUsers() {

      db.getUsersByCustomer(this.state.customer.id).then(users => {

        db.getContactsByCustomer(this.state.customer.id).then(contacts => {

          // add contacts that may not have accounts setup yet
          for(var i = 0; i < contacts.length; i++) {
            var isFound = false;
            
            for(var j = 0; j < users.length; j++) {
              if(contacts[i].id === users[j].contactID) {
                users[j].isMatched = true;
                isFound = true;
                break;
              }
            }
            if(!isFound) { // contact does not have a user account
              users.push( 
                { 
                  Email: contacts[i].email, 
                  FirstName: contacts[i].first_name, 
                  LastName: contacts[i].last_name, 
                  isMatched: false, 
                  contactID: contacts[i].id, 
                  customerID: this.state.customer.id 
                } 
              );
            } 
          }

          this.setState({users: users});
        });
      });
    }


    // addresses

    updateAddress(address) { // handles page updates
      var addresses = this.state.addresses;
      for(var i = 0; i < addresses.length; i++) {
        if(addresses[i].address_id === address.address_id) {
          addresses[i] = address;
          break;
        }
      }
      this.setState({addresses: addresses});
    }

    saveAddress(address) { // comes from the page
  
      db.updateAddress(address.address_id, address).then(() => {
      
          this.updateAddress(address);   
      });
    }

    //budgets

    newBudgetRule(rule) {
      var budgetRules = this.state.budgetRules;
      budgetRules.push(rule);
      this.setState({budgetRules: budgetRules});
    }

    updateBudgetExceptions(exceptions) {
      
      for(var i = exceptions.length - 1; i > -1 ; i--) {
        if(!exceptions[i].isHidden) {
          this.deleteBudgetException(exceptions[i]);
        }
      }

      this.setState({budgetExceptions: exceptions});
    }

    deleteBudgetRule(rule) {
      var rules = this.state.budgetRules;
      for(var i = 0; i < rules.length; i++) {
        if(rules[i].id === rule.id) {
          rules[i].isDeleted = true;
        }
      }
      this.setState({budgetRules: rules});
    }

    deleteBudgetRules() {
      var rules = this.state.budgetRules;
      for(var i = 0; i < rules.length; i++) {
        rules[i].isDeleted = true;
      }
      this.setState({budgetRules: rules});
    }

    deleteBudgetException(exception) {
      var exceptions = this.state.budgetExceptions;
      for(var i = 0; i < exceptions.length; i++) {
        if(exceptions[i].id === exception.id) {
          exceptions[i].isDeleted = true;
        }
      }
      this.setState({budgetExceptions: exceptions});
    }

    deleteBudgetExceptions() {
      var exceptions = this.state.budgetExceptions;
      for(var i = 0; i < exceptions.length; i++) {
        exceptions[i].isDeleted = true;
      }
      this.setState({budgetExceptions: exceptions});
    }

    newBudgetRules(newRules) {
      var rules = this.state.budgetRules;
      var exceptions = this.state.budgetExceptions;

      for(var i = 0; i < newRules.length; i++)
        if(newRules[i].product)
          exceptions.push(newRules[i]);
        else 
          rules.push(newRules[i]);

      rules.sort(this.byDescription);
      exceptions.sort(this.byProductID);

      this.setState({budgetRules: rules});
      this.setState({budgetExceptions: exceptions});
    }

    //pricing 

    newPricingRule(rule) {
      var pricingRules = this.state.pricingRules;
      pricingRules.push(rule);
      this.setState({pricingRules: pricingRules});
    }

    updatePricing(pricing) {
      
      // remove any pricing rules that aren't different from the default 
      for(var j = pricing.length - 1; j > -1; j--) {
        if(Number(pricing[j].Price) === Number(pricing[j].product.ListPrice)) {    
          this.deletePricingAdjustment(pricing[j]);
        }
      }

      this.setState({pricingExceptions: pricing});
    }

    deletePricingRule(pricing) {
      var adjustments = this.state.pricingRules;
      for(var i = 0; i < adjustments.length; i++) {
        if(adjustments[i].id === pricing.id) {
          adjustments[i].isDeleted = true;
        }
      }
      this.setState({pricingRules: adjustments});
    }

    deletePricingRules() {
      var adjustments = this.state.pricingRules;
      for(var i = 0; i < adjustments.length; i++) {
        adjustments[i].isDeleted = true;
      }
      this.setState({pricingRules: adjustments});
    }

    deletePricingAdjustment(exception) {
      var exceptions = this.state.pricingExceptions;
      for(var i = 0; i < exceptions.length; i++) {
        if(exceptions[i].id === exception.id) {
          exceptions[i].isDeleted = true;
        }
      }
      this.setState({pricingExceptions: exceptions});
    }

    deletePricingAdjustments() {
      var exceptions = this.state.pricingExceptions;
      for(var i = 0; i < exceptions.length; i++) {
        exceptions[i].isDeleted = true;
      }
      this.setState({pricingExceptions: exceptions});
    }

    newPricingRules(newRules) {
      var rules = this.state.pricingRules;
      var exceptions = this.state.pricingExceptions;

      for(var i = 0; i < newRules.length; i++)
        if(newRules[i].product)
          exceptions.push(newRules[i]);
        else 
          rules.push(newRules[i]);

      rules.sort(this.byDescription);
      exceptions.sort(this.byProductID);

      this.setState({pricingRules: rules});
      this.setState({pricingExceptions: exceptions});
    }

    // visibility

    newVisibilityRule(rule) {
      var visibilityRules = this.state.visibilityRules;
      visibilityRules.push(rule);
      this.setState({visibilityRules: visibilityRules});
    }

    updateVisibilityExceptions(exceptions) {

      for(var i = this.state.visibilityExceptions.length - 1; i > -1 ; i--) {
        if(!this.state.visibilityExceptions[i].isHidden) {
          this.deleteVisibilityException(this.state.visibilityExceptions[i]);
        }
      }

      this.setState({visibilityExceptions: exceptions});
    }

    deleteVisibilityRule(rule) {
      var rules = this.state.visibilityRules;
      for(var i = 0; i < rules.length; i++) {
        if(rules[i].id === rule.id) {
          rules[i].isDeleted = true;
        }
      }
      this.setState({visibilityRules: rules});
    }

    deleteVisibilityRules() {
      var rules = this.state.visibilityRules;
      for(var i = 0; i < rules.length; i++) {
        rules[i].isDeleted = true;
      }
      this.setState({visibilityRules: rules});
    }

    deleteVisibilityException(exception) {
      var exceptions = this.state.visibilityExceptions;
      for(var i = 0; i < exceptions.length; i++) {
        if(exceptions[i].id === exception.id) {
          exceptions[i].isDeleted = true;
        }
      }
      this.setState({visibilityExceptions: exceptions});
    }

    deleteVisibilityExceptions() {
      var exceptions = this.state.visibilityExceptions;
      for(var i = 0; i < exceptions.length; i++) {
        exceptions[i].isDeleted = true;
      }
      this.setState({visibilityExceptions: exceptions});
    }

    newVisibilityRules(newRules) {
      var rules = this.state.visibilityRules;
      var exceptions = this.state.visibilityExceptions;

      for(var i = 0; i < newRules.length; i++)
        if(newRules[i].product)
          exceptions.push(newRules[i]);
        else 
          rules.push(newRules[i]);

      rules.sort(this.byDescription);
      exceptions.sort(this.byProductID);

      this.setState({visibilityRules: rules});
      this.setState({visibilityExceptions: exceptions});
    }

    // general save

    updateCompany(property, value) {
      if (property === "rebate_percentage" && (value < 0 || value > 30)) {
        return;
      }
      var obj = this.state.company;
      obj[property] = value;
      this.setState({company: obj});
    }

    updateCustomer(property, value) {
      var obj = this.state.customer;
      obj[property] = value;
      this.setState({customer: obj});
    }

    saveCustomer() {
    
      db.updateCustomer(this.state.customer.id, this.state.customer).then(doc => {
        
        db.updateCompany(this.state.customer.id, this.state.company).then(() => {

          this.props.onChange();

          // update tax exempt status
          db.updateTaxExemptions(this.state.customer.id, { isExempt: this.state.isTaxExempt });     
  
          //update budget rules
          for(var o=0; o < this.state.budgetRules.length; o++) {
            var budgetRule = this.state.budgetRules[o];
            if(budgetRule.isDeleted) {
              db.deleteDocument('BudgetExceptions', budgetRule.id);
            } else {
              delete budgetRule.product;
              db.updateDocument('BudgetExceptions', budgetRule.id, budgetRule);
            }
          }
  
          //update budgetExceptions
          for(var p=0; p < this.state.budgetExceptions.length; p++) {
            var budgetException = this.state.budgetExceptions[p];
            if((!budgetException.isHidden && budgetException.id) || budgetException.isDeleted) {
              db.deleteDocument('BudgetExceptions', budgetException.id);
            } else {
              var p3 = budgetException.product;
              delete budgetException.product;
              db.updateDocument('BudgetExceptions', budgetException.id, budgetException);
              budgetException.product = p3;
            }
          }
  
          //update pricing rules
          for(var i=0; i < this.state.pricingRules.length; i++) {
            var pricing = this.state.pricingRules[i];
            if(pricing.isDeleted) {
              db.deletePricing(pricing.id);
            } else {
              delete pricing.product;
              db.updatePricing(pricing.id, pricing);
            }
          }
  
          //update pricing exceptions
          for(var j=0; j < this.state.pricingExceptions.length; j++) {
            var pricingException = this.state.pricingExceptions[j];
            if(pricingException.isDeleted) {
              db.deletePricing(pricingException.id);
            } else {
              var p4 = pricingException.product;
              delete pricingException.product;
              db.updatePricing(pricingException.id, pricingException);
              pricingException.product = p4;
            }
          }
          
          //update visibilityRules
          for(var m=0; m < this.state.visibilityRules.length; m++) {
            var rule = this.state.visibilityRules[m];
            if(rule.isDeleted) {
              db.deleteVisibilityExceptions(rule.id);
            } else {
              db.updateVisibilityExceptions(rule.id, rule);
            }
          }
          
          //update visibilityExceptions
          for(var n=0; n < this.state.visibilityExceptions.length; n++) {
            var exception = this.state.visibilityExceptions[n];
            if((!exception.isHidden && exception.id) || exception.isDeleted) {
              db.deleteVisibilityExceptions(exception.id);
            } else {
              var p6 = exception.product;
              delete exception.product;
              db.updateVisibilityExceptions(exception.id, exception);
              exception.product = p6;
            }
          }
  
          if(!this.state.customer.id) { // i.e. if it's a new obj
  
            this.updateCustomer("id", doc.id);
            
            // reset to blank
            this.setState({customer:{
              Name: "",
              Description: ""
            }});
  
          } else { // i.e. an existing obj
        
          }
        });
      });
    }

    // utility / display
        
    trim(value, maxLength) {
      if(value.length > maxLength)
          return value.substring(0,maxLength) + "...";
      else
          return value;
    }

    isDeleted(pricing) {
      if(pricing.isDeleted) {
        return "none";
      } else {
        return "table-row";
      }
    }

    iconDisplay(pricing) {
      if(pricing.isDeleted) {
        return "none";
      } else {
        return "block";
      }
    }

    statusColumn(user) {
      if(user.isRegistered) {
        return <div style={{color:"green"}}>SIGNED UP</div>
      } else if(user.isMatched) {
        return <div style={{color:"green"}}>INVITED</div>
      } else {
        return <div style={{color:"gray"}}>NOT SIGNED UP</div>
      }
    }

    updateUser(user) {
      console.log('updated user:');
      console.log(user);
    }

    getProductName(product) {
      if(product) 
        return this.trim(product.ProductName,69);
      else 
        return "Missing Product Information (try reloading)";
    }

    render() {
      const { classes } = this.props;

      return (
        <div>
          
          <IconButton
            aria-label="Edit" style={{padding:"6px"}}
            onClick={() => this.handleClickOpen("modal")}>
            <Edit  />
          </IconButton>
          
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            fullScreen
            open={this.state.modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{backgroundColor:"#efefef"}} >

              <GridContainer direction="row">
                <GridItem xs={12} sm={12} md={6}>
                  <h4 className={classes.modalTitle}>Edit Customer</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} style={{textAlign:"right"}}>
                  
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
              style={{backgroundColor:"#efefef"}}>
             <CustomTabs
                headerColor="success"
                tabs={[
                  {
                    tabName: "General",
                    tabIcon: School,
                    tabContent: (
                    
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={12}>
                          <CustomInput
                            id="Name"
                            labelText="Name"
                            value={this.state.customer.customer_name}
                            inputProps={{
                                placeholder: "Name",
                                disabled: true
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={(e) => this.updateCustomer("Name", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={5}>
                          <CustomSelect
                            labelText="Custom Pricing Profile"
                            inputProps={{ placeholder: "Custom Pricing Profile" }}
                            formControlProps={{ fullWidth: true }}
                            onChange={(e) => this.updateCompany("pricingProfile", e.target.value)}
                            options={this.state.pricingProfiles}
                            value={this.state.company.pricingProfile}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={1}>
                          <br />
                          <StaticProfile company={this.state.company} customer={this.state.customer} />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <CustomSelect
                            labelText="Product Visibility Profile"
                            inputProps={{ placeholder: "Product Visibility Profile" }}
                            formControlProps={{ fullWidth: true }}
                            onChange={(e) => this.updateCompany("visibilityProfile", e.target.value)}
                            options={this.state.visibilityProfiles}
                            value={this.state.company.visibilityProfile}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <CustomInput
                            id="rebate_percentage"
                            labelText="Rebate Percentage(# from 0 to 30)"
                            value={this.state.company.rebate_percentage || ''}
                            inputProps={{
                              placeholder: "Rebate Percentage"
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                            type="number"
                            onChange={(e) => this.updateCompany("rebate_percentage", e.target.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                          <CustomSelect
                            labelText="Affiliate"
                            inputProps={{ placeholder: "Affiliate" }}
                            isRequired
                            formControlProps={{ fullWidth: true }}
                            onChange={(e) => this.updateCompany("affiliate", e.target.value)}
                            options={this.state.affiliates}
                            value={this.state.company.affiliate}
                          />
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName:"Budgets",
                    tabIcon: Budget,
                    tabContent: (
                      <GridContainer direction="row">
                        <GridItem xs={9} sm={6} md={4} style={{textAlign:"right"}}>
                          <BudgetableItemsEditor button customer={this.state.customer} onChange={this.updateBudgetExceptions} onNewRule={this.newBudgetRule} itemExceptions={this.state.budgetExceptions} /> 
                        </GridItem>
                        <GridItem xs={3} sm={6} md={8}>
                          
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Budget Tracking Rules</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            All products that match the rule will be ignored for budget purposes.   
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Filter</TableCell>
                                <TableCell style={{padding:"6px", width:"23px"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL BUDGET RULES? This action cannot be undone." confirmed={() => this.deleteBudgetRules()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.budgetRules.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell>
                                  {value.description}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                                  <div style={{display:this.iconDisplay(value)}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this rule? This action cannot be undone." confirmed={() => this.deleteBudgetRule(value)} />
                                  </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Excluded Products</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            These specific products will always be excluded from budget tracking.
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px"}}>ID</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell style={{maxWidth:"23px", padding:"3px"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL BUDGET EXCEPTIONS? This action cannot be undone." confirmed={() => this.deleteBudgetExceptions()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.budgetExceptions.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px",color:"#78be7b"}}>
                                  {value.product.ProductID}
                                </TableCell>
                                <TableCell>
                                  {this.trim(value.product.ProductName,69)}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"3px", width:"23px"}}> 
                                  <div style={{display:this.iconDisplay(value)}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to remove this product? This action cannot be undone." confirmed={() => this.deleteBudgetException(value)} />
                                  </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName: "Locations",
                    tabIcon: Location,
                    tabContent: (
                      
                        <GridContainer direction="row">
                          <GridItem xs={12} sm={12} md={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Ship To</TableCell>
                                  <TableCell>Street Address</TableCell>
                                  <TableCell>City</TableCell>
                                  <TableCell>State</TableCell>
                                  <TableCell>Postal</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {this.state.addresses.map((value,index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <AddressEditor address={value} onChanged={this.updateAddress} />
                                  </TableCell>
                                  <TableCell>
                                    {value.name}
                                  </TableCell>
                                  <TableCell>
                                    <input type="text" value={value.shipToName} placeholder="ship to name" 
                                          onChange={(e) => { value.shipToName=e.target.value; this.updateAddress(value); }} 
                                          onBlur={(e) => { this.saveAddress(value) }}
                                          className={classes.modalTextBox} style={{textAlign:"left"}} />
                                  </TableCell>
                                  <TableCell>
                                    {value.address}
                                  </TableCell>
                                  <TableCell>
                                    {value.city}
                                  </TableCell>
                                  <TableCell>
                                    {value.state}
                                  </TableCell>
                                  <TableCell>
                                    {value.zip}
                                  </TableCell>
                                </TableRow>
                              ))}
                              </TableBody>
                            </Table>
                          </GridItem>
                        </GridContainer>
                    )
                  },
                  {
                    tabName:"Products",
                    tabIcon: Visibility,
                    tabContent: (
                      <GridContainer direction="row">
                        <GridItem xs={9} sm={6} md={4} >
                          <VisibilityEditor button customer={this.state.customer} onChange={this.updateVisibilityExceptions} onNewRule={this.newVisibilityRule} visibilityExceptions={this.state.visibilityExceptions} /> 
                        </GridItem>
                        <GridItem xs={3} sm={6} md={8}>
                          
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Hidden Product Rules</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            All products that fit the specified criteria will be hidden.   
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Filter</TableCell>
                                <TableCell style={{padding:"6px", width:"23px"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL VISIBILITY RULES? This action cannot be undone." confirmed={() => this.deleteVisibilityRules()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.visibilityRules.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell>
                                  {value.description}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                                  <div style={{display:this.iconDisplay(value)}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this visibility rule? This action cannot be undone." confirmed={() => this.deleteVisibilityRule(value)} />
                                  </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Hidden Products</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            These specific products will always be hidden.
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px"}}>ID</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell style={{maxWidth:"23px", padding:"3px"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL VISIBILITY EXCEPTIONS? This action cannot be undone." confirmed={() => this.deleteVisibilityExceptions()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.visibilityExceptions.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px",color:"#78be7b"}}>
                                  {value.product.ProductID}
                                </TableCell>
                                <TableCell>
                                  {this.getProductName(value.product)}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"3px", width:"23px"}}> 
                                  <div style={{display:this.iconDisplay(value)}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to remove this rule and show the product? This action cannot be undone." confirmed={() => this.deleteVisibilityException(value)} />
                                  </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName: "Pricing",
                    tabIcon: Pricing,
                    tabContent: (
                      <GridContainer direction="row">
                        <GridItem xs={9} sm={6} md={4} style={{textAlign:"right"}}>
                          <Tooltip title="Add Pricing Adjustment" placement="left">
                            <PricingEditor button customer={this.state.customer} onChange={this.updatePricing} onNewRule={this.newPricingRule} pricingExceptions={this.state.pricingExceptions} /> 
                          </Tooltip>
                        </GridItem>
                        <GridItem xs={3} sm={6} md={8}>
                          
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Pricing Rules</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            These pricing rules will be applied to all products that fit the specified criteria.   
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Filter</TableCell>
                                <TableCell style={{textAlign:"right"}}>Discount</TableCell>
                                <TableCell style={{padding:"6px", width:"23px"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL PRICING RULES? This action cannot be undone." confirmed={() => this.deletePricingRules()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.pricingRules.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell>
                                  {value.description}
                                </TableCell>
                                <TableCell style={{textAlign:"right"}}>
                                  {value.discount}%
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"6px", width:"23px"}}> 
                                  <div style={{display:this.iconDisplay(value)}}>
                                  <Tooltip id="tooltip-top-start" title="Delete Pricing Rule" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this pricing rule? This action cannot be undone." confirmed={() => this.deletePricingRule(value)} />
                                  </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4>Product Specific Pricing</h4>
                          <div style={{fontSize:"12px", color:"#696969", marginTop:"-23px"}}>
                            These product specific pricing adjustments will override any pricing rules 
                            that may also be applicable.
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                              <TableCell style={{maxWidth:"69px", paddingRight:"3px"}}>ID</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell style={{textAlign:"right"}}>Price</TableCell>
                                <TableCell style={{maxWidth:"23px", padding:"3px"}}>
                                  <Tooltip id="tooltip-top-start" title="Delete" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete ALL PRICING EXCEPTIONS? This action cannot be undone." confirmed={() => this.deletePricingAdjustments()} />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.pricingExceptions.map((value,index) => (
                              <TableRow key={index} style={{display:this.isDeleted(value)}}>
                                <TableCell style={{maxWidth:"69px", paddingRight:"3px",color:"#78be7b"}}>
                                  {value.product.ProductID}
                                </TableCell>
                                <TableCell>
                                  {this.trim(value.product.ProductName,54)}
                                </TableCell>
                                <TableCell style={{textAlign:"right"}}>
                                  ${value.Price}
                                </TableCell>
                                <TableCell className={classes.tableActions} style={{padding:"3px", width:"23px"}}> 
                                  <div style={{display:this.iconDisplay(value)}}>
                                  <Tooltip id="tooltip-top-start" title="Delete Pricing Adjustment" placement="top" classes={{ tooltip: classes.tooltip }} >
                                    <ConfirmDialog header="Confirm Delete" description="Are you sure you want to delete this pricing adjustment? This action cannot be undone." confirmed={() => this.deletePricingAdjustment(value)} />
                                  </Tooltip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            </TableBody>
                          </Table>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName: "Users",
                    tabIcon: UserAccount,
                    tabContent: (
                      
                        <GridContainer direction="row">
                          <GridItem xs={12}>
                            <Button onClick={this.syncContacts} color="gray">
                              <Refresh />Synchronize Contacts
                            </Button>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell>First Name</TableCell>
                                  <TableCell>Last Name</TableCell>
                                  <TableCell>Email Address</TableCell>
                                  <TableCell>Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {this.state.users.map((value,index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <UserEditor userAccount={value} onChange={this.updateUser} />
                                  </TableCell>
                                  <TableCell>
                                    {value.FirstName}
                                  </TableCell>
                                  <TableCell>
                                    {value.LastName}
                                  </TableCell>
                                  <TableCell>
                                    {value.Email}
                                  </TableCell>
                                  <TableCell>
                                    {this.statusColumn(value)}
                                  </TableCell>
                                </TableRow>
                              ))}
                              </TableBody>
                            </Table>
                          </GridItem>
                        </GridContainer>
                    )
                  }    
                ]}
              />

            </DialogContent>
            
            <DialogActions
              className={classes.modalFooter }
              style={{backgroundColor:"#efefef"}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.isTaxExempt}
                      onChange={(e) => this.setState({"isTaxExempt": e.target.checked})}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{ label: classes.label }}
                  label="Tax Exempt"
                />
                <Button onClick={() => this.handleClose("modal")} >
                  Cancel
                </Button>
                &nbsp;
                <Button
                  disabled={!this.state.company?.affiliate}
                  onClick={() => {
                      this.saveCustomer();
                      this.handleClose("modal");
                    }}
                  color="success">
                  Save
                </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
  
const mapStateToProps = state => {
  return state;
}

CustomerEditor = connect(mapStateToProps)(CustomerEditor);
export default withStyles(modalStyle)(CustomerEditor);