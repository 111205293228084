//algolia full text search
import algoliaSearch from 'algoliasearch';

var editClient = algoliaSearch('XNS8WHB8E8', '574afea5c78229385f133a4c2bc04530');
var productIndex = editClient.initIndex('Products');

function Product(id, isVisible, productID, name, description, manufacturerID, brandID, categoryID, subCat1ID, subCat2ID, subCat3ID, colorScentOther, size, casePack, listPrice, cost, photo, thumbnail) {
    this.id = id;
    this.productID = productID;
    this.name = name;
    this.description = description;
    this.manufacturerID = manufacturerID;
    this.brandID = brandID;
    this.categoryID = categoryID;
    this.subCat1ID = subCat1ID;
    this.subCat2ID = subCat2ID;
    this.subCat3ID = subCat3ID;
    this.colorScentOther = colorScentOther;
    this.casePack = casePack;
    this.size = size;
    this.cost = cost;
    this.listPrice = listPrice;
    this.photo = photo;
    this.thumbnail = thumbnail;
    this.isVisible = isVisible;
    this.attachments = [];
    this.microsite = false;
}

export const applySettings = () => {
    productIndex.setSettings({
        distinct: 1,
        attributeForDistinct: 'id',
        attributesForFaceting: ['filterOnly(manufacturerID)','filterOnly(isVisible)','filterOnly(categoryID)','filterOnly(subCat1ID)','filterOnly(subCat2ID)','filterOnly(subCat3ID)']
      });
}

export const deleteProduct = (product) => {
    
    productIndex.deleteObject(product.id, function(err, content) {
        if (err) throw err;    
    });
}

export const upsertProduct = (product) => {

    var p = new Product(product.id, product.IsVisible, product.ProductID, product.ProductName, product.BriefDescription, product.ManufacturerID, product.BrandID, product.CategoryID, product.SubCat1ID, product.SubCat2ID, product.SubCat3ID, product.colorScentOther, product.Size, product.CasePack, product.ListPrice, product.WholesalePrice);
    
    if(product.Photos) {
        if(product.Photos.length > 0) {
            for(var i = 0; i < product.Photos.length; i++) {
                if(product.Photos[i].isCover) {
                    p.photo = product.Photos[i].URL;
                }
                if (product.Photos[i].isThumb) {
                    p.thumbnail = product.Photos[i].URL;
                }
            }
        }
    }

    if(product.Attachments) {
        p.attachments = product.Attachments;
    } else {
        p.attachments = [];
    }

    if(product.Content) {
        p.Content = product.Content;
    }

    if(product.productIDNumber)
        p.productIDNumber = product.productIDNumber;

    if(product.Microsite)
        p.microsite = product.Microsite;
    

    p.objectID = product.id;

    console.log('product being saved to Alogolia');
    console.log(p);

    productIndex.addObject(p, function(err, content) {
        if(err) console.log('algolia save error');
        if (err) throw err;
        
    });
}

export const searchProducts = (query, manufacturers, category, subCat1, subCat2, subCat3) => {

    return new Promise(function(resolve, reject) {
        
        var filter = "categoryID:" + category 

        if(subCat1.length > 0){
            filter = filter + " AND ("
            for(var j = 0; j < subCat1.length; j++) {
                if(j === subCat1.length - 1) 
                    filter = filter + "subCat1ID:" + subCat1[j];
                else 
                    filter = filter + "subCat1ID:" + subCat1[j] + " OR ";
            }
            filter = filter + ")"
        }

        if(subCat2.length > 0){
            filter = filter + " AND ("
            for(var k = 0; k < subCat2.length; k++) {
                if(k === subCat2.length - 1) 
                    filter = filter + "subCat2ID:" + subCat2[k];
                else 
                    filter = filter + "subCat2ID:" + subCat2[k] + " OR ";
            }
            filter = filter + ")"
        }

        if(subCat3.length > 0){
            filter = filter + " AND ("
            for(var l = 0; l < subCat3.length; l++) {
                if(l === subCat3.length - 1) 
                    filter = filter + "subCat3ID:" + subCat3[l];
                else 
                    filter = filter + "subCat3ID:" + subCat3[l] + " OR ";
            }
            filter = filter + ")"
        }

        if(manufacturers.length > 0) {
            filter = filter + " AND ("
            for(var i = 0; i < manufacturers.length; i++) {
                if(i === manufacturers.length - 1)
                    filter = filter + "manufacturerID:" + manufacturers[i];
                else
                    filter = filter + "manufacturerID:" + manufacturers[i] + " OR ";
            }
            filter = filter + ")"
        }
        
    /*      
        filter = filter + " AND "        
        filter = filter + "isVisible:true"
    */

        productIndex.search({query: query, filters: filter, hitsPerPage: 600}).then(results => {
            
            var objs = [];
            
            for(var i = 0; i < results.hits.length; i++) {
                var obj = results.hits[i];
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const getProductsIDs = (query, manufacturers, category, subCat1, subCat2, subCat3, referenceData) => {

    return new Promise(function(resolve, reject) {
        
        var filter = "categoryID:" + category 

        if(subCat1.length > 0){
            filter = filter + " AND ("
            for(var j = 0; j < subCat1.length; j++) {
                if(j === subCat1.length - 1) 
                    filter = filter + "subCat1ID:" + subCat1[j];
                else 
                    filter = filter + "subCat1ID:" + subCat1[j] + " OR ";
            }
            filter = filter + ")"
        }

        if(subCat2.length > 0){
            filter = filter + " AND ("
            for(var k = 0; k < subCat2.length; k++) {
                if(k === subCat2.length - 1) 
                    filter = filter + "subCat2ID:" + subCat2[k];
                else 
                    filter = filter + "subCat2ID:" + subCat2[k] + " OR ";
            }
            filter = filter + ")"
        }

        if(subCat3.length > 0){
            filter = filter + " AND ("
            for(var l = 0; l < subCat3.length; l++) {
                if(l === subCat3.length - 1) 
                    filter = filter + "subCat3ID:" + subCat3[l];
                else 
                    filter = filter + "subCat3ID:" + subCat3[l] + " OR ";
            }
            filter = filter + ")"
        }

        if(manufacturers.length > 0) {
            filter = filter + " AND ("
            for(var i = 0; i < manufacturers.length; i++) {
                if(i === manufacturers.length - 1)
                    filter = filter + "manufacturerID:" + manufacturers[i];
                else
                    filter = filter + "manufacturerID:" + manufacturers[i] + " OR ";
            }
            filter = filter + ")"
        }
        
    /*      
        filter = filter + " AND "        
        filter = filter + "isVisible:true"
    */

        productIndex.search({query: query, filters: filter, attributesToRetrieve: [
            "id",
            "content"
          ], hitsPerPage: 500}).then(results => {
            
            var objs = [];
            
            for(var i = 0; i < results.hits.length; i++) {
                var obj = results.hits[i];
                obj.referenceData = referenceData;
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const getProductsByID = (id) => {

    return new Promise(function(resolve, reject) {
        
        productIndex.search({query: id, restrictSearchableAttributes: ['productID']}).then(result => {
            
            console.log("result.hits");
            console.log(result.hits);

            var objs = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const getProductsByName = (name) => {

    return new Promise(function(resolve, reject) {
        
        productIndex.search({query: name, restrictSearchableAttributes: ['name']}).then(result => {
            
            console.log("result.hits");
            console.log(result.hits);

            var objs = [];
            
            for(var i = 0; i < result.hits.length; i++) {
                var obj = result.hits[i];
                objs.push(obj);
            }

            resolve(objs);
        });
    });
}

export const clearCacheProducts = () => {
    productIndex.clearCache();
}
