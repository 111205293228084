//react
import React from "react";

//router
import { Redirect } from "react-router-dom";

//firebase
import { db } from 'assets/firebase';

//redux
import { connect } from 'react-redux';

// styles
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { TreeView, TreeItem } from '@material-ui/lab';
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Switch from "@material-ui/core/Switch";

//materialKit components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSelect from "components/Select/Select.jsx";
import Invoice from "components/Invoice/Invoice.jsx";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function dateConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();

  var time = month + ' ' + date + ', ' + year;
  return time;
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  textBox: {
    borderStyle:"none", 
    backgroundColor:"#efefef", 
    padding:"6px", 
    height:"21px", 
    borderRadius:"6px", 
    fontSize:"12px", 
    width: 100
  },
  iconButton: {
    padding: 4,
    marginLeft: 2
  }
};

class RebatesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      isLoading: true,

      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      rebateOnly: true,
      customers: [],

      totalRebates: 0
    }
  }

  componentWillMount() {
    this.refreshInvoices();
  }

  refreshInvoices() {
    this.setState({isLoading: true});
    const { year, month } = this.state;
    const monthStr = Number(month) < 10 ? `0${month}` : month;
    const startDate = `${year}-${monthStr}-01`;
    const endDate = `${year}-${monthStr}-31`;
    const promises = [db.getCompanies(), db.getInvoicesForRebates(startDate, endDate)]
    Promise.all(promises).then(results => {
      const companies = results[0]
      const invoices = results[1]
      let customers = [];
      this.props.customers.forEach(customer => {
        const company = companies.find(c => c.id === customer.id)
        const customerInvoices = []
        let customerTotal = 0;
        invoices.forEach(invoice => {
          if (invoice.customer_id === customer.id) {
            const rebates_amount = company ? this.getRebatesAmount(invoice, company) : 0;
            invoice.rebates_amount = rebates_amount;
            customerTotal += rebates_amount;
            customerInvoices.push(invoice);
          }
        })
        customers.push({
          ...customer,
          invoices: customerInvoices,
          total_rebates: customerTotal,
          company: company,
        })
      })
      this.setState({ customers, isLoading: false });
    })
  }

  showLoading() {
    if (this.state.isLoading) {
      return "block";
    } else {
      return "none";
    }
  }

  getYearOptions() {
    const options = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 10; i++) {
      options.push({ key: currentYear - i, label: currentYear - i })
    }
    return options;
  }

  getMonthOptions() {
    const options = [];
    for (let i = 1; i < 13; i++) {
      options.push({ key: i, label: i })
    }
    return options;
  }

  getCustomerOptions() {
    const options = [];
    this.props.customers.forEach(customer => {
      options.push({ key: customer.id, label: customer.customer_name })
    })
    return options;
  }

  changeFilter = fieldName => (e) => {
    const { value } = e.target;
    this.setState({
      [fieldName]: value
    }, () => {
      this.refreshInvoices()
    })
  }

  changeRebateCheck = (e) => {
    const { checked } = e.target;
    this.setState({
      rebateOnly: checked
    })
  }

  getRebatesAmount(invoice, company) {
    const { total, custom_field_hash, status } = invoice;
    const { rebate_percentage } = company;

    let rebate = custom_field_hash && custom_field_hash.cf_rebate_unformatted;

    if (!rebate_percentage || status !== 'paid' || !rebate) {
      return 0;
    }

    return total * Number(rebate_percentage) / 100
  }

  downloadPDF() {
    var url = 'https://us-central1-kekaha-ebf1f.cloudfunctions.net/getPDF';

    const { customers } = this.props;
    const { customerID, year, month, invoices } = this.state;
    const monthStr = month.length === 1 ? `0${month}` : month;
    const selectedCustomer = customers.find(customer => customer.id === customerID)

    var html = '<html>';
    html = html + '<head>';
    html = html + '<style>';
    html =
      html +
      'h2 {font-size:17px; font-family:arial, helvetica, sans-serif; font-weight:600}';
    html =
      html +
      '.lineItem {font-size:8px; line-height:13px; font-family:arial, helvetica, sans-serif; }';
    html = html + '</style>';
    html = html + '</head>';
    html = html + '<body>';

    html =
      html +
      "<img src='https://www.eccjan.com/ecclesia.jpg' style='height:123px; margin-left:-12px;' />";
    html = html + '<br />';
    html = html + "<div class='lineItem' >4100 Eldorado Pkwy</div>";
    html = html + "<div class='lineItem' >Ste. 100-#121</div>";
    html = html + "<div class='lineItem' >McKinney, Texas 75070</div>";
    html = html + "<div class='lineItem' >844 4ECCJAN (432-2526)</div>";
    html =
      html +
      "<div class='lineItem' style='color:gray;' >https://www.eccjan.com</div>";
    html = html + `<h2>Rebates (${year}-${monthStr})</h2>`;
    html =
      html +
      "<div class='lineItem' style='color:green' >Prepared for " +
      selectedCustomer.customer_name +
      ' by ' +
      this.props.user.firstName +
      ' ' +
      this.props.user.lastName +
      '</div>';
    html =
      html +
      "<div class='lineItem' style='color:green' >" +
      dateConverter(new Date().getTime()) +
      '</div>';
    html = html + '<br />';
    html = html + '<div>';
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; color: gray;'>Number</div>";
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; color: gray;'>Issue Date</div>";
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; color: gray;'>Paid Date</div>";
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; color: gray;'>Due Date</div>";
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; color: gray;'>Paid Amount</div>";
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; color: gray; text-align:right'>Total</div>";
    html = html + '</div>';
    html = html + '<hr />';

    invoices.forEach(invoice => {
      html = html + '<div>';
      html =
        html +
        "<div class='lineItem' style='width:80px; display: inline-block;'>" +
        invoice.invoice_number +
        '</div>';
      html =
        html +
        "<div class='lineItem' style='width:80px; display: inline-block;'>" +
        invoice.date +
        '</div>';

      const postedDate = invoice.status === 'paid' ? invoice.posted_date : '';
      html =
        html +
        "<div class='lineItem' style='width:80px; display: inline-block;'>" +
        postedDate +
        '</div>';

      html =
        html +
        "<div class='lineItem' style='width:80px; display: inline-block;'>" +
        invoice.due_date +
        '</div>';
      const paidAmount = invoice.status === 'paid' ? `$${numberWithCommas(invoice.total.toFixed(2))}` : '$0.00'
      html =
        html +
        "<div class='lineItem' style='width:80px; display: inline-block;'>" +
        paidAmount +
        '</div>';

      html =
        html +
        "<div class='lineItem' style='width:80px; display: inline-block; text-align:right'>" +
        `$${numberWithCommas(invoice.rebates.toFixed(2))}` +
        '</div>';
      html = html + '</div>';

    })

    html = html + '<div>';
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600;'></div>";
    html =
      html +
      "<div class='lineItem' style='width:321px; display: inline-block; font-weight:600;'></div>";
    html =
      html +
      "<div class='lineItem' style='width:80px; display: inline-block; font-weight:600; text-align:right'>Total: $" +
      numberWithCommas(this.state.totalRebates.toFixed(2)) +
      '</div>';
    html = html + '</div>';

    html = html + '</body>';
    html = html + '</html>';

    var data = {
      html: html,
    };

    fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.blob();
        } else {
          console.log('error1');
          console.log(response);
        }
      })
      .then((blob) => {
        this.downloadFile(blob, `rebates-${selectedCustomer.customer_name}-${year}-${monthStr}.pdf`);
      })
      .catch((error) => {
        // If there is any error you will catch them here
        console.log('error getting PDF for rebates: ' + error);
      });
  }

  downloadFile(blob, fileName) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
  }

  render() {
    const { classes } = this.props;

    if (!this.props.user.authenticated) {
      return <Redirect to='/login' />
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={11} md={10} style={{display: 'flex', alignItems: 'center'}}>
                  <h4 className={classes.cardTitleWhite}>Rebates</h4>
                </GridItem>
                <GridItem xs={12} sm={1} md={2} style={{ textAlign: "right" }}>
                  {this.state.invoices.length > 0 && (
                    <Tooltip
                      title="Download"
                      placement="top"
                    >
                      <IconButton style={{color: 'white'}} onClick={() => this.downloadPDF()}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={false} sm={false} md={6}>
                  &nbsp;
                </GridItem>
                <GridItem xs={12} sm={12} md={3} >
                  <CustomSelect
                    labelText="Year"
                    inputProps={{ placeholder: "Year" }}
                    formControlProps={{ fullWidth: true, disabled: this.state.isLoading }}
                    onChange={this.changeFilter("year")}
                    options={this.getYearOptions()}
                    value={this.state.year}
                    dis
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} >
                  <CustomSelect
                    labelText="Month"
                    inputProps={{ placeholder: "Month" }}
                    formControlProps={{ fullWidth: true, disabled: this.state.isLoading }}
                    onChange={this.changeFilter("month")}
                    options={this.getMonthOptions()}
                    value={this.state.month}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} style={{display: 'none', justifyContent: 'center'}} >
                  
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.rebateOnly}
                        onChange={(e) => this.changeRebateCheck(e)}
                      />
                    }
                    disabled={this.state.isLoading}
                    style={{marginTop: 38}}
                    label="Rebate"
                  />
                  
                </GridItem>
                {!this.state.isLoading && (
                  <GridItem xs={12}>                 
                    <GridContainer style={{padding:"3px", fontSize:"13px", backgroundColor:"#ffffff"}}>
                        <GridItem xs={9}>
                            Customer
                        </GridItem>
                        <GridItem xs={3} style={{textAlign:"right", fontSize:"13px", paddingRight:"20px"}}>
                            Total
                        </GridItem>
                        <GridItem xs={12}>
                            <hr />
                        </GridItem>
                    </GridContainer>

                    <TreeView 
                      className={classes.root}
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                    >
                      {this.state.customers
                        .filter(c => this.state.rebateOnly ? c.company && !!c.company.rebate_percentage : true)
                        .map((customer) => (
                        <TreeItem
                          nodeId={customer.id} 
                          style={{marginBottom:"13px"}}
                          label={
                            <GridContainer style={{marginLeft:"1px", padding:"3px", fontSize:"14px", borderBottom:"0.5px solid #efefef", backgroundColor:"#ffffff"}}>
                              <GridItem xs={9}>
                                {customer.customer_name}
                              </GridItem>
                              <GridItem xs={3} style={{textAlign:"right", fontSize:"14px"}}>
                                <div style={{marginRight:"10px"}}>
                                  ${numberWithCommas(customer.total_rebates.toFixed(2))}
                                </div>
                              </GridItem>
                            </GridContainer>
                          } 
                        >
                          {customer.invoices.map((invoice) => (
                            <TreeItem
                              nodeId={invoice.invoice_id} 
                              label={
                                <GridContainer style={{padding:"3px", color:"#545454", paddingLeft:"30px", fontSize:"14px", borderBottom:"0.5px solid #efefef"}}>
                                  <GridItem xs={4}>
                                    {invoice.date}
                                  </GridItem>
                                  <GridItem xs={4}>
                                    <Invoice invoice={invoice} link hideIcon />
                                  </GridItem>
                                  <GridItem xs={4} style={{textAlign:"right"}}>
                                    <div style={{marginRight:"10px"}}>
                                      ${numberWithCommas(invoice.rebates_amount.toFixed(2))}
                                    </div>
                                  </GridItem>
                                </GridContainer> 
                              } 
                            />
                          ))}                            
                        </TreeItem>
                      ))}
                    </TreeView>
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <div style={{ display: this.showLoading(), textAlign: "center", marginTop: "89px", marginBottom: "89px" }}>
                    <CircularProgress />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return state;
}

RebatesList = connect(mapStateToProps)(RebatesList);
export default withStyles(styles)(RebatesList);
